import products from "./products";
import overlays from "./overlays";
import store from "./store";
import models from "./models";
import rootScope from "./rootScope";
import broadcast from "./broadcast";
import dataLayer from "./dataLayer";
import detectDevice from "./detectDevice";
import utils from "./utils";

class OverlaysMenuController{


    constructor(view) {
        this.view = view;

        this.view.bindOverlayClick(this.selectOverlay);
        this.view.bindNotify(this.notify);
        this.view.bindOverlayClickPremium(this.selectOverlayPremium);

        store.on("usedLang", this.update.bind(this));
        store.on("overlay", this.changeOverlay.bind(this));
        broadcast.on("backPressed", ()=>{
            this.view.closeActiveMenu();
        })

        this.update();

    }

    changeOverlay(nameoverlay){

        const overlay = overlays[nameoverlay];
        if( overlay.parentOverlay )
            nameoverlay = overlay.parentOverlay;
        else if (overlay.buttonId)
            nameoverlay = overlay.buttonId;

        this.view.changeButton(nameoverlay, overlay.parentMenu.replace(/.+-/g, ""));
    }


    notify(open, menu){
        if (open){
            // Se lanza este evento
            const event = new CustomEvent('overlayMenuOpened', {detail: {menu: menu}});
            document.dispatchEvent(event);
            if (menu === "settings") {
                dataLayer.push({
                    'ga4_action_name': 'visor_settings',
                    'ga4_tag_name': 'click'
                })
            }
        }else{
            const event = new CustomEvent('overlayMenuClosed', {detail: {menu: menu}});
            document.dispatchEvent(event);
        }

        if (! open ) {
            broadcast.emit("closeLegend", true);
        }

        store.set("openMenus", open);

    }

    update(){



        this.view.updateElements(
            Object.keys(overlays).map(
                function(nameoverlay){
                    let children = null;
                    if (overlays[nameoverlay].childkey)
                        children = store.getAllowed(overlays[nameoverlay].childkey);

                    let icon = null;

                    if(overlays[nameoverlay].icon)
                        icon = (rootScope.resourcesServer ? rootScope.resourcesServer  : "")  + "css/svgs/" + overlays[nameoverlay].icon;

                    const ispremium = overlays[nameoverlay].premium && !store.get("premium");
                    const premiumurl =  (rootScope.resourcesServer ? rootScope.resourcesServer  : "")  + "css/svgs/diamante_premium.svg";

                    return {
                        ident: overlays[nameoverlay].ident,
                        parentMenu: overlays[nameoverlay].parentMenu,
                        hidden: overlays[nameoverlay].hidden,
                        buttonId: overlays[nameoverlay].buttonId,
                        children: children,
                        icon: icon,
                        fullname: overlays[nameoverlay].getName(),
                        self: overlays[nameoverlay].selfMenu,
                        premium: ispremium,
                        premiumurl: premiumurl
                    };
                }
            ),
            store.get("overlay")

        )

    }

    selectOverlayPremium(nameoverlay) {
        utils.openLinkPremium(nameoverlay)
    }
    selectOverlay(nameoverlay){

        const overlay = overlays[nameoverlay];

        if (overlay.childkey)
            nameoverlay = store.get(overlay.childkey);

        const nameproduct = models.getProduct(nameoverlay, store.get("product"));

        const product = products[nameproduct];

        if(product.checkOverlayAvailability(nameoverlay)) {
            store.set("overlay", nameoverlay);
            dataLayer.push({
                'ga4_action_name': 'visor_overlay_'+nameoverlay,
                'ga4_tag_name': 'click'
            })
            if (overlay.childkey)
                store.set(overlay.childkey, nameoverlay);
            return true;
        }
        else {
            const event = new CustomEvent('ShowMessage', {detail: product.getOverlayWarning(nameoverlay)});
            document.dispatchEvent(event);
            return false;
        }

    }

}


export default OverlaysMenuController;

// import LevelSwitchController from "../app/LevelSwitchController";


class RangeView {
    private readonly rangeView: HTMLDivElement | null | undefined;
    private readonly inputbar: HTMLInputElement | null | undefined;
    private readonly output: HTMLOutputElement | null | undefined;
    private opciones:{text: string, value: string, selected: boolean}[] | null | undefined;
    private selectElement: ((value: (string | null)) => any) | undefined ;

    constructor(rangeViewElement:HTMLElement){
        this.rangeView =  <HTMLDivElement> rangeViewElement;
        if (this.rangeView) {
            this.inputbar = this.rangeView.getElementsByTagName("input")[0];
            this.output = this.rangeView.getElementsByTagName("output")[0];

            this.inputbar.addEventListener("input", this.change.bind(this));
        }


    }

    update(opciones:{text: string, value: string, selected: boolean}[]){
        this.opciones = opciones;

        let index = 0;
        for (const opcion of this.opciones) {
            if (opcion.selected)
                break;
            index++;
        }

        if (index >= opciones.length)
            index= opciones.length - 1;

        if ( this.opciones ) {
            this.inputbar?.setAttribute("max", (this.opciones.length - 1).toString());

            if (this.inputbar) {
                this.inputbar.max = (this.opciones.length - 1).toString();
                this.inputbar.value = index.toString();
            }

            if (this.output)
                this.output.innerText = this.opciones[index].text;
        }

    }

    bindSelect (handler: (value:string|null) => any){
        this.selectElement = handler;
    }

    change(){
        if (this.opciones && this.inputbar && this.output) {
            const index = <number><unknown> this.inputbar?.value;
            this.output.innerText = this.opciones[index].text;

            if (this.selectElement)
                this.selectElement(this.opciones[index].value);

            const max =  Number(this.inputbar.max);
            const porcentaje = (index * (100 / max));

            this.inputbar.style.background = `linear-gradient(to right, #0074AD ${porcentaje}%, #d3d3d3 ${porcentaje}%)`;

        }

    }

    setDisabled(disabled: boolean){
        if(this.inputbar)
            this.inputbar.disabled = disabled;

        if (disabled){
            this.rangeView?.setAttribute("aria-disabled", "true");
            this.rangeView?.classList.add("disabled");
        }
        else {
            this.rangeView?.setAttribute("aria-disabled", "false");
            this.rangeView?.classList.remove("disabled");
        }
    }

}

export default RangeView;

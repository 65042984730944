import utils from "./utils";
import t from "./rootScope";

export default L.Map.extend({
    minZoom: 3,
    // bgColor: "#000",
    myMarkers: {
        icon: L.divIcon({
            className: "icon-dot",
            html: '<div class="pulsating-icon"></div>',
            iconSize: [10, 10],
            iconAnchor: [5, 5]
        }),
        pulsatingIcon: L.divIcon({
            className: "icon-dot",
            html: '<div class="pulsating-icon repeat"></div>',
            iconSize: [10, 10],
            iconAnchor: [5, 5]
        }),
        myLocationIcon: L.divIcon({
            className: "location-icon",
            // html: '<div class="pulsating-icon repeat"></div>',
            // iconUrl: "css/svgs/location.svg",
            iconSize: [32, 32],
            iconAnchor: [16, 32]
        }),
        webcamIcon: L.divIcon({
            className: "iconfont icon-webcam",
            iconSize: [10, 10],
            iconAnchor: [5, 5]
        }),
        pulsatingWebcamIcon: L.divIcon({
            className: "iconfont icon-webcam",
            html: '<div class="pulsating-icon repeat"></div>',
            iconSize: [10, 10],
            iconAnchor: [5, 5]
        })
    },
    center: function(location, animate) {
        void 0 === animate && (animate = false);
        const zoom = location.zoom ? utils.bound(location.zoom, this.minZoom, 20) : this.getZoom();
        if (location.paddingLeft || location.paddingTop) {
            const paddingLeft = location.paddingLeft || 0
                , paddingTop = location.paddingTop || 0
                , pcoords = this.project([location.lat, location.lon], zoom).subtract([paddingLeft / 2, paddingTop / 2])
                , coords = this.unproject(pcoords, zoom);
            this.setView(coords, zoom, {
                animate: animate
            })
        } else
            this.setView([location.lat, location.lon], zoom, {
                animate: animate
            });
        return this
    },
    ensurePointVisibleX: function(e, t, n) {
        const i = this.latLngToContainerPoint([e, t]).x;
        if (i < n)
            this.panBy([i - n, 0]);
    },
    ensurePointVisibleY: function(e, n, i) {
        const s = this.latLngToContainerPoint([e, n]).y;
        if (s > t.map.height - i)
            this.panBy([0, s - (t.map.height - i)]);
    },
    setZoomCenter: function(e, t) {
        this._zoomCenter = L.point(e, t);
        return this;
    },
    removeZoomCenter: function() {
        this._zoomCenter = void 0;
        return this;
    }
})

import utils from "./utils";
import Class from "./Class";
import store from "./store";
import http from "./http";
import scope from "./rootScope";
import calendar from "./Calendar";
import trans from "./trans";


// W.define("Product", ["utils", "rootScope", "http", "store", "Class", "Calendar", "Window", "rhMessage"], function(e, t, n, i, Class, a, r, o) {
export default Class.extend({
    ident: null,
    initPromise: null,
    maxTileZoom: 10,
    animationSpeed: 3600,
    fileSuffix: "png",
    JPGtransparency: 0,
    PNGtransparency: 0,
    dataQuality: "normal",
    betterDataQuality: [],
    global: true,
    animation: 1,
    modelName: void 0,
    modelResolution: void 0,
    provider: void 0,
    interval: void 0,
    forecastSize: 240,
    directory: void 0,
    labelsTemp: 1,
    imVersion: 2,
    logo: void 0,
    overlays: [],
    symbols: false,
    // copy: "",
    availability:{},

    // inicialización
    _init: function() {
        var e;
        this.productExpires = 0;
        this.minifest = {};

        e = {};
        e[2] = "{server}/img/maps/{directory}/<z>/<x>/<y>/{level}/{path}_{filename}.{fileSuffix}";
        e[3] = "{server}/im/v3.0/{directory}/{refTime}/{path}/wm_grid_257/<z>/<x>/<y>/{filename}-{level}.{fileSuffix}";

        this.pathGenerator = this.pathGenerator || e[this.imVersion];

        // this.keyGenerator = "{server}/img/maps-frontend/{directory}/{refTime}/<z>/<x>/<y>/{level}/{path}_{filename}.{fileSuffix}";
    },
    refTime: function() {
        return this.calendar ? this.calendar.refTime : ""
    }
    ,
    getUpdateTimes: function() {
        return this.calendar ? {
            refTime: this.calendar.refTimeTxt,
            minUpdate: this.calendar.updateTs
        } : {}
    }
    ,
    setExpireTime: function() {
        this.productExpires = Date.now() + 5 * utils.tsMinute
    }
    ,
    // Avanza un ts adelane o atrás según t
    moveTs: function(t, n) {
        // if (void 0 === n && (n = !1),
        //     n) {
        //
        //     var s = this.acTimes.indexOf(store.get("acTime"));
        //     if (s > -1)
        //         return s = utils.bound(s + (t ? 1 : -1), 0, this.acTimes.length),
        //             store.set("acTime", this.acTimes[s]),
        //             !0
        // } else {
        var calendar = this.calendar
            , paths = calendar.paths
            , timestamps = calendar.timestamps
            , indexpath = paths.indexOf(store.get("path"));

        if (indexpath > -1) {
            indexpath = utils.bound(indexpath + (t ? 1 : -1), 0, paths.length);
            store.set("timestamp", timestamps[indexpath]);
            return true;
        }
        // }
    }
    ,
    getTimeFrag: function() {
        return (new Date).toISOString().replace(/.*T(\d+):(\d+).*/, "$1$2")
    },
    loadMinifest: function(cache) {
        var hhmm = this.getTimeFrag()
            , server = this.server || scope.server
            , directory = this.directory
            , url;
        // if ( 3 === this.imVersion )
        //     url = server + "/im/v3.0/" + directory + "/info.json?" + hhmm;
        // else
        // url = server + "/imagenes.meteored.com/teselasdatos/" + directory + "/minifest.json?" + hhmm;
        url = utils.template(server + "/web/maps/" + directory + ".json?" + hhmm, {s: 'a'});

        // url = 3 === this.imVersion ? server + "/im/v3.0/" + a + "/info.json?" + i : server + "/" + a + "/minifest2.json?" + i;
        return http.get(url, {
            cache: ! cache
        })
    }
    ,
    open: function(options) {
        var product = this;
        const starting = typeof options !== "undefined" && options.starting;
        const forceChange = typeof options !== "undefined" && options.forceChange;
        // if (! scope.isMobileOrTablet )
        //     this.printLogo();

        if (this.loadingPromise)
            return this.loadingPromise;
        else if (Date.now() < this.productExpires &&  ( ! forceChange || starting) )
            return Promise.resolve(this.calendar);
        else {
            this.loadingPromise = new Promise(
                function(resolve) {
                    product.loadMinifest().then(
                        function(response) {
                            var minifest = response.data;
                            if ( minifest.refTime ) {
                                minifest.ref = minifest.refTime;
                                delete minifest.refTime;
                            }
                            if ( product.minifest.ref !== minifest.ref ) {
                                product.minifest = minifest;
                                product.calendar = calendar.instance({
                                    numOfHours: product.forecastSize,
                                    minifestFile: product.minifest
                                });
                                store.set("lastModified", new Date(product.minifest.ref).getTime())
                            }
                        }).catch(function(t) {
                            product.createBackupMinifest();
                            console.error("Product", "Minifest load/parsing failed", t);

                    }).then(function() {
                        product.setExpireTime();
                        product.loadingPromise = null;
                        resolve(product.calendar);
                    })
                }
            );

            return this.loadingPromise;

        }


    }
    ,
    close: function() {
        // t.isMobileOrTablet || this.removeLogo()
    },
    createBackupMinifest: function() {
        // if (this.imVersion >= 3) {
        //     var e = "Cannot get info.json from ims server.";
        //     throw r.instance({
        //         ident: "message",
        //         className: "bg-error",
        //         html: "<span>" + e + "</span>"
        //     }).open(),
        //         e
        // }
        this.minifest = {
            note: "!!!This is automatically generated fallback minifest!!!",
            ref: (new Date).toISOString().replace(/T.*$/, "T00:34:56Z"),
            update: (new Date).toISOString(),
            dst: [[3, 3, 144]]
        };
        this.calendar = calendar.instance({
            numOfHours: this.forecastSize,
            minifestFile: this.minifest
        });
    },
    isInBounds: function(e) {
        return !this.bounds || e.west > this.bounds.west && e.east < this.bounds.east && e.north < this.bounds.north && e.south > this.bounds.south
    },
    pointIsInBounds: function(e) {
        return !this.bounds || +e.lng > this.bounds.west && +e.lng < this.bounds.east && +e.lat < this.bounds.north && +e.lat > this.bounds.south
    },
    checkOverlayAvailability(nameoverlay){

        const datesavail = this.availability[nameoverlay];
        if (! datesavail )
            return true;
        else{
            const actualdate= new Date();
            const startdate = new Date(actualdate.getFullYear(), datesavail.start.month - 1, datesavail.start.day, 0, 0 );
            const enddate = new Date(actualdate.getFullYear(), datesavail.end.month - 1, datesavail.end.day, 0, 0 );

            enddate.setDate(enddate.getDate() + 1);

            return actualdate.getTime() >= startdate.getTime() && actualdate.getTime() < enddate;

        }

    },
    getOverlayWarning(nameoverlay){

        const datesavail = this.availability[nameoverlay];
        if (datesavail) {
            const actualdate = new Date();
            const startdate = new Date(actualdate.getFullYear(), datesavail.start.month - 1, datesavail.start.day, 0, 0);
            const enddate = new Date(actualdate.getFullYear(), datesavail.end.month - 1, datesavail.end.day, 0, 0);

            return trans['OVERLAYWARNING'].replace('_START_', startdate.toLocaleString(store.get("usedLang").replace('_','-'), { month: 'long', day: 'numeric' })).replace('_END_', enddate.toLocaleString(store.get("usedLang").replace('_','-'), { month: 'long', day: 'numeric' }));

        }

        return null;

    }

    // printLogo: function() {
    //     o.clear(),
    //     this.logo && (this.logoEl || (this.logoEl = document.createElement("div"),
    //         this.logoEl.innerHTML = this.logo,
    //         this.logoEl.className = "rh-absoluted rh-transparent"),
    //         o.insert(this.logoEl))
    // },
    // removeLogo: function() {
    //     this.logoEl && this.logo && o.remove(this.logoEl)
    // }
});


// Caché LRU: El algoritmo Least Recently Used (LRU) descarta primero los elementos menos usados recientemente


function lruCache(limite) {
    this.size = 0,
        this.limit = limite,
        this._keymap = {}
}

// Inserta un elemento en caché
lruCache.prototype.put = function(key, value) {
    // crea el elemento
    var element = {
        key: key,
        value: value
    };

    this._keymap[key] = element;

    if(this.tail){                  // El elemento más reciente pasa a ser el segundo más reciente
        this.tail.newer = element,
            element.older = this.tail
    }
    else                        // La caché está vacia
        this.head = element;

    this.tail = element;        // tail apunta al elemento recien insertado (más reciente)

    if (this.size === this.limit) // Si se llega al límite de tamaño se elimina el elemento menos reciente
        return this.shift();
    this.size++
};

// Transforma a JSON
lruCache.prototype.toJSON = function() {
    for (var json = [], element = this.head; element; )
        json.push({
            key: element.key,
            value: element.value
        }),
            element = element.newer;

    return json
};

// Se elimina el elemento más antiguo de la caché y se devuelve
lruCache.prototype.shift = function() {
    var element = this.head;    // Elemento más antiguo

    if(element){
        if(this.head.newer){    // head apunta al siguiente elemento más antiguo
            this.head = this.head.newer;
            this.head.older = void 0;

        } else
            this.head = void 0
        element.newer = element.older = void 0;
        delete this._keymap[element.key];           // Se elimina el elemento de la caché

    }
    return element
};

// Obtiene un elemento y lo coloca como el más reciente
lruCache.prototype.get = function(key, t) {
    var element = this._keymap[key];
    if (void 0 !== element){
        if(element !== this.tail){
            if (element.newer) {                     // Si apunta a un elemento más reciente
                element === this.head && (this.head = element.newer);  // Si era el más antiguo ahora el más antiguo es el siguiente
                element.newer.older = element.older; // El apuntador older del siguiente elemento apuntara al older del elemento
            }

            if( element.older)                       // Si apunta a un elemento más antiguo
                element.older.newer = element.newer; // El apuntador newer del más antiguo apunta al siguiente
            element.newer = void 0,
                element.older = this.tail;

            this.tail && (this.tail.newer = element), // Se inserta al final de la cola como elemento más nuevo
                this.tail = element;

        }

        return t ? element : element.value

    }
};

// Elimina un elemento elemento con key key
lruCache.prototype.remove = function(key) {
    var element = this._keymap[key];
    if (element){
        delete this._keymap[element.key];        // Borra el elemento de keymap

        if (element.newer && element.older){
            element.older.newer = element.newer;
            element.newer.older = element.older;
        } else{
            if(element.newer){
                element.newer.older = void 0;
                this.head = element.newer;
            } else if (element.older) {
                element.older.newer = void 0;
                this.tail = element.older;
            }
            else
                this.head = this.tail = void 0;

        }
        this.size--;
        return element.value;
    }
};

// Borra todos los elementos
lruCache.prototype.removeAll = function() {
    this.head = this.tail = void 0,
        this.size = 0,
        this._keymap = {}
}


export default lruCache;

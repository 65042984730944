/**
 * Modulo que devuelve un objeto con los textos traducidos de un idioma determinado
 *
 * @module trans
 */


import utils from "./utils";
import storage from "./storage";
import rootScope from "./rootScope";
import store from "./store";
import langEn from "../../../maps-frontend/assets/lang/en_US.json";
import router from "./router";
import broadcast from "./broadcast";


let langFilesTemplates = {
    main: {
        loaded: "en_US",
        filename: "lang/{lang}.json",
        test: "TITLE"
    }
    // ,
    // widgets: {
    //     filename: "lang/widgets/{lang}.json",
    //     test: "EMBED_SELECT_VIEW"
    // },
    // alerts: {
    //     filename: "lang/alerts/{lang}.json",
    //     test: "ALERT_DELETE"
    // },
    // settings: {
    //     filename: "lang/settings/{lang}.json",
    //     test: "S_LANG"
    // },
    // register: {
    //     filename: "lang/register/{lang}.json",
    //     test: "ALREADY_REGISTERED"
    // },
    // webcams: {
    //     filename: "lang/webcams/{lang}.json",
    //     test: "CAM_STREAM"
    // },
    // maps: {
    //     filename: "lang/maps/{lang}.json",
    //     test: "WM_MAP"
    // }
    }
    , trans = langEn
    , lang = "en_US"
    , storedLang = store.get("lang")                    // idioma almacenado
    , selectedLang = router.lang || rootScope.prefLang; // el que viene en la url o el por defecto

/**
 * Función que devuelve el texto traducido para un tag
 *
 * @param     {string}   tag   String que contiene el tag del que se va a obtener el texto traducido
 *
 * @returns   {string}         String con el texto traducido
 */
function translate(tag) {
    // return /\|/.test(tag) ? text.replace(/(\w+)\|(\w+):(\w+)/,
    //         function(str, n, i, s) {
    //             const traduccion = trans[n];
    //             return traduccion && s ? traduccion.replace(/{{[^}]+}}/g, s) : tag
    //         }) :
    return trans[tag] || tag;
}



/**
 * Función que carga el fichero json de un idioma
 *
 * @param    {string}           filekey  String que contiene la key de la plantilla del path del fichero
 * @param    {string}           lang     String que contiene el idioma
 *
 * @returns  {Promise<object>}           Devuelve el objeto con las traducciones a partir del json
 */
trans.loadLangFile = function(filekey, lang) {

    if ( ! lang )
        lang = store.get("usedLang");

    return new Promise(
        function(resolve, a) {
            if (langFilesTemplates[filekey].loaded !== lang) {
                let langfile = langFilesTemplates[filekey]
                    , filename = langfile.filename
                    , test = langfile.test;

                filename = utils.template(filename, {
                    lang: lang
                });

                const absoluteURL = !! rootScope.resourcesServer;
                // (rootScope.resourcesServer ? rootScope.resourcesServer  : "") +

                storage.getFile((absoluteURL ? (rootScope.resourcesServer + rootScope.assets + "/" + filename)  : filename) + `?v${rootScope.langversion}`, {
                    absoluteURL: absoluteURL,
                    test: test
                }).then(
                    function(langdata) {
                        Object.assign(trans, langdata);
                        langFilesTemplates[filekey].loaded = lang;
                        resolve(langdata);
                    }
                ).catch(a)
            } else
                resolve()
        }
    )
};

/**
 * Función que carga un fichero json de un idioma y traduce los textos de la web
 *
 * @param  {string}  lang   String que contiene el idioma
 */
function loadLangAndTranslate(lang) {

    // Filtra las plantillas langFiles que tienen el campo algo en el campo 'loaded'
    // y crea las promesas que cargan los ficheros de idioma
    const langFilesPromises = Object.keys(langFilesTemplates).filter(
        function(key) {
            return langFilesTemplates[key].loaded
        }
    ).map(
        function(keyfile) {
            return trans.loadLangFile(keyfile, lang)
        }
    );

    // Cuando se han cargado los ficheros se traduce el documento principal
    Promise.all(langFilesPromises).then(
        function() {
            trans.translateDocument(document.body);
            store.set("usedLang", lang);
            // document.documentElement.lang = lang;
        }
    )

}

const tagtypes = ["title", "alt", "placeholder", "t", "afterbegin", "beforeend", "tooltipsrc"];

/**
 * Función que traduce un elemento de la página web
 *
 * @param document  Objeto html que se va a traducir
 */
trans.translateDocument = function(document) {
    tagtypes.forEach(
        function(tagtype) {

            let node, translation, nodes = document.querySelectorAll("[data-" + tagtype + "]"), nnodes = nodes.length;

            for ( let index = 0; index < nnodes; index++ ) {
                node = nodes[index];

                translation = translate(node.dataset[tagtype]);

                switch ( tagtype ) {
                    case "t":
                        if ( /</.test(translation) )
                            node.innerHTML = translation
                        else
                            node.textContent = translation;
                        break;
                    case "alt":
                    case "title":
                    case "placeholder":
                        if ( void 0 !== node[tagtype] )
                            node[tagtype] = translation
                        break;
                    case "tooltipsrc":
                        node.dataset.tooltip = translation;
                        break;
                    case "afterbegin":
                        if ( Node.TEXT_NODE === node.firstChild.nodeType )
                            node.removeChild(node.firstChild);
                        node.insertAdjacentHTML(tagtype, translation);
                        break;
                    case "beforeend":
                        if ( Node.TEXT_NODE === node.lastChild.nodeType )
                            node.removeChild(node.lastChild);
                        node.insertAdjacentHTML(tagtype, translation)
                }
            }
        })
}



/**
 * Función que carga el idioma y traduce los elementos de la web
 *
 * @constructor
 */
const DOMContentLoaded = function() {
    // trans.translateDocument(document.body);
    storedLang = store.get("lang");

    // Si el idioma almacenado es valido se selecciona
    if ( storedLang !== "auto" && utils.isValidLang(storedLang) )
        selectedLang = storedLang;

    if ( selectedLang ) {
        if ( utils.isValidLang(selectedLang) )
            lang = selectedLang
        else {
            selectedLang = selectedLang.replace("-", "_");

            lang = utils.isValidLang(selectedLang) ? selectedLang : "en_US";

            if ( selectedLang !== lang )
                rootScope.missingLang = selectedLang
        }
    }


    loadLangAndTranslate(lang);
    store.on("lang", function(lang) {

        if( "auto" !== lang && lang !== store.get("usedLang") ) {
            loadLangAndTranslate(lang);
        }
    })
};

// // La traducción debe realizarse cuando los elementos de la web están cargados
// if ( "loading" !== document.readyState )
//     DOMContentLoaded();
// else
//     document.addEventListener("DOMContentLoaded", DOMContentLoaded);
broadcast.on("DOMContentLoaded", DOMContentLoaded);

export default trans;


/**
 * Clase que representa un botón para alternar el modo de pantalla completa.
 */
class FullScreenButtonView {

    /**
     * El botón que activará/desactivará el modo de pantalla completa.
     * @type {HTMLButtonElement}
     */
    private button: HTMLButtonElement;

    /**
     * El manejador para redibujar las capas del visor.
     * @type {Function}
     */
    private redrawLayers: (() => any) | null = null;

    /**
     * Crea una instancia de FullScreenButtonView.
     * @param {HTMLButtonElement} button - El botón que activará/desactivará el modo de pantalla completa.
     */
    constructor(button: HTMLButtonElement) {
        this.button = button;
        this.button.addEventListener("click", this._toggleFullScreen.bind(this));

        const logometeored : HTMLLinkElement = document.getElementById("logo_meteored") as HTMLLinkElement;
        logometeored.href = window.location.origin;
    }

    /**
     * Asigna un manejador para redibujar las capas del visor.
     * @param {Function} handler - El manejador para redibujar las capas del visor.
     */
    public bindRedrawLayers(handler: () => any) {
        this.redrawLayers = handler;
    }

    /**
     * Alterna el modo de pantalla completa.
     * Cambia la clase del contenedor para activar/desactivar el modo de pantalla completa,
     * y actualiza la visibilidad de los íconos de pantalla completa y salir de pantalla completa.
     * También muestra/oculta el logotipo de Meteored.
     * @private
     */
    private _toggleFullScreen() {
        const imgfullscreen = this.button.getElementsByClassName("fullscreen")[0];
        const imgexitfullscreen = this.button.getElementsByClassName("exitfullscreen")[0];
        const logometeored = document.getElementById("logo_meteored");
        const capturebutton = document.getElementById("capture_button");

        // Buscar el contenedor del visor
        const container = document.querySelector("#visor2") || document.querySelector("body");

        if (container?.classList.contains("fullscreen")) {
            container.classList.remove("fullscreen");

            // Ocultar el botón de pantalla completa y mostrar el de salir de pantalla completa
            imgfullscreen.classList.remove("hidden");
            imgexitfullscreen.classList.add("hidden");

            // Mostrar el logotipo de Meteored
            logometeored?.classList.add("hidden");

            // Esconder el botón de captura
            capturebutton?.classList.add("hidden");
        } else {
            container?.classList.add("fullscreen");

            // Mostrar el botón de pantalla completa y ocultar el de salir de pantalla completa
            imgfullscreen.classList.add("hidden");
            imgexitfullscreen.classList.remove("hidden");

            // Ocultar el logotipo de Meteored
            logometeored?.classList.remove("hidden");

            // Mostrar el botón de captura
            capturebutton?.classList.remove("hidden");
        }

        // Redibujar las capas del visor
        if (this.redrawLayers) {
            this.redrawLayers();
        }

    }

}

export default FullScreenButtonView;

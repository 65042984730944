// define("GlObj", [], function() {

    function GlObj (keepRefs, keepRefsShaders) {
        // void 0 === e && (e = !1),
        // void 0 === t && (t = !1),
        this.keepRefs = keepRefs;
        this.keepRefsShaders = keepRefsShaders;
        this.reset();
    }

    GlObj.getNextPowerOf2Size = function(value) {
        return 1 << Math.floor(Math.log2(value + value - 1))
    }

    GlObj.removeFromArray = function(value, array) {
        var index, selectedIndex = -1;

        for (index = 0; index < array.length; index++)
            if ( array[index] === value )
                selectedIndex = index;

        if ( selectedIndex > -1 )
            array.splice(selectedIndex, 1);
        return selectedIndex;

    }

    GlObj.littleEndian = (
        function () {
            var buffer = new ArrayBuffer(2);
            new DataView(buffer).setInt16(0, 256, true);
            return new Int16Array(buffer)[0] === 256;
        }
    )()

    GlObj.prototype = {
        reset: function() {
            this.framebuffers = [];
            this.buffers = [];
            this.shaders = [];
            this.programs = [];
            this.textures = [];
            this.gl = null;
            this.canvas = null;
        },
        create: function(canvas, attributes, id) {
            this._id = id || "noname";

            if ( ! this.gl && ! this.canvas ) {
                this.canvas = canvas;
                this.gl = canvas.getContext("webgl", attributes) || canvas.getContext("experimental-webgl", attributes);
                return this.gl;
            }
        },
        get: function() {
            return this.gl;
        },
        getCanvas: function() {
            return this.canvas;
        },
        createShader: function(source, isVertexShader, name) {
            const gl = this.get()
                , shader = gl.createShader(isVertexShader ? gl.VERTEX_SHADER : gl.FRAGMENT_SHADER);

            if ( this.keepRefsShaders )
                this.shaders.push(shader);

            gl.shaderSource(shader, source);
            gl.compileShader(shader);

            if ( !gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                var error = new Error(gl.getShaderInfoLog(shader));
                error.contextLost = gl.isContextLost();
                error.isVertexShader = isVertexShader;
                error.name = name;
                error.full = "ERROR compileShader! name: " + error.name + "; " + (error.isVertexShader ? "VS" : "FS") + "; " + (error.contextLost ? "contextLost" : "NOT contextLost") + "; msg: " + error.message;

                throw error;
            }
            return shader;
        },
        createProgramObj: function(vertexSource, fragmentSource, defines, name) {
            var i, error, gl = this.get(), program = gl.createProgram(),
                attributes = {
                    program: program
                }, headerSource = "";

            if (!program) {
                (error = new Error).full = "gl.createProgram() is null; name: " + name;
                throw error;
            }

            if (this.keepRefs)
                this.programs.push(program);

            program.name = name;
            if (defines && defines.length > 0)
                for (i = 0; i < defines.length; i++)
                    headerSource += "#define " + defines[i] + "\n";

            var d = this.createShader(headerSource + vertexSource, true, name)
                , u = this.createShader(headerSource + fragmentSource, false, name);

            gl.attachShader(program, d);
            gl.attachShader(program, u);
            gl.linkProgram(program);

            if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
                (error = new Error(gl.getProgramInfoLog(program))).contextLost = gl.isContextLost();
                error.name = name;
                error.full = "ERROR linkProgram! name: " + error.name + "; " + (error.contextLost ? "contextLost" : "NOT contextLost") + "; msg: " + error.message;

                throw error;
            }

            var nattrs = gl.getProgramParameter(program, gl.ACTIVE_ATTRIBUTES);  // Atributos del programa?

            for (i = 0; i < nattrs; i++) {
                var attr = gl.getActiveAttrib(program, i);
                attributes[attr.name] = gl.getAttribLocation(program, attr.name);
            }


            var nuniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS);

            for (i = 0; i < nuniforms; i++) {

                var uniform = gl.getActiveUniform(program, i);
                attributes[uniform.name] = gl.getUniformLocation(program, uniform.name);
            }
            return attributes;
        },
        deleteProgramObj: function(program) {
            GlObj.removeFromArray(program, this.programs);
            this.get().deleteProgram(program);
        },
        bindAttribute: function(buffer, attrLocation, size, type, normalize, stride, offset) {
            var gl = this.get();

            gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
            gl.enableVertexAttribArray(attrLocation);
            gl.vertexAttribPointer(attrLocation, size, type, normalize, stride, offset);
        },
        createTextureFromBase64: function(minFilter, magFilter, wrap, urlImage) {
            var globj = this
                , gl = this.get()
                , image = new Image
                , texture = this.createTexture2D(minFilter, magFilter, wrap, null, 1, 1, gl.RGBA);


            image.onload = function() {
                globj.resizeTexture2D(texture, image, image.width, image.height, gl.RGBA)
            };

            image.src = urlImage;

            return texture;
        },
        createTexture2D: function(minFilter, magFilter, wrap, image, width, height, r) {
            var gl = this.get()
                , texture = gl.createTexture();

            if (this.keepRefs)
                this.textures.push(texture);

            texture._width = width;
            texture._height = height;
            gl.bindTexture(gl.TEXTURE_2D, texture);

            this.setBindedTexture2DParams(minFilter, magFilter, wrap);

            return this.resizeTexture2D(texture, image, width, height, r);
        },
        deleteTexture2D: function(texture) {
            GlObj.removeFromArray(texture, this.textures);
            this.get().deleteTexture(texture);
        },
        bindTexture2D: function(texture, textureUnitIndex, uImageLoc) {
            var gl = this.get();

            gl.activeTexture(gl.TEXTURE0 + (textureUnitIndex || 0));
            gl.bindTexture(gl.TEXTURE_2D, texture);

            if (uImageLoc)
                gl.uniform1i(uImageLoc, textureUnitIndex)
        },
        resizeTexture2D: function(texture, image, width, height, format) {
            var gl = this.get();

            format = format || gl.RGBA;
            texture._width = width;
            texture._height = height;
            texture._format = format;

            gl.bindTexture(gl.TEXTURE_2D, texture);

            if ( null === image || image instanceof Uint8Array )
                gl.texImage2D(gl.TEXTURE_2D, 0, format, width, height, 0, format, gl.UNSIGNED_BYTE, image);
            else
                gl.texImage2D(gl.TEXTURE_2D, 0, format, format, gl.UNSIGNED_BYTE, image);

            gl.bindTexture(gl.TEXTURE_2D, null);

            return texture;
        },

        setBindedTexture2DParams: function(minFilter, magFilter, wrap) {

            var gl = this.get();

            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, minFilter);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, magFilter);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, wrap);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, wrap);

        },
        createBuffer: function(bufferdata) {
            var buffer = this.get().createBuffer();

            if (this.keepRefs)
                this.buffers.push(buffer);

            this.setBufferData(buffer, bufferdata);

            return buffer;
        },
        deleteBuffer: function(buffer) {
            GlObj.removeFromArray(buffer, this.buffers);
            this.get().deleteBuffer(buffer);
        },
        setBufferData: function(buffer, data) {
            var gl = this.get();

            gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
            gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
        },
        createIndexBuffer: function(bufferdata) {
            var gl = this.get()
                , buffer = gl.createBuffer();

            if (this.keepRefs)
                this.buffers.push(buffer);

            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer);
            gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, bufferdata, gl.STATIC_DRAW);

            return buffer;
        },
        createFramebuffer: function() {
            var frameBuffer = this.get().createFramebuffer();
            if ( this.keepRefs )
                this.framebuffers.push(frameBuffer);
            return frameBuffer
        },
        deleteFramebuffer: function(framebuffer) {
            GlObj.removeFromArray(framebuffer, this.framebuffers);
            this.get().deleteFramebuffer(framebuffer);
        },

        bindFramebuffer: function(framebuffer, texture) {
            var gl = this.get();
            gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
            if (texture)
                gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
        },
        release: function() {
            if (this.gl) {
                var gl = this.get();
                gl.flush();
                gl.finish();

                var i, object, length = this.textures.length;

                for (i = 0; i < length; i++) {
                    object = this.textures[i];
                    if (gl.isTexture(object))
                        gl.deleteTexture(object);
                }

                length = this.programs.length;
                for (i = 0; i < length; i++) {
                    object = this.programs[i];
                    if (gl.isProgram(object))
                        gl.deleteProgram(object);
                }

                length = this.shaders.length;
                for (i = 0; i < length; i++) {
                    object = this.shaders[i];
                    if (gl.isShader(object))
                        gl.deleteShader(object);
                }

                length = this.buffers.length;
                for (i = 0; i < length; i++) {
                    object = this.buffers[i];
                    if (gl.isBuffer(object))
                        gl.deleteBuffer(object);
                }

                length = this.framebuffers.length;
                for (i = 0; i < length; i++) {
                    object = this.framebuffers[i];
                    if (gl.isFramebuffer(object))
                        gl.deleteFramebuffer(object);
                }

                this.reset();
            }
        }
    }

    export default GlObj;
// });
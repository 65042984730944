

class DataLayer{
    constructor() {
        window.dataLayer = window.dataLayer || [];
        function gtag(){
            dataLayer.push(arguments)
        }
        gtag('js', new Date());
        gtag('config', 'GTM-P78ZFTD');
    }

    push(params){
        const paramsret = Object.assign({
            'event': 'ga4_event',
            'ga4_event_name': 'action'
        },params)
        dataLayer.push(paramsret)
    }
}


const dLayer = new DataLayer();

export default dLayer;

import layers from "./layers";
import overlays from "./overlays";
import store from "./store";
import products from "./products";
import broadcast from "./broadcast";
import dataLayer from "./dataLayer";


class LegendRenderController {
    constructor (view){
        this.view = view;

        this.view.bindPaintLegend(this.paintLegend.bind(this));

        // Vincula la notificación de la vista al controlador.
        this.view.bindNotify(this.notify);

        store.on("overlay", this.view.updateBars.bind(this.view));
        store.on("usedLang", this.view.updateBars.bind(this.view));
        broadcast.on("metricChanged", this.view.updateBars.bind(this.view));
        store.on("product", this.updateProductMessage.bind(this));

        // Solo se cierra la leyenda a través evento backPressed si es true
        this.closeLegend = true;

        // Si se abre algún menú se bloquea el cierre de la leyenda
        store.on("openMenus", (open)=>{
            if (open && this.view.isOpen())
                this.closeLegend = false;
        })

        // Se desbloquea el cierre de la leyenda si se le ha notificado
        broadcast.on("closeLegend", ()=>{
            this.closeLegend = true;
        });

        // Captura el evento backPressed
        broadcast.on("backPressed", ()=>{
            if( this.closeLegend )
                this.view.close();
        })

    }

    updateProductMessage(){
        const product = products[store.get("product")];
        if (product.copy && this.view.message) {
            this.view.message.innerText = product.copy;
            this.view.message.classList.add("visible");
        }
        else if (this.view.message){
            this.view.message.classList.remove("visible");
        }
    }


    paintLegend() {
        // const element = this.view.bars;

        const overlay = overlays[store.get("overlay")];

        const view= this.view;

        if (overlay.htmlLegend){
            this.view.regendLegendHTML(overlay.htmlLegend);
        }
        else if (overlay.legends) {
            let nlegend = 1; //, legends = this.legends;

            overlay.legends.forEach(function (layerName){

                const layer = layers[layerName];


                if (layer.m && layer.m.description) {
                    const metric = layer.l || layer.m;
                    const indexMetric = metric.description.indexOf(layer.m.metric) + 1;

                    layer.c.getColor();

                    view.renderLegend(layer.c.gradient,
                        layer.c.continuous,
                        nlegend,
                        metric.getLines().map(
                            function (line) {
                                return {value: line[0], text: line[indexMetric].toString()};
                            }
                        ),
                        layer.m.metric,
                        layer.getName());
                }

                nlegend++;

            });

        }
        else if ( overlay.m && overlay.m.description ) {

            const metric = overlay.l || overlay.m;
            const indexMetric = metric.description.indexOf(overlay.m.metric) + 1;

            // const indexMetric = overlay.m.description.indexOf(overlay.m.metric) + 1;

            overlay.c.getColor();

            view.renderLegend(overlay.c.gradient,
                overlay.c.continuous,
                1,
                metric.getLines().map(
                    function (line) {
                        return {value: line[0], text: line[indexMetric].toString()};
                    }
                ),
                overlay.m.metric,
                overlay.getName()
                )

            // this.m.renderLegend(this.c, element, this.l || this.m, this.getName());
        }
        // else
        //     element.innerHTML = "";

    }

    /**
     * Método que notifica la apertura o cierre de la leyenda .
     * @param {boolean} open - Estado de apertura de la leyenda.
     * @private
     */
    notify(open){
        // Almacena el estado de apertura de la ventana.
        store.set("openLegend", open);
        if (open){
            dataLayer.push({
                'ga4_action_name': 'visor_legend',
                'ga4_tag_name': 'click'
            })
        }
    }

}

export default LegendRenderController;

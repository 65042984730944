import color from "./Color";

// Paletas de colores que usa WINDY
export default {
    temp: color.instance({
        ident: "temp",
        steps: 2048,
        prepare: true,

        default: [[-50, [255, 211, 255, 255]],
            [-42, [255, 152, 255, 255]],
            [-40, [253, 100, 253, 255]],
            [-38, [247, 58, 247, 255]],
            [-36, [234, 25, 236, 255]],
            [-34, [214, 6, 216, 255]],
            [-32, [181, 0, 184, 255]],
            [-30, [172, 31, 255, 255]],
            [-28, [141, 13, 222, 255]],
            [-26, [113, 5, 188, 255]],
            [-24, [90, 2, 151, 255]],
            [-22, [66, 0, 113, 255]],
            [-20, [0, 44, 142, 255]],
            [-18, [2, 58, 166, 255]],
            [-16, [6, 77, 188, 255]],
            [-14, [15, 99, 205, 255]],
            [-12, [26, 119, 222, 255]],
            [-10, [3, 175, 243, 255]],
            [-8, [47, 207, 252, 255]],
            [-6, [81, 230, 253, 255]],
            [-4, [148, 245, 255, 255]],
            [-2, [195, 252, 255, 255]],
            [0, [23, 194, 95, 255]],
            [2, [16, 216, 57, 255]],
            [4, [12, 234, 8, 255]],
            [6, [65, 244, 4, 255]],
            [8, [114, 255, 0, 255]],
            [10, [246, 252, 64, 255]],
            [12, [251, 235, 39, 255]],
            [14, [255, 215, 29, 255]],
            [16, [255, 203, 21, 255]],
            [18, [255, 182, 16, 255]],
            [20, [255, 130, 37, 255]],
            [22, [251, 107, 28, 255]],
            [24, [247, 83, 22, 255]],
            [26, [244, 46, 11, 255]],
            [28, [229, 0, 0, 255]],
            [30, [170, 0, 0, 255]],
            [32, [146, 19, 19, 255]],
            [34, [121, 34, 34, 255]],
            [36, [91, 39, 39, 255]],
            [38, [65, 34, 34, 255]],
            [40, [99, 71, 71, 255]],
            [42, [113, 86, 86, 255]],
            [44, [125, 100, 100, 255]],
            [46, [135, 119, 119, 255]],
            [48, [150, 140, 140, 255]],
            [50, [165, 160, 160, 255]],
            [52, [187, 187, 187, 255]],
            [54, [216, 216, 216, 255]]]

        // default: [[203, [115, 70, 105, 255]], [218, [202, 172, 195, 255]], [233, [162, 70, 145, 255]], [248, [143, 89, 169, 255]], [258, [157, 219, 217, 255]], [265, [106, 191, 181, 255]], [269, [100, 166, 189, 255]], [273.15, [93, 133, 198, 255]], [274, [68, 125, 99, 255]], [283, [128, 147, 24, 255]], [294, [243, 183, 4, 255]], [303, [232, 83, 25, 255]], [320, [71, 14, 0, 255]]]
    }),
    wind: color.instance({
        ident: "wind",
        steps: 2048,
        prepare: 1,
        default: [[0, [61, 81, 209, 255]],
            [5, [59, 118, 177, 255]],
            [10, [58, 143, 155, 255]],
            [15, [57, 168, 133, 255]],
            [20, [56, 192, 112, 255]],
            [25, [55, 218, 90, 255]],
            [30, [53, 255, 58, 255]],
            [35, [124, 255, 0, 255]],
            [40, [202, 255, 0, 255]],
            [45, [252, 255, 0, 255]],
            [50, [255, 232, 0, 255]],
            [55, [255, 207, 0, 255]],
            [60, [255, 181, 0, 255]],
            [65, [255, 155, 0, 255]],
            [70, [255, 131, 0, 255]],
            [75, [240, 94, 12, 255]],
            [80, [220, 60, 20, 255]],
            [85, [200, 30, 15, 255]],
            [90, [170, 0, 15, 255]],
            [95, [150, 0, 0, 255]],
            [100, [130, 0, 30, 255]],
            [105, [153, 0, 68, 255]],
            [110, [185, 5, 100, 255]],
            [115, [220, 25, 130, 255]],
            [120, [240, 60, 175, 255]],
            [125, [255, 100, 225, 255]],
            [130, [255, 150, 250, 255]],
            [135, [253, 200, 251, 255]],
            [140, [253, 230, 252, 255]]
        ]
        // default: [[0, [98, 113, 183, 255]], [1, [57, 97, 159, 255]], [3, [74, 148, 169, 255]], [5, [77, 141, 123, 255]], [7, [83, 165, 83, 255]], [9, [53, 159, 53, 255]], [11, [167, 157, 81, 255]], [13, [159, 127, 58, 255]], [15, [161, 108, 92, 255]], [17, [129, 58, 78, 255]], [19, [175, 80, 136, 255]], [21, [117, 74, 147, 255]], [24, [109, 97, 163, 255]], [27, [68, 105, 141, 255]], [29, [92, 144, 152, 255]], [36, [125, 68, 165, 255]], [46, [231, 215, 215, 256]], [51, [219, 212, 135, 256]], [77, [205, 202, 112, 256]], [104, [128, 128, 128, 255]]]
    }),
    sst: color.instance({
        ident: "sst",
        steps: 2048,
        prepare: true,

        default: [[-3, [15, 15, 50, 255]],
            [-2, [11, 11, 79, 255]],
            [-1, [7, 7, 136, 255]],
            [0, [4, 4, 193, 255]],
            [1, [2, 2, 246, 255]],
            [2, [29, 36, 250, 255]],
            [3, [62, 76, 246, 255]],
            [4, [96, 116, 241, 255]],
            [5, [129, 156, 236, 255]],
            [6, [161, 197, 232, 255]],
            [7, [162, 214, 208, 255]],
            [8, [128, 209, 166, 255]],
            [9, [95, 204, 123, 255]],
            [10, [62, 200, 81, 255]],
            [11, [30, 195, 38, 255]],
            [12, [14, 194, 3, 255]],
            [13, [78, 210, 0, 255]],
            [14, [148, 228, 0, 255]],
            [15, [219, 246, 0, 255]],
            [16, [255, 237, 0, 255]],
            [17, [255, 220, 10, 255]],
            [18, [255, 198, 30, 255]],
            [19, [255, 180, 50, 255]],
            [20, [255, 160, 70, 255]],
            [21, [255, 130, 50, 255]],
            [22, [255, 106, 20, 255]],
            [23, [255, 86, 0, 255]],
            [24, [255, 53, 0, 255]],
            [25, [255, 0, 0, 255]],
            [26, [245, 40, 40, 255]],
            [27, [200, 70, 70, 255]],
            [28, [170, 80, 80, 255]],
            [29, [140, 60, 60, 255]],
            [30, [100, 40, 40, 255]],
            [31, [60, 20, 20, 255]],
            [32, [40, 10, 10, 255]]
        ]

        // default: [[203, [115, 70, 105, 255]], [218, [202, 172, 195, 255]], [233, [162, 70, 145, 255]], [248, [143, 89, 169, 255]], [258, [157, 219, 217, 255]], [265, [106, 191, 181, 255]], [269, [100, 166, 189, 255]], [273.15, [93, 133, 198, 255]], [274, [68, 125, 99, 255]], [283, [128, 147, 24, 255]], [294, [243, 183, 4, 255]], [303, [232, 83, 25, 255]], [320, [71, 14, 0, 255]]]
    }),

    // ,
    // windDetail: color.instance({
    //     ident: "windDetail",
    //     steps: 256,
    //     default: [[0, [243, 243, 243, 255]], [3, [243, 243, 243, 255]], [4, [0, 200, 254, 255]], [6, [0, 230, 0, 255]], [10, [254, 174, 0, 255]], [19, [254, 0, 150, 255]], [100, [151, 50, 222, 255]]]
    // }),
    // wavesDetail: color.instance({
    //     ident: "wavesDetail",
    //     steps: 256,
    //     default: [[0, [255, 255, 255, 0]], [.1, [255, 255, 255, 0]], [1, [180, 180, 255, 255]], [2.5, [254, 174, 0, 255]], [20, [255, 255, 255, 255]]]
    // }),
    // periodDetail: color.instance({
    //     ident: "periodDetail",
    //     steps: 256,
    //     default: [[0, [255, 255, 255, 0]], [5, [255, 255, 255, 0]], [10, [255, 237, 180, 255]], [20, [180, 255, 180, 255]]]
    // }),
    // altitudeDetail: color.instance({
    //     ident: "altitudeDetail",
    //     steps: 256,
    //     default: [[0, [255, 197, 254, 256]], [129, [255, 199, 254, 256]], [149, [255, 167, 179, 256]], [279, [255, 177, 179, 256]], [299, [175, 203, 255, 256]], [879, [157, 194, 255, 256]], [914, [159, 255, 170, 256]], [1499, [163, 255, 172, 256]], [7999, [255, 255, 255, 256]]]
    // }),
    // visibilityDetail: color.instance({
    //     ident: "visibilityDetail",
    //     steps: 256,
    //     default: [[0, [251, 180, 251, 256]], [1600, [253, 173, 255, 256]], [2200, [255, 175, 176, 256]], [5e3, [255, 165, 165, 256]], [6e3, [179, 187, 255, 256]], [8e3, [169, 182, 255, 256]], [9e3, [179, 255, 187, 256]], [15e3, [178, 255, 171, 255]], [20004, [255, 255, 255, 256]]]
    // }),
    // dewpointSpreadDetail: color.instance({
    //     ident: "dewpointSpreadDetail",
    //     steps: 256,
    //     default: [[0, [251, 180, 251, 256]], [.1, [253, 173, 255, 256]], [.25, [255, 175, 176, 256]], [.5, [255, 165, 165, 256]], [1, [179, 187, 255, 256]], [2, [169, 182, 255, 256]], [3, [179, 255, 187, 256]], [4, [178, 255, 171, 255]], [5, [255, 255, 255, 256]]]
    // }),
    rh: color.instance({
        ident: "rh",
        steps: 1024,
        continuous: true,
        default: [
            // [0, [173, 85, 56, 255]], [30, [173, 110, 56, 255]], [40, [173, 146, 56, 255]], [50, [105, 173, 56, 255]], [60, [56, 173, 121, 255]], [70, [56, 174, 173, 255]], [75, [56, 160, 173, 255]], [80, [56, 157, 173, 255]], [83, [56, 148, 173, 255]], [87, [56, 135, 173, 255]], [90, [56, 132, 173, 255]], [93, [56, 123, 173, 255]], [97, [56, 98, 157, 255]], [100, [56, 70, 114, 255]]
            [0, [84, 48, 5, 255]],
            [10, [140, 81, 10, 255]],
            [20, [191, 129, 45, 255]],
            [30, [223, 194, 125, 255]],
            [40, [246, 232, 195, 255]],
            [45, [255, 255, 255, 255]],
            [55, [199, 234, 229, 255]],
            [60, [128, 205, 193, 255]],
            [70, [53, 151, 143, 255]],
            [80, [1, 102, 95, 255]],
            [90, [0, 60, 48, 255]]
        ]
    }),
    // pressure: color.instance({
    //     ident: "pressure",
    //     steps: 1024,
    //     default: [[99e3, [142, 179, 184, 255]], [99500, [104, 180, 179, 255]], [1e5, [69, 167, 166, 255]], [100300, [57, 131, 147, 255]], [100600, [57, 118, 147, 255]], [100900, [57, 91, 147, 255]], [101500, [58, 117, 53, 255]], [101900, [159, 161, 65, 255]], [102200, [173, 136, 57, 255]], [102500, [170, 84, 67, 255]], [103e3, [94, 60, 81, 255]]]
    // }),
    // altitude: color.instance({
    //     ident: "altitude",
    //     steps: 1024,
    //     default: [[0, [105, 83, 83, 255]], [500, [162, 82, 140, 255]], [750, [99, 174, 174, 255]], [1000.15, [73, 106, 160, 255]], [1500, [75, 131, 70, 255]], [2e3, [191, 193, 93, 255]], [3e3, [184, 149, 73, 255]], [5e3, [182, 99, 83, 255]], [1e4, [171, 81, 102, 255]], [15e3, [108, 77, 97, 255]]]
    // }),
    // deg0: color.instance({
    //     ident: "deg0",
    //     steps: 1024,
    //     default: [[0, [188, 197, 195, 255]], [500, [155, 195, 189, 255]], [750, [93, 173, 156, 255]], [1000.15, [80, 141, 129, 255]], [1500, [55, 122, 109, 255]], [2e3, [39, 93, 82, 255]], [3e3, [33, 68, 73, 255]], [5e3, [32, 55, 71, 255]], [1e4, [28, 33, 64, 255]], [15e3, [6, 6, 6, 255]]]
    // }),
    isozero: color.instance({
           ident: "isozero",
           steps: 1024,
           default: [
               [0, [201, 206, 239, 255]],
               [10, [114, 140, 244, 255]],
               [100, [33, 107, 252, 255]],
               [200, [10, 186, 239, 255]],
               [300, [48, 239, 249, 255]],
               [400, [43, 201, 150, 255]],
               [600, [33, 226, 21, 255]],
               [800, [153, 252, 1, 255]],
               [1000, [234, 242, 48, 255]],
               [1200, [255, 181, 5, 255]],
               [1500, [249, 135, 40, 255]],
               [2000, [249, 76, 17, 255]],
               [3000, [226, 23, 107, 255]],
               [4500, [247, 109, 214, 255]]
           ]
    }),
    // levels: color.instance({
    //     ident: "levels",
    //     steps: 2048,
    //     default: [[0, [111, 111, 111, 255]], [1e3, [111, 111, 111, 255]], [4e3, [80, 121, 154, 255]], [8e3, [78, 179, 102, 255]], [1e4, [189, 189, 68, 255]], [12e3, [177, 80, 80, 255]], [15e3, [178, 80, 178, 255]], [2e4, [184, 184, 184, 255]]]
    // }),
    rain: color.instance({
        ident: "rain",
        steps: 2048,
        opaque: false,
        clipbottom: true,
        // default: [[0, [111, 111, 111, 255]], [.6, [60, 116, 160, 255]], [6, [59, 161, 161, 255]], [8, [59, 161, 61, 255]], [10, [130, 161, 59, 255]], [15, [161, 161, 59, 255]], [20, [161, 59, 59, 255]], [31, [161, 59, 161, 255]], [50, [168, 168, 168, 255]]]
        default: [
            // [0, [89, 89, 89, 0]],
            // [0, [255, 255, 255, 0]],
            // [0.001, [255, 255, 255, 255]],
            [0.2, [151, 255, 255, 255]],
            [0.5, [119, 229, 255, 255]],
            [1, [53, 199, 255, 255]],
            [2, [53, 144, 255, 255]],
            [3, [52, 89, 255, 255]],
            [4, [37, 43, 213, 255]],
            [5, [25, 25, 112, 255]],
            [10, [89, 37, 126, 255]],
            [15, [113, 55, 144, 255]],
            [20, [136, 72, 163, 255]],
            [25, [160, 89, 181, 255]],
            [30, [184, 106, 200, 255]],
            [35, [208, 124, 218, 255]],
            [40, [231, 141, 237, 255]],
            [45, [255, 158, 255, 255]],
            [50, [255, 128, 128, 255]],
            [60, [222, 7, 9, 255]],
            [70, [189, 13, 18, 255]],
            [80, [155, 20, 26, 255]],
            [100, [122, 27, 35, 255]],
            [120, [89, 33, 44, 255]],
            [150, [0, 0, 0, 255]]
        ]
    }),
    // ptype: color.instance({
    //     ident: "ptype",
    //     steps: 128,
    //     default: [[0, [111, 111, 111, 255]], [1, [0, 208, 239, 255]], [2, [0, 0, 255, 255]], [3, [197, 27, 195, 255]], [4, [129, 63, 63, 255]], [5, [227, 227, 227, 255]], [6, [129, 195, 129, 255]], [7, [202, 211, 57, 255]], [8, [183, 119, 8, 255]], [9, [227, 73, 19, 255]], [10, [195, 63, 63, 255]]]
    // }),
    // rainClouds: color.instance({
    //     ident: "rainClouds",
    //     steps: 128,
    //     default: [[0, [67, 87, 166, 255]], [.8, [70, 102, 163, 255]], [2, [62, 171, 171, 255]], [6, [62, 171, 171, 255]], [8, [62, 142, 62, 255]], [10, [129, 156, 62, 255]], [15, [171, 171, 62, 255]], [20, [169, 62, 62, 255]], [31, [171, 62, 171, 255]], [50, [177, 177, 177, 255]]]
    // }),
    clouds: color.instance({
        ident: "clouds",
        steps: 800,
        opaque: false,
        continuous: true,
        // default: [[0, [146, 130, 70, 0]], [10, [132, 119, 70, 0]], [30, [116, 116, 116, 255]], [80, [173, 183, 182, 255]], [95, [190, 193, 193, 255]], [100, [213, 213, 205, 255]]]
        default: [
            // [0, [89, 89, 89, 0]],
            // [0, [146, 130, 70, 0]],
            [0, [191, 191, 191, 0]],
            [50, [191, 191, 191, 255]],
            [60, [198, 198, 198, 255]],
            [70, [204, 204, 204, 255]],
            [80, [210, 210, 210, 255]],
            [90, [217, 217, 217, 255]],
            [100, [224, 223, 223, 255]]
        ]
    }),
    // lclouds: color.instance({
    //     ident: "lclouds",
    //     steps: 800,
    //     default: [[0, [156, 142, 87, 255]], [10, [143, 131, 87, 255]], [30, [129, 129, 129, 255]], [60, [137, 159, 182, 255]], [100, [187, 187, 187, 255]]]
    // }),
    // hclouds: color.instance({
    //     ident: "hclouds",
    //     steps: 800,
    //     default: [[0, [156, 142, 87, 255]], [10, [143, 131, 87, 255]], [30, [125, 157, 157, 255]], [50, [141, 169, 169, 255]], [100, [187, 187, 187, 255]]]
    // }),
    // mclouds: color.instance({
    //     ident: "mclouds",
    //     steps: 800,
    //     default: [[0, [156, 142, 87, 255]], [10, [143, 131, 87, 255]], [30, [157, 192, 157, 255]], [50, [145, 171, 145, 255]], [100, [187, 187, 187, 255]]]
    // }),
    // cape: color.instance({
    //     ident: "cape",
    //     steps: 1024,
    //     default: [[0, [110, 110, 110, 255]], [350, [110, 110, 110, 255]], [400, [93, 95, 127, 255]], [500, [37, 98, 145, 255]], [800, [37, 165, 37, 255]], [1500, [163, 161, 55, 255]], [2e3, [155, 112, 63, 255]], [2500, [162, 55, 55, 255]], [5001, [151, 68, 151, 255]]]
    // }),
    ivt: color.instance({
            ident: "ivt",
            continuous: false,
            steps: 2048,
            default: [

                // [200, [102, 102, 102, 255]],
                // [250, [255, 255, 0, 255]],
                // [300, [255, 228, 0, 255]],
                // [400, [255, 200, 0, 255]],
                // [500, [255, 173, 0, 255]],
                // [600, [255, 130, 0, 255]],
                // [700, [255, 80, 0, 255]],
                // [800, [255, 30, 0, 255]],
                // [1000, [235, 0, 16, 255]],
                // [1200, [184, 0, 58, 255]],
                // [1400, [133, 0, 99, 255]],
                // [1600, [87, 0, 136, 255]]

                [200, [102, 102, 102, 255]],
                [250, [164, 219, 130, 255]],
                [300, [104, 201, 92, 255]],
                [400, [55, 183, 70, 255]],
                [500, [32, 188, 188, 255]],
                [600, [14, 141, 186, 255]],
                [700, [13, 56, 184, 255]],
                [800, [41, 9, 132, 255]],
                [1000, [87, 10, 130, 255]],
                [1200, [135, 9, 89, 255]],
                [1400, [155, 10, 10, 255]],
                [1600, [197, 14, 14, 255]]

            ]
        }
    ),

    waterprec: color.instance({
        ident: "waterprec",
        continuous: false,
        steps: 2048,
        default: [
            [0, [102, 102, 102, 255]],
            [2, [104, 104, 104, 255]],
            [4, [108, 108, 108, 255]],
            [6, [117, 117, 117, 255]],
            [8, [131, 131, 131, 255]],
            [10, [158, 158, 158, 255]],
            [12, [186, 186, 186, 255]],
            [14, [217, 217, 217, 255]],
            [16, [228, 228, 226, 255]],
            // [0, [102, 92, 81, 255]],
            // [2, [104, 94, 83, 255]],
            // [4, [108, 98, 86, 255]],
            // [6, [117, 106, 93, 255]],
            // [8, [131, 119, 104, 255]],
            // [10, [158, 144, 125, 255]],
            // [12, [186, 175, 148, 255]],
            // [14, [217, 211, 173, 255]],
            // [16, [228, 226, 181, 255]],
            [18, [206, 229, 150, 255]],
            [20, [157, 220, 105, 255]],
            [22, [110, 196, 65, 255]],
            // [18, [206, 229, 150, 255]],
            // [20, [157, 220, 105, 255]],
            // [22, [110, 196, 65, 255]],
            [24, [43, 175, 59, 255]],
            [26, [36, 146, 49, 255]],
            [28, [33, 133, 83, 255]],
            [30, [37, 155, 91, 255]],
            [32, [46, 193, 128, 255]],
            [34, [44, 193, 193, 255]],
            [36, [39, 156, 169, 255]],
            [38, [33, 122, 145, 255]],
            [40, [31, 110, 132, 255]],
            [42, [29, 98, 127, 255]],
            [44, [28, 81, 123, 255]],
            [46, [27, 71, 120, 255]],
            [48, [27, 61, 119, 255]],
            [50, [86, 27, 131, 255]],
            [55, [174, 52, 255, 255]],
            [60, [203, 124, 255, 255]],
            [70, [255, 81, 166, 255]]

            // [54, [174, 52, 255, 255]],
            // [58, [203, 124, 255, 255]],
            // [62, [255, 156, 207, 255]],
            // // [62, [193, 96, 255, 255]],
            // [66, [251, 139, 195, 255]],
            // [70, [255, 121, 186, 255]],
            // // [74, [214, 145, 255, 255]],
            // // [80, [229, 186, 255, 255]],
            // [75, [255, 81, 166, 255]]
        ]
    }),
    atemp: color.instance({
        ident: "atemp",
        continuous: false,
        steps: 2048,
        default: [
            [-28, [248, 186, 197, 255]],
            [-24, [243, 168, 210, 255]],
            [-20, [235, 149, 221, 255]],
            [-18, [230, 132, 232, 255]],
            [-16, [223, 113, 244, 255]],
            [-14, [182, 91, 236, 255]],
            [-12, [146, 87, 218, 255]],
            [-10, [108, 82, 202, 255]],
            [-8, [72, 78, 183, 255]],
            [-7, [36, 75, 166, 255]],
            [-6, [0, 70, 147, 255]],
            [-5, [19, 94, 173, 255]],
            [-4, [40, 116, 198, 255]],
            [-3, [59, 138, 223, 255]],
            [-2.5, [100, 183, 248, 255]],
            [-2, [120, 205, 247, 255]],
            [-1.5, [140, 227, 246, 255]],
            [-1, [159, 248, 244, 255]],
            [-0.5, [255, 255, 255, 255]],
            [0, [255, 255, 255, 255]],
            [0.5, [255, 254, 190, 255]],
            [1, [254, 234, 160, 255]],
            [1.5, [253, 210, 131, 255]],
            [2, [253, 179, 101, 255]],
            [2.5, [248, 139, 81, 255]],
            [3, [239, 99, 62, 255]],
            [4, [221, 61, 45, 255]],
            [5, [194, 28, 38, 255]],
            [6, [157, 37, 57, 255]],
            [7, [186, 67, 85, 255]],
            [8, [217, 96, 115, 255]],
            [10, [241, 134, 168, 255]],
            [12, [253, 167, 221, 255]],
            [14, [228, 152, 191, 255]],
            [16, [201, 134, 164, 255]],
            [18, [178, 117, 137, 255]],
            [20, [153, 101, 110, 255]],
            [24, [127, 84, 78, 255]],
            [28, [103, 69, 52, 255]]
        ]
    }),
    lightDensity: color.instance({
        ident: "lightDensity",
        // opaque: false,
        continuous: true,
        clipbottom: true,
        steps: 2048,
        default: [
            // [0, [136, 136, 136, 255]], [.015, [136, 136, 136, 255]], [.025, [136, 200, 0, 255]], [.1, [218, 218, 0, 255]], [1, [241, 95, 0, 255]], [2, [248, 78, 120, 255]], [4, [135, 0, 0, 255]], [15, [221, 101, 255, 255]]
            // [0, [179, 179, 179, 0]],
            [0.1, [179, 179, 179, 255]],
            [0.5, [255, 255, 51, 255]],
            [1, [254, 220, 49, 255]],
            [1.5, [254, 184, 47, 255]],
            [2, [253, 149, 46, 255]],
            [3, [253, 113, 44, 255]],
            [4, [252, 78, 42, 255]],
            [5, [227, 26, 28, 255]],
            [6, [189, 0, 38, 255]],
            [8, [128, 0, 38, 255]],
            [10, [80, 2, 121, 255]],
            [15, [150, 7, 224, 255]],
            [20, [184, 73, 243, 255]],
            [30, [204, 127, 246, 255]],
            [50, [226, 182, 250, 255]],
            [100, [237, 209, 252, 255]]
        ]
    }),
    // cbase: color.instance({
    //     ident: "cbase",
    //     steps: 512,
    //     default: [[0, [166, 93, 165, 255]], [129, [162, 97, 160, 255]], [149, [167, 91, 91, 255]], [279, [167, 91, 91, 255]], [299, [98, 122, 160, 255]], [879, [98, 122, 160, 255]], [914, [90, 169, 90, 255]], [1499, [91, 167, 99, 255]], [7999, [119, 141, 120, 255]]]
    // }),
    snow: color.instance({
        ident: "snow",
        steps: 2048,
        clipbottom: true,
        opaque: false,
        // default: [[0, [97, 97, 97, 255]], [2, [69, 82, 152, 255]], [10, [65, 165, 167, 255]], [20, [65, 141, 65, 255]], [50, [168, 168, 65, 255]], [80, [170, 126, 63, 255]], [120, [167, 65, 65, 255]], [500, [168, 65, 168, 255]]]
        default: [
            // [0, [89, 89, 89, 0]],
            // [0, [170, 255, 170, 0]],
            [0.2, [170, 255, 170, 255]],
            [0.5, [72, 241, 82, 255]],
            [1, [47, 213, 56, 255]],
            [2, [32, 178, 41, 255]],
            [3, [26, 146, 33, 255]],
            [4, [0, 110, 0, 255]],
            [5, [0, 70, 0, 255]],
            [10, [255, 255, 0, 255]],
            [15, [255, 215, 0, 255]],
            [20, [255, 190, 0, 255]],
            [25, [255, 165, 0, 255]],
            [30, [222, 139, 9, 255]],
            [35, [189, 112, 18, 255]],
            [40, [155, 86, 26, 255]],
            [45, [115, 65, 27, 255]],
            [50, [255, 150, 255, 255]],
            [60, [191, 103, 204, 255]],
            [70, [158, 80, 178, 255]],
            [80, [126, 57, 153, 255]],
            [100, [94, 33, 128, 255]],
            [120, [62, 10, 102, 255]],
            [150, [46, 7, 75, 255]]
        ]
    }),
    rainAccu: color.instance({
        ident: "rainAccu",
        // opaque: 0,
        steps: 4096,
        clipbottom: true,
        opaque: false,
        // clipbottom: true,
        // default: [[0, [89, 89, 89, 0]], [1, [90, 88, 101, 255]], [5, [97, 88, 132, 255]], [10, [52, 117, 142, 255]], [30, [11, 140, 129, 255]], [40, [92, 153, 100, 255]], [80, [159, 157, 84, 255]], [120, [211, 154, 120, 255]], [500, [250, 157, 190, 255]], [8e3, [220, 220, 220, 255]]]
        default: [
            // [0, [89, 89, 89, 0]],
            // [0, [146, 130, 70, 255]],
            [1, [52, 210, 250, 255]],
            [2, [52, 190, 250, 255]],
            [3, [52, 170, 250, 255]],
            [4, [52, 150, 250, 255]],
            [5, [52, 130, 250, 255]],
            [10, [52, 100, 250, 255]],
            [15, [52, 0, 250, 255]],
            [20, [112, 0, 250, 255]],
            [25, [132, 0, 250, 255]],
            [30, [152, 0, 250, 255]],
            [35, [172, 0, 250, 255]],
            [40, [192, 0, 250, 255]],
            [45, [212, 0, 250, 255]],
            [50, [232, 0, 250, 255]],
            [75, [255, 0, 200, 255]],
            [100, [232, 0, 100, 255]],
            [150, [170, 0, 60, 255]],
            [200, [130, 0, 20, 255]],
            [300, [80, 0, 0, 255]]
        ]
    }),
    snowAccu: color.instance({
        ident: "snowAccu",
        // opaque: 0,
        steps: 4096,
        clipbottom: true,
        opaque: false,
        // clipbottom: true,
        // default: [[0, [89, 89, 89, 0]], [1, [90, 88, 101, 255]], [5, [97, 88, 132, 255]], [10, [52, 117, 142, 255]], [30, [11, 140, 129, 255]], [40, [92, 153, 100, 255]], [80, [159, 157, 84, 255]], [120, [211, 154, 120, 255]], [500, [250, 157, 190, 255]], [8e3, [220, 220, 220, 255]]]
        default: [
            [1.0, [46, 255, 58, 255]],
            [2.0, [44, 241, 55, 255]],
            [3.0, [41, 227, 51, 255]],
            [4.0, [38, 211, 48, 255]],
            [5.0, [35, 194, 44, 255]],
            [10, [32, 178, 41, 255]],
            [15, [29, 162, 37, 255]],
            [20, [26, 146, 33, 255]],
            [25, [23, 130, 29, 255]],
            [30, [20, 120, 20, 255]],
            [35, [255, 255, 58, 255]],
            [40, [255, 224, 51, 255]],
            [45, [254, 193, 43, 255]],
            [50, [254, 161, 36, 255]],
            [75, [253, 128, 28, 255]],
            [100, [253, 64, 14, 255]],
            [150, [253, 0, 0, 255]],
            [200, [192, 0, 0, 255]],
            [300, [131, 0, 0, 255]]
        ]
    }),

    probprec: color.instance({
        ident: "probprec",
        // opaque: 0,
        steps: 2048,
        clipbottom: true,
        opaque: false,

        // default: [[0, [89, 89, 89, 0]], [1, [90, 88, 101, 255]], [5, [97, 88, 132, 255]], [10, [52, 117, 142, 255]], [30, [11, 140, 129, 255]], [40, [92, 153, 100, 255]], [80, [159, 157, 84, 255]], [120, [211, 154, 120, 255]], [500, [250, 157, 190, 255]], [8e3, [220, 220, 220, 255]]]
        default: [
            [15, [235, 246, 255, 255]],
            [20, [214, 226, 255, 255]],
            [25, [181, 201, 255, 255]],
            [30, [142, 178, 255, 255]],
            [35, [127, 150, 255, 255]],
            [40, [114, 133, 248, 255]],
            [45, [99, 112, 248, 255]],
            [50, [0, 158, 30, 255]],
            [55, [60, 188, 61, 255]],
            [60, [122, 218, 85, 255]],
            [65, [185, 249, 110, 255]],
            [70, [255, 249, 19, 255]],
            [75, [255, 206, 14, 255]],
            [80, [255, 163, 9, 255]],
            [85, [229, 0, 0, 255]],
            [90, [189, 0, 0, 255]],
            [95, [129, 0, 0, 255]]
        ]
    }),
    waves: color.instance({
        ident: "waves",
        steps: 1024,
        default: [
            [0.0, [0, 0, 180, 255]],
            [0.2, [0, 0, 251, 255]],
            [0.5, [18, 123, 252, 255]],
            [1, [115, 159, 252, 255]],
            [2, [0, 255, 0, 255]],
            [3, [140, 255, 0, 255]],
            [4, [255, 255, 0, 255]],
            [5, [255, 200, 0, 255]],
            [6, [255, 150, 0, 255]],
            [7, [255, 100, 0, 255]],
            [8, [255, 0, 0, 255]],
            [10, [255, 75, 75, 255]],
            [12, [255, 120, 120, 255]],
            [14, [255, 165, 165, 255]],
            [16, [255, 210, 210, 255]],
            [20, [255, 225, 225, 255]]
        ]
    }),
    icecover: color.instance({
        ident: "icecover",
        steps: 1024,
        default: [
            [0.05, [255, 255, 255, 255]],
            [1, [255, 255, 255, 255]]
        ]

    }),

    // currents: color.instance({
    //     ident: "currents",
    //     steps: 256,
    //     default: [[0, [64, 77, 143, 255]], [.02, [50, 86, 142, 255]], [.06, [50, 123, 142, 255]], [.1, [64, 120, 103, 255]], [.15, [50, 133, 50, 255]], [.2, [50, 141, 50, 255]], [.3, [142, 132, 50, 255]], [.4, [142, 113, 50, 255]], [.5, [130, 77, 61, 255]], [.6, [115, 50, 68, 255]], [.7, [142, 50, 104, 255]], [.8, [105, 68, 131, 255]], [.85, [81, 70, 131, 255]], [.9, [65, 98, 131, 255]], [1, [73, 122, 131, 255]], [1.5, [143, 143, 143, 255]], [4, [143, 143, 143, 255]]]
    // }),
    visibility: color.instance({
        ident: "visibility",
        steps: 2048,
        opaque: false,
        continuous: true,
        default: [
            [0, [127, 39, 4, 255]],
            [100, [166, 54, 3, 255]],
            [500, [217, 72, 1, 255]],
            [1000, [241, 105, 19, 255]],
            [2000, [253, 141, 60, 255]],
            [3000, [253, 174, 107, 255]],
            [5000, [253, 208, 162, 255]],
            [10000, [254, 230, 206, 255]],
            [20000, [254, 230, 206, 0]]
            // [0, [163, 89, 163, 255]], [1600, [161, 89, 163, 255]], [2200, [167, 86, 86, 255]], [5e3, [167, 86, 86, 255]], [6e3, [89, 97, 163, 255]], [8e3, [89, 101, 163, 255]], [9e3, [60, 188, 73, 255]], [15e3, [83, 167, 75, 255]], [20004, [121, 121, 121, 255]]
        ]
    }),
    uvi: color.instance({
        ident: "uvi",
        steps: 1024,
        // opaque: false,
        clipbottom: true,
        default: [
            // [0, [255, 255, 255, 0]],
            [0.1, [175, 255, 175, 255]],
            [1, [80, 180, 0, 255]],
            [2, [160, 205, 0, 255]],
            [3, [248, 226, 0, 255]],
            [4, [248, 180, 0, 255]],
            [5, [248, 135, 0, 255]],
            [6, [248, 90, 0, 255]],
            [7, [232, 43, 15, 255]],
            [8, [216, 0, 28, 255]],
            [9, [255, 0, 150, 255]],
            [10, [181, 73, 255, 255]],
            [11, [153, 140, 255, 255]],
            [12, [127, 165, 255, 255]],
            [13, [102, 191, 255, 255]],
            [14, [76, 216, 255, 255]],
            [15, [0, 240, 255, 255]]
        ]
    }),

    // gtco3: color.instance({
    //     ident: "gtco3",
    //     steps: 512,
    //     default: [[80, [115, 70, 105, 256]], [218, [162, 70, 145, 256]], [220, [110, 81, 217, 256]], [260, [79, 151, 193, 256]], [320, [82, 203, 167, 256]], [360, [59, 197, 67, 256]], [420, [231, 174, 5, 256]], [500, [232, 83, 25, 256]]]
    // }),
    aod550: color.instance({
        ident: "aod550",
        steps: 8e3,
        opaque: false,
        continuous: true,
        // default: [[0, [0, 102, 151, 256]], [.25, [125, 182, 209, 256]], [.5, [170, 183, 189, 256]], [1, [194, 195, 125, 256]], [2, [232, 83, 25, 256]], [3, [189, 52, 19, 256]], [4, [75, 12, 0, 256]]]
        default: [
            // [0, [89, 89, 89, 255]],
            [0, [252, 221, 220, 0]],
            [0.10, [252, 221, 220, 255]],
            [0.15, [250, 213, 211, 255]],
            [0.20, [249, 204, 202, 255]],
            [0.25, [255, 236, 174, 255]],
            [0.30, [244, 220, 141, 255]],
            [0.35, [234, 205, 110, 255]],
            [0.40, [223, 190, 81, 255]],
            [0.45, [212, 175, 55, 255]],
            [0.50, [239, 127, 25, 255]],
            [0.60, [243, 139, 46, 255]],
            [0.70, [247, 152, 66, 255]],
            [0.80, [251, 165, 88, 255]],
            [0.90, [255, 178, 110, 255]],
            [1.00, [253, 187, 129, 255]],
            [1.30, [225, 153, 115, 255]],
            [1.60, [197, 124, 84, 255]],
            [1.90, [169, 96, 58, 255]],
            [2.20, [141, 73, 37, 255]],
            [2.50, [99, 74, 60, 255]],
            [3.00, [75, 59, 53, 255]],
            [3.50, [50, 42, 41, 255]],
            [4.00, [25, 23, 23, 255]],
            [4.50, [0, 0, 0, 255]]
        ]


    }),
    pm2p5: color.instance({
        ident: "pm2p5",
        steps: 2048,
        default: [
            // [0, [0, 102, 151, 256]], [10, [125, 182, 209, 256]], [15, [170, 183, 189, 256]], [25, [194, 195, 125, 256]], [150, [232, 83, 25, 256]], [200, [189, 52, 19, 256]], [1e3, [75, 12, 0, 256]]
            [0, [64, 79, 61, 255]],
            [2, [104, 121, 95, 255]],
            [4, [148, 162, 129, 255]],
            [6, [195, 204, 164, 255]],
            [8, [245, 245, 200, 255]],
            [10, [245, 238, 189, 255]],
            [12, [244, 229, 178, 255]],
            [14, [244, 216, 167, 255]],
            [16, [244, 202, 156, 255]],
            [18, [238, 180, 142, 255]],
            [20, [232, 157, 128, 255]],
            [22, [226, 133, 114, 255]],
            [25, [220, 107, 102, 255]],
            [30, [214, 89, 89, 255]],
            [35, [208, 78, 101, 255]],
            [40, [202, 66, 116, 255]],
            [45, [196, 56, 133, 255]],
            [50, [190, 46, 151, 255]],
            [55, [185, 36, 171, 255]],
            [60, [167, 27, 175, 255]],
            [65, [138, 19, 166, 255]],
            [70, [110, 12, 157, 255]],
            [75, [65, 0, 129, 255]],
            [220, [34, 0, 100, 255]],
            [365, [13, 0, 71, 255]],
            [510, [1, 0, 42, 255]],
            [655, [0, 2, 13, 255]],
            [800, [0, 2, 13, 255]]
        ]
    }),
    pm10: color.instance({
        ident: "pm10",
        steps: 4096,
        default: [
            [0, [64, 79, 61, 255]],
            [5, [104, 121, 95, 255]],
            [10, [148, 162, 129, 255]],
            [15, [195, 204, 164, 255]],
            [20, [245, 245, 200, 255]],
            [25, [245, 238, 189, 255]],
            [30, [244, 229, 178, 255]],
            [35, [244, 216, 167, 255]],
            [40, [244, 202, 156, 255]],
            [45, [238, 180, 142, 255]],
            [50, [232, 157, 128, 255]],
            [55, [226, 133, 114, 255]],
            [60, [220, 107, 102, 255]],
            [65, [214, 89, 89, 255]],
            [70, [208, 78, 101, 255]],
            [75, [202, 66, 116, 255]],
            [80, [196, 56, 133, 255]],
            [85, [190, 46, 151, 255]],
            [90, [185, 36, 171, 255]],
            [95, [167, 27, 175, 255]],
            [100, [138, 19, 166, 255]],
            [200, [110, 12, 157, 255]],
            [300, [65, 0, 129, 255]],
            [400, [34, 0, 100, 255]],
            [500, [13, 0, 71, 255]],
            [700, [1, 0, 42, 255]],
            [900, [0, 2, 13, 255]],
            [1100, [0, 2, 13, 255]],
            [1300, [0, 2, 13, 255]]
        ]
    }),
    no2: color.instance({
        ident: "no2",
        steps: 4096,
        // clipbottom: true,
        default: [
            // [0, [0, 102, 151, 256]], [1.5, [125, 182, 209, 256]], [2, [170, 183, 189, 256]], [3, [194, 195, 125, 256]], [30, [232, 83, 25, 256]], [40, [189, 52, 19, 256]], [100, [75, 12, 0, 256]]
            [0, [63, 97, 229, 255]],
            // [2, [30, 60, 225, 255]],
            [5, [53, 120, 230, 255]],
            [10, [10, 147, 235, 255]],
            [15, [0, 200, 240, 255]],
            [20, [0, 220, 0, 255]],
            [30, [129, 225, 87, 255]],
            [40, [160, 230, 50, 255]],
            [50, [236, 230, 70, 255]],
            [75, [242, 186, 47, 255]],
            [100, [246, 122, 54, 255]],
            [125, [248, 91, 57, 255]],
            [150, [250, 60, 60, 255]],
            [175, [245, 29, 86, 255]],
            [200, [240, 0, 126, 255]],
            [250, [183, 0, 73, 255]],
            [300, [126, 0, 35, 255]]

        ]
    }),
    so2: color.instance({
        ident: "so2",
        steps: 4096,
        // clipbottom: true,
        default: [
            // [0, [171, 171, 171, 255]], [.05, [170, 174, 143, 255]], [.1, [164, 163, 120, 255]], [.5, [158, 139, 74, 255]], [2, [151, 112, 24, 255]], [10, [140, 81, 0, 255]], [20, [132, 64, 0, 255]], [50, [121, 45, 0, 255]], [80, [102, 11, 0, 255]]
            [0, [63, 97, 229, 255]],
            // [2, [30, 60, 225, 255]],
            [5, [53, 120, 230, 255]],
            [10, [42, 155, 239, 255]],
            [15, [0, 200, 240, 255]],
            [20, [0, 220, 0, 255]],
            [30, [129, 225, 87, 255]],
            [40, [160, 230, 50, 255]],
            [50, [236, 230, 70, 255]],
            [75, [242, 186, 47, 255]],
            [100, [246, 122, 54, 255]],
            [125, [248, 91, 57, 255]],
            [150, [250, 60, 60, 255]],
            [175, [245, 29, 86, 255]],
            [200, [240, 0, 124, 255]],
            [400, [202, 0, 88, 255]],
            [600, [164, 0, 59, 255]],
            [800, [126, 0, 35, 255]]
        ]
    }),

    o3: color.instance({
        ident: "o3",
        steps: 4096,
        // clipbottom: true,
        default: [
            // [0, [171, 171, 171, 255]], [.05, [170, 174, 143, 255]], [.1, [164, 163, 120, 255]], [.5, [158, 139, 74, 255]], [2, [151, 112, 24, 255]], [10, [140, 81, 0, 255]], [20, [132, 64, 0, 255]], [50, [121, 45, 0, 255]], [80, [102, 11, 0, 255]]
            [0, [20, 40, 155, 255]],
            [20, [30, 60, 225, 255]],
            [30, [15, 85, 229, 255]],
            [40, [0, 116, 233, 255]],
            [50, [0, 158, 237, 255]],
            [60, [0, 200, 240, 255]],
            [70, [0, 230, 204, 255]],
            [80, [0, 220, 2, 255]],
            [90, [129, 225, 87, 255]],
            [100, [160, 230, 50, 255]],
            [120, [236, 231, 70, 255]],
            [140, [239, 199, 65, 255]],
            [160, [243, 162, 59, 255]],
            [180, [246, 122, 54, 255]],
            [200, [250, 60, 60, 255]],
            [220, [246, 29, 87, 255]],
            [240, [241, 0, 128, 255]],
            [305, [212, 0, 101, 255]],
            [370, [183, 0, 75, 255]],
            [435, [155, 0, 53, 255]],
            [500, [126, 0, 35, 255]]
        ]
    }),
    co: color.instance({
        ident: "co",
        steps: 2048,
        // clipbottom: true,
        default: [
            // [0, [171, 171, 171, 255]], [.05, [170, 174, 143, 255]], [.1, [164, 163, 120, 255]], [.5, [158, 139, 74, 255]], [2, [151, 112, 24, 255]], [10, [140, 81, 0, 255]], [20, [132, 64, 0, 255]], [50, [121, 45, 0, 255]], [80, [102, 11, 0, 255]]
            [0, [20, 40, 155, 255]],
            [50, [30, 60, 225, 255]],
            [100, [0, 116, 233, 255]],
            [150, [0, 200, 240, 255]],
            [200, [0, 220, 0, 255]],
            [250, [129, 225, 87, 255]],
            [300, [160, 230, 50, 255]],
            [350, [237, 232, 71, 255]],
            [400, [239, 211, 59, 255]],
            [450, [242, 188, 47, 255]],
            [500, [246, 122, 54, 255]],
            [600, [248, 91, 57, 255]],
            [700, [250, 60, 60, 255]],
            [800, [247, 40, 40, 255]],
            [900, [244, 19, 78, 255]],
            [1000, [240, 0, 126, 255]],
            [1500, [183, 0, 74, 255]],
            [2000, [126, 0, 35, 255]]
        ]
    }),
    aqimet: color.instance({
        ident: "aqimet",
        steps: 4096,
        discrete: true,
        default:[
            [0, [0, 200, 240, 255]],
            [20, [0, 200, 2, 255]],
            [40, [236, 231, 70, 255]],
            [60, [246, 122, 54, 255]],
            [80, [255, 0, 0, 255]],
            [100, [155, 0, 53, 255]]
        ]
    }),
    aqieu: color.instance({
        ident: "aqieu",
        steps: 4096,
        discrete: true,
        default:[
            [0, [80, 240, 230, 255]],
            [20, [80, 204, 170, 255]],
            [40, [240, 230, 65, 255]],
            [60, [255, 80, 80, 255]],
            [80, [150, 0, 50, 255]],
            [100, [125, 33, 129, 255]]
        ]
    }),
    aqiusa: color.instance({
        ident: "aqiusa",
        steps: 4096,
        discrete: true,
        default:[
            [0, [0,   228, 0, 255]],
            [50, [255, 255, 0, 255]],
            [100, [255, 126, 0, 255]],
            [150, [255, 0, 0, 255]],
            [200, [143, 63, 151, 255]],
            [300, [126, 0, 35, 255]]
        ]
    }),
    aqimpol: color.instance({
        ident: "aqimpol",
        steps: 4096,
        discrete: true,
        default:[
            [0, [204, 204, 204, 255]],
            [20, [148, 162, 129, 255]],
            [40, [181, 199, 109, 255]],
            [60, [236, 233, 70, 255]],
            [80, [246, 140, 36, 255]],
            [100, [255, 0,   0, 255]]
        ]
    }),
    pollen: color.instance({
        ident: "pollen",
        steps: 4096,
        // clipbottom: true,
        default: [
            [0, [64, 79, 61, 255]],
            // [0.1, [104, 121, 95, 255]],
            [1, [148, 162, 129, 255]],
            [2, [195, 204, 164, 255]],
            [3, [245, 245, 200, 255]],
            [4, [245, 238, 189, 255]],
            [5, [244, 229, 178, 255]],
            [7, [244, 216, 167, 255]],
            [9, [244, 202, 156, 255]],
            [11, [238, 180, 142, 255]],
            [13, [232, 157, 128, 255]],
            [16, [226, 133, 114, 255]],
            [19, [220, 107, 102, 255]],
            [22, [214, 89, 89, 255]],
            [25, [208, 78, 101, 255]],
            [30, [202, 66, 116, 255]],
            [35, [196, 56, 133, 255]],
            [40, [190, 46, 151, 255]],
            [45, [185, 36, 171, 255]],
            [50, [167, 27, 175, 255]],
            [60, [138, 19, 166, 255]],
            [70, [128, 14, 159, 255]],
            [80, [115, 9, 151, 255]],
            [90, [104, 4, 144, 255]],
            [100, [94, 0, 136, 255]],
            [200, [81, 0, 134, 255]],
            [500, [65, 0, 129, 255]],
            [1000, [34, 2, 100, 255]],
            [5000, [13, 0, 71, 255]]

        ]
    }),
    // cosc: color.instance({
    //     ident: "cosc",
    //     steps: 1024,
    //     default: [[0, [124, 124, 124, 255]], [70, [124, 124, 108, 255]], [110, [164, 157, 72, 255]], [200, [136, 113, 47, 255]], [450, [39, 31, 31, 255]], [2200, [255, 22, 22, 255]]]
    // }),
    // dust: color.instance({
    //     ident: "dust",
    //     steps: 1024,
    //     default: [[0, [171, 171, 171, 255]], [10, [148, 137, 118, 255]], [80, [124, 104, 59, 255]], [800, [100, 73, 0, 255]], [1200, [74, 44, 0, 255]]]
    // }),
    radar: color.instance({
        ident: "radar",
        steps: 255,
        opaque: false,
        save: false,
        sync: false,
        // default: [[0, [40, 16, 158, 0]], [3, [40, 16, 158, 20]], [8, [40, 16, 158, 100]], [14, [0, 101, 154, 180]], [20, [0, 144, 147, 220]], [26, [0, 179, 125, 240]], [32, [117, 208, 89, 255]], [36, [220, 220, 30, 255]], [40, [244, 202, 8, 255]], [44, [245, 168, 24, 255]], [48, [236, 130, 63, 255]], [52, [205, 75, 75, 255]], [56, [182, 45, 100, 255]], [60, [156, 16, 109, 255]], [64, [125, 0, 108, 255]], [68, [92, 0, 100, 255]], [100, [0, 0, 0, 255]], [101, [0, 0, 0, 0]], [255, [0, 0, 0, 0]]]
        default: [
            [0, [63, 254, 252, 0]],
            [5, [63, 254, 252, 255]],
            [10, [63, 254, 252, 255]],
            [11, [58, 241, 253, 255]],
            [12, [54, 226, 252, 255]],
            [13, [49, 210, 252, 255]],
            [14, [44, 193, 251, 255]],
            [15, [40, 176, 250, 255]],
            [16, [35, 159, 249, 255]],
            [17, [31, 141, 249, 255]],
            [18, [26, 122, 248, 255]],
            [19, [22, 103, 247, 255]],
            [20, [17, 83, 246, 255]],
            [21, [13, 63, 245, 255]],
            [22, [9, 43, 245, 255]],
            [23, [4, 22, 244, 255]],
            [24, [0, 0, 243, 255]],
            [25, [116, 245, 96, 255]],
            [26, [106, 236, 89, 255]],
            [27, [97, 226, 82, 255]],
            [28, [88, 217, 76, 255]],
            [29, [79, 208, 70, 255]],
            [30, [71, 199, 64, 255]],
            [31, [63, 189, 58, 255]],
            [32, [56, 180, 52, 255]],
            [33, [48, 171, 47, 255]],
            [34, [42, 161, 43, 255]],
            [35, [38, 152, 40, 255]],
            [36, [33, 143, 38, 255]],
            [37, [29, 134, 35, 255]],
            [38, [26, 124, 32, 255]],
            [39, [22, 115, 30, 255]],
            [40, [254, 255, 73, 255]],
            [41, [254, 247, 71, 255]],
            [42, [254, 239, 69, 255]],
            [43, [253, 230, 67, 255]],
            [44, [252, 221, 65, 255]],
            [45, [252, 212, 62, 255]],
            [46, [251, 203, 60, 255]],
            [47, [251, 193, 58, 255]],
            [48, [250, 184, 56, 255]],
            [49, [249, 174, 54, 255]],
            [50, [249, 165, 52, 255]],
            [51, [248, 155, 50, 255]],
            [52, [247, 145, 48, 255]],
            [53, [247, 135, 46, 255]],
            [54, [246, 125, 44, 255]],
            [55, [237, 34, 34, 255]],
            [56, [228, 31, 34, 255]],
            [57, [218, 28, 35, 255]],
            [58, [209, 26, 35, 255]],
            [59, [199, 24, 35, 255]],
            [60, [189, 21, 36, 255]],
            [61, [180, 19, 35, 255]],
            [62, [170, 17, 35, 255]],
            [63, [161, 15, 35, 255]],
            [64, [151, 13, 34, 255]],
            [65, [141, 12, 34, 255]],
            [66, [132, 10, 33, 255]],
            [67, [122, 9, 32, 255]],
            [68, [113, 7, 30, 255]],
            [69, [103, 6, 29, 255]],
            [70, [246, 73, 209, 255]],
            [71, [237, 72, 208, 255]],
            [72, [227, 70, 206, 255]],
            [73, [218, 69, 204, 255]],
            [74, [208, 68, 201, 255]],
            [75, [199, 66, 197, 255]],
            [76, [186, 64, 189, 255]],
            [77, [172, 62, 180, 255]],
            [78, [158, 60, 170, 255]],
            [79, [146, 58, 161, 255]],
            [80, [133, 56, 151, 255]],
            [81, [122, 53, 142, 255]],
            [82, [111, 51, 132, 255]],
            [83, [100, 48, 123, 255]],
            [84, [90, 45, 113, 255]],
            [85, [80, 42, 104, 255]]
        ]
    }),
    // blitz: color.instance({
    //     ident: "blitz",
    //     steps: 288,
    //     opaque: !1,
    //     save: !1,
    //     sync: !1,
    //     default: [[0, [12, 0, 0, 240]], [.05, [48, 0, 0, 240]], [.1, [100, 20, 0, 240]], [.5, [130, 70, 0, 220]], [1, [140, 130, 20, 200]]]
    // }),
    ir: color.instance({
        ident: "ir",
        steps: 256,
        continuous: true,
        opaque: false,
        save: false,
        sync: false,
        default: [
            [-90,[138, 11, 133, 255]],
            [-89,[149, 22, 140, 255]],
            [-88,[160, 33, 146, 255]],
            [-87,[171, 44, 152, 255]],
            [-86,[182, 56, 159, 255]],
            [-85,[193, 67, 165, 255]],
            [-84,[204, 78, 171, 255]],
            [-83,[215, 89, 177, 255]],
            [-82,[226, 100, 184, 255]],
            [-81,[237, 111, 190, 255]],
            [-80,[200, 200, 200, 255]],
            [-79,[180, 180, 180, 255]],
            [-78,[160, 160, 160, 255]],
            [-77,[140, 140, 140, 255]],
            [-76,[120, 120, 120, 255]],
            [-75,[100, 100, 100, 255]],
            [-74,[80, 80, 80, 255]],
            [-73,[60, 60, 60, 255]],
            [-72,[40, 40, 40, 255]],
            [-71,[20, 20, 20, 255]],
            [-70,[0, 0, 0, 255]],
            [-69,[26, 0, 0, 255]],
            [-68,[51, 0, 0, 255]],
            [-67,[77, 0, 0, 255]],
            [-66,[102, 0, 0, 255]],
            [-65,[128, 0, 0, 255]],
            [-64,[153, 0, 0, 255]],
            [-63,[179, 0, 0, 255]],
            [-62,[204, 0, 0, 255]],
            [-61,[230, 0, 0, 255]],
            [-60,[255, 0, 0, 255]],
            [-59,[255, 26, 5, 255]],
            [-58,[255, 51, 10, 255]],
            [-57,[255, 77, 15, 255]],
            [-56,[255, 102, 20, 255]],
            [-55,[255, 128, 25, 255]],
            [-54,[255, 153, 30, 255]],
            [-53,[255, 179, 35, 255]],
            [-52,[255, 204, 40, 255]],
            [-51,[255, 230, 45, 255]],
            [-50,[255, 255, 50, 255]],
            [-49,[235, 255, 50, 255]],
            [-48,[214, 255, 50, 255]],
            [-47,[194, 255, 50, 255]],
            [-46,[173, 255, 50, 255]],
            [-45,[153, 255, 50, 255]],
            [-44,[132, 255, 50, 255]],
            [-43,[112, 255, 50, 255]],
            [-42,[91, 255, 50, 255]],
            [-41,[71, 255, 50, 255]],
            [-40,[50, 255, 50, 255]],
            [-39,[45, 230, 58, 255]],
            [-38,[40, 204, 65, 255]],
            [-37,[35, 179, 73, 255]],
            [-36,[30, 153, 81, 255]],
            [-35,[25, 128, 89, 255]],
            [-34,[20, 102, 96, 255]],
            [-33,[15, 77, 104, 255]],
            [-32,[10, 51, 112, 255]],
            [-31,[5, 26, 119, 255]],
            [-30,[0, 0, 127, 255]],
            [-29,[6, 28, 141, 255]],
            [-28,[11, 57, 155, 255]],
            [-27,[17, 85, 170, 255]],
            [-26,[22, 113, 184, 255]],
            [-25,[28, 142, 198, 255]],
            [-24,[33, 170, 212, 255]],
            [-23,[39, 198, 227, 255]],
            [-22,[44, 227, 241, 255]],
            [-21,[50, 255, 255, 255]],
            [-20,[255, 255, 255, 255]],
            [-19,[251, 251, 251, 255]],
            [-18,[247, 247, 247, 255]],
            [-17,[242, 242, 242, 255]],
            [-16,[238, 238, 238, 255]],
            [-15,[234, 234, 234, 255]],
            [-14,[230, 230, 230, 255]],
            [-13,[226, 226, 226, 255]],
            [-12,[222, 222, 222, 255]],
            [-11,[217, 217, 217, 255]],
            [-10,[213, 213, 213, 255]],
            [-9,[209, 209, 209, 255]],
            [-8,[205, 205, 205, 255]],
            [-7,[201, 201, 201, 255]],
            [-6,[196, 196, 196, 255]],
            [-5,[192, 192, 192, 255]],
            [-4,[188, 188, 188, 255]],
            [-3,[184, 184, 184, 255]],
            [-2,[180, 180, 180, 255]],
            [-1,[176, 176, 176, 255]],
            [0,[171, 171, 171, 255]],
            [1,[167, 167, 167, 255]],
            [2,[163, 163, 163, 255]],
            [3,[159, 159, 159, 255]],
            [4,[155, 155, 155, 255]],
            [5,[150, 150, 150, 255]],
            [6,[146, 146, 146, 255]],
            [7,[142, 142, 142, 255]],
            [8,[138, 138, 138, 255]],
            [9,[134, 134, 134, 255]],
            [10,[130, 130, 130, 255]],
            [11,[125, 125, 125, 255]],
            [12,[121, 121, 121, 255]],
            [13,[117, 117, 117, 255]],
            [14,[113, 113, 113, 255]],
            [15,[109, 109, 109, 255]],
            [16,[105, 105, 105, 255]],
            [17,[100, 100, 100, 255]],
            [18,[96, 96, 96, 255]],
            [19,[92, 92, 92, 255]],
            [20,[88, 88, 88, 255]],
            [21,[84, 84, 84, 255]],
            [22,[79, 79, 79, 255]],
            [23,[75, 75, 75, 255]],
            [24,[71, 71, 71, 255]],
            [25,[67, 67, 67, 255]],
            [26,[63, 63, 63, 255]],
            [27,[59, 59, 59, 255]],
            [28,[54, 54, 54, 255]],
            [29,[50, 50, 50, 255]],
            [30,[46, 46, 46, 255]],
            [31,[42, 42, 42, 255]],
            [32,[38, 38, 38, 255]],
            [33,[33, 33, 33, 255]],
            [34,[29, 29, 29, 255]],
            [35,[25, 25, 25, 255]],
            [36,[21, 21, 21, 255]],
            [37,[17, 17, 17, 255]],
            [38,[13, 13, 13, 255]],
            [39,[8, 8, 8, 255]],
            [40,[4, 4, 4, 255]],
            [41,[0, 0, 0, 255]]
        ]
    }),
    wv: color.instance({
        ident: "wv",
        steps: 256,
        continuous: true,
        opaque: false,
        save: false,
        sync: false,
        default: [
            [-80,[251, 251, 251, 255]],
            [-79,[247, 247, 247, 255]],
            [-78,[243, 243, 243, 255]],
            [-77,[239, 239, 239, 255]],
            [-76,[234, 234, 234, 255]],
            [-75,[230, 230, 230, 255]],
            [-74,[226, 226, 226, 255]],
            [-73,[222, 222, 222, 255]],
            [-72,[218, 218, 218, 255]],
            [-71,[214, 214, 214, 255]],
            [-70,[210, 210, 210, 255]],
            [-69,[206, 206, 206, 255]],
            [-68,[202, 202, 202, 255]],
            [-67,[197, 197, 197, 255]],
            [-66,[193, 193, 193, 255]],
            [-65,[189, 189, 189, 255]],
            [-64,[185, 185, 185, 255]],
            [-63,[181, 181, 181, 255]],
            [-62,[177, 177, 177, 255]],
            [-61,[173, 173, 173, 255]],
            [-60,[169, 169, 169, 255]],
            [-59,[165, 165, 165, 255]],
            [-58,[160, 160, 160, 255]],
            [-57,[156, 156, 156, 255]],
            [-56,[152, 152, 152, 255]],
            [-55,[148, 148, 148, 255]],
            [-54,[144, 144, 144, 255]],
            [-53,[140, 140, 140, 255]],
            [-52,[136, 136, 136, 255]],
            [-51,[132, 132, 132, 255]],
            [-50,[128, 128, 128, 255]],
            [-49,[123, 123, 123, 255]],
            [-48,[119, 119, 119, 255]],
            [-47,[115, 115, 115, 255]],
            [-46,[111, 111, 111, 255]],
            [-45,[107, 107, 107, 255]],
            [-44,[103, 103, 103, 255]],
            [-43,[99, 99, 99, 255]],
            [-42,[95, 95, 95, 255]],
            [-41,[90, 90, 90, 255]],
            [-40,[86, 86, 86, 255]],
            [-39,[82, 82, 82, 255]],
            [-38,[78, 78, 78, 255]],
            [-37,[74, 74, 74, 255]],
            [-36,[70, 70, 70, 255]],
            [-35,[66, 66, 66, 255]],
            [-34,[62, 62, 62, 255]],
            [-33,[58, 58, 58, 255]],
            [-32,[53, 53, 53, 255]],
            [-31,[49, 49, 49, 255]],
            [-30,[45, 45, 45, 255]],
            [-29,[41, 41, 41, 255]],
            [-28,[37, 37, 37, 255]],
            [-27,[33, 33, 33, 255]],
            [-26,[29, 29, 29, 255]],
            [-25,[25, 25, 25, 255]],
            [-24,[21, 21, 21, 255]],
            [-23,[16, 16, 16, 255]],
            [-22,[12, 12, 12, 255]],
            [-21,[8, 8, 8, 255]],
            [-20,[4, 4, 4, 255]],
            [-19,[0, 0, 0, 255]]
        ]
    }),
    // fog: color.instance({
    //     ident: "fog",
    //     steps: 512,
    //     default: [[0, [110, 110, 110, 255]], [1, [200, 200, 200, 255]], [2, [200, 200, 255, 255]]]
    // }),
    // justGray: color.instance({
    //     ident: "justGray",
    //     steps: 4,
    //     default: [[-2e4, [111, 111, 111, 255]], [2e4, [111, 111, 111, 255]]]
    // }),
    // efiWind: color.instance({
    //     ident: "efiWind",
    //     steps: 256,
    //     default: [[-1, [5, 165, 189, 256]], [-.8, [30, 175, 119, 256]], [-.4, [111, 111, 111, 255]], [.4, [111, 111, 111, 255]], [.8, [187, 174, 24, 256]], [1, [189, 80, 32, 256]]]
    // }),
    // efiTemp: color.instance({
    //     ident: "efiTemp",
    //     steps: 256,
    //     default: [[-1, [43, 54, 209, 256]], [-.8, [60, 164, 179, 256]], [-.4, [111, 111, 111, 255]], [.4, [111, 111, 111, 255]], [.8, [128, 147, 24, 255]], [1, [213, 0, 110, 256]]]
    // }),
    // efiRain: color.instance({
    //     ident: "efiRain",
    //     steps: 256,
    //     default: [[-1, [151, 75, 0, 256]], [-.8, [187, 180, 0, 256]], [-.4, [111, 111, 111, 255]], [.4, [111, 111, 111, 255]], [.8, [1, 162, 177, 256]], [1, [4, 8, 181, 256]]]
    // }),
    // fires: color.instance({
    //     ident: "fires",
    //     steps: 8e3,
    //     default: [[0, [111, 111, 111, 255]], [.001, [129, 125, 84, 256]], [.01, [215, 174, 63, 256]], [.1, [199, 141, 43, 256]], [1, [199, 83, 20, 256]], [5, [203, 12, 12, 256]]]
    // })
}

import store from "./store";
import overlays from "./overlays";
import rootScope from "./rootScope";

class AQISwitchController{
    constructor(view) {
        this.view = view;

        this.addElements();

        this.view.bindSelect(this.select.bind(this))

        store.on("usedLang", this.updateOverlays.bind(this));
    }

    updateOverlays(){
        this.view.remove();
        this.addElements();
    }

    addElements (){

        let aqioverlayselected = store.get ("aqioverlay");
        let overlayselected = store.get ("overlay");

        if(rootScope.aqioverlays.includes(overlayselected)){
            aqioverlayselected = overlayselected;
        }

        this.view.update(
            rootScope.aqioverlays.map(function(nameoverlay)
            {
                return {
                    text: overlays[nameoverlay].getName(),
                    value: nameoverlay,
                    selected: nameoverlay === aqioverlayselected,
                };

            })
        );
    }

    select (value){
        store.set("overlay", value);
        store.set("aqioverlay", value);
    }
}

export default AQISwitchController;

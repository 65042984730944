import store from "./store";
import detectDevice from "./detectDevice";
import utils from "./utils";

class PremiumLinkController {
    constructor( premiumLinkView  ) {
        this.view = premiumLinkView;

        this.view.bindOpenLinkPremium(this.openLinkPremium);


    }


    openLinkPremium(){
        const nameoverlay = store.get("overlay");
        utils.openLinkPremium(nameoverlay)
    }



}

export default PremiumLinkController;

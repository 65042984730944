// W.define("models", ["store", "broadcast", "$", "rootScope", "products", "layers", "overlays"], function(e, t, n, i, s, a, r) {
// define("models", ["store", "broadcast", "$", "rootScope", "products", "layers", "overlays"], function(store, broadcast, $, rscope, products, layers, overlays) {

import store from "./store";
import broadcast from "./broadcast";
import $ from "./$";
import rscope from "./rootScope";
import products from "./products";
import layers from "./layers";
import overlays from "./overlays";

    var modelname = $('meta[name="model"]');

    if ( modelname && modelname.content &&
        rscope.globalProducts.includes(modelname.content) &&
        "ecmwf" !== modelname.content)
        store.set("product", modelname.content);

    var layer2product = {}
        , overlay2product = {}
        , productnames = Object.keys(products);

    Object.keys(layers).forEach(
        function(layername) {
            layer2product[layername] = [];
            for (var i = 0; i < productnames.length; i++)
                if ( products[productnames[i]].overlays.includes(layername) )
                    layer2product[layername].push(productnames[i])
        });

    Object.keys(overlays).forEach(
        function(overlayname) {
            overlay2product[overlayname] = [];
            for (var i = 0; i < productnames.length; i++)
                if ( products[productnames[i]].overlays.includes(overlayname) )
                    overlay2product[overlayname].push(productnames[i])
        });

    broadcast.once("paramsChanged",
        function() {
            setBestProduct();
            broadcast.on("mapChanged", setBestProduct);
        });


    var setBestProduct = function() {

            var visibleProducts = betterProducts(rscope.map).concat(rscope.globalProducts);

            // Si el producto actual no está entre los visibles
            if (store.set("visibleProducts", visibleProducts) && ! visibleProducts.includes(store.get("product"))) {
                // Seleccionamos el producto preferido
                var prefferedProduct = store.get("prefferedProduct")
                    , overlay = store.get("overlay");

                // Si el overlay actual está dentro de los overlays posibles del producto preferido lo establecemos
                if (products[prefferedProduct].overlays.includes(overlay))
                    return store.set("product", prefferedProduct);

                // Sino cogemos el primer producto que contenga el overlay
                var filteredProducts = visibleProducts.filter(
                    function(productname) {
                        return products[productname].overlays.includes(overlay)
                    });

                if ( filteredProducts.length )
                    store.set("product", filteredProducts[0]);
            }
        },
        betterProducts = function(map, type) {
            var productname, i, selectedproducts = type ? rscope.localPointProducts : rscope.localProducts, betterProducts = [];
            for (i = 0; i < selectedproducts.length; i++) {
                productname = selectedproducts[i];
                // Si el centro del mapa está dentro de los límites del modelo
                if ( products[productname].pointIsInBounds(map) )
                    betterProducts.push(productname);
            }
            return betterProducts
        };

    export default {
        betterProducts: betterProducts,
        getProduct: function(layer, product) {
            var layerproducts = layer2product[layer]
                , prefferedProduct = store.get("prefferedProduct");

            //FIXME: arreglar
            // if ( 2 === layerproducts.length )
            if (2 === layerproducts.length && layerproducts.includes("cams")){
                if(products.camsEu.pointIsInBounds(rscope.map))
                    return "camsEu";
                else
                    return "cams";
            }
            else if (layerproducts.includes(product))
                return product;
            else if (layerproducts.includes(prefferedProduct))
                return prefferedProduct;
                // else if ( layerproducts.includes("ecmwfWaves") && "ecmwf" === prefferedProduct )
                //     return "ecmwfWaves";
                // else if ( layerproducts.includes("gfsWaves") && "gfs" === prefferedProduct)
            //     return "gfsWaves";
            else
                return layerproducts[0];
            // }
        },
        layer2product: layer2product,
        overlay2product: overlay2product,
        getPointProducts: function(map) {
            return rscope.globalPointProducts.concat(betterProducts(map, true))
        }
    }

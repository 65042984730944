/**
 * Modulo que devuelve la clase base Class. El resto de clases se crean a partir de esta.
 *
 * @module Class
 */


export default  {

        /**
         * Extiende la clase actual asignando las propiedades que vienen en arguments. Cada argumento
         * deberia ser del tipo "propiedad: valor".
         *
         // * @returns {module:Class}
         */
        extend: function () {
            let Class = Object.create(this);
            for (let n = 0; n < arguments.length; n++) {
                let argumento = arguments[n];
                for (let propiedad in argumento)
                    Class[propiedad] = argumento[propiedad]
            }
            return Class
        },

        /**
         * Crea una instancia de la clase y la inicializa
         // * @returns {module:Class}
         */
        instance: function () {
            // Extiende las propiedades de la clase con las nuevas
            let objeto = this.extend.apply(this, arguments);

            // Si la clase tiene una funcion _init la ejecuta
            if ("function" == typeof objeto._init)
                objeto._init.call(objeto); //llama al constructor

            return objeto;
        }
    };

import Class from "./Class";
import trans from "./trans";
import utils from "./utils";
import lazyImages from "./lazyImages";

export default Class.extend({
        ident: "",
        dependencies: [],
        _init: function() {
            this.isLoaded = false,
                this.loading = false,
                this.depsLoaded = false,
                this.coreLoaded = false,
                this.open = this.load
        },

        load: function() {
            var plugin = this;

            if (this.isLoaded)
                return Promise.resolve(true);
            else if(this.loading)
                return this.promise;
            else {
                this.loading = true;
                this.promise = new Promise(function(resolve, reject) {
                    for (var loadingdeps = [], i = 0; i < plugin.dependencies.length; i++) {
                        var namedep = plugin.dependencies[i]
                            , plugindep = J.plugins[namedep]
                            , pluginpromise = plugindep.load();
                        plugindep && ! plugindep.isLoaded && loadingdeps.push(pluginpromise)
                    }
                    // plugin.langFile &&
                    // loadingdeps.push(trans.loadLangFile(plugin.langFile)),
                    Promise.all(loadingdeps).then(function() {
                        if (plugin.depsLoaded = true,
                            plugin.coreLoaded)
                            return plugin.isLoaded = true,
                                void resolve();
                        var modulo = plugin.ident;
                        import(`../plugins/${modulo}`).then(data => {
                            plugin.coreLoaded = true;
                            plugin.isLoaded = true;
                            plugin.loading = false;

                            if(data.plugincode && data.plugincode.html){

                                const pluginsdiv= document.querySelector("#plugins");
                                if (pluginsdiv) {
                                    let plugindiv = pluginsdiv.querySelector("plugin-" + modulo);
                                    if ( !plugindiv ) {
                                        plugindiv = document.createElement("div");
                                        plugindiv.id = "plugin-" + modulo;
                                        plugindiv.innerHTML = data.plugincode.html;
                                        trans.translateDocument(plugindiv);

                                        pluginsdiv.appendChild(plugindiv);

                                        const lazyImagesFunc = lazyImages();
                                        lazyImagesFunc(plugindiv);
                                    }
                                }
                            }

                            resolve(data.default);
                        }).catch(function(error) {
                            console.error("plugin", "Failed to load plugin: " + plugin.ident+ " " + error),
                                plugin.loading = false,
                                reject(plugin)
                        })
                    }).catch(function(t) {
                        console.error("plugin", "Plugin error: " + plugin.ident, t),
                            reject()
                    })
                });
                return this.promise;
            }

        },
        open: function() {},
        close: function() {},
        toggle: function() {}
    });

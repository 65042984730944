import store from "./store";
import broadcast from "./broadcast";
import products from "./products";
import Animation from "./Animation";
import overlays from "./overlays";
import trans from "./trans";
import dataLayer from "./dataLayer";
import utils from "./utils";

class TimeSliderController{

    constructor(view, animation) {
        this.view = view;
        this.nslot = -1;

        if (animation)
            this.animation=animation;
        else
            this.animation=this.createAnimation();

        this.view.bindHandleStartAnimation(this.startAnimation.bind(this));
        this.view.bindHandleStopAnimation(this.stopAnimation.bind(this));
        this.view.bindHandleSetTimestamp(this.setTimestamp.bind(this));
        this.view.bindHandleTs2Day(this.ts2Day.bind(this));
        this.view.bindHandleClickPremium(this.clickPremium.bind(this));


        const product = products[store.get('product')],
            calendar = product.calendar;

        this.registerTimestampEvent();

        // this.view.bindChangeTimeStamp(this.changeTimeStamp.bind(this));
        this.view.hourSelector.addEventListener("scroll", this.onscroll.bind(this))
        // this.hourSelector?.addEventListener("scroll", this.onscroll.bind(this))
        // this.view.updateTimeSteps()

        // store.on("product", this.update.bind(this));
        store.on("calendar", this.update.bind(this));

        store.on("overlay", this.updateHourDay.bind(this));

        if (calendar)
            this.update(calendar);

        store.on("usedLang", function () {
            let product = products[store.get('product')],
                calendar = product.calendar;

            this.update(calendar);
        }.bind(this));

        broadcast.on("redrawFinished", function () {
            this.view.setRedrawFinished(true);
            // if (!this.view.playing)
            this.view.unsetLoading();

        }.bind(this));

        this.animation.on("timeAnimationElapsed", function (displacement){
            this.view.displaceScroll(displacement);
        }.bind(this));

        this.animation.on("timeAnimationLoading", function (isLoading){
            if(isLoading)
                this.view.setLoading();
            else
                this.view.unsetLoading();

        }.bind(this))

        this.animation.on("timeAnimationStart", function (){
                this.view.block();
        }.bind(this));

        this.animation.on("timeAnimationStop", function (){
            this.view.unblock();
        }.bind(this));

        const tscontroller = this;
        ["product", "overlay", "level"].forEach(
            function (itemchaged) {
                store.on(itemchaged, function () {
                    this.view.setLoading();
                }.bind(tscontroller))
            }
        );
    }

    startAnimation(loopSteps, futuresteps){
        this.pushEvent()
        this.animation.startAnimation(loopSteps, futuresteps);
    }

    pushEvent(){
        dataLayer.push({
            'ga4_action_name': 'visor_play',
            'ga4_tag_name': 'click'
        })
    }

    stopAnimation(){
        this.animation.stopAnimation();
    }

    createAnimation(){
        return new Animation();
    }

    registerTimestampEvent(){
        store.on("timestamp", this.updateTimestampView.bind(this));
    }


    updateTimestampView(ts){
        const nslot = this.calendar.indxBestTs(ts);
        this.view.changeSlot(nslot);
        this.updateHourDay();
    }


    getNslotTimestamp(){
        const calendar = this.getCalendar()
        return this.nslot < 0 ? calendar.timestamps[0] + calendar.step * this.nslot : calendar.timestamps[this.nslot];
    }

    updateHourDay(){
        const calendar = this.getCalendar();
        const overlay = overlays[store.get("overlay")];


        const ts = this.getNslotTimestamp()


        this.view.setDayText(calendar.ts2day(ts));

        let hourText = calendar.ts2hour(ts);
        if (overlay.showDay) {

            const today = new Date();
            const todayTs = Date.UTC (today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);

            const selectedDate = new Date(ts);
            const selectedDateTs = Date.UTC (selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0);

            const day = (selectedDateTs - todayTs) / (1000 * 24 * 60 * 60);
            if (overlay.showDay && day === 0)
                hourText = `${trans.TODAY}: ${calendar.ts2hour(ts)}`
            else if (overlay.showDay && day === 1)
                hourText = `${trans.TOMORROW}: ${calendar.ts2hour(ts)}`
        }
        this.view.setHourText(hourText);
    }

    getCalendar(){
        return this.calendar;
    }

    getTimestamp(){
        return store.get("timestamp");
    }

    setTimestamp(timestamp){

        store.set("timestamp", timestamp);
    }

    ts2Day(timestamp){
        const calendar = this.getCalendar();
        return calendar.ts2day(timestamp);
    }

    changeTimeStamp(nslot){
        // let product = products[store.get('product')],
        //     calendar = product.calendar;
        const calendar = this.getCalendar();

         // new Promise(function(resolve){
         //   setTimeout(function(){ store.set("timestamp", calendar.timestamps[this.nslot])}.bind(this), 100)
         // }.bind(this));
        // return new Promise(function (resolve){
        //     store.set("timestamp", calendar.timestamps[this.nslot]);
        //     resolve (calendar.timestamps[this.nslot]);
        // }.bind(this));

        // if (calendar)
        this.setTimestamp(calendar.timestamps[nslot]);
        // store.set("timestamp", calendar.timestamps[nslot]);
        // console.info("timestamp: " + calendar.timestamps[nslot]);
        // dataLoader.resolveTiles();
    }

    onscroll(){

        // let product = products[store.get('product')],
        //     calendar = product.calendar;

        if (this.nslot !== this.view.getNslot() ) {

            this.nslot = this.view.getNslot();

            // store.set("timestamp", calendar.timestamps[this.nslot]);
            // let starttime = window.performance.now();
            if (! this.view.playing)
                this.changeTimeStamp(this.nslot);
            // let endtime = window.performance.now();

            // console.info ("tiempo: "+ (endtime - starttime).toString() )

            this.updateHourDay();
        }
    }

    update(calendar){

        this.calendar = calendar;

        // this.registerTimestampEvent();

        let tsnow = calendar.bestTs( new Date ()),
            tsnowstr = calendar.ts2hour(tsnow),
            selectedTs = calendar.bestTs(this.getTimestamp()),
            selectedTsStr = calendar.ts2day(selectedTs);

        this.view.updateTimeSteps(
            calendar.timestamps.map(
                function( ts ){
                    let hourstr = calendar.ts2hour(ts);

                    return  {ts, text: hourstr };
                }
            ),
            { ts: tsnow, text: tsnowstr },
            { ts: selectedTs, text: selectedTsStr}

        );



    }


    clickPremium(){
        const nameoverlay = store.get("overlay");
        utils.openLinkPremium(nameoverlay)
    }



}

export default TimeSliderController;

import lrucache from "./lruCache";
import rootscope from "./rootScope";
import broadcast from "./broadcast";


var cache = new lrucache(50)
    , nerrors = 0
    , maxerrors = rootscope.isMobile ? 3 : 6;

var canvas = document.createElement("canvas")
    , ctx = canvas.getContext("2d");

function fgTileLoader(url) {
    this.url = url;
    this.foretile = null;
        // this.renderedtile = renderedtile,
    this.status = "undefined";
    return this;
}

fgTileLoader.prototype.load = function() {
    var loader = this;
    // var tile = this.renderedtile;

    this.status = "loading";
    this.promise = new Promise(function(resolve, reject) {
            var foretile = new Image;
            foretile.crossOrigin = "Anonymous";

            foretile.onload = function() {

                loader.foretile = foretile;

                const forecanvas = L.DomUtil.create("canvas");
                forecanvas.width = foretile.width;
                forecanvas.height = foretile.height;

                const ctx = forecanvas.getContext("2d");

                ctx.drawImage(foretile, 0, 0);

                // Deja cargados los datos de la tesela Foreground, para que solo se haga una vez y mejore el rendimiento
                loader.data= ctx.getImageData(0,0,forecanvas.width, forecanvas.height);
                loader.status = "loaded";

                nerrors = 0;
                resolve(loader);
            }

            foretile.onerror = function() { // Función en caso de fallo

                loader.status = "failed";
                broadcast.emit("loadingFailed", loader.url);
                ++nerrors > maxerrors && (broadcast.emit("noConnection"),
                    nerrors = 0);
                reject("Failed to load tile: " + loader.url);
            }

            foretile.src = loader.url;   // URL de la tesela ForeGround

        }
    );
    return this.promise;
};

export default {
    loadTile: function(url) {
        // var url = whichTile.url             // Buscamos en caché si existe el loader para la url
        var loader = cache.get(url);

        if (!loader)                        // Si no existe, lo creamos, metemos en caché y devolvemos la promesa
            return loader = new fgTileLoader(url),
                cache.put(url, loader),
                loader.load();
        // Chequeamos el status
        switch (loader.status) {
            case "loaded":
                return Promise.resolve(loader);
            case "loading":
                return loader.promise;
            case "failed":
                return cache.remove(url),
                    Promise.reject("Failed to load tile: " + url)
        }
    }
}

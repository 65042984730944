/**
 * Modulo que devuelve una función que permite renderizar las teselas del visor a partir de las teselas de datos
 *
 * @module renderTile
 */

import render from "./render"
import layers from "./layers"
import fgLoader from "./fgTileLoader";
import utils from "./utils";


/**
 * Función que renderiza una tesela
 *
 * @param {Number} inc             Incremento todo
 * @param {Object} canvas          Objeto Canvas donde se renderiza la tesela
 * @param {Number} syncCounter     Contador de sincronización. Evita que se pinten teselas anteriores al último cambio
 *                                 de parámetros
 * @param {Object} infotile
 * @param {Object} dataloader
 * @param {string} urlFGTile
 *
 * @alias module:renderTile
 */
let exports = function(inc, canvas, syncCounter, infotile, dataloader, urlFGTile) {

    if (syncCounter === this.syncCounter) {
        Date.now();
        inc |= 0;
        let testTransparency,
            lparams = this.latestParams, // "this" es un TileLayerCanvas
            isMultiColor = lparams.isMultiColor,
            datawindy = dataloader.data,
            ctx = canvas.getContext("2d"),
            canvastmp = document.createElement("canvas"),
            imgdata = render.imgData.data, renderctx;

        canvastmp.width = 256;
        canvastmp.height = 256;
        renderctx = canvastmp.getContext("2d");

        "png" === lparams.fileSuffix ? lparams.PNGtransparency && (testTransparency = render.testPNGtransparency) : lparams.JPGtransparency && (testTransparency = render.testJPGtransparency);

        let wTL, wTR, wBL, wBR, wx, wy, v1, v2, v3, fill1, fill2, ncol, nrow,
            transparencyOK = 0,
            trans = 0 | infotile.trans,                 // Cuantas teselas pinto por tesela windy en ancho
            translog2 = 0 | Math.log2(trans),           // Log2 de trans
            ntiles = 0 | Math.log2(trans * trans),    // Cuantas teselas puedo pintar por tesela windy
            intx = 0 | infotile.intX,                   // Pos X dentro de la tesela windy
            inty = 0 | infotile.intY,                   // Pos Y dentro de la tesela windy
            wPixelsMiniTile = 256 >> translog2,         // 256/(n teselas en 1 tesela windy) --> relación resolución entre tesela normal y windy
                                                        // Dentro de una tesela windy cuantos pixeles (en ancho) tiene cada minitesela
            wtable = render.getWTable(trans),           // Tabla de pesos para interpolación bilineal
            poswtable = 0,
            pos = 0,
            x0 = intx * wPixelsMiniTile | 0,            // Dentro de la tesela windy coord X donde comienza la minitesela que se usa para renderizar
            y0 = inty * wPixelsMiniTile | 0,            // Dentro de la tesela windy coord Y donde comienza la minitesela que se usa para renderizar
            intXMiniTile = 0,  intYMiniTile = 0,
            intXMiniTilePrev = 256, nrowwindy = 0, ncolwindy=0, xwtable = 0, ywtable = 0,
            value1TL = 0, value1TR = 0, value1BL = 0, value1BR = 0,
            value2TL = 0, value2TR = 0, value2BL = 0, value2BR = 0,
            value3TL = 0, value3TR = 0, value3BL = 0, value3BR = 0,
            layer = layers[lparams.layer],              // Se obtienen la capa
            renderFromG = "G" === lparams.renderFrom,
            renderFromB = "B" === lparams.renderFrom,   // Si se renderiza desde el canal B
            renderFromRG = "RG" === lparams.renderFrom, // Si se renderiza desde el canal R y G
            renderFromRGB = "RGB" === lparams.renderFrom, // Si se renderiza desde los canale R, G y B
            decode1 = dataloader.decodeR,               // Función que decodifica del canal R
            decode2 = dataloader.decodeG,               // Función que decodifica del canal G
            decode3 = dataloader.decodeB,
            promiseFgTile = null,
            layercanvas = this;

        if (urlFGTile)
            promiseFgTile = fgLoader.loadTile(urlFGTile);

        // /**
        //  * Interpola un valor a partir de sus 4 vecinos
        //  *
        //  * @param {Number} valueTL       Valor del vecino arriba a la izquierda
        //  * @param {Number} valueTR       Valor del vecino arriba a la derecha
        //  * @param {Number} valueBL       Valor del vecino abajo a la izquierda
        //  * @param {Number} valueBR       Valor del vecino abajo a la derecha
        //  * @param {Function} decodeFun   Función que decodifica el valor
        //  * @returns {Number}             Valor interpolado y decodificado
        //  *
        //  * @private
        //  */
        // let interpolateValue = function ( valueTL, valueTR, valueBL, valueBR, decodeFun ){
        //     let value;
        //
        //     // Si está disponible la tabla de pesos
        //     if( wtable ) {
        //         value = valueTL * wtable[poswtable] +
        //             valueTR * wtable[poswtable + 1] +
        //             valueBL * wtable[poswtable + 2] +
        //             valueBR * wtable[poswtable + 3] >> ntiles;
        //
        //     }else
        //         value = valueTL * wTL + valueTR * wTR + valueBL * wBL + valueBR * wBR;
        //
        //     return decodeFun(value);
        //
        // }
        let interpolateValue = render.interpolateBilineal;

        if ( isMultiColor ) {
            if(renderFromRGB)
                fill2 = render.createCombinedFill3paramFun(imgdata, inc, layer.getColor(), layer.getColor2(), layer.getColor3(), layer.getAmountByColor);
            else
                fill2 = render.createCombinedFill2paramFun(imgdata,inc, layer.getColor(), layer.getColor2(), layer.getAmountByColor);

            fill1 = render.createFillFun(imgdata, inc, layer.getColor());
        } else
            fill1 = fill2 = render.createFillFun(imgdata, inc, layer.getColor(), layer.bgColor);

        if ( renderFromG )
            decode1 = dataloader.decodeG;               // Si se renderiza desde B la función decode1 cambia
        if ( renderFromB )
            decode1 = dataloader.decodeB;               // Si se renderiza desde B la función decode1 cambia

        for (nrow = 0; nrow < 256; nrow += inc) {
            intYMiniTile = nrow >> translog2;           // intYMiniTile = nrow / trans --> pos Y dentro de la minitesela dentro de la tesela windy
            ywtable = nrow - (intYMiniTile << translog2);              // ywtable es la coord Y dentro de la tabla wtable del peso que hay que coger

            for (ncol = 0; ncol < 256; ncol += inc) {

                intXMiniTile = ncol >> translog2;       // intXMiniTile = nrow / trans --> pos X dentro de la minitesela dentro de la tesela windy
                xwtable = ncol - (intXMiniTile << translog2);                   // xwtable es la coord X dentro de la tabla wtable del peso que hay que coger

                if (intXMiniTilePrev !== intXMiniTile) {                // Se entra solo si se cambia de minitesela
                    nrowwindy = intYMiniTile + y0;      // Pos Y dentro de la tesela windy del pixel Top Left
                    ncolwindy = intXMiniTile + x0;
                    pos = 2056 + ncolwindy + ((nrowwindy << 8) + nrowwindy) << 2; // Se calcula pos dentro del array de datos de la tesela windy
                    // nrowwindy * (256 + 1) ya que la tesela windy lleva una columna más
                    // Luego se suma ncolwindy y se multiplica por 4 ya que cada pixel son 4 bytes
                    // 8*257 = 2056 es el espacio que ocupan los cuadrados de codificación float.

                    if (testTransparency)
                        transparencyOK = testTransparency(datawindy, pos);

                    if (renderFromG)
                        pos += 1;

                    if (renderFromB)
                        pos += 2;      // Se usa el canal B en vez de el R

                    value1TL = datawindy[pos];
                    value1TR = datawindy[pos + 4];
                    value1BL = datawindy[pos + 1028];
                    value1BR = datawindy[pos + 1032];

                    if (renderFromRG || renderFromRGB) {   // Se usa el canal R y G
                        value2TL = datawindy[pos + 1];
                        value2TR = datawindy[pos + 5];
                        value2BL = datawindy[pos + 1029];
                        value2BR = datawindy[pos + 1033];
                    }

                    if (renderFromRGB) {              // Se usa el canal R, G y B
                        value3TL = datawindy[pos + 2];
                        value3TR = datawindy[pos + 6];
                        value3BL = datawindy[pos + 1030];
                        value3BR = datawindy[pos + 1034];
                    }
                    intXMiniTilePrev = intXMiniTile
                }

                if (transparencyOK)
                    fill1(ncol, nrow, NaN);
                else {
                    if ( wtable ) {
                        poswtable = xwtable + (ywtable << translog2) << 2;      // Pos dentro de wtable
                    } else {  // Si no existe la tabla wtable calculamos los pesos e interpolamos
                        wx = xwtable / trans;
                        wy = ywtable / trans;
                        wTL = (1 - wx) * (1 - wy);
                        wTR = wx * (1 - wy);
                        wBL = wy * (1 - wx);
                        wBR = wx * wy;
                    }

                    v1 = interpolateValue(wtable, ntiles, poswtable, value1TL, value1TR, value1BL, value1BR, wTL, wTR, wBL, wBR, decode1 );

                    if (renderFromRG || renderFromRGB) {      // Si se renderiza los canales R y G se saca el valor del canal G
                        v2 = interpolateValue(wtable, ntiles, poswtable, value2TL, value2TR, value2BL, value2BR, wTL, wTR, wBL, wBR, decode2 );
                    }

                    if (renderFromRGB) {      // Si se renderiza los canales R y G se saca el valor del canal G
                        v3 = interpolateValue(wtable, ntiles, poswtable, value3TL, value3TR, value3BL, value3BR, wTL, wTR, wBL, wBR, decode3 );
                    }


                    if (isMultiColor)
                        fill2(ncol, nrow, v1, v2, v3);
                    else
                        fill2(ncol, nrow, renderFromRG ? Math.sqrt(v1 * v1 + v2 * v2) : v1); // Si renderFromRG no es null calculamos el módulo (viento)

                }
            }
        }
        renderctx.putImageData(render.imgData, 0, 0);

        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(canvastmp,0,0,256,256, 0, 0, canvas.width, canvas.height);

        if (promiseFgTile)
            promiseFgTile.then(
                fgloader => {
                    if (syncCounter === layercanvas.syncCounter) {

                        const ctx = canvas.getContext("2d", {willReadFrequently: true});
                        const canvasdata = ctx.getImageData(0,0,canvas.width, canvas.height);

                        utils.overlapcanvas(canvasdata, fgloader.data, canvas.width, canvas.height);

                        ctx.putImageData(canvasdata, 0, 0);
                    }
                }
            )

        // // // "pattern" in lparams && lparams.pattern in W && W[lparams.pattern].addPattern(ctx, trans, datawindy, 2056, x0, y0, wPixelsMiniTile, decode1, decode2)
    }

}

export default exports;
// });

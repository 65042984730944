import renderer from "./Renderer";
import testWebGl from "./testWebGl";
import map from "./map";
import tileLayer from "./tileLayer";
import utils from "./utils";
import broadcast from "./broadcast";
import tileInterpolator from "./tileInterpolatorInstance";
import render from "./render";
import store from "./store";

// W.define("renderers", ["Renderer", "testWebGl", "map", "plugins", "tileLayer", "utils", "tileInterpolator"], function(renderer, t, map, i, tileLayer, a, r) {

var TileLayerRenderer = renderer.extend({
    onopen: function( params ) {
        if ( map.hasLayer(tileLayer) )
            tileLayer.paramsChanged.call(tileLayer, params);
        else
        {
            // console.info("Abriendo tilelayer");
            tileLayer.init(params);
            tileLayer.addTo(map);
            tileLayer.getContainer().classList.add("overlay-layer");
            broadcast.emit("tileLayerOpened");
        }
    },
    interpolator: tileInterpolator,
    paramsChanged: tileLayer.paramsChanged.bind(tileLayer),
    redraw: tileLayer.redrawLayer.bind(tileLayer),
    onclose: function(e) {
        // console.info("Cerrando tilelayer");
        e.includes("tileLayer") || e.includes("tileLayerPatternator") || e.includes("efi") || map.removeLayer.call(map, tileLayer);
        broadcast.emit("tileLayerClosed");

    }
})
    , renderers = {};
renderers.tileLayer = TileLayerRenderer.instance();
//     l.tileLayerPatternator = o.instance({
//         dependency: "patternator",
//         onopen: function(e) {
//             W.require("rainPattern"),
//                 W.require("ptypePattern"),
//                 this.onopen = o.onopen.bind(this),
//                 o.onopen.call(this, e)
//         }
//     }),
//     l.radar = e.instance({
//         dependency: "radar",
//         onopen: function() {
//             this.onopen = i.radar.onopen,
//                 this.onclose = i.radar.onclose,
//                 this.redraw = i.radar.onredraw,
//                 this.interpolator = W.require("radarInterpolator")
//         }
//     }),
//     l.satellite = e.instance({
//         dependency: "satellite",
//         onopen: function() {
//             this.onopen = i.satellite.onopen,
//                 this.onclose = i.satellite.onclose,
//                 this.redraw = i.satellite.onredraw,
//                 this.interpolator = i.satellite.interpolator
//         }
//     }),
//     l.capAlerts = e.instance({
//         dependency: "cap-alerts",
//         onopen: function() {
//             var e = i["cap-alerts"];
//             this.onopen = function(t) {
//                 e.onopen(t),
//                     e.isOpen = !0
//             }
//                 ,
//                 this.onclose = function() {
//                     e.onclose(),
//                         e.isOpen = !1
//                 }
//         }
//     }),

let selectedPois;
const changeTsFunc = (ts) => {render.emit("rendered", "symbolsLayer");}
renderers.symbols = renderer.instance({
    onopen: function( params ) {
        selectedPois = store.get("pois");
        render.emit("updateBasemap", {coverWholeMap:false, hasSea: false, insideBounds:false});



        store.set("pois", "symbols");

        broadcast.emit("tileLayerOpened");



        store.on("timestamp", changeTsFunc);
        // broadcast.emit("redrawFinished");
        render.emit("rendered", "symbolsLayer")

        // if (params.daily)
        // store.set("day", 1);

    },
    onclose: function (){
        store.off("timestamp", changeTsFunc);
        if(selectedPois !== "symbols")
            store.set("pois", selectedPois);
    }
});

renderers.radar = renderer.instance({
    dependency: "radar",
    interpolator: tileInterpolator,
    onopen: function(params) {
            const core = this.core;
            this.onopen = core.onopen;
            this.onclose = core.onclose;
            this.redraw = core.redraw;
            core.onopen(params);
            this.interpolator = core.interpolator;
            // this.redraw = core.onredraw;
//                 this.interpolator = W.require("radarInterpolator")
    }
});

renderers.satellite = renderer.instance({
    dependency: "satellite",
    onopen: function(params) {
        const core = this.core;
        this.onopen = core.onopen;
        this.onclose = core.onclose;
        this.redraw = core.redraw;
        this.paramsChanged = core.paramsChanged;
        core.onopen(params);
        // this.redraw = core.onredraw;
//                 this.interpolator = W.require("radarInterpolator")
    }
});

renderers.warnings = renderer.instance({
    dependency: "warnings",
    onopen: function(e) {
        var core = this.core;
        this.onopen = core.onopen,
            this.onclose = core.onclose,
            this.paramsChanged = core.paramsChanged,
            this.redraw = core.redraw,
            core.onopen(e)
    }
});

renderers.hurricanes = renderer.instance({
    dependency: "hurricanes",
    onopen: function(e) {
        var core = this.core;
        this.onopen = core.onopen,
            this.onclose = core.onclose,
            this.paramsChanged = core.paramsChanged,
            this.redraw = core.redraw,
            core.onopen(e)
    }
});

renderers.isolines = renderer.instance({
    dependency: "isolines",
    onopen: function(e) {
        // var t = J.require("isolines");
        var core = this.core;
        this.onopen = core.onopen,
            this.onclose = core.onclose,
            this.paramsChanged = core.paramsChanged,
            this.redraw = core.redraw,
            core.onopen(e)
    }
});

// renderers.picker = renderer.instance({
//     dependency: "picker",
//     onopen: function(e) {
//         // var t = J.require("isolines");
//         var core = this.core;
//         this.onopen = core.onopen;
//         this.onclose = core.onclose;
//         this.paramsChanged = core.paramsChanged;
//         this.redraw = core.redraw;
//         core.onopen(e);
//     }
// });

var rendererparticles = renderer.instance({
    dependency: "particles",
    onopen: function(e) {
        // var t = require('./particlesOld');
        var core = this.core;
        this.paramsChanged = core.paramsChanged,
            this.onclose = core.close,
            this.redraw = core.redraw,
            core.open(e)
    }
})
, rendererGlParticles = renderer.instance({
    dependency: "gl-particles",
    onopen: function( e) {
        // var t = J.require("gl-particles");
        var core = this.core;
        this.paramsChanged = core.paramsChanged;
        this.onclose = core.close;
        this.redraw = core.redraw;

        if (! core.open(e)) {
            renderers.particles = rendererparticles;
            this.close();
            rendererparticles.open(e);
        }
    }
});

// renderers.particles = rendererparticles;
renderers.particles = testWebGl.useGLparticles() ? rendererGlParticles : rendererparticles;
// renderers.particles = rendererGlParticles;

// return l.particles = t.useGLparticles() ? d : c,
//     l.map = e.instance({
//         dependency: "map-layers",
//         onopen: function() {
//             var e = i["map-layers"];
//             this.onopen = e.onopen,
//                 this.onclose = e.onclose,
//                 this.redraw = a.emptyFun,
//                 this.paramsChanged = a.emptyFun,
//                 this.interpolator = W.require("elevationInterpolator")
//         }
//     }),
//     l.efi = o.instance({
//         dependency: "extreme-forecast",
//         onopen: function(e) {
//             o.onopen.call(this, e),
//                 i["extreme-forecast"].onopen()
//         },
//         onclose: function(e) {
//             o.onclose.call(this, e),
//                 i["extreme-forecast"].onclose()
//         }
//     }),
export default renderers;

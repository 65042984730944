/**
 * Modulo que devuelve la clase Evented, que permite gestionar la suscripción de funciones callback a eventos.
 *
 * @module Evented
 */

import Class from './Class';
    // let latestBcasts = [];
    // W.latestBcasts = t,

export default Class.extend(
    {

        /**
         * Inicializa una instancia de la clase Evented
         *
         * @private
         */
        _init: function() {
            this.latestId = 1;
            this._eventedCache = {};
            this.trigger = this.emit;
            this.fire = this.emit;
        },

        /**
         * Ejecuta todas las funciones callback suscritas a un evento.
         *
         * @param {string} key     Nombre del evento
         * @param {*}      [arg1]  Primer argumento que se pasa a las funciones callback
         * @param {*}      [arg2]  Segundo argumento que se pasa a las funciones callback
         * @param {*}      [arg3]  Tercer argumento que se pasa a las funciones callback
         * @param {*}      [arg4]  Cuarto argumento que se pasa a las funciones callback
         */
        emit: function(key, arg1, arg2, arg3, arg4) {

            // let ident = this.ident;
            //
            // latestBcasts.push({
            //     ts: Date.now(),
            //     txt: ident + ": " + key + ("string" == typeof arg1 ? " " + arg1 : "")
            // });
            //
            // if ( latestBcasts.length > 5 )
            //     latestBcasts.shift();

            // Se obtiene el evento desde la caché de eventos
            let event = this._eventedCache[key];

            if (event)
                // Se recorren todos los elementos subscritos al evento
                for (let index = event.length; index--; ) {
                    let element = event[index];
                    try {
                        // Se ejecuta la función callback del elemento
                        element.callback.call(element.context, arg1, arg2, arg3, arg4);
                        // Si once es true se desuscribe el elemento para que no se ejecute
                        // en posteriores llamadas al aevento
                        if ( element.once )
                            this.off(element.id);

                    } catch (t) {
                        console.error("Evented", "Failed to call " + key, t)
                    }
                }
        },

        /**
         * Subscribe una función callback a un evento.
         *
         *
         * @param   {string}   key        Nombre del evento
         * @param   {function} callback   Función que se registra en el evento
         * @param   {object}   [context]  Objeto que se usa como 'this' en la función
         * @param   {boolean}  [once]     Si es true la función callback solo se ejecuta la primera vez
         *
         * @returns {number}              El identificador del evento
         */
        on: function(key, callback, context, once) {
            // Si el evento no está registrado en la caché de eventos
            // se crea.
            if (! (key in this._eventedCache) )
                this._eventedCache[key] = [];

            // Se añade un nuevo elemento al evento que contiene
            // la función callback.
            this._eventedCache[key].push({
                id: ++this.latestId,
                callback: callback,
                context: context || this,
                once: once
            });

            return this.latestId
        },

        /**
         *
         * Subscribe una función callback a un evento. Solo se ejecutará la primera vez
         * que se llame al evento.
         *
         * @param   {string}   key        Nombre del evento
         * @param   {function} callback   Función que se registra en el evento
         * @param   {object}   [context]  Objeto que se usa como 'this' en la función
         *
         * @returns {number}              El identificador del evento
         */
        once: function(key, callback, context) {
            return this.on(key, callback, context,true);
        },

        // Elimina un objeto de un evento
        /**
         *
         * Desuscribe una función callback de un evento
         *
         * @param   {string|number} key         Nombre del evento o ID del elemento regitrado
         * @param   {function}      [callback]
         * @param   {object}        [context]
         */
        off: function(key, callback, context) {

            // Si key es de tipo númerico hay que buscar el elemento que tenga un ID igual que ese valor
            if ("number" == typeof key)
                // Se busca entre los elementos registrados en todos los eventos
                for (let keytmp in this._eventedCache) {

                    let elementsarray = this._eventedCache[keytmp];

                    if (elementsarray) {
                        for (let j = elementsarray.length; j--; )
                            // Si se encuentra el elemento con id == key se elimina
                            if ( elementsarray[j].id === key )
                                elementsarray.splice(j, 1);

                        // si el número de elementos sucritos al evento se queda en 0 se borra
                        // el evento de la caché de eventos
                        if ( 0 === elementsarray.length )
                            delete this._eventedCache[keytmp];
                    }
                }
            else {
                // Si key es de tipo string se accede directamente al array de elementos
                // suscritos al evento
                let elements = this._eventedCache[key];
                if (elements) {
                    for (let i = elements.length; i--; )
                        // Si coincide el callback y el context (o directamente no hay context)
                        // con los del  elemento se elimina
                        if ( elements[i].callback === callback &&
                            ( ! context || context === elements[i].context ) )
                            elements.splice(i, 1);

                    // si el número de elementos sucritos al evento se queda en 0 se borra
                    // el evento de la caché de eventos
                    if ( 0 === elements.length )
                        delete this._eventedCache[key];
                }
            }
        }
    }
);

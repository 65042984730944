import utils from "./utils";
import map from "./map";
import loader from "./dataLoader";
import render from "./render";
import Class from "./Class";
import scope from "./rootScope";

// define("DataTiler",["utils", "map", "dataLoader", "render", "Class", "rootScope"], function(utils, map, loader, render, Class, scope) {  // e, t,
// W.define("DataTiler", ["utils", "dataLoader", "render", "Class", "rootScope"], function(utils, loader, render, Class, scope) {  // e, t,
    export default Class.extend({
        _tag: "DataTiler",
        loader: loader,
        syncCounter: 0,
        subdomains: 'abc',
        _getSubdomain: function (tilePoint) {
            const index = Math.abs(tilePoint.x + tilePoint.y) % this.subdomains.length;
            return this.subdomains[index];
        },
        getTiles: function(paramspromise) {
            var datatiler = this,
                // map = this.map,
                zoom = map.getZoom();
            if (Math.floor(zoom) === zoom) {
                this.syncCounter++;
                var bounds = map.getPixelBounds(),
                    tilesCoords = [],
                    minX = bounds.min.x >> 8,
                    maxX = bounds.max.x >> 8,
                    minY = bounds.min.y < 0 ? 0 : (bounds.min.y >> 8),
                    maxY = bounds.max.y >= ( render.tileW(zoom) << 8 ) ?  ( render.tileW(zoom) - 1 ) : ( bounds.max.y  >> 8 );

                // Sacamos las coordenadas de todas las teselas que se van a pintar en el mapa y las apilamos en tilesCoords
                for (var y = minY; y <= maxY; y++)
                    for (var x = minX; x <= maxX; x++) {
                        var coords = map.activeLayer._wrapCoords.call(map.activeLayer, {
                            x: x,
                            y: y,
                            z: zoom
                        });
                        tilesCoords.push(coords)
                    }

                paramspromise.then((params) => {
                    var mapscopeclone = utils.clone(scope.map)
                        , dataZoom = render.getDataZoom(params, zoom)
                        , mapscope = Object.assign(mapscopeclone, {
                        pixelOriginX: bounds.min.x,
                        pixelOriginY: bounds.min.y,
                        dZoom: dataZoom,
                        origTiles: {
                            minX: minX,
                            minY: minY,
                            maxX: maxX,
                            maxY: maxY
                        }
                    })
                        , urlsloading = {}
                        , promises = [];

                    // Para cada tesela que se va a pintar que se va a pintar en el mapa
                    tilesCoords.forEach(function(coords) {
                        var whichTile = render.whichTile(coords, params); // Sacamos los parámetros de la tesela de datos que necesitamos
                        whichTile.s = datatiler._getSubdomain(coords);
                        if ((whichTile = datatiler.processTile(whichTile, params)) && !urlsloading[whichTile.url]) {
                            urlsloading[whichTile.url] = 1;
                            var promiseLoad = datatiler.loader.loadTile(whichTile); // Comenzamos la carga de la tesela de datos
                            promises.push(promiseLoad)                              // apilamos la promesa
                        }
                    });
                    // Cuando terminen todas las cargas de las teselas de datos ejecutaremos postProcess
                    Promise.all(promises).then(this.postProcess.bind(this, this.syncCounter, mapscope, params))

                })

            }
        },
        processTile: function(coords) {
            return coords
        },
        postProcess: function(syncCounter, mapscope, params, dtiles) {
            if (syncCounter === this.syncCounter) {
                // Se ordenan las teselas de datos de menor a mayor para x e y
                var sortedTiles = this.sortTiles(mapscope, params, dtiles);
                // Transición entre zoom de datos y original
                this.trans = render.getTrans(mapscope.zoom, mapscope.dZoom),
                    // log2 de trans
                    this.shift = Math.log2(this.trans),
                    this.lShift = Math.log2(this.trans * this.trans);

                var x = mapscope.pixelOriginX / this.trans % 256        // coordenada X interna dentro de la tesela de datos para pixelOriginX
                    , y = mapscope.pixelOriginY / this.trans % 256;     // coordenada Y interna dentro de la tesela de datos para pixelOriginY
                x < 0 && (x = 256 + x);
                this.offsetX = x * this.trans | 0, // Dentro de la primera tesela de datos, X que corresponde con el punto TL del mapa
                    this.offsetY = y * this.trans | 0, // Dentro de la primera tesela de datos, Y que corresponde con el punto TL del mapa
                    this.offset = 2056,                // Offset de los cuadraditos de colores de codificación
                    this.width = mapscope.width,       // Ancho del mapa
                    this.height = mapscope.height,     // Alto del mapa
                    this.w = render.getWTable(this.trans),  // Tabla de pesos de interpolación bilineal
                    this.tilesReady.call(this, sortedTiles, mapscope, params)  // LLama a tilesReady que implementará una clase hija
            }
        },
        sortTiles: function(mapscope, params, dtiles) {
            function whichTile(x, y, mapscope) {
                var coords = map.activeLayer._wrapCoords.call(map.activeLayer, {
                    x: x,
                    y: y,
                    z: mapscope.zoom
                });
                return render.whichTile(coords, params)
            }

            var sorteddtiles = [], xprev, yprev, sortRow = function(y) {
                var coordsDataTile = whichTile(mapscope.origTiles.minX, y, mapscope);

                if (!coordsDataTile || coordsDataTile.y !== yprev) {
                    xprev = null;
                    for (var rowdtiles = [], x = mapscope.origTiles.minX; x <= mapscope.origTiles.maxX; x++)
                        if ((coordsDataTile = whichTile(x, y, mapscope)) && coordsDataTile.x !== xprev) {
                            var dtileselected = dtiles.filter(
                                function(loader) {
                                    return loader.x === coordsDataTile.x && loader.y === coordsDataTile.y
                                })[0];

                            rowdtiles.push(dtileselected),
                                xprev = coordsDataTile.x,
                                yprev = coordsDataTile.y
                        }
                    rowdtiles.length > 0 && sorteddtiles.push(rowdtiles)
                }
            };

            for (var y = mapscope.origTiles.minY; y <= mapscope.origTiles.maxY; y++)
                sortRow(y);

            return sorteddtiles
        }
    })
// });

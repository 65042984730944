const legends = {
    cbase: {
        description: ["m", "ft"],
        lines: [[0, 0, 0], [200, 300, 1e3], [500, 500, 1500], [1500, "1.5k", 5e3]]
    },
    sst: {
        description: ["°C", "°F"],
        lines: [[-3, -3, 27], [0, 0, 30], [2, 2, 35], [4, 4, 39], [6, 6, 43], [8, 8, 46], [10, 10, 50],
            [12, 12, 53], [14, 14, 57], [16, 16, 60], [18, 18, 65],
            [20, 20, 68], [22, 22, 72], [24, 24, 75], [26, 26, 79],
            [28, 28, 82], [30, 30, 86], [32, 32, 90]]
    },
    ctops: {
        description: ["m", "ft"],
        lines: [[0, 0, 0], [5e3, "5k", "FL150"], [9e3, "9k", "FL300"], [12e3, "12k", "FL400"], [15e3, "15k", "FL500"]]
    },
    rainAccu: {
        description: ["mm", "in"],
        lines: [[5, 5, ".2"], [10, 10, ".4"], [20, 20, ".8"], [40, 40, 1.5], [1e3, "1m", "3ft"]]
    }
}

const getLinesFun = function (){
    return this.lines;
}

for (let key of Object.keys(legends))
    legends[key].getLines = getLinesFun;

export default legends;



import BaseMap from "./BaseMap";
import scope from "./rootScope";

var basemaps={};
// basemaps.foreground = BaseMap.instance(
//     {
//      ident: "foreground",
//      url: scope.tileServer + '/teselas/foreground/{z}/{x}/{y}.png'
//     }
// ),
basemaps.light = BaseMap.instance(
    {
        ident: "light",
        url: scope.tileServer + '/img/background/light/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png',
        bgcolor: "#acc8f0",
        trans: "LIGHT"
    }
);
basemaps.dark = BaseMap.instance(
    {
        ident: "dark",
        url: scope.tileServer + '/img/background/dark/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png',
        bgcolor: "#000",
        trans: "DARK"
    }
);
basemaps.land = BaseMap.instance(
    {
        ident: "land",
        url: scope.tileServer +'/img/tiles/viewer/all/v2/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.jpg',
        noseaurl: scope.tileServer +'/img/tiles/viewer/ground/v2/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png',

        bgcolor: "#000",
        trans: "LAND"
    }
);

// https://api.mapbox.com/v4/mapbox.satellite/{zoom}/{x}/{y}{@2x}.png?access_token=pk.eyJ1IjoianNhbmNoZXp0aWVtcG8iLCJhIjoiY2tmZHp4ZXU0MDA2dTJxbzRpYzIxN3hldCJ9.dLxasaaClT_teyooRhyHeA
export default basemaps;

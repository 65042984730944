/**
 * Modulo que devuelve la clase RadarCalendar, que permite gestionar los pasos de tiempo del radar de precipitación.
 *
 * @module RadarCalendar
 */

import format from "../../app/format";
import utils from "../../app/utils";
import Class from "../../app/Class";
import trans from "../../app/trans";


export default Class.extend({
    weekdays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    localeHours: undefined,
    startOfTimeLine: undefined,
    endOfcalendar: undefined,
    minifestFile: undefined,
    timestamps: undefined,
    interTimestamps: undefined,
    endOfCal: undefined,
    days: undefined,

    /**
     * Inicializa una instancia de la clase RadarCalendar
     *
     * @private
     */
    _init: function() {
        // Tiempo em milisegundos del salto de tiempo
        if(!this.step)
            this.step = utils.tsMinute * 5;

        this.maxoffset=0;

        // Final de la linea temporal
        this.endOfTimeLine =  this.endOfTimeLine || this.getLastDate();
        this.obsend = this.endOfTimeLine.getTime();
        this.end = this.obsend + this.maxoffset * 1000;

        // Inicio de la linea temporal 24 horas antes
        this.startOfTimeline = this.add(this.endOfTimeLine, - ((this.premium ? 24 : 12) * 60 - 10), "minutes" );
        this.start = this.startOfTimeline.getTime();

        this.days = [];

        this.type = "mixed";
        this.timestamps = [];
        this.paths = [];
        this.interTimestamps = [];

        this.localeHours = format.getHoursFunction();

        if ( this.minifestFile && this.createTimestampsFromMinifest(this.minifestFile) )
            this.minifestValid = true;
        else
        {
            this.createTimestamps();
            this.minifestValid = false;
        }

        for (let l = 1; l < this.paths.length; l++)
            this.interTimestamps.push(this.timestamps[l - 1] + Math.floor((this.timestamps[l] - this.timestamps[l - 1]) / 2));

        this.indxCurrentTs = this.indxBestTs(Date.now());
        // this.currentTs = this.timestamps[this.indxCurrentTs];

        return this;
    },

    getIdxCurrentTs: function (){
        return this.indxCurrentTs;
    },

    getCurrentTs: function (){
        return this.timestamps[this.indxCurrentTs];
    },

    /**
     * Suma n unidades (en horas o días) a una fecha
     *
     * @param   {Date}    fecha  Fecha a la que se le suma una cantidad
     * @param   {number}  count  Cantidad de horas o días a sumar
     * @param   {string}  type   Si lo que contiene count son horas o minutos
     *
     * @returns {Date}           Fecha resultado de la suma
     */
    add: function(fecha, count, type) {
        const date = new Date(fecha.getTime());
        date.setTime(fecha.getTime() +
            ("hours" === type ? 60 : 1) * count * utils.tsMinute);
        return date;
    },

    /**
     * Límita un timestamp al intervalo comprendido entre el ts de inicio y el final del calendario
     *
     * @param   {number}  timestamp timestamp al que se le aplica el bound
     *
     * @returns {number}            El mismo timestamp si está dentro del intervalo, el valor mínimo del intervalo
     *                              si está por debajo o el valor máximo del intervalo si el ts está por encima.
     *
     */
    boundTs: function(timestamp) {
        return utils.bound(timestamp, this.start, this.end);
    },


    /**
     * Obtiene la fecha y hora más cercana a la fecha y hora actual que sea múltiplo del paso de tiempo
     *
     * @returns {Date}           Fecha múltiplo del paso del tiempo
     */
    getLastDate(){
        const stepminutes= this.step / 60000;
        const fecha = new Date;
        let minutes = fecha.getMinutes(); // - (fecha.getMinutes() % stepminutes);
        minutes = minutes - (minutes % stepminutes);

        fecha.setMinutes(minutes);
        fecha.setSeconds(0);
        fecha.setMilliseconds(0);

        return fecha;
    },

    /**
     * Rellena el array de timestamps con timestamps múltiplos de 3 desde el ts inicial
     */
    createTimestamps: function() {
        for (let timestep = this.start; timestep <= this.end; timestep += this.step) {
            this.timestamps.push(timestep);
            this.paths.push(timestep/1000);
        }
    },

    /**
     * Establece la fecha y hora de inicio y fin a partir del objeto minifest
     *
     * @param   {Object}  minifest  Objeto que contiene la información de los timestamps de un producto
     */
    prepareTimesFromMinifest: function(minifest) {
        if ( minifest && "object" == typeof minifest && minifest.generated && minifest.timestamps ){
            const timestamps = minifest.timestamps.past;
            const nowcast = minifest.timestamps.nowcast;

            // Fecha de actualización del fichero minifest
            this.updateTs = new Date(minifest.generated).getTime();


            if(nowcast && nowcast.maxoffset){
                this.maxoffset = nowcast.maxoffset;
                this.obsend = this.endOfTimeLine.getTime();
                this.end = this.obsend + this.maxoffset * 1000;
            }

            if (timestamps && timestamps.length > 0) {
                // Si la fecha del primer timestamp es más reciente que la de inicio
                if (timestamps[0] * 1000 > this.start) {
                    this.start = timestamps[0] * 1000;
                    this.startOfTimeline = new Date(this.start);
                }
                // Si la fecha del último timestamp es anterior que la de fin
                if (timestamps[timestamps.length-1] * 1000 < this.obsend) {
                    this.obsend = timestamps[timestamps.length - 1] * 1000;
                    this.end = this.obsend + this.maxoffset * 1000;
                    this.endOfTimeline = new Date(this.end);
                }
            }


            return true;
        }
        else{
            console.error("Calendar", "Invalid format of minifest ");

            return false;
        }
    },

    /**
     * Rellena el array de timestamps a partir de los timestamps leidos del objeto minifest del radar
     *
     * @param   {Object}  minifest  Objeto que contiene la información de los timestamps de un producto
     */
    createTimestampsFromMinifest: function(minifest) {

        if ( ! this.prepareTimesFromMinifest(minifest) )
            return false;

        let tsnow = new Date();
        tsnow -= tsnow % this.step;


        // Diferencia entre el último momento de la observación y el que corresponde con el instante actual
        let delay = tsnow - this.obsend;

        // Si es mayor que 30 minutos no hacemos el ajuste
        if ( delay > 30 * utils.tsMinute )
            delay = 0;


        if (minifest.timestamps && minifest.timestamps.past) {

            const timestamps = minifest.timestamps.past;

            let timestep = this.start;
            for (let lasttimestep= timestep/1000, i=0; timestep <= this.obsend; ) {

                const ts = timestep / 1000;
                if (timestamps[i] === ts) {
                    this.timestamps.push(timestep + delay);
                    this.paths.push(ts);
                    lasttimestep = ts;
                    timestep += this.step;
                    i++;
                }
                else if (ts < timestamps[i]) {
                    this.timestamps.push(timestep + delay);
                    this.paths.push(lasttimestep);
                    timestep += this.step;
                }
                else
                    i++;
            }

            const tsobsend = this.obsend / 1000;

            for (let acumstep = this.step ;timestep <= this.end; timestep += this.step, acumstep+=this.step ){

                this.timestamps.push(timestep + delay);
                this.paths.push(tsobsend+"-"+acumstep/1000);
            }

        }

        return true;
    },


    /**
     * Devuelve el paso de tiempo más cercano del radar
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          timestamp con el paso del tiempo del radar
     */
    bestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.timestamps[n];
        return this.timestamps[this.paths.length - 1];
    },

    /**
     * Devuelve el indice del paso de tiempo más cercano del radar
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          indice del timestamp con el paso del tiempo del producto
     */
    indxBestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return n;
        return this.paths.length - 1;
    },
    /**
     * Devuelve el siguiente paso de tiempo al paso de tiempo más cercano del producto o modelo
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          timestamp con el paso del tiempo del producto
     */
    nextBestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.timestamps[Math.min(n + 1, this.paths.length - 1 )];
        return this.timestamps[this.paths.length - 1];
    },
    /**
     * Devuelve el path que corresponde al paso de tiempo más cercano
     *
     * @param   {Number}   ts      timestamp del que se busca el path
     *
     * @returns {string}           el path correspondiente al paso de tiempo más cercano
     */
    ts2path: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.paths[n];
        return this.paths[this.paths.length - 1];
    },
    /**
     * Devuelve el path que corresponde al siguiente paso de tiempo del paso de tiempo más cercano
     *
     * @param   {Number}   ts      timestamp del que se busca el path
     *
     * @returns {string}           El path correspondiente al siguiente paso de tiempo al más cercano
     */
    ts2nextpath: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.paths[Math.min(n + 1, this.paths.length - 1 ) ];
        return this.paths[this.paths.length - 1];
    },


    /**
     * Devuelve la hora local para un timestamp
     *
     * @param   {Number}   ts       Timestamp del que se quiere obtener la hora
     *
     * @returns {string}            Un string con la hora local formateada a 24h o a 12h
     */
    ts2hour: function(ts) {
        const date = new Date(ts);
        // console.info(date.get)
        return this.localeHours(date.getHours(), date.getMinutes());
    },
    /**
     * Devuelve un string con el día de la semana y el día del mes
     *
     * @param   {Number}   ts       Timestamp del que se quiere obtener el string
     *
     * @returns {string}            Un string con el día de las semana (Lunes, martes,...) y el día del mes (1..31)
     */
    ts2day: function (ts){
        const date = new Date(ts)
            , daymonth = date.getDate()
            , dayweek = date.getDay();

        return trans[this.weekdays[dayweek]] + " " + daymonth;
    }
});



/** Clase que controla el comportamiento visual del TimeSlider */
class TimeSliderView {

    protected readonly slider: Element | null = null;
    protected readonly hourSelector: HTMLElement | null = null;
    protected readonly loading: HTMLElement | null = null;
    protected readonly hourActiva: HTMLElement | null = null;
    protected readonly buttonPlay: HTMLElement | null = null;
    protected readonly buttonStop: HTMLElement | null = null;
    protected readonly daySpan: Element | null = null;

    /**
     * Ancho del elemento li que contiene el timestep
     * @protected
     */
    protected liWidth: number = 43;

    protected liPremiumWidth: number = 48;
    /**
     * Número de elementos li con el timestep que caben en el ancho de la ventana
     * @private
     */
    private scrollWidth: number = 0;
    /**
     * Si se está reproduciendo automáticamente
     * @protected
     */
    protected playing: boolean = false;
    /**
     * Id o número del timestep selecionado
     * @protected
     */
    protected nslot: number = -1;
    /**
     * Número de slots premium en el slider.
     * @protected
     */
    protected nPremiumSlots: number = 0;
    /**
     * Número de timesteps que contiene el slider
     * @protected
     */
    protected nsteps: number = 0;
    /**
     * Identificador del temporizador que realiza la reproducción automática
     * @private
     */
    private intervalPlaying: number = -1;
    /**
     * Timestamp en el que se pintó el frame anterior cuando se inicia la animación
     * @protected
     */
    protected prevtimestamp:number | null | undefined;
    /**
     * Identificador de la animación
     * @protected
     */
    protected frameID: number | null | undefined;
    /**
     * Si se ha cambiado de slot de tiempo o timestamp
     * @protected
     */
    protected slotChanged: boolean | null | undefined;
    /**
     * Si el visor ha terminado de repintarse
     * @protected
     */
    protected redrawFinished: boolean | null | undefined;
    /**
     * Si está deshabilitado o no
     * @private
     */
    private disabled: boolean | null | undefined;
    /**
     * Margen que se deja en el texto los días
     * @private
     */
    private marginDay: number = 11;

    private ndays: number = 0;

    protected firstTime: boolean = true;

    protected _handleStartAnimation: ((loopSteps: number, futuresteps: boolean) => any) |undefined |null = null;

    protected _handleStopAnimation: (() => any) |undefined |null = null;

    protected _handleSetTimestamp: ((ts: number) => any) |undefined |null = null;

    protected _handleTs2Day: ((ts: number) => any) |undefined |null = null;

    protected _handleClickPremium: (() => any) |undefined |null = null;

    protected futureSteps: boolean = true;

    protected loopsteps: number = 0;

    protected pos={ left: 0, x: 0};


    /**
     * Establece el manejador que inicia la animación al hacer click sobre el menú play
     *
     * @param handler Función manejadora
     */
    bindHandleStartAnimation(handler: (loopSteps: number) => any){
        this._handleStartAnimation = handler;
    }

    /**
     * Establece el manejador que detiene la animación al hacer click sobre el menú Stop
     *
     * @param handler Función manejadora
     */
    bindHandleStopAnimation(handler: () => any){
        this._handleStopAnimation = handler;
    }

    /**
     * Establece el manejador que cambia de timestamp
     *
     * @param handler Función manejadora
     */
    bindHandleSetTimestamp(handler: (ts: number) => any){
        this._handleSetTimestamp = handler;
    }


    bindHandleTs2Day(handler: (ts: number) => any){
        this._handleTs2Day = handler;
    }

    bindHandleClickPremium(handler: () => any){
        this._handleClickPremium = handler;
    }

    /**
     * Crea e inicializa un objeto
     *
     * @param timeSliderElement
     */
    constructor( timeSliderElement:Element ) {
        // let slider:Element|null = document.querySelector(id);
        let slider:Element|null = timeSliderElement;
        const container=document.getElementById('#visor2') || document.body;

        if (slider) {
            this.slider = slider;
            this.hourSelector = slider.querySelector("#days");
            this.daySpan = slider.querySelector("#week-day-radar");
            this.buttonPlay = slider.querySelector("#buttonPlay");
            this.buttonStop = slider.querySelector("#buttonStop");
            this.loading = slider.querySelector("#loading");
            this.hourActiva = slider.querySelector("#hour-activa");

            const contbar = slider.querySelector("#cont-bar");

            contbar?.addEventListener("scroll", this.onscroll.bind(this), true);
            contbar?.addEventListener("click", this.onclick.bind(this), true);
            contbar?.addEventListener("touchstart", this.onclick.bind(this), true);

            // this.slider = slider;

            // this.hourSelector?.addEventListener("scroll", this.onscroll.bind(this), false);
            // this.hourSelector?.addEventListener("click", this.onclick.bind(this), false);

            // this.hourActiva?.addEventListener("scroll", this.onscroll.bind(this), false);
            // this.hourActiva?.addEventListener("click", this.onclick.bind(this), false);


            // Función que se ejecuta al hacer click sobre el botón Play
            this.buttonPlay?.addEventListener("click", () =>{
                // document.querySelector("#controles_mm")?.classList.remove('open');
                container.classList.add('timeslider-close');

                if ( ! this.playing && this.redrawFinished )
                    this.startPlaying(this.loopsteps, this.futureSteps);
            });

            // Función que se ejecuta al hacer click sobre el botón Stop
            this.buttonStop?.addEventListener("click", (e:any) =>{
                this.checkAndStopPlaying();
                e.stopPropagation();
            });


            const mouseMoveHandler = (e:MouseEvent) => {
                // How far the mouse has been moved
                const dx = e.clientX - this.pos.x;

                // Scroll the element
                if (this.hourSelector)
                    this.hourSelector.scrollLeft = this.pos.left - dx;
            };

            const mouseUpHandler =  () => {

                if (this.hourSelector) {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);

                    this.hourSelector.style.cursor = 'grab';
                    this.hourSelector.style.removeProperty('user-select');
                }
            };

            const mouseDownHandler = (e: MouseEvent) => {

                if (this.playing)
                    this.stopPlaying();

                const hourSelector = this.hourSelector;

                if (hourSelector) {

                    // Change the cursor and prevent user from selecting the text
                    hourSelector.style.cursor = 'grabbing';
                    hourSelector.style.userSelect = 'none';

                    this.pos = {
                        // The current scroll
                        left: hourSelector?.scrollLeft,
                        // Get the current mouse position
                        x: e.clientX,
                    };

                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                }
            };

            this.hourSelector?.addEventListener("mouseover", ()=>{ if (this.hourSelector) this.hourSelector.style.cursor = 'grab';} );
            this.hourSelector?.addEventListener("mousedown",mouseDownHandler);


            document.addEventListener("overlayMenuOpened", this.checkAndStopPlaying.bind(this));
            document.addEventListener("overlayMenuOpened", this.disableSlider.bind(this));
            document.addEventListener("overlayMenuClosed", this.enableSlider.bind(this));

            const premiumButton = slider.querySelector("#premium24h");
            premiumButton?.addEventListener("click", (e: Event) => {
                if (this._handleClickPremium)
                    this._handleClickPremium();
            });

        }
    }

    getHtmlElement(){
        return this.slider;
    }

    /**
     * Devuelve el número de elementos <li> con el timestep que caben en el ancho de la ventana
     */
    getDivision(){
        if ( ! this.scrollWidth ) {
            this.scrollWidth = window.innerWidth / this.liWidth;
        }
        return this.scrollWidth;
    }

    /**
     * Establece si el visor ha terminado de pintar todas las teselas del último ts
     * @param value Valor boleano que indica se se ha terminado de repintar
     */
    setRedrawFinished(value: boolean){
        this.redrawFinished = value;
        if (this.redrawFinished)
            this.slotChanged = false;
    }


    /**
     * Desplaza el scroll a un número de slot concreto
     * @param nslot Número de slot a desplazar
     */
    changeSlot(nslot:number){
        const oldslot = this.nslot;
        this.nslot=nslot;

        this.changeClasses(oldslot, this.nslot);

        if (this.hourSelector) {
            const liwidth = this.nslot < 0 ? this.liPremiumWidth : this.liWidth;
            this.hourSelector.scrollLeft = this.nPremiumSlots * this.liPremiumWidth + liwidth * this.nslot;
        }

    }

    setFutureSteps(futureSteps: boolean){
        this.futureSteps=futureSteps;
    }

    setLoopSteps(loopSteps: number){
        this.loopsteps=loopSteps;
    }

    /**
     * Desplaza el scroll en una cantidad dada
     * @param amount valor entre 0 y 1 que indica la cantidad a desplazar
     */
    displaceScroll(amount:number){
        let desplazamiento = amount * window.innerWidth / this.getDivision();
        if (this.hourSelector && ! this.slotChanged) {

            // Calcula el id del nuevo timestep seleccionado
            let id = Math.floor((this.hourSelector.scrollLeft - this.liPremiumWidth * this.nPremiumSlots + desplazamiento) / this.liWidth);
            if ( id > this.nslot ) {
                this.slotChanged = true;
                this.nslot = id;
                this.hourSelector.scrollLeft = this.liPremiumWidth * this.nPremiumSlots + this.nslot * this.liWidth;
            }
            else
                this.hourSelector.scrollLeft = this.hourSelector.scrollLeft + desplazamiento;
        }
    }



    /**
     * Establece el texto del día de la semana
     */
    setDayText(daytext: string){
        if (this.daySpan)
            this.daySpan.textContent = daytext;

    }

    setHourText(hourtext: string){
        let hourSpan : Element | null | undefined = this.hourActiva?.getElementsByClassName("hour").item(0);
        if(hourSpan)
        {
            hourSpan.textContent = hourtext;
        }
    }

    /**
     * Devuelve el id del timestep seleccionado
     */
    getNslot(){
        return this.nslot;
    }

    /**
     * Asigna las classes "future", "past" y "actual" adecuadas para todos los días entre oldslot y nslot
     *
     * @param oldslot id del slot seleccionado anteriormente
     * @param newslot id del nuevo slot seleccionado
     */
    changeClasses(oldslot: number, newslot: number){

        if (this.hourSelector) {
            const liArray = Array.from(this.hourSelector.querySelectorAll("[data-nts]"));
            const liElementActual = liArray.filter(element =>  (element as HTMLLIElement).dataset["nts"] === newslot.toString())[0];
            const liOldElement = liArray.filter(element =>  (element as HTMLLIElement).dataset["nts"] === oldslot.toString())[0];

            if (liElementActual && liOldElement) {
                // Se obtiene el id del día actual
                const dayIdActual = liElementActual.parentElement?.parentElement?.id;

                // Se obtiene el id del día previo
                const dayOldId = liOldElement.parentElement?.parentElement?.id;
                if (dayIdActual && dayOldId) {

                    const dayIdActualNumber =  Number(dayIdActual.split('-')[1]);
                    const dayOldIdNumber = Number(dayOldId.split('-')[1]);

                    // Se obtienen el div del día actual y del previo
                    const dayDivActual = this.slider?.querySelector(`#${dayIdActual}`);
                    const dayOldDiv = this.slider?.querySelector(`#${dayOldId}`);

                    // Si el día actual es posterior al previo se le añade al estilo del div la clase "actual" y a las anteriores "past"
                    if (dayIdActual > dayOldId ) {
                        dayOldDiv?.classList.remove("actual");
                        dayOldDiv?.classList.add("past");

                        for ( let i=dayOldIdNumber + 1;  i<dayIdActualNumber; i++ ){
                            let dayDiv = this.slider?.querySelector(`#day-${i}`);
                            dayDiv?.classList.remove("future");
                            dayDiv?.classList.add("past");
                        }

                        dayDivActual?.classList.remove("future");
                        dayDivActual?.classList.add("actual");
                    }
                    // Si el día actual es anterior al previo se le añade al estilo del div la clase "actual" y a las anteriores "future"
                    else if ( dayIdActual < dayOldId ){
                        dayOldDiv?.classList.remove("actual");
                        dayOldDiv?.classList.add("future");

                        for ( let i=dayOldIdNumber - 1;  i > dayIdActualNumber; i-- ){
                            let dayDiv = this.slider?.querySelector(`#day-${i}`);
                            dayDiv?.classList.remove("past");
                            dayDiv?.classList.add("future");
                        }

                        dayDivActual?.classList.remove("past");
                        dayDivActual?.classList.add("actual");
                    }

                    // Si se hac cambiado de día se corrigen los margenes (left y right) para que encaje bien al hacer el translate: -50%
                    if ( dayIdActual != dayOldId ){
                        // const spanRect = spanElement.getBoundingClientRect();
                        // const marginLeftValue = parseInt(window.getComputedStyle(spanElement, null).getPropertyValue('margin-left'));
                        if (dayOldDiv){
                            const spanElement: HTMLSpanElement = <HTMLSpanElement>dayOldDiv.firstElementChild;
                            if (dayOldIdNumber > 0)
                                spanElement.style.marginLeft=`${this.marginDay}px`;
                            if (dayOldIdNumber < this.ndays - 1)
                                spanElement.style.marginRight=`${this.marginDay}px`;
                        }
                        if (dayDivActual) {
                            const spanElement: HTMLSpanElement = <HTMLSpanElement>dayDivActual.firstElementChild;
                            if (dayIdActualNumber > 0)
                                spanElement.style.marginLeft=(this.marginDay + spanElement.clientWidth/2) + "px";

                            if (dayIdActualNumber < this.ndays - 1)
                                //     spanElement.style.marginRight= (this.marginDay - spanElement.clientWidth - (this.marginDay + spanElement.clientWidth/2) + 21 )  + "px";
                                // else
                                spanElement.style.marginRight=(this.marginDay - spanElement.clientWidth/2) + "px";
                        }
                    }
                }
            }

        }

    }


    /**
     * Función que se ejecuta al hacer scroll sobre el TimeSlider
     */
    onscroll () {

        const container=document.getElementById('#visor2') || document.body;

        if(!this.playing)
            container.classList.remove('timeslider-close');
            // this.slider?.classList.add('open');

        setTimeout(() => {
            if ( ! this.firstTime)
                container.classList.add('timeslider-close');
                // this.slider?.classList.remove('open');
            else
                this.firstTime = false;
        }, 5000);

        let id = this.nslot;

        // Calcula el id del nuevo timestep seleccionado
        if(this.hourSelector) {
            const liWidth = id < 0 ? this.liPremiumWidth : this.liWidth;
            id = Math.round((this.hourSelector.scrollLeft - (this.nPremiumSlots * this.liPremiumWidth)) / liWidth);
        }


        this.slider?.classList.toggle("premium", id < 0);


        // Tiene que ser menor que nsteps - 1. Si es menor que 0 se trata de un timestep premium
        // id = Math.max(Math.min(id, this.nsteps - 1), 0);
        id = Math.min(id, this.nsteps - 1);

        // Si el id es premium se deshabilita el botón de reproducción
        if (id < 0)
            this.disableButtonPlay()
        else if (! this.disabled)
            this.enableButtonPlay()


        this.changeClasses(this.nslot, id);

        // Si no está reproducciendose y el nuevo id es distinto del antiguo
        if ( ! this.playing && id !== this.nslot ) {
            this.nslot = id;
        }
    }

    onclick () {
        if (this.playing)
            this.stopPlaying();
    }


    /**
     * Deshabilita el botón de reproducción.
     */
    disableButtonPlay(){
        this.buttonPlay?.classList.add("disabled");
    }

    /**
     * Habilita el botón de reproducción.
     */
    enableButtonPlay(){
        this.buttonPlay?.classList.remove("disabled");
    }


    /**
     * Actualiza los pasos de tiempo del TimeSlider
     *
     * @param timesteps Array que contiene los timesteps del TimeSlider. El campo text contiene el texto de la hora
     * @param tsnow timestep de la hora actual
     * @param selectedTs timestep de la hora seleccionada. El campo text contiene el texto del día de la semana
     * @param domainurl URL del dominio
     */
    updateTimeSteps ( timesteps : { ts: number, text: string, premium: boolean}[] ,
                      tsnow: { ts: number, text: string},
                      selectedTs: { ts: number, text: string}, domainurl: string = "" ) {


        let day=-1, nday=0;
        let newDayDiv
        let nts=0, nslot=1, ntsPremium = 0;

        let hourSelector = this.hourSelector;
        let daySpan = this.daySpan;
        let ul: HTMLUListElement | null = null;
        let ulFirstDay: HTMLUListElement | null = null;
        let span: HTMLSpanElement | null = null;
        let spanFirst: HTMLSpanElement | null = null, spanLast: HTMLSpanElement | null = null;

        // Borra todos los timesteps que hubiera
        while (this.hourSelector?.firstChild) {
            this.hourSelector.removeChild(this.hourSelector.firstChild);
        }

        const slider = this;

        let currentday: HTMLDivElement | null = null;
        let isFuture: boolean = false;

        this.nPremiumSlots = 0;

        // timesteps.forEach(function(timestep)
        for(let timestep of timesteps)
        {
            let date= new Date(timestep.ts);

            // Si el timestep cambia de día se añade un nuevo elemento div para el día
            if (date.getDay()!== day)
            {
                newDayDiv = document.createElement("div");
                newDayDiv.id = `day-${nday}`;
                ul = document.createElement("ul");
                span = document.createElement("span");
                span.classList.add("weekday");


                if (slider._handleTs2Day)
                    span.textContent=slider._handleTs2Day(timestep.ts);


                newDayDiv.classList.add("day");
                newDayDiv.classList.add(isFuture? "future" : "past");
                hourSelector?.appendChild(newDayDiv);
                newDayDiv.appendChild(span);
                newDayDiv.appendChild(ul);

                if (day == -1) {
                    spanFirst = span;
                    ulFirstDay = ul;
                }

                day=date.getDay();
                nday++;
                currentday = newDayDiv;
                spanLast= span;
            }

            // Elemento div para el timestep
            let newHourDiv = document.createElement("li");
            newHourDiv.classList.add("div-hour");
            if(! timestep.premium) {
                // newHourDiv.id = "ts-" + nts;
                newHourDiv.dataset["nts"] = nts.toString();
                newHourDiv.style.cursor = 'pointer';
                newHourDiv.addEventListener("click", (e: Event) => {
                    if (this._handleSetTimestamp)
                        this._handleSetTimestamp(timestep.ts);
                });
            }else{
                // newHourDiv.id =  ntsPremium.toString();
                newHourDiv.dataset["nts"] = (-(ntsPremium + 1)).toString();
                newHourDiv.addEventListener("click", (e: Event) => {
                    if (newHourDiv.dataset["nts"]) {
                        const ntsPremium = parseInt(newHourDiv.dataset["nts"]);
                        this.changeSlot(ntsPremium);
                    }
                });
            }
            // Si el timestep coincide con el timestep seleccionado se le añade
            // la clase de estilo "hour-activa"
            if (selectedTs.ts === timestep.ts){
                newHourDiv.classList.add("hour-activa");

                // Elemento seleccionado
                nslot=nts;

                // Se actualiza el texto del día
                if (daySpan)
                    daySpan.textContent=selectedTs.text;

                // Si es el día actual
                if (currentday) {
                    // Se le añade la clase actual
                    currentday.classList.remove("past");
                    currentday.classList.add("actual");
                    // Los siguientes divs serán futuros
                    isFuture = true;

                    // Se corrige el margen (left y right)
                    const spanElement: HTMLSpanElement = <HTMLSpanElement>currentday.firstElementChild;
                    if (spanElement != spanFirst)
                        spanElement.style.marginLeft=(slider.marginDay + spanElement.clientWidth/2) + "px";
                    spanElement.style.marginRight=(slider.marginDay - spanElement.clientWidth/2) + "px";
                }
            }

            ul?.appendChild(newHourDiv);
            // slider.liWidth= newHourDiv.clientWidth;

            // Texto del TimeStep
            const spanText = document.createElement("span");
            spanText.textContent = timestep.text;
            if (timestep.premium) {
                const img = document.createElement("img");
                // img.dataset["src"] = domainurl + "css/svgs/diamante_premium_white.svg";
                img.src = domainurl + "css/svgs/diamante_premium_white.svg";
                img.alt = "premium";
                img.width = 12;
                img.height = 12;
                // img.classList.add("lazy-image");

                newHourDiv.appendChild(img);

                newHourDiv.classList.add("premium");
                slider.nPremiumSlots++;
            }

            // let hourText = document.createTextNode(timestep.text);
            newHourDiv.appendChild(spanText);

            // Si el timestep coincide con el de la hora actual
            if (tsnow.ts === timestep.ts){
                let divActiva=document.createElement("div");
                divActiva.classList.add("div-activa");

                divActiva.setAttribute("draggable", "true");
                newHourDiv.appendChild(divActiva);

            }

            if (! timestep.premium) {
                if (nts === 0){
                    slider.liWidth = newHourDiv.clientWidth;
                }
                nts++;
            }
            else {
                if (ntsPremium === 0){
                    slider.liPremiumWidth = newHourDiv.clientWidth;
                }
                ntsPremium++;
            }
        }

        const liElements = hourSelector?.getElementsByTagName("li")
        if (liElements){
            for (let i = 0; i < liElements.length; i++){
                const nts = parseInt(liElements[i].dataset["nts"]?? "0");
                if (nts < 0)
                    liElements[i].dataset["nts"] = ((-nts - 1) - this.nPremiumSlots).toString();
            }
        }


        // console.info("spanFirstWidth: "+spanFirst.clientWidth)
        // console.info("spanwidth: "+spanLast.clientWidth)
        if (spanFirst)
            spanFirst.style.marginLeft= (- spanFirst.clientWidth ) + "px";

        if (spanLast)
            spanLast.style.marginRight=-(spanLast.clientWidth + spanLast?.clientWidth/2) + "px";

        this.ndays = nday;

        // Actualiza el elemento seleccionado
        this.nslot = nslot;

        // Establece el scroll correcto
        if (hourSelector)
            hourSelector.scrollLeft = this.nPremiumSlots * this.liPremiumWidth + this.nslot * this.liWidth;

        // Actualiza en número de pasos de tiempo
        this.nsteps = nts;


        if (ntsPremium > 0){

            const buttonRewind = document.createElement("li");
            buttonRewind.classList.add("buttonrewind");

            const imgRewind = document.createElement("img");
            // imgRewind.dataset["src"] = domainurl + "css/svgs/rewind.svg";
            // imgRewind.classList.add("lazy-image");
            imgRewind.src = domainurl + "css/svgs/rewind.svg";
            imgRewind.alt = "rewind";
            imgRewind.width = 12;
            imgRewind.height = 12;

            buttonRewind.appendChild(imgRewind);

            buttonRewind.addEventListener("click", (e: Event) => {
                if (this._handleClickPremium)
                    this._handleClickPremium();
            });

            ulFirstDay?.insertAdjacentElement("afterbegin", buttonRewind);
        }

    }



    /**
     * Quita el icono de loading y permite utilizar el timeslider
     */
    unsetLoading(){
        if (this.hourSelector && ! this.disabled) {
            this.hourSelector.style.overflowX = "scroll";
        }
        if (this.loading)
            this.loading.style.display = 'none';
        if (this.buttonPlay && ! this.playing)
            this.buttonPlay.style.display = 'inline';
        if (this.buttonStop &&  this.playing)
            this.buttonStop.style.display = 'inline';
    }

    /**
     * Pone el icono de loading e impide utilizar el timeslider
     */
    setLoading(){
        if (this.hourSelector) {
            this.hourSelector.style.overflowX = "hidden";
        }
        if (this.loading)
            this.loading.style.display = 'inline';
        if (this.buttonPlay)
            this.buttonPlay.style.display = 'none';
        if (this.buttonStop)
            this.buttonStop.style.display = 'none';
    }


    /**
     * Inicia la reproducción automática del slider
     */
    startPlaying(loopSteps:number, futuresteps: boolean = true){
        if (!this.playing && this._handleStartAnimation)
            this._handleStartAnimation(loopSteps, futuresteps);
    }

    /**
     * Detiene la reproducción automática del slider
     */
    stopPlaying(){
        if (this.playing && this._handleStopAnimation)
            this._handleStopAnimation();

    }

    /**
     * Desbloquea el timeslider para que el usuario pueda usarlo
     */
    unblock (){

        /* Si está reproduciendo... */
        if (this.playing) {
            // /* Indicamos que está detenido */
            this.playing = false;
            /* Ocultamos botón STOP */
            if(this.buttonStop)
                this.buttonStop.style.display = 'none';
            /* Mostramos el botón de PLAY */
            if(this.buttonPlay)
                this.buttonPlay.style.display = 'inline';
            /* Volvemos a habilitar el scroll */
            // if (this.hourSelector)
            //     this.hourSelector.style.overflowX="scroll";


        }
    }

    /**
     * Bloquea el timeslider para que el usuario no pueda usarlo
     */
    block(){

        // if (this.hourSelector)
        //     this.hourSelector.style.overflowX="hidden";

        if (this.buttonPlay)
            this.buttonPlay.style.display = 'none';

        if (this.buttonStop)
            this.buttonStop.style.display = 'inline';

        this.playing = true;

    }

    /**
     * Chequea si se está reproducciendo automáticamente el slider y los detiene
     */
    checkAndStopPlaying() {
        /* Si está reproduciendo... */
        if (this.playing) {
            /* ...lo detenemos */
            this.stopPlaying();
        }
    }

    /**
     * Deshabilita el uso del TimeSlider
     */
    disableSlider(){
        this.disabled = true;
        if (this.buttonPlay)
            this.buttonPlay.classList.add("disabled");
        if (this.hourSelector)
            this.hourSelector.style.overflowX="hidden";
    }

    /**
     * Habilita el uso del TimeSlider
     */
    enableSlider(){
        this.disabled = false;
        if (this.buttonPlay && this.nslot >= 0)
            this.buttonPlay.classList.remove("disabled");
        if (this.hourSelector)
            this.hourSelector.style.overflowX="scroll";
    }

}


export default TimeSliderView;

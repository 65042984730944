import store from "./store";
import broadcast from "./broadcast";

const displayPoiOnMap = function() {
    const pois = store.get("pois")
        , poisTemporary = store.get("poisTemporary");
    return true; /*Tc.getArray().length > 0 && "favs" === pois ||*/ "empty" !== pois /*&& "favs" !== pois*/ || "empty" !== poisTemporary;
}

const openPoiLibs = function e() {
    if(displayPoiOnMap()){
        broadcast.emit("rqstOpen", "poi-libs");
        store.off("pois", e);
        store.off("poisTemporary", e);
    }
}


broadcast.once("redrawFinished", function() {
        if (displayPoiOnMap())
            openPoiLibs()
        else {
            store.on("pois", openPoiLibs);
            store.on("poisTemporary", openPoiLibs);
        }
    }
);

export default {
    displayPoiOnMap: displayPoiOnMap
    // poisCheckboxes: Wh
}

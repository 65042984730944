import Evented from './Evented';


/**
 * Descripción
 *
 * @exports render
 */
let render = Evented.instance({
    ident: "render"
});

/**
 * <p>
 * Tabla que relaciona el nivel de zoom de la tesela a renderizar con el zoom nivel de zoom
 * de la tesela de datos que se va a necesitar para el renderizado, así como con un nivel de calidad.
 * Los niveles de calidad son: <i>low, normal, high, ultra</i> y <i>top</i>. Según el nivel de calidad elegido
 * se utilizan niveles de zoom de datos más altos si el nivel es bajo y más altos si el nivel es alto.
 * </p>
 * <p>Por ejemplo, para renderizar una tesela de nivel 4:</p>
 *
 * <code> render.zoom2zoom['normal'][4] </code> devuelve <b>2</b>. <br>
 * <code> render.zoom2zoom['high'][4] </code> devuelve <b>3</b>.
 *
 * @type {{normal: number[], high: number[], top: number[], low: number[], ultra: number[]}}
 */
render.zoom2zoom = {
    top: [0, 0, 0, 2, 3, 4, 5, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
    ultra: [0, 0, 0, 2, 3, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    high: [0, 0, 0, 2, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    normal: [0, 0, 0, 2, 2, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    low: [0, 0, 0, 1, 1, 1, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
    discrete:  [0, 0, 2, 3, 4, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
};

/**
 * Número de teselas que caben en el ancho (o alto) del mapa del mundo para un nivel de zoom
 *
 * @param   {number}  zoom      Nivel de zoom
 *
 * @returns {number}            Número de teselas
 */
render.tileW = function(zoom) { // Números de teselas en ancho para un nivel de zoom
    return Math.pow(2, zoom)
};

/**
 * Dado el zoom de la tesela a renderizar y el zoom de la tesela de datos, obtiene
 * el número de teselas que se pueden renderizar con una tesela de datos (a lo ancho o alto).
 *
 * @param   {number}  zoom      Nivel de zoom de la tesela a renderizar
 * @param   {number}  zoomDatos Nivel de zoom de la tesela de datos
 * @returns {number}            Número de teselas
 */
render.getTrans = function(zoom, zoomDatos) { // Número de teselas (en ancho) por tesela windy
    return render.tileW(zoom) / render.tileW(zoomDatos);
};


// Array que contiene los indices de calidad
let tablasz2z = Object.keys(render.zoom2zoom);

/**
 * En función de los parámetros del mapa a pintar, devuelve el nivel de zoom de datos que se va a utilizar.
 *
 * @param   {object}  params    Objeto que contiene los parámetros del mapa a pintar.
 * @param   {number}  zoom      Nivel de zoom de la tesela a renderizar
 * @returns {number}            Nivel de zoom de datos.
 */
render.getDataZoom = function(params, zoom) { // Para un nivel de zoom real devuelve el nivel de zoom windy que necesita

    let quality = params.dataQuality;

    // Si el valor quality es númerico,  se devuelve zoom - quality. Cuanto mayor
    // es el valor de quality se devuelve un nivel de zoom más alto
    if (Number.isInteger(quality))
        return Math.min(params.maxTileZoom, zoom - quality);

    // Si quality es string y está activo upgradeDataQuality se devuelve el indice de calidad
    // inmediatamente superior a la calidad quality.
    let indexquality = params.upgradeDataQuality ?
        tablasz2z[Math.max(tablasz2z.indexOf(quality) - 1, 0)] :
        quality;

    return Math.min(params.maxTileZoom, render.zoom2zoom[indexquality][zoom])
};

/**
 * Dadas las coordenadas (x, y, zoom) de una tesela a renderizar y los parámetros del mapa, devuelve un objeto
 * que contiene la url y coordenadas de la tesela de datos que hay que descargar. <br>
 * El objeto también contiene información de la porción de tesela de datos que hay que utilizar para el renderizado
 * y las funciones de transformación sobre los canales de los datos.
 *
 * @param   {object}       coords  Objeto que contiene la coordenada (x, y) de la tesela el zoom z.
 * @param   {object}       params  Parámetros del mapa que se está pintando.
 * @returns {null|object}          Objeto que contiene toda la información de la tesela de datos.
 */
render.whichTile = function(coords, params) { // Dadas las coordenadas (x, y) y zoom (z) de una tesela devuelve las coordenadas y zoom de las de windy

    // Si no contiene la url que sirve de plantilla
    if (!params.fullPath)
        return null;

    let zoomreal = coords.z
        , zoom = render.getDataZoom(params, zoomreal)
        , trans = render.getTrans(zoomreal, zoom)
        , x = Math.floor(coords.x / trans)
        , y = Math.floor(coords.y / trans)
        , intx = coords.x % trans
        , inty = coords.y % trans
        , url = params.fullPath.replace("<z>", zoom).replace("<y>", y).replace("<x>", x)
        , nextUrl = params.fullNextPath.replace("<z>", zoom).replace("<y>", y).replace("<x>", x)
        , width = render.tileW(zoom);

    // Si las coordenadas de la tesela se sale de las posibles para el zoom
    if ( x < 0 || y < 0 || x >= width || y >= width )
        return null;
    else
        return   {
            url: url,
            nextUrl: nextUrl,
            x: x,
            y: y,
            z: zoom,
            intX: intx,
            intY: inty,
            trans: trans,
            transformR: params.transformR || null,
            transformG: params.transformG || null,
            transformB: params.transformB || null
        }
};

/**
 * A partir de los datos obtenidos en una tesela JPEG, testea si el dato que se codifica en una determinada posición
 * es NAN. Para ello, comprueba si el bit más significativo del canal blue está puesto a 1. Tambien comprueba si el
 * bit más significativo del pixel de la derecha, el de abajo y el de abajo a la derecha está puesto a 1 FIXME.
 *
 * @param   {number[]}  data Array que contiene los datos de la imagen.
 * @param   {number}    pos  Posición dentro del array de datos
 * @returns {boolean}        Un booleano <b>true</b> si contiene NAN o <b>false</b> en caso contrario
 */
render.testJPGtransparency = function(data, pos) {
    return (192 & data[pos + 2] || 192 & data[pos + 6] || 192 & data[pos + 1030] || 192 & data[pos + 1034]) !== 0
};


/**
 * A partir de los datos obtenidos en una tesela PNG, testea si el dato que se codifica en una determinada posición
 * es NAN. Para ello, comprueba si el canal Alpha es 0. También comprueba si el canal blue del pixel de la derecha,
 * el de abajo y el de abajo a la derecha es también 0 FIXME.
 *
 * @param   {number[]}  data Array que contiene los datos de la imagen.
 * @param   {number}    pos  Posición dentro del array de datos
 * @returns {boolean}        Un booleano <b>true</b> si contiene NAN o <b>false</b> en caso contrario
 */
render.testPNGtransparency = function(data, pos) {
    return !(data[pos + 3] && data[pos + 7] && data[pos + 1028 + 3] && data[pos + 1028 + 7])
};

// Tablas de pesos para la interpolación (bilineal)
render.wTables = {};

render.getWTable = function(trans) { // Obtiene una matriz de pesos para un trans
    if (trans in render.wTables)
        return render.wTables[trans];

    let wtable, y, x, index = 0;

    if (!(trans <= 32))
        return null;

    wtable = new Uint16Array(4 * trans * trans);
    for (y = 0; y < trans; y++)
        for (x = 0; x < trans; x++) {
            wtable[index++] = (trans - y) * (trans - x);
            wtable[index++] = (trans - y) * x;
            wtable[index++] = y * (trans - x);
            wtable[index++] = x * y;
        }

    render.wTables[trans] = wtable;  // asigna la matriz de pesos creada al trans

    return wtable;
};

/**
 *
 * @param img
 * @param paleta1
 * @param paleta2
 * @param paleta3
 * @param getAmountByColorFunc
 */
render.createCombinedFill3paramFun = function(img, inc, paleta1, paleta2, paleta3, getAmountByColorFunc) {
    let fillFun1 = render.createFillFun(img, inc, paleta1)
        , fillFun2 = render.createFillFun(img, inc, paleta2)
        , fillFun3 = render.createFillFun(img, inc, paleta3);

    return function(ncol, nrow, value1, value2, value3) {

        let amount = getAmountByColorFunc(value1, value2, value3);

        if (amount >= 0 && amount < 4) {
            switch (amount) {
                case 0:
                    fillFun1(ncol, nrow, value1);
                    break;
                case 1:
                    fillFun2(ncol, nrow, value2);
                    break;
                case 2:
                    fillFun3(ncol, nrow, value3)
                    break;
            }
        }
    }
};

render.createCombinedFill2paramFun = function(img, inc, paleta1, paleta2, getAmountByColorFunc) {
    let fillFun1 = render.createFillFun(img, inc, paleta1)
        , fillFun2 = render.createFillFun(img, inc, paleta2);

    return function(ncol, nrow, value1, value2) {
        let amount = getAmountByColorFunc(value1, value2);
        if (amount >= 0 && amount < 2) {
            switch (amount) {
                case 0:
                    fillFun1(ncol, nrow, value1);
                    break;
                case 1:
                    fillFun2(ncol, nrow, value2);
                    break;
            }
        }
    }
};

render.createCombinedFillFun = function(img, paleta1, paleta2, getAmountByColorFunc) {
    let colorarray1 = paleta1.colors
        , colorarray2 = paleta2.colors
        , val2idx1 = paleta1.value2index.bind(paleta1)
        , val2idx2 = paleta2.value2index.bind(paleta2)
        , fillFun1 = render.createFillFun(img, 2, paleta1)
        , fillFun2 = render.createFillFun(img, 2, paleta2)
        , assignColor = function(pos, R, G, B) {
        img[pos] = R;
        img[pos + 1] = G;
        img[pos + 2] = B;
    };
    return function(ncol, nrow, value1, value2) {
        let amount = getAmountByColorFunc(value1, value2),
            pos, idx1, idx2, R1, G1, B1, R2, G2, B2;

        if (amount > 0 && amount < 4) {
            pos = (nrow << 8) + ncol << 2;
            idx1 = val2idx1(value1);
            idx2 = val2idx2(value2);
            R1 = colorarray1[idx1++];
            G1 = colorarray1[idx1++];
            B1 = colorarray1[idx1++];
            R2 = colorarray2[idx2++];
            G2 = colorarray2[idx2++];
            B2 = colorarray2[idx2++];
        }

        switch (amount) {
            case 0:
                fillFun1(ncol, nrow, value1);

                break;
            case 1:
                assignColor(pos, R2, G2, B2);
                assignColor(pos + 4, R1, G1, B1);
                assignColor(pos + 1024, R1, G1, B1);
                assignColor(pos + 1028, R1, G1, B1);

                break;
            case 2:
                assignColor(pos, R2, G2, B2);
                assignColor(pos + 4, R2, G2, B2);
                assignColor(pos + 1024, R1, G1, B1);
                assignColor(pos + 1028, R1, G1, B1);

                break;
            case 3:
                assignColor(pos, R2, G2, B2);
                assignColor(pos + 4, R2, G2, B2);
                assignColor(pos + 1024, R2, G2, B2);
                assignColor(pos + 1028, R1, G1, B1);

                break;
            case 4:
                fillFun2(ncol, nrow, value2)
        }
    }
};

render.createfillColorFun = function (img, inc) {
    switch (inc) {
        case 1:
            return function (ncol, nfila, color) {

                let posimg = (nfila << 8) + ncol << 2;

                img[posimg++] = color[0];
                img[posimg++] = color[1];
                img[posimg++] = color[2];
                img[posimg] = color[3];
            }
                ;
        case 2:
            return function (ncol, nfila, color) {

                let posimg = (nfila << 8) + ncol << 2;

                img[posimg] = img[posimg + 4] = color[0];
                img[posimg + 1] = img[posimg + 5] = color[1];
                img[posimg + 2] = img[posimg + 6] = color[2];
                img[posimg + 3] = img[posimg + 7] = color[3];
                img[posimg += 1024] = img[posimg + 4] = color[0];
                img[posimg + 1] = img[posimg + 5] = color[1];
                img[posimg + 2] = img[posimg + 6] = color[2];
                img[posimg + 3] = img[posimg + 7] = color[3];
            }

    }

};

render.createFillFun = function(img, inc, paleta, bgcolor) {
    let colorarray = paleta.colors
        , fval2indx = paleta.value2index.bind(paleta);
    switch (inc) {
        case 1:
            return function(ncol, nfila, value) {
                let posimg = (nfila << 8) + ncol << 2;   // nfila * 256 * 4  + ncol * 4
                // , indx = fval2indx(value);
                if(paleta.clipbottom && value < paleta.min ) {
                    if (bgcolor){
                        img[posimg++]=bgcolor[0];
                        img[posimg++]=bgcolor[1];
                        img[posimg++]=bgcolor[2];
                        img[posimg++]=bgcolor[3];
                    }
                    img[posimg++] = img[posimg++] =
                        img[posimg++] = img[posimg] = 0;
                }
                else {
                    let indx = fval2indx(value);
                    img[posimg++] = colorarray[indx++];
                    img[posimg++] = colorarray[indx++];
                    img[posimg++] = colorarray[indx++];
                    img[posimg] = colorarray[indx];
                }
            }
                ;
        case 2:  // Rellena también el pixel derecha, abajo y abajo-derecha
            return function(ncol, nfila, value) {
                let posimg = (nfila << 8) + ncol << 2
                    , indx = fval2indx(value), R, G, B, A;


                if (paleta.clipbottom && value < paleta.min){
                    if (bgcolor){
                        R=bgcolor[0];
                        G=bgcolor[1];
                        B=bgcolor[2];
                        A=bgcolor[3];
                    }
                    else
                        R = G = B = A = 0;
                } else {
                    R = colorarray[indx++];
                    G = colorarray[indx++];
                    B = colorarray[indx++];
                    A = colorarray[indx];

                }
                img[posimg] = img[posimg + 4] = R;
                img[posimg + 1] = img[posimg + 5] = G;
                img[posimg + 2] = img[posimg + 6] = B;
                img[posimg + 3] = img[posimg + 7] = A;
                img[posimg += 1024] = img[posimg + 4] = R;
                img[posimg + 1] = img[posimg + 5] = G;
                img[posimg + 2] = img[posimg + 6] = B;
                img[posimg + 3] = img[posimg + 7] = A;
            }
    }
};

let canvas = document.createElement("canvas")
    , ctx = canvas.getContext("2d");

canvas.width = canvas.height = 256;
ctx.fillStyle = "black";
ctx.fillRect(0, 0, 256, 256);
render.imgData = ctx.getImageData(0, 0, 256, 256);

render.interpolateNearest = function(wtable, ntiles, pos,
                                     valueTL, valueTR, valueBL, valueBR,
                                     weightTL, weightTR, weightBL, weightBR, decodeFun) {
    let value;

    if ( null !== wtable ) {
        weightTL = wtable[pos];
        weightTR = wtable[pos + 1];
        weightBL = wtable[pos + 2];
        weightBR = wtable[pos + 3];
    }

    let maxWeight = Math.max(weightTL, weightTR, weightBL, weightBR);

    if ( maxWeight === weightTL )
        value = valueTL;
    else if ( maxWeight === weightTR )
        value = valueTR;
    else if ( maxWeight === weightBL )
        value = valueBL;
    else if ( maxWeight === weightBR )
        value = valueBR;
    else
        value = 0;

    return decodeFun(value);
}


render.interpolateBilineal = function(wtable, ntiles, pos,
                                     valueTL, valueTR, valueBL, valueBR,
                                     weightTL, weightTR, weightBL, weightBR, decodeFun) {
    let value;

    // Si está disponible la tabla de pesos
    if( wtable ) {
        value = valueTL * wtable[pos] +
            valueTR * wtable[pos + 1] +
            valueBL * wtable[pos + 2] +
            valueBR * wtable[pos + 3] >> ntiles;

    }else
        value = valueTL * weightTL + valueTR * weightTR + valueBL * weightBL + valueBR * weightBR;

    return decodeFun(value);

}


export default render;

/**
 * Clase que controla el comportamiento visual de MapDescriptionView
 */
class MapDescriptionView {


    /**
     * Elemento HTML que contiene la descripción (título, modelo, nivel e icono) del mapa
     * @private
     */
    private desc_mapa: HTMLDivElement | null | undefined;
    /**
     * Elemento HTML que contiene el nombre completo del tipo de mapa
     * @private
     */
    private titulo: HTMLSpanElement | null | undefined;
    /**
     * Elemento HTML que contiene el modelo y el nivel de presión seleccionados
     * @private
     */
    private subtitulo: HTMLSpanElement | null | undefined;
    /**
     * Elemento HTML que contiene el icono correspondiente con el tipo de mapa
     * @private
     */
    private icon: HTMLSpanElement | null | undefined;



    /**
     * Crea e inicializa un objeto
     * @param divDescriptionElement
     */
    constructor(divDescriptionElement: HTMLElement | null){



        // this.desc_mapa = <HTMLDivElement>document.querySelector("#desc_mapa");
        // this.titulo = divLegend?.querySelector("#titulo");
        if (divDescriptionElement) {
            this.desc_mapa =  <HTMLDivElement> divDescriptionElement;
            this.titulo = <HTMLDivElement>divDescriptionElement.querySelector("#titulo");
            // this.subtitulo = divLegend?.querySelector("#subtitulo");
            this.subtitulo = <HTMLDivElement>divDescriptionElement.querySelector("#subtitulo");
            this.icon = <HTMLSpanElement>divDescriptionElement.querySelector("#icon");
        }

    }


    /**
     * Establece el título del tipo de mapa, el subtitulo y la imagen del icono que le corresponde
     * @param titulo Titulo del tipo de mapa
     * @param subtitulo Contiene el modelo y nivel seleccionados
     * @param iconopath Ruta del fichero de icono
     */
    updateDesc (titulo: string, subtitulo: string, iconopath: string){

        iconopath = iconopath.replace('_blue','');
        if (this.titulo)
            this.titulo.textContent=titulo;

        if (this.subtitulo)
            this.subtitulo.textContent=subtitulo;

        if (this.icon) {
            if(this.icon.firstChild) {
                let img = <HTMLImageElement> this.icon.firstChild;

                // Si ya existía un icono y no se ha cambiado de tipo de mapa no se actualiza
                if (img.src !== location.href + iconopath) {
                    img.src = iconopath;
                }
            }
        }
        // if (!this.desc_mapa?.classList.contains("visible"))
        //     this.desc_mapa?.classList.add("visible");
    }
}

export default MapDescriptionView;

import CheckBoxView from "../ui/CheckBoxView";
import store from "./store";

class CBIsobarasController{
    constructor(view) {
        this.view = view;

        const anim = store.get("isolines");

        this.view.checkbox.checked = anim !== "off";

        this.view.checkbox.addEventListener("change", this.change.bind(this));

        store.on("isolines",function(type){
            this.view.checkbox.checked = type !== "off";
        }.bind(this))

    }


    change() {
        if( ! this.view.checkbox.checked )
            store.set("isolines", "off");
        else
            store.set("isolines", "pressure");
    }

}

export default CBIsobarasController;

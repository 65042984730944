import Evented from "./Evented";
import http from "./http";
import store from "./store";
import countriesCodes from "./countriesCodes";
import rootScope from "./rootScope";
import utils from "./utils";

const Search = Evented.extend({

    _init(){
        Evented._init.call(this);
        let lang = store.get("lang");
        lang = (lang === "auto") ? "en_US" : lang;
        const countryISO = store.get("country");
        const countryCode = countriesCodes[countryISO].code;
        // let website = store.get("website");

        //website = (website === "nodef" || website === "apps" || website === "dashboard") ? "es" : website;

        this.searchurl = `${rootScope.searchURL}/txt/${lang}/`;
        this.countryUrl = `${rootScope.searchURL}/top/${lang}/${countryCode}.json`
        this.locationUrl = `${rootScope.searchURL}/gps/${lang}/`;

    },

    searchLocation: function(coords)
    {
        const locationUrl = this.locationUrl + utils.encodeCoords(coords) + ".json";
        http.get(locationUrl).then(
            (response) => {
                const resultados = response.data.data.respuesta.localizacion[0].search.resultados;
                this.emit("foundresults", resultados);
            }
        );
    },
    searchCountry(){
    // , {headers:{ meteored: "loquesea"}}
        http.get(this.countryUrl).then(
            (response) => {
                const resultados = response.data.data.respuesta.localizacion[0].search.resultados;
                this.emit("foundresults", resultados);
            }
        );
    },
    search: function (searchtext) {
        const searchUrl = this.searchurl + searchtext + "/";

        http.get(searchUrl).then(
            (response) => {
                const resultados = response.data.data.respuesta.localizacion[0].search.resultados;
                this.emit("foundresults", resultados);
                // console.info(resultados);
            }
        );

    }
})

export default Search.instance();

import plugins from "./plugins";
import broadcast from "./broadcast";
import utils from "./utils";
// import rootScope from "./rootScope";




function getPlugin(e) {
    return e in plugins && plugins[e];
}

function closePlugin(nameplugin, event) {
    const plugin = getPlugin(nameplugin);
    if(plugin && !plugin.neverClose)
        plugin.close({
            ev: event
        })
}

// function hd(nameplugin, paneArray) {
//     let n = [];
//
//     utils.each(plugins,
//         function(plugin, r) {
//             if(plugin.pane && paneArray.includes(plugin.pane) && plugin.isOpen && r !== nameplugin)
//                 n.push(plugin);
//         }
//     );
//     return n;
// }
broadcast.on("rqstOpen", function(nameplugin, params) {
        let plugin = nameplugin in plugins ? plugins[nameplugin]: null;

        if (plugin) {
            let pane = plugin.pane
                , openPlugin = function(disableOpeningAnimation) {
                        plugin.open(
                            params
                        //     {
                        //     params: params,
                        //     disableOpeningAnimation: disableOpeningAnimation
                        // }
                        )
                };
                // , a = function(t) {
                //     hd(nameplugin, t).forEach(
                //         function(plugin) {
                //             plugin.close({
                //                 disableClosingAnimation: true
                //             })
                //         }
                //     )
                // };

            // if (pane) {
            //     if ("bottom" === pane) {
            //         a(["bottom", "rhpane", "lhpane", "top"]);
            //         openPlugin();
            //     }
            //     else if ("center" === pane) {
            //         a(["lhpane", "rhpane", "center"]);
            //         openPlugin();
            //     }
            //     else if ("rhpane" === pane || "lhpane" === pane) {
            //         a(["bottom", "center"]);
            //         plugins.multimodel.close({
            //             disableClosingAnimation: true
            //         });
            //
            //         if (rootScope.isMobile) {
            //             a(["rhpane", "lhpane", "nearest"]);
            //             openPlugin();
            //         }
            //         else {
            //             const o = hd(nameplugin, [pane]);
            //
            //             if(o.length) {
            //                 openPlugin(!0);
            //                 setTimeout((function () {
            //                         o[0].close({
            //                             disableClosingAnimation: true,
            //                             doNotResetURL: true
            //                         })
            //                     }
            //                 ), 100)
            //             }
            //             else
            //                 openPlugin();
            //         }
            //     }
            //     else {
            //         a([pane]);
            //         openPlugin();
            //     }
            // }
            // else
                openPlugin()
        } else
            console.error("pluginCtrl", "Attmpt to open non existent plugin " + nameplugin)
    }
);

broadcast.on("rqstClose", closePlugin);

broadcast.on("closeAllPlugins",
    function(nameExcludedPlugin) {
        utils.each(
            plugins,
            function(plugin, namePlugin) {
                if(plugin.pane && plugin.isOpen && nameExcludedPlugin !== namePlugin)
                    closePlugin(namePlugin)
            }
        )
    }
);

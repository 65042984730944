import dataSpecifications from "./dataSpecifications";
import storage from "./storage";
import rootscope from "./rootScope";
import Evented from "./Evented";
import broadcast from "./broadcast";


var array = {},
    store = Evented.instance({
        ident: "store"
    }),

    compare = function(key, spec, elements) {
        if (spec.compare)                                       // Si existe función compare la usa
            return !spec.compare(elements, getFunction(key));
        else{
            const value = getFunction(key);
            if (value !== null) {                          // Si hay un elemento en array con key lo compara
                return value !== elements;
            }
            else
                return getDefaultFunc(key) !== elements;        // Sino compara con el por defecto
        }
    },

    checkallowed = function(spec, elements) {
        if (typeof spec.allowed === "function")         // Si allowed es una función
            return spec.allowed(elements);              // devuelve lo que devuelva la función
        else{
            if(Array.isArray(spec.def))                 // Si spec.def es un array y también chequea que cada elemento del array está dentro de allowed
                return Array.isArray(elements) &&
                    elements.every(function(element) {
                        return spec.allowed.includes(element)
                    });
            else
                return spec.allowed.includes(elements); // Sino comprueba que el elemento t está dentro del array allowed

        }
    },

    setDefaultFunc = function(key, element) {
        dataSpecifications[key].def = element;
        delete array[key];
    },

    setFunction = function(key, elements, options) {  //d

        if ( void 0 === options )
            options = {};

        const spec = dataSpecifications[key];
        if (spec) {
            if ( !options.doNotCheckValidity && ! checkallowed(spec, elements) ) // Si los elementos no son permitidos por la especificación
                return spec.asyncSet ? Promise.reject() : void 0;

            if ( spec.syncSet && (options.forceChange || compare(key, spec, elements)) ) {  // Si está definida syncset
                const a = spec.syncSet(elements, options);                                // establecemos los elementos
                if (options.forceChange || compare(key, spec, a)) { // actualizamos
                    update(key, spec, options, a);
                    return true;
                }
            } else {
                if (spec.asyncSet) {
                    if (options.forceChange || compare(key, spec, elements)) {

                        const promise = spec.asyncSet(elements, options);

                        promise.then(function(elements) {
                            if (options.forceChange || compare(key, spec, elements))
                                update(key, spec, options, elements);
                        }).catch(function(e) {
                            return console.error("store", "Unable to change store value " + key + ", " + elements, e)
                        });

                        return promise;
                    }
                    return Promise.resolve(elements)
                }
                if (options.forceChange || compare(key, spec, elements)) {
                    update(key, spec, options, elements);
                    return true;
                }
            }

        } else
            console.error("store", "Trying to set dataSpec. ident:", key)
    },

    update = function(key, spec, options, element) {
        if (null === element)
            delete array[key]                                  // Si element es null borramos el elemento con clave key
        else
            array[key] = element;                              // Sino le asignamos element

        if (spec.save && !options.doNotStore && storage.isAvbl) { // Si el storage está disponible y no se ha especificado que no se guarde
            const time = options.update || Date.now();            // timestamp
            storage.put("settings_" + key, element);          // almacenamos en storage el elemento

            if (spec.sync) {
                spec.update = time;                             // actualizamos el valor update y almacenamos en storage el time
                storage.put("settings_" + key + "_ts", time);
                storage.put("lastSyncableUpdatedItem", time);
            }
            // rootscope.user && spec.sync && !options.doNotSaveToCloud && store.emit("_cloudSync")   // Para nubes
        }
        store.emit(key, null === element ? spec.def : element, options);   // lanzamos evento
    },

    getFunction = function(key) {   //h
        if (key in array)
            return array[key];
        var element, spec = dataSpecifications[key];

        if ( spec )
        {
            if ( spec.save && storage.isAvbl ) {
                if ( null === (element = storage.get("settings_" + key)) )
                    element = spec.def
                else
                if ( ! checkallowed(spec, element) ) {
                    console.error("store", "Attempt to get invalid value from localStorage", key);
                    element = spec.def;
                }
            }
            else
                element = spec.def;

            array[key] = element;
            return element
        }
        else{
            console.error("Trying to get invalid dataSpec. ident:", key);
            return null;
        }
    },

    // getFunction = function(key) {
    //     if (key in array)
    //         return array[key];
    //     else
    //         return null;
    // },
    getDefaultFunc = function(key) { //m
        return dataSpecifications[key].def
    };
// store.once("country", function(e) {
//     setDefaultFunc("hourFormat", /us|uk|ph|ca|au|nz|in|eg|sa|co|pk|my/.test(e) ? "12h" : "24h"),
//         setFunction("isImperial", /us|my|lr/.test(e))
// });

let generate, uuid = storage.get("UUID");

if (! uuid) {
    generate = function() {   // si no existe uuid se crea
        return Math.floor(65536 * (1 + Math.random())).toString(16).substring(1)
    }
    uuid = generate() + generate() + "-" + generate() + "-" + generate() + "-" + generate() + "-" + generate() + generate() + generate();

    storage.put("UUID", uuid)
}

// return getFunction("firstUserSession") || setFunction("firstUserSession", Date.now()),
//     rootscope.sessionCounter = getFunction("sessionCounter201803") + 1,
// setFunction("sessionCounter201803", rootscope.sessionCounter),
// broadcast.emit("identityCreated", uuid),
// broadcast.emit("provisionaryToken", storage.get("userToken")),
// broadcast.on("tokenRecieved", function(e) {
//     return storage.put("userToken", e)
// }),
export default Object.assign(store, {
    get: getFunction,

    set: setFunction,

    remove: function(key, options) {
        void 0 === options && (options = {
            doNotCheckValidity: 1
        }), setFunction(key, null, options)
    },

    insert: function(key, element) {
        return dataSpecifications[key] = element
    },

    defineProperty: function(key, property, value) {
        return dataSpecifications[key][property] = value
    },

    getProperty: function(key) {
        return dataSpecifications[key]
    },

    setDefault: setDefaultFunc,

    is12hFormat: function() {
        return "12h" === getFunction("hourFormat")
    },

    getDeviceID: function() {
        return uuid
    },

    getAll: function() {
        Object.keys(dataSpecifications).map(function(e) {
            return console.log(dataSpecifications + ":", getFunction(e))
        })
    },

    getDefault: getDefaultFunc,

    getAllowed: function(key) {
        var allowed = dataSpecifications[key].allowed;
        return allowed && Array.isArray(allowed) ? allowed : "Allowed values are checked by function"
    }
});

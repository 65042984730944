import $ from "./$";
import utils from "./utils";
import http from "./http";
import store from "./store";
import broadcast from "./broadcast";
// import router from "./router";


function getGPSlocation(locparams) {

    if( store.get("platform") === "apps" )
        return Promise.resolve(getMyLatestPos());

    if ( void 0 === locparams ) {
        locparams = {
            enableHighAccuracy: false,
            timeout: 7e3
        }
    }
    return navigator.geolocation ? new Promise(function(resolve) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    var gpsLocation = {
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                        source: "gps",
                        ts: Date.now()
                    };
                    store.set("gpsLocation", gpsLocation);
                    broadcast.emit("newLocation", gpsLocation);
                    resolve(gpsLocation);
                }, function(e) {
                    resolve(getMyLatestPos())
                }, locparams)
        }
    ) : Promise.resolve(getMyLatestPos())
}
function getFallbackName (e, t) {
    return parseFloat(e).toFixed(2) + ", " + parseFloat(t).toFixed(2)
}

function setIpLocation (lat, lon, zoom, country, city, source) {
    if ( country ) {
        country = country.toLowerCase();
        store.set("country", country);
    }

    var ipLocation = {
        ts: Date.now(),
        source: source,
        lat: parseFloat(lat),
        lon: parseFloat(lon),
        zoom: zoom,
        name: city || getFallbackName(lat, lon)
    };
    store.set("ipLocation", ipLocation);
    broadcast.emit("newLocation", ipLocation);
}

function getMyLatestPos () {
    const ipLocation = store.get("ipLocation");
        // , gpsLocation = store.get("gpsLocation");

    // if (ipLocation && gpsLocation){
    //     return ipLocation.ts > gpsLocation.ts ? ipLocation : gpsLocation;
    // }
    // else if ( ! gpsLocation && ipLocation ){
    //     return ipLocation;
    // }
    // else if (  gpsLocation && !ipLocation ){
    //     return gpsLocation;
    // }
    if (ipLocation)
        return ipLocation;
    else {
        return {
            lat: 0,
            lon: -1 * (new Date).getTimezoneOffset() / 4,
            cc: "us",
            source: "fallback",
            zoom: 3,
            name: ""
        }

    }

    // return ipLocation && gpsLocation ? ( ipLocation.ts > gpsLocation.ts ? ipLocation : gpsLocation ) : gpsLocation || (ipLocation || {
    //     lat: 0,
    //     lon: -1 * (new Date).getTimezoneOffset() / 4,
    //     cc: "us",
    //     source: "fallback",
    //     zoom: 3,
    //     name: ""
    // })
}


// try {
//     // getGPSlocation();
//     /*var data, geoip = $('meta[name="geoip"]');
//     if (geoip && geoip.content && (data = geoip.content.split(","))) {
//         var lat = data[1]
//             , lon = data[2]
//             , country = data[3]
//             , city = data[4];
//         setIpLocation(lat, lon, country, city, "meta")
//     } else
//         http.get("https://www.windy.com/node/umisteni").then(function(e) {
//             var data = e.data;
//             setIpLocation(data.ll[0], data.ll[1], data.country, data.city, "api")
//         }).catch(console.error.bind(null, "geolocation", "Unable to load||parse /node/umisteni"))*/
// } catch (e) {
//     console.error("geolocation", "Module initialization failed", e)
// }

export default {
    getFallbackName: getFallbackName,
    getGPSlocation: getGPSlocation,
    getMyLatestPos: getMyLatestPos,
    setIpLocation: setIpLocation,
    getHomeLocation: function(e) {
        var startUp = store.get("startUp")
            , ts = Date.now();

        if ("location" === startUp)
            e(store.get("homeLocation"));
        else if ("ip" === startUp) {
            var pos = getMyLatestPos();
            if ("fallback" === pos.source || ts - pos.ts > 12 * utils.tsHour)
                broadcast.once("newLocation", e)
            else
                e(getFallbackName)
        } else {
            var location = store.get("gpsLocation");
            if ( location && "gps" === location.source && ts - location.ts < utils.tsHour)
                e(location)
            else
                getGPSlocation().then(e)
        }
    }
}

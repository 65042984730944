import lruCache from "./lruCache";
import rscope from "./rootScope";
import utils from "./utils";
import broadcast from "./broadcast";

// W.define("http", ["lruCache", "rootScope", "utils", "broadcast"], function(e, t, n, i) {
var cache = new lruCache(50)
// , a = ""
//  r = 0
// , o = null;
//     , l = function(e, i) {
//             var s = document.head.querySelector('meta[name="token"]')
//                 , r = {
//                 token: s && s.content,
//                 token2: rscope.userToken || i || "pending",
//                 uid: o,
//                 sc: rscope.sessionCounter,
//                 pr: +e,
//                 v: rscope.version
//             };
//             a = utils.qs(r)
//     };
//
// l(),
//     broadcast.on("tokenRecieved", l.bind(null, false)),
//     broadcast.on("provisionaryToken", l.bind(null, true)),
//     broadcast.on("identityCreated", function(e) {
//         o = e,
//             l()
//     });

var c = function(response) {
        return {
            headers: response.headers,
            status: response.status,
            data: response.data && response.isJSON ? JSON.parse(response.data) : response.data
        }
    }
    // , accept = "application/json binary/" + (W.assets || "").replace(/\./g, "")
    , accept = "application/json";
    //e, o , l

function requestFUN(method, url, params) {
        let cachedPromise, promise;
        if (params == null)
            params = {};

        if ("object" == typeof params.qs) {
            let qs = utils.qs(params.qs);
            if (qs)
                url = utils.addQs(url, qs)
        }
        // var f = /^\/?users\//.test(url) || params.withCredentials
        //     , urlinicial = url;
        let urlinicial = url;

        if (false === params.cache && "get" === method)
            params.cache = true;

        if (params.cache)
            cachedPromise = cache.get(url)

        // if (void 0 === l.cache && "get" === e && (l.cache = true),
        // l.cache && (u = s.get(o)))


        if (cachedPromise) {
            if (Promise.resolve(cachedPromise) === cachedPromise)
                return cachedPromise;
            else
                return Promise.resolve(c(cachedPromise));
        }

        let request = new XMLHttpRequest;

        if (params.timeout)
            request.timeout = params.timeout;

        // var v = false;

        //
        // if (! /^http/.test(url) && ! /^v\/\d+/.test(url) ) {
        //
        //     url = utils.joinPath(rscope.nodeServer, url);
        //     if ( /node\.windy/.test(url) )
        //         url = utils.addQs(url, a + "&poc=" + ++r);
        //     if ( /^\/?forecast\//.test(p)) {
        //         var array = /^(.+)\/forecast\/([^/]+)\/([^/]+)\/(.+)$/.exec(url)
        //             , w = array[1]
        //             , b = array[2]
        //             , T = array[3]
        //             , L = b + "/" + T + "/" + y[4];
        //         url = w + "/" + ("Zm9yZWNhc3Q/" + window.btoa(T).replace(/=/g, "")) + "/" + window.btoa(L).replace(/=/g, ""),
        //             v = true
        //     }
        // }
        url = encodeURI(url);
        request.open(method, url, true);

        if (params.binary)
            request.responseType = "arraybuffer";
        // if (f)
        //     request.withCredentials = true;

        request.setRequestHeader("Accept", accept);

        promise = new Promise(function(resolve, reject) {
                var matched,
                    responseheaders = {},
                    response = {};

                request.onreadystatechange = function() {

                    // El estado 4 indica que el response está listo

                    if (request.readyState === 4) {
                        if (params.parseHeaders)
                            request.getAllResponseHeaders().split(/\n/).forEach(
                                function (header) {
                                    matched = header.match(/(.*:?): (.*)/);
                                    if ( matched )
                                        responseheaders[matched[1].toLowerCase()] = responseheaders[2];
                                });

                        if (request.status >= 200 && request.status < 300 || 304 === request.status) {
                            response = {
                                status: request.status,
                                headers: responseheaders,
                                data: null
                            };
                            if ( params.binary )
                                response.data = request.response;
                            else {
                                // if (v) {
                                //     response.isJSON = true;
                                //     response.data = window.atob(request.responseText);
                                // }
                                // else {
                                response.isJSON = params.json || /json/.test(request.getResponseHeader("Content-Type") || "");
                                response.data = request.responseText;
                                // }
                            }
                            if ( params.cache )
                                cache.put(urlinicial, response);

                            var o = c(response);

                            resolve(o);

                        } else {
                            if ( 0 === request.status )
                                broadcast.emit("noConnection", "http");
                            if ( params.cache )
                                cache.remove(urlinicial);
                            reject(request.status);
                        }
                    }
                }
            }
        );

        var S = null;
        if ( "post" === method || "put" === method ) {
            request.setRequestHeader("Content-type", "application/json; charset=utf-8");
            S = JSON.stringify(params.data);
        }

        try {
            request.send(S)
        } catch (e) {
            broadcast.emit("noConnection", "http");
            return Promise.reject(e);
        }
        if ( params.cache )
            cache.put(urlinicial, promise);

        return promise;
    }

export default {
    get: requestFUN.bind(null, "get"),
    delete: requestFUN.bind(null, "delete"),
    post: requestFUN.bind(null, "post"),
    put: requestFUN.bind(null, "put"),
    resetCache: function() {
        return cache.removeAll()
    }
}

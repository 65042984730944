// define("location", ["trans", "store", "$", "utils", "broadcast", "rootScope", "router", "picker", "overlays", "format", "plugins"], function(trans, store, $, utils, broadcast, rootScope, router, picker, overlays, format, plugins) { //e, t, n, i, s, a, r, o, l, c, d

import trans from "./trans";
import store from "./store";
import utils from "./utils";
import broadcast from "./broadcast";
import rootScope from "./rootScope";
import router from "./router";
import overlays from "./overlays";
import format from "./format";
import plugins from "./plugins";


// define("location", ["trans","store", "$", "utils", "broadcast", "rootScope", "router",  "overlays", "format", "plugins"], function( trans, store, $, utils, broadcast, rootScope, router, overlays, format, plugins) { //e, t, n, i, s, a, r, o, l, c, d
    var search, seoString = format.seoString,
        seoLang = format.seoLang,
        seoUrlString = format.seoUrlString,
        url = router.url,
        // g = Date.now(),
        // title = "Windy: " + trans.TITLE,
        // title = "Vienty: " ,
        // y = $('meta[name="description"]') || {},
        // w = y && y.content,
        personalizedTitle = false,
        // T = $('link[rel="canonical"]'),
        overlayName = null, S = null, E = null;

    if ( "/" === url )
        url = "";

    // var A = function(e) {
    //     return J.models && J.models.layer2product[e].length > 1
    // },
    // getFrag = function(e) {
    //     var frag = "/maps-frontend/" + (search ? "?" + search : "");
    //     return frag
    // }
    // ,
    //     setCanonical = function(e) {
    //     return T.href = utils.joinPath("https://www.windy.com", e)
    // }
    // , x = utils.emptyFun;


    // if ( rootScope.isCrawler )
    //     plugins.seo.load().then(function() {
    //         E = J.require("seo"),
    //         x = E.showURL
    //     });
    // else
    // {
    // if (window.history && window.history.replaceState) {
    //     x = utils.debounce(
    //         function () {
    //             try {
    //                 // var e = getPrefix();
    //                 window.history.replaceState({
    //                     url: url,
    //                     search: search
    //                 }, "", getFrag(e));
    //
    //                 // setCanonical(e)
    //             } catch (e) {
    //             }
    //         }, 200)
    // }
    // }

    var getPrefix = function() {
            if ("" === url) {
                var overlay = store.get("overlay");
                // return "wind" !== overlay && overlayName ? titleWithLang() + "-" + overlay : ""
                return seoLang(store.get("usedLang"));
            }
            return url;

            // if ( /^\/?(overlays|settings|privacy|tools|widgets|favs|hurricanes|webcams)/.test(url) && (overlayName || S) )
            // {
            //     if ( S )
            //         return utils.joinPath("" + seoLang(store.get("usedLang")) + seoUrlString(S), url);
            //     else {
            //         return overlayName ? utils.joinPath(titleWithLang(), url) : void 0;
            //     }
            // }
            // else
            //     return url;

            // return /^\/?(overlays|settings|privacy|tools|widgets|favs|hurricanes|webcams)/.test(url) && (overlayName || S) ?
            //      S ? utils.joinPath("" + seoLang(store.get("usedLang")) + seoUrlString(S), url) :
            //          overlayName ? utils.joinPath(titleWithLang(), url) : void 0    :
            //      url
        }
        ,
        titleWithLang = function() {
            return seoLang(store.get("usedLang")) + "-" + seoString(overlayName)
        }
        ,
        changeLocation = utils.debounce(
            function() {
                if ( !params )
                    return;
                var isolines, map = rootScope.map,
                    coords = [utils.normalizeLatLon(map.lat), utils.normalizeLatLon(map.lng), map.zoom],
                    timestamp = store.get("timestamp");

                // "accumulations" === O.product && "next24h" !== O.acTime ? coords.unshift(O.acTime) : O.path && Math.abs(g - timestamp) > 65e6 && coords.unshift(O.path.replace(/\//g, "-"));


                coords.push("lang:" + store.get("usedLang"));
                // if ( "wind" !== params.overlay )
                coords.unshift(params.overlay);
                if ("surface" !== params.level)
                    coords.unshift(params.level);
                if ( ! /^ecmwf/.test(params.product) /*&& A(params.overlay)*/ )
                    coords.unshift(params.product);
                if ("off" !== (isolines = store.get("isolines")) )
                    coords.push("isolines:" + isolines);
                if (params.map !== "light")
                    coords.push("map:" + params.map);

                // rootScope.picker && coords.push("m:" + utils.latLon2str(rootScope.picker));
                // rootScope.customAnimation && coords.push(rootScope.customAnimation);
                search = coords.join(",")
                //     ,
                // x()
            }, 300)
        , params = null;

    function changeTitle() {
        if ( ! personalizedTitle ) {
            var overlay = store.get("overlay")
                , name = overlays[overlay].getName();

            // if ( "wind" === overlay )
            // {
            //     overlayName = null,
            //     document.title = title
            // } else {
            overlayName = name;
            document.title = "Vienty: " + name;
            // }
        }
    }

    broadcast.on("paramsChanged", function(e) {
        params = e,
            changeLocation()
    }),
        broadcast.on("mapChanged", changeLocation),
        broadcast.on("detailRendered", changeLocation),
        // picker.on("pickerOpened", R),
        // picker.on("pickerMoved", R),
        // picker.on("pickerClosed", R),
        store.on("particlesAnim", changeLocation),
        // store.on("overlay", changeTitle),
        // store.on("usedLang", changeTitle);
    store.on("usedLang", changeLocation);

    export default {
        description: function(e) {
            return y.content = e
        },
        // setCanonical: setCanonical,
        // getFrag: getFrag,
        titleWithLang: titleWithLang,
        getPath: function() {
            return url
        },
        getTitle: function() {
            return overlayName
        },
        getSearch: function() {
            return search
        },
        getURL: function() {
            // return "https://www.windy.com/" + getFrag()
            return window.location.origin + window.location.pathname + "?" + search;
        },
        deleteSearch: function() {
            return search = null
        },
        url: function(e, t) {
            void 0 === t && (t = null),
                url = e,
                S = t//,
            //x()
        },
        title: function(text) {
            overlayName = text,
                document.title = "Vienty: " + text,
                personalizedTitle = true
        },
        reset: function() {
            personalizedTitle = false;
            // y && (y.content = w),
            url = "";
            S = null;
            // changeTitle()//,
            //x()
        }
    }

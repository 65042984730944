import html2canvas from "html2canvas";

/**
 * Clase que representa un botón para capturar la vista actual del visor.
 */
class CaptureButtonView  {

    /**
     * El elemento de botón que desencadena la acción de captura.
     * @type {HTMLButtonElement}
     * @private
     */
    private button: HTMLButtonElement;
    /**
     * Función para obtener la fecha formateada en el idioma del usuario.
     * @type {(() => string) | null}
     * @private
     */    private getFormattedDate: (() => string) | null = null;
    /**
     * La cadena de fecha formateada.
     * @type {string | null}
     * @private
     */
    private formattedDate: string | null = null;

    /**
     * Crea una instancia de CaptureButtonView.
     * @param {HTMLButtonElement} button - El elemento de botón que desencadena la acción de captura.
     */
    constructor(button: HTMLButtonElement) {
        this.button = button;
        this.button.addEventListener("click", this._capture.bind(this));
    }

    /**
     * Vincula una función manejadora para obtener la fecha formateada.
     * @param {() => string} handler - La función que devuelve la fecha formateada.
     */
    public bindGetFormattedDate(handler: () => string) {
        this.getFormattedDate = handler;
    }

    /**
     * Oculta ciertos elementos del DOM antes de capturar la vista del contenedor.
     * También ajusta la posición de los elementos SVG en el contenedor clonado para que
     * se vean correctamente en la captura.
     * @param {HTMLElement} element - El elemento clonado del contenedor original.
     * @private
     */
    private onclone(element: HTMLElement) {

        // Oculta ciertos elementos para que no se vean en la captura
        ["full_screen_button", "more_info", "plugins", "controles_mm", "capture_button", "menus", "checksDesktop"].map(
            id => element.querySelector("#" + id)?.setAttribute("style", "display:none")
        );
        element.querySelector("#buttonsRight")?.setAttribute("style", "display:none");
        element.querySelector("#leyenda")?.setAttribute("style", "bottom: 0px");

        // Obtiene la fecha formateada en el idioma del usuario
        const formattedDate = this.getFormattedDate?.() || null;

        this.formattedDate = formattedDate;

        // Añaade la fecha formateada al contenedor clonado
        if (formattedDate) {
            const divfecha = document.createElement("div");
            divfecha.id = "fechaCaptura";
            const pFecha = document.createElement("p");
            pFecha.innerText = formattedDate;
            divfecha.appendChild(pFecha);

            element.querySelector("#logo_meteored")?.insertAdjacentElement("afterend", divfecha);
        }

        // Ajusta la posición de los elementos SVG en el contenedor clonado para que se vean correctamente en la captura
        const overlayPane = document.getElementsByClassName("leaflet-overlay-pane")[0];
        const overlayPaneClone = element.getElementsByClassName("leaflet-overlay-pane")[0];
        if (overlayPaneClone) {
            const svgs = overlayPane.getElementsByTagName("svg");
            const svgsClone = overlayPaneClone.getElementsByTagName("svg");
            for (let i = 0; i < svgsClone.length; i++) {
                const svg = svgs[i];
                const transform = svg.style.transform;
                const translate3dRegex = /translate3d\(([^,]+)px, ([^,]+)px, ([^)]+)px\)/;
                const matches = translate3dRegex.exec(transform);
                const x = matches ? parseFloat(matches[1]) : 0;
                const y = matches ? parseFloat(matches[2]) : 0;

                const svgClone = svgsClone[i];

                svgClone.removeAttribute("style");
                svgClone.style.left = `${x}px`;
                svgClone.style.top = `${y}px`;

            }
        }
    }

    /**
     * Captura la vista actual del contenedor del visor y la guarda como una imagen.
     * El método oculta ciertos elementos, captura la vista usando html2canvas,
     * y luego restaura los elementos ocultos.
     * @private
     */
    private _capture() {

        // Conteneddor del visor
        const container: HTMLElement | null = document.querySelector("#visor2") || document.querySelector("body");
        const body: HTMLElement | null = document.querySelector("body");


        const options ={
            allowTaint: true,
            onclone: (e: any) => {
                this.onclone(e)
            },
            useCORS: true,
            windowHeight: body?.offsetHeight,
            windowWidth: body?.offsetWidth,
            height: body?.offsetHeight,
            width: body?.offsetWidth,
        }

        // Apaño para que los textos salgan centrados verticalmente
        document.body?.setAttribute("style", "line-height: 0.5");


        if (container) {

            // Elimina las clases que indican la dirección en la que giran los huracanes, para que aparezcan correctamente
            // en la captura
            const hurricanes = document.getElementsByClassName("icono-current-hurr");
            let classes =[];
            for (let i = 0; i< hurricanes.length; i++) {
                const hurricane = hurricanes[i] as HTMLDivElement;
                if (hurricane) {
                    const svg = hurricane.getElementsByTagName("svg")[0];
                   if(svg.classList.contains("heminorte")) {
                       svg.classList.remove("heminorte");
                       classes[i] = "heminorte";
                   }
                   else if(svg.classList.contains("hemisur")) {
                       svg.classList.remove("hemisur");
                       classes[i] = "hemisur";
                   }
                }
            }

            // Realiza la captura
            html2canvas(container, options).then(canvas => {
                let link = document.createElement("a");
                link.download = "Meteored_" + (document.querySelector("#titulo") as HTMLElement)?.innerText.replace(",","_")  + "_" + this.formattedDate?.replace("", "_").replace(",", "") + ".png";

                link.href = canvas.toDataURL();
                link.click();
                link.remove();

                // Restaura las clases eliminadas para que vuelvan a girar los huracanes
                for (let i = 0; i< hurricanes.length; i++) {
                    const hurricane = hurricanes[i] as HTMLDivElement;
                    if (hurricane) {
                        const svg = hurricane.getElementsByTagName("svg")[0];
                        svg.classList.add(classes[i]);
                    }
                }
                // Elimina el estilo que se añadió para centrar los textos verticalmente
                document.body?.removeAttribute("style");
            });
        }
    }
}


export default CaptureButtonView;

import basemaps from "./baseMapsInstances";
import store from "./store";


class MapSwitchController{
    constructor(view) {
        this.view = view;

        this.addElements();

        this.view.bindSelect(this.select.bind(this))

        store.on("usedLang", this.updateMaps.bind(this));
    }

    updateMaps(){
        this.view.remove();
        this.addElements();
    }

    addElements (){

        const mapselected = store.get ("map");

        this.view.update(
            Object.keys(basemaps).map(
                function(basemapname){

                    return {
                        text: basemaps[basemapname].getName(),
                        value: basemapname,
                        selected: mapselected === basemapname,
                    };
                }
            )
        );

        // this.value = store.get ("map");
    }

    select (value){
        store.set("map", value);
    }
}

export default MapSwitchController;

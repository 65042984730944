import renderers from "./renderers";
import layers from "./layers";
import overlays from "./overlays";
import broadcast from "./broadcast";
import utils from "./utils";
import render from "./render";

var keysrenderers = Object.keys(renderers)
    , spec = {}
    , nrenderers = 0
    , timer = 0;

broadcast.on("paramsChanged", function(s) {
    spec = s;
    clearTimer();

    var layersoverlay = overlays[s.overlay].layers.slice()
        , rendererlayers = [];

    if ( "off" !== s.isolines )
        layersoverlay.unshift(s.isolines + "Isolines");

    if ( s.hurricanes !== "off" )
        layersoverlay.unshift("hurricanes");

    if ( s.warnings !== "off" )
        layersoverlay.unshift("warnings");


    // Para cada layer creamos un objeto con su renderer y sus params
    var renderersandparams = layersoverlay.map(
        function(layerkey) {  // Ejecuta la función para cada elemento del array - No confundir con la instancia map de layers
            var layer = layers[layerkey];
            rendererlayers.push(layer.renderer); // renderers que vamos a necesitar para pintar los layers
            return {
                    renderer: layer.renderer,
                    params: layer.getParams.call(layer, spec, spec.product)
                }
        });

    // Para todos los renderers que existen cerramos aquellos que estén montados pero no necesitemos para pintar los layers
    keysrenderers.forEach(function(key) {
        var renderer = renderers[key];
        if(rendererlayers.indexOf(key) < 0 && renderer.isMounted)
            renderer.close.call(renderer, rendererlayers)
    });

    var openingrenderers = [];

    nrenderers = renderersandparams.length;


    // Para cada renderer, si está montando llamamos a paramsChanged y sino los cargamos
    Promise.all(renderersandparams.map(
        (rendandparam) => {

            return rendandparam.params.then(
                (params) => {
                    const renderer = renderers[rendandparam.renderer];
                    if (renderer.isMounted)
                        renderer.paramsChanged.call(renderer, params)
                    else
                        openingrenderers.push(renderer.open.call(renderer, params))
                }
            );


            }
        )
    ).then(
        () => {
            if(openingrenderers.length > 0){
                Promise.all(openingrenderers).catch((function(e) {
                        console.error("renderCtrl", "Unable to load render", e)
                    }
                ));
            }
        }
    )

/*
    renderersandparams.forEach(function(rendandparam) {
        var renderer = renderers[rendandparam.renderer];
        if (renderer.isMounted)
            renderer.paramsChanged.call(renderer, rendandparam.params)
        else
            openingrenderers.push(renderer.open.call(renderer, rendandparam.params))
    });
    if(openingrenderers.length > 0)
        Promise.all(openingrenderers).catch(console.error.bind(null, "renderCtrl", "Unable to load render"));

    nrenderers = renderersandparams.length//,
        // timer = setTimeout(h, 5e3)*/
}),



    broadcast.on("movestart", function() {
        var overlay = overlays[spec.overlay];
        nrenderers = overlay && overlay.layers.length
    }),

    broadcast.on("redrawLayers", function() {
        nrenderers = 0,
            utils.each(renderers, function(renderer) {
                if(renderer.isMounted){
                    renderer.redraw(),
                        nrenderers++
                }
            })
    }),

    render.on("rendered", function() {
        if(--nrenderers <= 0){
            clearTimer();
            debounce();
            var overlay = overlays[spec.overlay];
            nrenderers = overlay && overlay.layers.length
        }
    });

var debounce = utils.debounce(function() {
    return broadcast.emit.call(broadcast, "redrawFinished", spec)
}, 200);

function clearTimer() {
    clearTimeout(timer),
        timer = 0
}

function h() {
    nrenderers = 0,
        broadcast.emit("redrawFinished", spec)
}

// define("params", ["store", "models", "overlays", "utils", "broadcast", "products", "router", "renderCtrl"], function(store, t, n, i, s, a, r, o) {
// define("params", ["store", "models", "overlays", "utils", "broadcast", "products",  "renderCtrl"], function(store, models, overlays, utils, broadcast, products,  o) {
import store from "./store";
import models from "./models";
import overlays from "./overlays";
import utils from "./utils";
import broadcast from "./broadcast";
import products from "./products";
import renderCtrl from "./renderCtrl";
import rootScope from "./rootScope";
import $ from "./$";
import product from "./Product";

    var l = utils.debounce(updateParams, 100);  // Cada segundo
    var setPoisTemporary= utils.debounce((poi)=>{store.set("poisTemporary", poi);}, 150);


    function c() {
        // Para cuando se actualiza alguno de estos elementos?
        ["acTime", "level", "isolines", "path", "overlay", "product", "map", "hurricanes", "warnings"].forEach(
            function(element) {
                store.on(element, l.bind(null, element)) // object <- null, param1 <- element
            })
    }
    function updateParams(element) {
        var overlay = store.get("overlay")
            , spec = {
            overlay: overlay,
            hasMoreLevels: overlays[overlay].hasMoreLevels,
            // acTime: store.get("acTime"),
            level: store.get("level"),
            isolines: store.get("isolines"),
            pasada: store.get("pasada"),
            proyeccion: store.get("proyeccion"),
            nextproy: store.get("nextproy"),
            path: store.get("path"),
            nextpath: store.get("nextpath"),
            product: store.get("product"),
            map: store.get("map"),
            hurricanes: store.get("hurricanes"),
            warnings: store.get("warnings")
        };
        // console.info("Mostrando spec: " + spec.overlay + " path: " + spec.path);
        broadcast.emit("paramsChanged", spec, element)
    }


    store.defineProperty("timestamp", "syncSet",
        function(ts) {
            ts = parseInt(ts);
            var calendar = store.get("calendar");
            if (calendar){
                ts = calendar.boundTs(ts);
                store.set("path", calendar.ts2path(ts));
                store.set("nextpath", calendar.ts2nextpath(ts));
                store.set("pasada", calendar.refTime);
                store.set("proyeccion", calendar.ts2proy(ts));
                store.set("nextproy", calendar.ts2nextproy(ts));
                // console.info("Hora actual: " + new Date(ts));
                // console.info("Hora de validez: " + new Date(calendar.bestTs(ts)).toUTCString());

                var fechaactual=new Date(ts);
                fechaactual.setMinutes(0, 0, 0)
                // document.getElementById('horaActual').innerHTML= fechaactual;
                // document.getElementById('horaValidez').innerHTML= new Date(calendar.bestTs(ts)).toUTCString();

            }
            return ts
        });


    store.defineProperty("product", "asyncSet",
        function(productname, options) {
            return new Promise(function(resolve, reject) {
                    var product = products[productname];
                    products[store.get("product")].close();
                    product.open(options).then(
                        function(calendar) {
                            utils.replaceClass(/product-\S+/, "product-" + productname, $('#visor2') || document.body);
                            if(calendar) {
                                store.set("calendar", calendar);
                                // store.set("timestamp", utils.bound(store.get("timestamp"), calendar.start, calendar.end), { //FIXME
                                //     forceChange: true
                                // })
                                store.set("timestamp", utils.bound(store.get("timestamp"), calendar.start, calendar.end), {
                                    forceChange: true
                                })
                            }
                            if ( product.levels ) {
                                store.set("availLevels", product.levels);
                                if ( ! product.levels.includes(store.get("level")) )
                                    store.set("level", "surface");
                            }


                            const pois = store.get("pois");

                            const overlay = overlays[store.get ("overlay")]

                            if ( pois === "symbols" &&  ! product.symbols  )
                                setPoisTemporary("cities");
                            else if ( overlay.symbols )
                                setPoisTemporary("empty");

                            // store.set("poisTemporary", "empty");
                            // if ( product.acTimes ) {
                            //     store.set("availAcTimes", product.acTimes);
                            //     if ( ! product.acTimes.includes(store.get("acTime")) )
                            //         store.set("acTime", r.acTimes[0]);
                            // }
                            if (/^gfs/.test(productname))
                                store.set("prefferedProduct", "gfs");
                            else if (/^ecmwf/.test(productname))
                                store.set("prefferedProduct", "ecmwf");

                            resolve(productname);
                        }).catch(reject)
                }
            )
        });

    var options,
        changeOverlay = function(nameoverlay) {


            const overlay = overlays[nameoverlay];
            const product = products[store.get("product")];
            const container=$('#visor2') || document.body;

            utils.replaceClass(/overlay-\S+/, "overlay-" + nameoverlay, container);
            utils.toggleClass(container, overlay && overlay.hasMoreLevels, "has-more-levels");
            store.set("availProducts", models.overlay2product[nameoverlay]);

            const pois = store.get("pois");

            if ( pois === "symbols" && ! overlay.symbols )
                setPoisTemporary("cities");
                // store.set("poisTemporary", "cities");
            else if (product.symbols)
                setPoisTemporary("empty");
                // store.set("poisTemporary", "empty");
        };

    store.defineProperty("overlay", "asyncSet",
        function(nameoverlay) {
            var product = models.getProduct(nameoverlay, store.get("product"));

            if (product === store.get("product")){
                changeOverlay(nameoverlay);
                return Promise.resolve(nameoverlay);
            } else
                return new Promise(
                    function(resolve) {
                        store.set("product", product).then(
                            function() {
                                changeOverlay(nameoverlay);
                                resolve(nameoverlay);
                            })
                    });
        });
    options = {
        starting: true,
        forceChange: true
    };

    store.set("product", store.get("product"), options).then(
        function() {
            store.set("overlay", store.get("overlay"), options).then(
                function() {
                    updateParams();
                    c();
                })
        }).catch(function(t) {
            console.error("params", "failed to launch params change", t);
            store.set("product", "ecmwf", options);
            store.set("overlay", "prcnub", options);
            setTimeout(function() {
                updateParams();
                c();
            }, 500)
    })

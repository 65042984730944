import utils from "./utils";
import store from "./store";
import object from "./Class";

// Representa una paleta de colores
export default object.extend({
    prepare: 0,
    save: 1,
    sync: 1,
    opaque: 1,
    clipbottom: false,

    // Función de inicialización
    _init: function() {
        this.key = "color2_" + this.ident,  // Según el nombre de la variable KEY

            store.insert(this.key, {
                def: this.default,   // colores por defecto
                save: this.save,
                sync: this.sync,
                allowed: this.checkValidity
            }),

            store.on(this.key, this.colorChanged, this),  // Sobre el objeto creado se ejecutará colorChanged si se lanza el evento key
        this.prepare && this.getColor()
    },

    // Comprueba que el array es un array bidimensional
    checkValidity: function(array) {
        if (!Array.isArray(array))
            return 0;
        for (var i = 0; i < array.length; i++)
            if (!Array.isArray(array[i]))
                return 0;
        return 1
    },
    // Si es true fuerza el cambio del array de colores (paleta)
    colorChanged: function(changed) {
        changed && this.colors && this.forceGetColor()
    },
    // Cambia la paleta
    changeColor: function(e, n) {
        store.set(this.key, e, n)
    },
    // Borra del store
    toDefault: function() {
        store.remove(this.key)
    },
    // Establece el mínimo y el máximo
    setMinMax: function() {
        this.min = this.gradient[0][0], // Valor mínimo de la paleta de colores
            this.max = this.gradient[this.gradient.length - 1][0]  // Valor máximo de la paleta de colores
    },
    // Borra el array de gradiente de color y lo fuerza a crearse de nuevo
    forceGetColor: function() {
        return this.colors = null,
            this.getColor()
    },
    // Devuelve un string con el color
    color: function(valor) {
        var color = this.RGBA(valor);
        return "rgb(" + color[0] + "," + color[1] + "," + color[2] + ")"
    },
    // Devuelve un string con el color oscurecido
    colorDark: function(valor, t) {
        var color = this.RGBA(valor);
        return "rgb(" + Math.max(0, color[0] - t) + "," + Math.max(0, color[1] - t) + "," + Math.max(0, color[2] - t) + ")"
    },
    // Devuelve el color correspondiente a un valor
    RGBA: function(valor) {
        var index = this.value2index(valor);
        return [this.colors[index], this.colors[++index], this.colors[++index], this.colors[++index]]
    },
    // Multiplica los elementos de un array por la constante t
    getMulArray: function(array, t) {
        for (var newarray = [], i = 0; i < array.length; i++)
            newarray.push(array[i] * t);
        return newarray
    },
    // Interpola entre color0 y color1 usando valuenorm
    lerpArray: function(color0, color1, valuenorm) {
        for (var d = 1 - valuenorm,
                 nchannels = color0.length,
                 color = [],
                 nchannel = 0;
             nchannel < nchannels; nchannel++)
            color.push(color0[nchannel] * d + color1[nchannel] * valuenorm);
        return color
    },
    // Pasa de RGBA a YUV
    rgba2yuva: function(color) {
        var R = color[0]
            , G = color[1]
            , B = color[2]
            , Y = .299 * R + .587 * G + .114 * B;
        return [Y, .565 * (B - Y), .713 * (R - Y), color[3]]
    },
    // Pasa de YUV a RGBA
    yuva2rgba: function(color) {
        var Y = color[0]
            , U =color[1]
            , V = color[2];
        return [Y + 1.403 * V, Y - .344 * U - .714 * V, Y + 1.77 * U, color[3]]
    },
    // Estudiar la diferencia entre usar esto o directamente RGBA
    gradYuva: function(color0, color1, valuenorm, i) {
        var color = this.lerpArray(color0, color1, valuenorm);  // interpola el color
        if (i) {
            var mod0 = this.vec2size(color0[1], color0[2])
                , mod1 = this.vec2size(color1[1], color1[2]);
            if (mod0 > .05 && mod1 > .05) {
                var mod = this.vec2size(color[1], color[2]);
                if (mod > .01) {
                    var l = (mod0 * (1 - valuenorm) + mod1 * valuenorm) / mod;
                    color[1] *= l,
                        color[2] *= l
                }
            }
        }
        return color
    },
    // saca el módulo de un vector
    vec2size: function(x, y) {
        return Math.sqrt(x * x + y * y)
    },
    // Saca un color interpolado a partir de valuenorm entre 0 y 1 pasando los colores primero a YUV y después pasando el resultado a RGBA
    getGradientColorYUVA: function(color0, color1, valuenorm) {
        for (var color0yuv = this.rgba2yuva(this.getMulArray(color0, 1 / 255)),  // Divide las componentes del color entre 255 y pasa a YUV
                 color1yuv = this.rgba2yuva(this.getMulArray(color1, 1 / 255)),
                 coloryuv = this.gradYuva(color0yuv, color1yuv, valuenorm, 1),
                 color = this.yuva2rgba(coloryuv),
                 i = 0; i < color.length; i++)
            color[i] = Math.max(0, Math.min(256 * color[i], 255)); // Multiplica las componentes por 255
        return color
    },
    // Multiplica los canales R G y B por el alpha/255
    makePremultiplied: function(color) {
        for (var alpha = color[3] / 255,
                 n = 0;
             n < 3; n++)
            color[n] = Math.max(0, Math.min(alpha * color[n], 255));
        return color
    },

    createGradientArray: function(opaque, alphamult) {

        // void 0 === alfamult && (alfamult = 0),
        // void 0 === n && (n = 1);
        // void 0 === opaque && (opaque = 1);
        var value, color, vnorm, nstep,
            steps = this.steps + 1,                     // Número de steps
            paleta = new Uint8Array(steps << 2),  // Número de steps * 4 canales
            inc = (this.max - this.min) / this.steps,   // incremento por cada step
            ipaleta = 0,
            gradient = this.gradient,                   //
            igradient = 1,
            v0 = gradient[0],
            v1 = gradient[igradient++];
        for (nstep = 0; nstep < this.steps; nstep++) {
            value = this.min + inc * nstep;
            if(value >= v1[0]  // Si el valor se sobrepasa el valor de v1
                && igradient < gradient.length ){
                v0 = v1;                                    // le asignamos v1 a v0 y a v1 el siguiente valor del array gradient
                v1 = gradient[igradient++];
            }

            if(this.discrete)
                value = v0[0];                                  // Escala discreta!!!!!!

            vnorm = (value - v0[0]) / (v1[0] - v0[0]);      // Normalizamos valor a un valor entre 0 y 1
            color = this.getGradientColorYUVA(v0[1], v1[1], vnorm);  // Color interpolado
            alphamult && this.makePremultiplied(color);
                paleta[ipaleta++] = Math.round(color[0]), // canal R
                paleta[ipaleta++] = Math.round(color[1]), // canal G
                paleta[ipaleta++] = Math.round(color[2]), // canal B
                paleta[ipaleta++] = opaque ? 255 : Math.round(color[3]);  // canal alpha
            // paleta[ipaleta++] = opaque ? 255 : Math.round(color[3]) < 255 ? 0 : Math.round(color[3]);  // canal alpha
            // }
        }

        value = this.min + inc * nstep;
        if(this.discrete && value >= v1[0]){
            color = this.getGradientColorYUVA(v0[1], v1[1], 1);  // Color interpolado
            alphamult && this.makePremultiplied(color);
            paleta[ipaleta - 4] = Math.round(color[0]), // canal R
                paleta[ipaleta - 3] = Math.round(color[1]), // canal G
                paleta[ipaleta - 2] = Math.round(color[2]), // canal B
                paleta[ipaleta - 1] = opaque ? 255 : Math.round(color[3]);
        }

        this.neutralGrayIndex = ipaleta;
        paleta[ipaleta++] = paleta[ipaleta++] = paleta[ipaleta++] = 128;
        paleta[ipaleta++] = 0;
        return paleta;
    },

    // createSteppedArray: function(e, t, n) {
    //     n || (n = t);
    //     for (var i,
    //              s = e.length,
    //              a = new Uint8Array(s),
    //              r = s >> 2,
    //              o = t >> 1,
    //              l = n,
    //              c = 0,
    //              d = 0;
    //          d < r; ) {
    //         for (i = 0; i < 4; i++)
    //             a[4 * d + i] = e[c + i];
    //         --l <= 0 && (l = t,
    //             c = 4 * (d + o)),
    //             d++
    //     }
    //     return a
    // },
    // combinedArray: function(t, n, i, s) {
    //     void 0 === i && (i = .5),
    //     void 0 === s && (s = .5);
    //     for (var a, r, o = t.length, l = new Uint8Array(o), c = 0; c < o; ) {
    //         for (r = i * ((r = (.299 * t[c] + .587 * t[c + 1] + .114 * t[c + 2]) / (.299 * n[c] + .587 * n[c + 1] + .114 * n[c + 2])) - 1),
    //                  r += 1,
    //                  a = 0; a < 3; a++)
    //             l[c + a] = e.bound(Math.round(r * n[c + a] * (1 - s) + s * t[c + a]), 0, 255);
    //         l[c + 3] = t[c + 3],
    //             c += 4
    //     }
    //     return l
    // },
    // Devuelve el objeto. Si no está creado el array de gradientes lo crea
    getColor: function() {
        var color = this;
        return this.colors ? this : (this.gradient = store.get(this.key),  // Si colors es NULL lo creamos e inicializamo llamando a creategradientarray
            this.setMinMax(),                                              // Establecemos el valor mínimo y máximo de la paleta
            this.colors = this.createGradientArray(this.opaque),           // Se crea el array de colores con nsteps
            this.startingValue = this.min,
            this.maxIndex = this.steps - 1 << 2,                           // Número de pasos * 4 canales
            this.step = (this.max - this.startingValue) / this.steps,      // Incremento por paso

            // Función que para un valor devuelve el indice donde está el color
            this.value2index = function(value) {
                return isNaN(value) ? color.neutralGrayIndex : Math.max(0, Math.min(color.maxIndex, (value - color.startingValue) / color.step << 2))
            }
            ,
            this)
    }
});

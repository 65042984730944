import lrucache from "./lruCache";
import rootscope from "./rootScope";
import utils from "./utils";
import broadcast from "./broadcast";
import store from "./store";


var cache = new lrucache(50)
    , nerrors = 0
    , maxerrors = rootscope.isMobile ? 3 : 6;

var canvas = document.createElement("canvas")
    , ctx = canvas.getContext("2d",{willReadFrequently: true});

function DataLoader(url, whichTile) {
    this.url = utils.template(url, {s: whichTile.s} );

    this.status = "undefined";
    this.data = null;
    this.x = whichTile.x;
    this.y = whichTile.y;
    this.z = whichTile.z;
    this.transformR = whichTile.transformR;
    this.transformG = whichTile.transformG;
    this.transformB = whichTile.transformB;

    return this;
}

DataLoader.prototype.load = function() {
    var loader = this;
    this.status = "loading";
    this.promise = new Promise(function(resolve, reject) {
            var image = new Image;
            image.crossOrigin = "Anonymous";

            image.onload = function() {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, image.width, image.height);

                var dataimage = ctx.getImageData(0, 0, image.width, image.height);

                loader.data = dataimage.data;
                loader.status = "loaded";

                var arrayfloats = function(data, width) {   // Decodifica en arrayfloats a partir de los cuadraditos el máximo y el mínimo que va tener cada canal
                    var R, G, B, i,
                        buffer = new ArrayBuffer(28), // buffer
                        arraybyte = new Uint8Array(buffer),    //
                        arrayfloat = new Float32Array(buffer),
                        pos = 4 * width * 4 + 8;          // fila 4 pixel 2 (primer cuadradito)

                    for (i = 0; i < 28; i++) {             // Leemos los 28 cuadraditos. Cada 4 cuadraditos forman un float
                        R = data[pos];
                        G = data[pos + 1];
                        B = data[pos + 2];
                        R = Math.round(R / 64);     // R se pasa al múltiplo de 64 más cercano (0-192)
                        G = Math.round(G / 16);     // G será un múltiplo de 4 (0-64)
                        B = Math.round(B / 64);     // B será un múltiplo de 1 (0-4)

                        arraybyte[i] = (R << 6) + (G << 2) + B;  // El byte se obtiene sumando los valores anteriores

                        pos += 16;                    // Siguiente cuadradito (4 pixeles)
                    }

                    return arrayfloat;

                }(loader.data, 257);

                var baseR = arrayfloats[0]
                    , incR = (arrayfloats[1] - arrayfloats[0]) / 255
                    , baseG = arrayfloats[2]
                    , incG = (arrayfloats[3] - arrayfloats[2]) / 255
                    , baseB = arrayfloats[4]
                    , incB = (arrayfloats[5] - arrayfloats[4]) / 255;

                loader.decodeR = loader.transformR ?
                    function(v) {
                        return loader.transformR(v * incR + baseR)
                    }
                    : function(v) {
                        return v * incR + baseR
                    }


                loader.decodeG = loader.transformG ? function(v) {
                        return loader.transformG(v * incG + baseG)
                    }
                    : function(v) {
                        return v * incG + baseG
                    }

                loader.decodeB = loader.transformB ? function(v) {
                        return loader.transformB(v * incB + baseB)
                    }
                    : function(v) {
                        return v * incB + baseB
                    }

                nerrors = 0;

                resolve(loader);
            }

            image.onerror = function() { // Función en caso de fallo
                loader.status = "failed";
                broadcast.emit("loadingFailed", loader.url);
                if (++nerrors > maxerrors) {
                    broadcast.emit("noConnection");
                    nerrors = 0
                }
                reject("Failed to load tile: " + loader.url);
            }

            image.src = loader.url;   // URL de la imagen (tesela windy)

            if (image.complete || void 0 === image.complete) {
                image.src = utils.emptyGIF;
                image.src = loader.url;
            }
        }
    );

    return this.promise;
}

export default {
    loadTile: function(whichTile) {
        let url = whichTile.url             // Buscamos en caché si existe el loader para la url
            , nextUrl = whichTile.nextUrl
            , loader = cache.get(url);

        // Cargamos también la tesela de la siguiente proyección
        if (url !== nextUrl && ! store.get("embed")){
            let loaderNext = cache.get(nextUrl);
            if (! loaderNext ){
                loaderNext = new DataLoader(nextUrl, whichTile);
                cache.put(nextUrl, loaderNext);

                loaderNext.load();
            }
        }

        if ( ! loader) {                     // Si no existe, lo creamos, metemos en caché y devolvemos la promesa
                loader = new DataLoader(url, whichTile);
                cache.put(url, loader);

                return loader.load();
        }
        // Chequeamos el status
        switch (loader.status) {
            case "loaded":
                return Promise.resolve(loader);
            case "loading":
                return loader.promise;
            case "failed":
                cache.remove(url);
                return Promise.reject("Failed to load tile: " + url);
        }
    }
}

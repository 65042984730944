import products from "./products";
import store from "./store";
import trans from "./trans";
import overlays from "./overlays";
import rootScope from "./rootScope";

class LevelSwitchController{

    constructor(view) {
        this.view = view;

        this.addElements();
        // this.view.remove();

        this.view.bindSelect(this.select.bind(this));

        store.on("product", this.changeModel.bind(this));
        store.on("usedLang", this.changeModel.bind(this));
        store.on("overlay", this.changeOverlay.bind(this));

    }

    addElements (){

        const oldLevel = store.get("level");

        const product = products[store.get("product")];

        const overlay = overlays[store.get("overlay")];

        const levels = overlay.levels ? overlay.levels : product.levels;

        this.view.update(
          levels.map(
              function(levelname){

                  // console.info(levelname);

                  // console.info(rootScope.levelsData[levelname][0]);
                  return {
                          text: levelname === "surface" ? trans.SFC : rootScope.levelsData[levelname][0],
                          value: levelname,
                          selected: levelname === oldLevel,
                  };
              }
          )

        );

    }


    changeModel (){
        // this.view.remove();
        this.addElements();
    }

    changeOverlay (){
        const overlay = overlays[store.get("overlay")];
        this.addElements();
        this.view.setDisabled(! overlay.hasMoreLevels);
    }

    select (value){
        store.set("level", value);

    }


}

export default LevelSwitchController;

import plugin from "../app/Plugin";


class PluginButtonView {
    private button: Element | null;
    private pluginId: string;
    private openPlugin: ((id:string|null) => any) | undefined;

    constructor(pluginButton: Element, pluginId: string){
        this.button = pluginButton;
        this.pluginId = pluginId;

        this.button.addEventListener("click", (event)=>{
            if(this.openPlugin)
                this.openPlugin(this.pluginId);
        })
    }

    bindOpenPlugin(handler: ((id:string|null) => any)){
        this.openPlugin = handler;
    }
}

export default PluginButtonView;

import store from "./store";

class CBDisableWebGlController {
    constructor(view) {
        this.view = view;

        this.view.checkbox.checked = store.get("disableWebGL");

        this.view.checkbox.addEventListener("change", this.change.bind(this));

    }

    change() {
        store.set("disableWebGL", this.view.checkbox.checked);

        setTimeout(()=>{location.reload();}, 200);
    }


}

export default CBDisableWebGlController;

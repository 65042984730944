import store from "./store";
import utils from "./utils";
import trans from "./trans";

// define("format", ["trans", "store", "utils"], function(e, t, n) {
var object = {}
, to12hformat = function(hour, minute) {
    var formatedtime = hour % 12 || 12;
    if ( minute != null )
        formatedtime += ":" + utils.pad(minute);
    formatedtime += ( hour >= 12 ) ? " PM" : " AM";

    return formatedtime;
}

, to24hformat = function(hour, minute) {
    return hour + ":" + (null != minute ? utils.pad(minute) : "00")
};

object.getHoursFunction = function() {
    return store.is12hFormat() ? to12hformat : to24hformat
}
object.hourUTC = function(timestamp) {
    return utils.pad(new Date(timestamp).getUTCHours()) + ":00Z"
}
object.hourMinuteUTC = function(timestamp) {
    var fecha = new Date(timestamp);
    return utils.pad(fecha.getUTCHours()) + ":" + utils.pad(fecha.getUTCMinutes()) + "Z"
}
    // ,
    // i.thousands = function(e) {
    //     return e ? e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""
    // }
const direcciones = ["N", "NE", "E", "SE", "S", "SW", "W", "NW", "N"];

function deg2dir(deg) {
    const n = Math.floor((+deg + 22.5) / 45);
    return trans["DIRECTION_" + direcciones[n]] || "-"
}
function todegrees(deg) {
    return deg + "°"
}

object.getDirFunction = function() {
    return store.get("numDirection") ? todegrees : deg2dir
}
    // ,
    // i.obsoleteClass = function(e, t) {
    //     void 0 === t && (t = 30);
    //     var n = (Date.now() / 1e3 - e) / 60;
    //     return n < .3 * t ? "fresh" : n < t ? "normal" : "obsolete"
    // }
    // ,
    //     i.howOld = function(t) {
    //         var i = !1
    //             , s = -1;
    //         if ("diffMin"in t)
    //             s = +t.diffMin;
    //         else if ("ts"in t)
    //             s = Math.floor((Date.now() - +t.ts) / 6e4);
    //         else if ("min"in t)
    //             s = Math.floor(Date.now() / 6e4 - +t.min);
    //         else {
    //             if (!("ux"in t))
    //                 return "";
    //             s = Math.floor((Date.now() / 1e3 - +t.ux) / 60)
    //         }
    //         if (s < 0) {
    //             if (t.translate)
    //                 return "";
    //             s = Math.abs(s),
    //                 i = !0
    //         }
    //         if (t && t.translate) {
    //             if (0 === s)
    //                 return e.NOW;
    //             if (s < 60)
    //                 return n.template(e.METAR_MIN_AGO, {
    //                     DURATION: s
    //                 });
    //             if (s < 240) {
    //                 var a = Math.floor(s / 60)
    //                     , r = s - 60 * a;
    //                 return n.template(e.METARS_H_M_AGO, {
    //                     DURATION: a,
    //                     DURATIONM: r
    //                 })
    //             }
    //             return s < 1440 ? utils.template(e.METAR_HOURS_AGO, {
    //                 DURATION: Math.floor(s / 60)
    //             }) : utils.template(e.METARS_DAYS_AGO, {
    //                 DURATION: Math.floor(s / 1440)
    //             })
    //         }
    //         return (t.useAgo && i ? "in " : "") + (s < 60 ? Math.floor(s) + "m" : Math.floor(s / 60) + "h " + Math.floor(s % 60) + "m") + (t.useAgo && !i ? " ago" : "")
    //     }
    // ;
    const c = function(e) {
        return [Math.abs(0 | e), "°", 0 | (e < 0 ? e = -e : e) % 1 * 60, "'", 0 | 60 * e % 1 * 60, '"'].join("")
    };
    object.DD2DMS = function(e, t) {
        return [e < 0 ? "S" : "N", c(e), ", ", t < 0 ? "W" : "E", c(t)].join("")
    }
    //     ,
    //     i.utcOffsetStr = function(e) {
    //         return (e < 0 ? "-" : "+") + n.pad(Math.abs(Math.round(e))) + ":00"
    //     }

    object.seoString = function(e) {
        return object.seoUrlString(e).replace(/\/+/g, "-")
    }
    ,
    object.seoUrlString = function(e) {
        return e.replace(/[,.]/g, " ").replace(/₂/g, "2").replace(/₃/g, "3").replace(/\s+/g, "-").replace(/-+/g, "-")
    }
    ,
    object.seoLang = function(e) {
        // return "en" === e ? "" : e + "/"
        return  e + "/"
    }
// ,
// i.animateViews = function(e, t) {
//     setTimeout(i.startAnimate(e, t), 2e3)
// }
// ,
// i.startAnimate = function(e, t) {
//     var s = Date.now()
//         , a = s + 4e3
//         , r = function() {
//         var o = Math.floor(n.smoothstep(s, a, Date.now()) * e);
//         t && (t.textContent = i.thousands(o) + " views"),
//         t && o < e && window.requestAnimationFrame(r)
//     };
//     r()
// }
// ,
export default object;

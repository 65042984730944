/**
 * Módulo que devuelve la clase Layer. La clase Layer permite configurar la capa en la que se renderiza o pintan las teselas.
 *
 * @module Layer
 */
import Class from "./Class";
import products from "./products";
import utils from "./utils";
import rootScope from "./rootScope";
import store from "./store";
import trans from "./trans";

export default Class.extend({
    c: null,
    m: null,
    ident: "",
    renderer: "tileLayer",
    filename: null,
    sea: false,
    coverWholeMap: false,
    dataQuality: null,
    qualityLevels: null,
    fileSuffix: null,
    JPGtransparency: 0,
    daily: false,
    renderParams: {
        renderFrom: "R"
    },
    getColor: function() {
        return this.c.getColor()
    },
    getName: function() {
        return trans[this.trans] || this.ident
    },
    getCalendar: function (productname){
        const calendar = products[productname] ? products[productname].calendar : products.ecmwf.calendar;

        return calendar ?
            Promise.resolve(calendar) :
            new Promise((
                function(resolve) {
                    products[productname].open.call(products[productname]).then((
                            function(calendar) {
                                return resolve(calendar)
                            }
                        ))
                }
        ))
    },
    getParams: function(params, productname) {
        let paramscloned = utils.clone(params)
            , product = products[this.product || productname]
            , levels = this.levels || product.levels;

        let paramsret = Object.assign(paramscloned, {
                layer: this.ident,
                server: this.server || product.server || rootScope.server,
                JPGtransparency: this.JPGtransparency || product.JPGtransparency,
                PNGtransparency: this.PNGtransparency || product.PNGtransparency,
                maxTileZoom: this.maxTileZoom || product.maxTileZoom,
                transformR: this.transformR || null,
                transformG: this.transformG || null,
                transformB: this.transformB || null,
                directory: product.directory,
                imVersion: product.imVersion,
                filename: this.filename || params.overlay || this.ident,
                fileSuffix: this.fileSuffix || product.fileSuffix,
                dataQuality: this.dataQuality || product.dataQuality,
                upgradeDataQuality: (this.betterDataQuality || product.betterDataQuality || []).includes(this.ident)
            });

        if ( paramsret.hasMoreLevels ){
            if (! levels.includes(paramsret.level))
                paramsret.level = levels[0];
        }
        else
            paramsret.level = "surface";

        if ( "100m" === paramsret.level && "wind" !== paramsret.overlay )
            paramsret.level = "surface";

        if ( this.qualityLevels && ! this.qualityLevels.includes(paramsret.level)){
            paramsret.dataQuality = product.dataQuality;
        }

        if (this.renderParams)
            paramsret = Object.assign(paramsret, this.renderParams);

        // paramsret.hasMoreLevels ? levels.includes(paramsret.level) || (paramsret.level = levels[0]) : paramsret.level = "surface",
        // "100m" === paramsret.level && "wind" !== paramsret.overlay && (paramsret.level = "surface"),
        // this.renderParams && (paramsret = Object.assign(paramsret, this.renderParams));//,

        paramsret.refTime = product.refTime();

        // if (/*this.product && /^cams/.test(product) && */this.product !== product)
        // if (product.ident !== productname)
        // {
        //     let calendar = products.ecmwf.calendar;
        //
        //     paramsret.path = calendar.ts2path(store.get("timestamp"));
        //     paramsret.refTime = calendar.refTime;
        //     paramsret.level = this.levels && this.levels[0] || paramsret.level;
        // }
        //
        // let pathGenerator = this.pathGenerator || product.pathGenerator;
        //
        // paramsret.fullPath = utils.template(pathGenerator, paramsret);
        //
        // pathGenerator = pathGenerator.replace(/\{\{?\s*(path)\s*\}?\}/g, "{nextpath}");
        //
        // paramsret.fullNextPath = utils.template(pathGenerator, paramsret);

        // paramsret.fullKey = utils.template(this.keyGenerator || product.keyGenerator, paramsret);
        // params.imVersion < 3 && (params.fullPath = utils.addQs(params.fullPath, "reftime=" + params.refTime));


        // return paramsret;

        const layer = this;

        const promise = product.ident === productname ?
            Promise.resolve() :
            this.getCalendar(product.ident).then(
                (calendar) => {
                    paramsret.path = calendar.ts2path(store.get("timestamp"));
                    paramsret.refTime = calendar.refTime;
                    paramsret.level = this.levels && this.levels[0] || paramsret.level;
                }
            );

        return promise.then(
            () => {
                let pathGenerator = layer.pathGenerator || product.pathGenerator;

                paramsret.fullPath = utils.template(pathGenerator, paramsret);

                pathGenerator = pathGenerator.replace(/\{\{?\s*(path)\s*\}?\}/g, "{nextpath}");

                paramsret.fullNextPath = utils.template(pathGenerator, paramsret);

                return paramsret;

            }
        );

        // return promise;
    }
});

import rscope from "./rootScope";
import Plugin from "./Plugin";
import TagPlugin from "./TagPlugin";

// define("plugins", ["rootScope", "Plugin", "TagPlugin", "TagAutoOpen", "RiotPlugin"], function(e, t, n, i, s) {
export default {
        // riot: t.instance({
        //     ident: "riot",
        //     location: "riot.v0312.js"
        // }),
        // geodesic: t.instance({
        //     ident: "geodesic",
        //     location: "geodesic110.js"
        // }),
        // d3: t.instance({
        //     ident: "d3",
        //     location: "d3.v0507.custom01.js"
        // }),
        // colorpicker: t.instance({
        //     ident: "colorpicker",
        //     location: "colorpicker.js"
        // }),
        // graticule: t.instance({
        //     ident: "graticule",
        //     location: "graticule20.js"
        // }),
        // nouislider: i.instance({
        //     ident: "nouislider",
        //     location: "nouislider.v0805.js"
        // }),
        // airgram: t.instance({
        //     ident: "airgram",
        //     dependencies: ["detail-render"]
        // }),
        // "favs-extended": t.instance({
        //     ident: "favs-extended"
        // }),
        "gl-particles": Plugin.instance({
            ident: "gl-particles"
        }),
        // gestures: i.instance({
        //     ident: "gestures"
        // }),
        particles: Plugin.instance({
            ident: "particles"
        }),
        //     "store-settings": t.instance({
        //         ident: "store-settings"
        //     }),
        //     "detail-render": t.instance({
        //         ident: "detail-render"
        //     }),
        //     "product-descriptions": t.instance({
        //         ident: "product-descriptions"
        //     }),
        //     patternator: t.instance({
        //         ident: "patternator"
        //     }),
        //     "cap-utils": t.instance({
        //         ident: "cap-utils"
        //     }),
        //     seo: t.instance({
        //         ident: "seo"
        //     }),
        //     nearest: t.instance({
        //         ident: "nearest"
        //     }),
        //     "plugin-data-loader": t.instance({
        //         ident: "plugin-data-loader"
        //     }),
        //     flatpickr: i.instance({
        //         ident: "flatpickr"
        //     }),
        //     fullscreen: t.instance({
        //         ident: "fullscreen"
        //     }),
        isolines: Plugin.instance({
            ident: "isolines",
            hasURL: false
        }),
        hurricanes: Plugin.instance({
            ident: "hurricanes"
        }),
        warnings: Plugin.instance({
            ident: "warnings"
        }),
        //     dev: n.instance({
        //         ident: "dev",
        //         exclusive: "neverClose",
        //         className: "window",
        //         dependencies: ["plugin-data-loader"],
        //         keyboard: !0
        //     }),
        //     patch: i.instance({
        //         ident: "patch",
        //         location: "https://www.windy.com/patch/index/latest/patch.js?refTime=" + (new Date).toISOString().replace(/^(.*):.*$/, "$1"),
        //         hasURL: !1
        //     }),
        //     "radar-sat": i.instance({
        //         ident: "radar-sat",
        //         hasURL: !1
        //     }),
        radar: Plugin.instance({
                ident: "radar",
                title: "RADAR",
                hasURL: false,
        //         exclusive: "neverClose",
        //         className: "shy left-border right-border notap",
        //         dependencies: ["radar-sat"],
        //         noCloseOnBackButton: !0
        }),
        satellite: Plugin.instance({
            ident: "satellite",
            title: "SATELLITE",
            hasURL: false,
            // exclusive: "neverClose",
            // className: "shy left-border right-border notap",
            // dependencies: ["radar-sat"],
            // noCloseOnBackButton: !0
        }),
        //     "cap-alerts": n.instance({
        //         ident: "cap-alerts",
        //         exclusive: "neverClose",
        //         hasURL: !1,
        //         dependencies: ["cap-utils"],
        //         className: "shy left-border right-border bottom-border"
        //     }),
        //     "extreme-forecast": n.instance({
        //         ident: "extreme-forecast",
        //         exclusive: "neverClose",
        //         hasURL: !1,
        //         className: "shy left-border right-border bottom-border flex-container"
        //     }),
        //     "map-layers": n.instance({
        //         ident: "map-layers",
        //         exclusive: "neverClose",
        //         hasURL: !1,
        //         dependencies: e.isMobileOrTablet ? [] : ["rhpane"],
        //         className: "shy left-border bottom-border rounded-box bg-transparent fg-yellow"
        //     }),
        //     "share-mobile": n.instance({
        //         ident: "share-mobile",
        //         hasURL: !1
        //     }),
        //     overlays: n.instance({
        //         title: "S_ADD_OVERLAYS",
        //         ident: "overlays",
        //         className: "plugin-rhpane top-border plugin-mobile-rhpane",
        //         exclusive: "rhpane"
        //     }),
        //     "hp-weather": n.instance({
        //         ident: "hp-weather",
        //         hasURL: !1,
        //         replaceMountingPoint: !0,
        //         className: "weather-box clickable-size",
        //         attachPoint: '[data-plugin="weather"]'
        //     }),
        //     detail: n.instance({
        //         ident: "detail",
        //         keyboard: !0,
        //         dependencies: e.isMobileOrTablet ? ["detail-render", "nearest"] : ["rhpane", "detail-render", "nearest"],
        //         className: "detail plugin-mobile-bottom-red bottom-border",
        //         htmlID: "detail",
        //         replaceMountingPoint: !0,
        //         hasURL: !1,
        //         exclusive: "all",
        //         attachPoint: e.isMobileOrTablet ? '[data-plugin="detail-rhpane"]' : '[data-plugin="detail"]'
        //     }),
        //     station: n.instance({
        //         ident: "station",
        //         exclusive: "all",
        //         dependencies: ["nearest"],
        //         className: "detail plugin-mobile-bottom-red bottom-border bg-white",
        //         hasURL: !0
        //     }),
        //     "nearest-stations": n.instance({
        //         ident: "nearest-stations",
        //         dependencies: ["nearest"],
        //         className: "drop-down-window left boxshadow",
        //         hasURL: !1,
        //         unmountOnClose: !0
        //     }),
        //     "nearest-webcams": n.instance({
        //         ident: "nearest-webcams",
        //         dependencies: ["nearest"],
        //         className: "window left boxshadow normal-closing-x",
        //         hasURL: !1,
        //         langFile: "webcams",
        //         unmountOnClose: !0
        //     }),
        //     "nearest-webcams-mobile": n.instance({
        //         ident: "nearest-webcams-mobile",
        //         dependencies: ["nearest"],
        //         className: "plugin-mobile-bottom-slide auto-height",
        //         hasURL: !1,
        //         langFile: "webcams",
        //         unmountOnClose: !0
        //     }),
        //     rhpane: i.instance({
        //         ident: "rhpane",
        //         className: "right-border top-border flex-container",
        //         htmlID: "detail-rhpane-wrapper",
        //         hasURL: !1,
        //         displayBlock: !1,
        //         exclusive: "neverClose",
        //         replaceMountingPoint: !0,
        //         dependencies: ["fullscreen"],
        //         attachPoint: '[data-plugin="detail-rhpane"]'
        //     }),
        //     user: n.instance({
        //         ident: "user",
        //         htmlID: "user",
        //         hasURL: !1,
        //         exclusive: "neverClose",
        //         dependencies: ["favs-extended"],
        //         attachPoint: '[data-plugin="user"]'
        //     }),
        //     "fav-alert-menu": n.instance({
        //         ident: "fav-alert-menu",
        //         className: "drop-down-window size-l",
        //         hasURL: !1,
        //         closeOnClick: !0,
        //         attachPoint: '[data-plugin="fav-alert-menu"]'
        //     }),
        //     contextmenu: n.instance({
        //         ident: "contextmenu",
        //         className: "drop-down-window",
        //         closeOnClick: !0,
        //         hasURL: !1
        //     }),
        //     "poi-libs": n.instance({
        //         ident: "poi-libs",
        //         hasURL: !1
        //     }),
        //     menu: n.instance({
        //         ident: "menu",
        //         className: "plugin-rhpane top-border plugin-mobile-rhpane",
        //         exclusive: "rhpane-mobile",
        //         title: "MENU"
        //     }),
            picker: TagPlugin.instance({
                ident: "picker",
                hasURL: false,
                className: "picker"
            }),

            detail: TagPlugin.instance({
                ident: "detail",
                hasURL: false
            }),

        //     waves: n.instance({
        //         ident: "waves",
        //         hasURL: !1
        //     }),
        //     wind: n.instance({
        //         ident: "wind",
        //         hasURL: !1
        //     }),
        //     "user-menu": n.instance({
        //         ident: "user-menu",
        //         className: "drop-down-window right menu-items",
        //         closeOnClick: !e.isMobile,
        //         unmountOnClose: !0,
        //         attachPoint: e.isMobileOrTablet ? ".lhpane-user-line" : "#user",
        //         hasURL: !1
        //     }),
        //     "promo-mobile-intro": n.instance({
        //         ident: "promo-mobile-intro",
        //         className: "fg-white rounded-box",
        //         hasURL: !1
        //     }),
        //     sounding: n.instance({
        //         ident: "sounding",
        //         className: e.isMobile ? "drop-down-window down" : "drop-down-window",
        //         dependencies: ["d3"],
        //         attachPoint: e.isMobile ? "#plugins" : "#map-container .leaflet-popup-pane",
        //         title: "SOUNDING",
        //         hasURL: !1
        //     }),
            "poi-libs": TagPlugin.instance({
                title: "SHOW_ON_MAP",
                ident: "poi-libs",
                hasURL: false,
        //         className: "plugin-rhpane top-border plugin-mobile-rhpane",
        //         exclusive: "rhpane"
            })
        //     articles: n.instance({
        //         ident: "articles",
        //         className: "plugin-lhpane top-border plugin-mobile-bottom-slide",
        //         exclusive: "lhpane",
        //         hasURL: !0
        //     }),
        //     screenshot: n.instance({
        //         ident: "screenshot",
        //         dependencies: ["upload"],
        //         hasURL: !0
        //     }),
        //     settings: n.instance({
        //         ident: "settings",
        //         langFile: "settings",
        //         hasURL: !0,
        //         title: "MENU_SETTINGS",
        //         exclusive: "lhpane",
        //         unmountOnClose: !0,
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: e.isMobileOrTablet ? [] : ["favs", "tools"]
        //     }),
        //     tools: n.instance({
        //         ident: "tools",
        //         hasURL: !0,
        //         unmountOnClose: !0,
        //         title: "MENU",
        //         exclusive: "lhpane",
        //         className: "plugin-lhpane top-border",
        //         dependencies: e.isMobileOrTablet ? [] : ["favs", "settings"]
        //     }),
        //     favs: n.instance({
        //         ident: "favs",
        //         title: "MENU_FAVS",
        //         hasURL: !0,
        //         keyboard: !0,
        //         unmountOnClose: !0,
        //         exclusive: "lhpane",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: e.isMobileOrTablet ? ["gestures", "favs-extended"] : ["gestures", "settings", "tools", "favs-extended"]
        //     }),
        //     alerts: s.instance({
        //         ident: "alerts",
        //         langFile: "alerts",
        //         exclusive: "lhpane",
        //         hasURL: !1,
        //         keyboard: !0,
        //         unmountOnClose: !0,
        //         title: "MY_ALERTS",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: ["riot", "nouislider", "favs-extended"]
        //     }),
        //     colors: s.instance({
        //         ident: "colors",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         unmountOnClose: !0,
        //         keyboard: !0,
        //         title: "S_COLORS",
        //         dependencies: ["riot", "colorpicker"]
        //     }),
        //     privacy: s.instance({
        //         ident: "privacy",
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         keyboard: !0,
        //         unmountOnClose: !0,
        //         title: "PRIVACY",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: ["riot"]
        //     }),
        //     hurricanes: s.instance({
        //         ident: "hurricanes",
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         unmountOnClose: !0,
        //         title: "HURR_TRACKER",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: ["riot"]
        //     }),
        //     debug: s.instance({
        //         ident: "debug",
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         unmountOnClose: !0,
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         dependencies: ["riot"]
        //     }),
        //     info: s.instance({
        //         ident: "info",
        //         dependencies: e.isMobileOrTablet ? ["riot", "product-descriptions"] : ["riot", "rhpane", "product-descriptions"],
        //         className: "drop-down-window down",
        //         closeOnClick: !e.isMobileOrTablet,
        //         hasURL: !1,
        //         unmountOnClose: !0,
        //         exclusive: "middle-mobile",
        //         attachPoint: e.isMobileOrTablet ? "#plugins" : "#info-icon"
        //     }),
        //     "cap-alert": s.instance({
        //         ident: "cap-alert",
        //         dependencies: ["riot", "cap-utils", "detail-render"],
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "lhpane",
        //         hasURL: !1,
        //         title: "WX_WARNINGS"
        //     }),
        //     distance: s.instance({
        //         ident: "distance",
        //         title: "MENU_DISTANCE",
        //         exclusive: "lhpane",
        //         keyboard: !1,
        //         hasURL: !0,
        //         className: "plugin-mobile-bottom-red bottom-border",
        //         dependencies: ["riot", "geodesic"]
        //     }),
        //     animate: s.instance({
        //         ident: "animate",
        //         exclusive: "all",
        //         keyboard: !0,
        //         title: "Create Animation"
        //     }),
        //     airport: n.instance({
        //         ident: "airport",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "lhpane",
        //         hasURL: !1,
        //         dependencies: ["detail-render", "gestures"]
        //     }),
        //     tides: s.instance({
        //         ident: "tides",
        //         className: "plugin-rhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "rhpane",
        //         dependencies: ["riot", "poi-libs"]
        //     }),
        //     share: n.instance({
        //         ident: "share",
        //         title: "SHARE",
        //         hasURL: !1,
        //         keyboard: !0,
        //         className: "window"
        //     }),
        //     widgets: s.instance({
        //         ident: "widgets",
        //         exclusive: "all",
        //         langFile: "widgets",
        //         title: "EMBED",
        //         keyboard: !0,
        //         className: "plugin-rhpane top-border plugin-mobile-fullscreen"
        //     }),
        //     multimodel: s.instance({
        //         ident: "multimodel",
        //         dependencies: ["riot", "detail-render"],
        //         className: "plugin-mobile-fullscreen"
        //     }),
        //     login: s.instance({
        //         ident: "login",
        //         className: "plugin-rhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "all",
        //         keyboard: !0
        //     }),
        //     register: s.instance({
        //         ident: "register",
        //         langFile: "register",
        //         className: "plugin-rhpane top-border plugin-mobile-fullscreen",
        //         exclusive: "all",
        //         keyboard: !0
        //     }),
        //     "article-publisher": n.instance({
        //         ident: "article-publisher",
        //         dependencies: ["flatpickr"],
        //         className: "plugin-rhpane top-border left-border",
        //         hasURL: !0,
        //         title: "Windy Article publisher",
        //         keyboard: !0
        //     }),
        //     rplanner: n.instance({
        //         ident: "rplanner",
        //         onurl: function() {
        //             return {
        //                 url: "distance",
        //                 title: null
        //             }
        //         },
        //         unmountOnClose: !0,
        //         exclusive: "all",
        //         dependencies: e.isMobileOrTablet ? ["geodesic"] : ["geodesic", "rhpane"],
        //         className: "detail plugin-mobile-bottom-red bottom-border bg-white",
        //         hasURL: !0,
        //         attachPoint: e.isMobileOrTablet ? '[data-plugin="detail-rhpane"]' : '[data-plugin="rplanner"]'
        //     }),
        //     uploader: n.instance({
        //         ident: "uploader",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         dependencies: ["upload"],
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         title: "Upload KML, GPX or geoJSON file",
        //         keyboard: !0
        //     }),
        //     upload: n.instance({
        //         ident: "upload",
        //         hasURL: !1
        //     }),
        //     plugins: n.instance({
        //         ident: "plugins",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         dependencies: ["plugin-data-loader"],
        //         exclusive: "lhpane",
        //         hasURL: !0,
        //         title: "Install Windy plugin",
        //         keyboard: !0
        //     }),
        //     webcams: n.instance({
        //         ident: "webcams",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         className: "plugin-lhpane top-border plugin-mobile-bottom-slide",
        //         exclusive: "all",
        //         hasURL: !0,
        //         keyboard: !0
        //     }),
        //     "webcams-detail": n.instance({
        //         ident: "webcams-detail",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         className: "plugin-lhpane top-border plugin-mobile-bottom-slide",
        //         exclusive: "all",
        //         hasURL: !1,
        //         keyboard: !0
        //     }),
        //     "webcams-add": n.instance({
        //         ident: "webcams-add",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         exclusive: "all",
        //         hasURL: !1,
        //         title: "D_MISSING_CAM",
        //         keyboard: !0
        //     }),
        //     "webcams-edit": n.instance({
        //         ident: "webcams-edit",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         exclusive: "all",
        //         hasURL: !1,
        //         keyboard: !0
        //     }),
        //     "webcams-remove": n.instance({
        //         ident: "webcams-remove",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         exclusive: "all",
        //         hasURL: !1,
        //         keyboard: !0
        //     }),
        //     "webcams-mobile": n.instance({
        //         ident: "webcams-mobile",
        //         hasURL: !1,
        //         langFile: "webcams",
        //         className: "plugin-mobile-bottom-slide"
        //     }),
        //     "webcams-embed": n.instance({
        //         ident: "webcams-embed",
        //         exclusive: "all",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         title: "EMBED",
        //         keyboard: !0,
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header"
        //     }),
        //     "webcams-fullscreen": n.instance({
        //         ident: "webcams-fullscreen",
        //         unmountOnClose: !0,
        //         langFile: "webcams",
        //         hasURL: !1,
        //         dependencies: ["fullscreen"]
        //     }),
        //     maps: n.instance({
        //         ident: "maps",
        //         langFile: "maps",
        //         className: "plugin-lhpane top-border plugin-mobile-fullscreen-no-header",
        //         exclusive: "all",
        //         keyboard: !0,
        //         title: "Maps"
        //     })
    }


import Overlay from "./Overlay";
import utils from "./utils";
import overlaysdef from "../../../maps-frontend/modules/data/overlays";
import layers from "./layers";
import trans from "./trans";
import store from "./store";

// define(["Overlay", "layers", "colors"], function(overlay, t, n) {

// Object.defineProperty(e, "metric", {
//     get: function() {
//         return this.m ? this.m.metric : ""
//     }
// });
var overlays = {};

Object.keys(overlaysdef).forEach(
    key => {
        overlays[key]=Overlay.instance(
            overlaysdef[key]
        );
    }
)


overlays["precip"].getDefaultValue = function (values){
    return values[1] < 0 ? 0 : values[1];
}

overlays["nieve"].getDefaultValue = function (values){
    return values[2] < 0 ? 0 : values[2];
}

overlays["radar"].getDefaultValue = function (values){
    return (values[0] < 0 || isNaN(values[0])) ? 0 : values[0];
}


overlays["viento"].createPickerHTML = function(values, degtodir) {
    let windObj= utils.wind2obj(values)
        , windDirHtml = utils.windDir2html(windObj);
    return "".concat(this.createPickerTitle(), '<div class="p-metric" data-do="changeMetric">')
        .concat(this.convertValue(windObj.wind, " <span>", "</span>"), '</div>');
        // .concat(this.convertValue(windObj.wind, " <span>", "</span>"), '<i title="Direction from, true north">')
        // .concat(windDirHtml)
        // .concat(degtodir(windObj.dir), "</i></div>")
}

overlays["ivt"].createPickerHTML = function(values, t) {
    let windObj= utils.wind2obj(values);
        // , windDirHtml = utils.windDir2html(windObj);
    return this.createPickerTitle() + '<div class="p-metric" data-do="changeMetric">' +
        this.convertNumber(windObj.wind) + ' <span>'+ this.m.metric + '</span></div>';
}

overlays["prcnub"].createPickerHTML = function(values, t) {

    let metricprec, metricnieve,
        convertedValue,
        layerPrecip = layers["precip"],
        layerNieve = layers["nieve"];

    metricprec = layerPrecip.m;
    metricnieve = layerNieve.m;

    convertedValue = metricprec.convertValue(values[1] - values[2], " <span>", "</span>");

    const precstr = `<div class="block">
                        <div class="p-title inlined" data-icon-before="&#xe805;">${trans.RAIN}</div>
                        <div class=\"p-metric\" data-do=\"changeMetric\">${convertedValue}</div>
                     </div>`;


    convertedValue = metricnieve.convertValue(values[2], " <span>", "</span>");

    const nievestr = `<div class="block separator">
                        <div class="p-title inlined" data-icon-before="&#xe808;">
                            ${layerNieve.getName()}
                        </div>
                        <div class="p-metric" data-do="changeMetric">
                            ${convertedValue}
                        </div>
                      </div>`


    return precstr + nievestr;

}




overlays["uvi"].createPickerHTML = function(values, t) {

    let value = this.getDefaultValue(values),
        convertedValue;


    convertedValue = " " + this.convertNumber(value) + " ";

    return `<div class="block">
                ${this.createPickerTitle()}
                <div class="p-metric" data-do="changeMetric">${convertedValue}</div>
            </div>`;

}

overlays["duaod550"].createPickerHTML = function(values, t) {

    let value = this.getDefaultValue(values),
        convertedValue;

    const title = '<div class="p-title inlined" data-icon-before="' + (this.iconfont ? this.iconfont : "") + '">'+this.getName()+ " (AOD)</div>";


    convertedValue = " " + this.convertNumber(value) + " ";

    return `<div class="block">
                ${title}
                <div class="p-metric" data-do="changeMetric">${convertedValue}</div>
            </div>`;

}

overlays["snowdepth"].createPickerHTML = function(values, t){
    let value = this.getDefaultValue(values);
    let valuetext = "";
    const morethantext=trans.MORETHAN;

    if (value > 1990){
        valuetext = trans.MORETHAN.replace("_X_", this.m.metric==="cm" ? "20 m"  : this.convertValue(2000, " <span>", "</span>"));
    }else if (value > 990){
        valuetext = trans.MORETHAN.replace("_X_", this.m.metric==="cm" ? "10 m"  : this.convertValue(1000, " <span>", "</span>"));
    } else if (value > 490){
        valuetext = trans.MORETHAN.replace("_X_", this.m.metric==="cm" ? "5 m"  : this.convertValue(5000, " <span>", "</span>"));
    }else{
        valuetext = this.convertValue(value, " <span>", "</span>");
    }

    return `<div class="block">
                ${this.createPickerTitle()}
                <div class="p-metric" data-do="changeMetric">${valuetext}</div>
            </div>`;

}


overlays["aqimet"].createPickerHTML =
    overlays["aqiusa"].createPickerHTML =
        overlays["aqieu"].createPickerHTML =
            overlays["aqimpol"].createPickerHTML = function(values, t) {

    let value = this.getDefaultValue(values),
        convertedValue;

    const title = '<div class="p-title inlined" data-icon-before="' + (this.iconfont ? this.iconfont : "") + '">'+this.getName()+ "</div>";


    convertedValue = " " + this.convertNumber(value) + " ";

    return `<div class="block">
                ${title}
                <div class="p-metric" data-do="changeMetric">${convertedValue}</div>
            </div>`;

}

overlays["warnings"].htmlLegend = `
                                    <span class="slight">${trans.RISK0}</span>
                                    <span class="moderate">${trans.RISK1}</span>
                                    <span class="important">${trans.RISK2}</span>
                                    <span class="extreme">${trans.RISK3}</span>
                                 `
store.on("usedLang", ()=>{
    overlays["warnings"].htmlLegend = `
                                    <span class="slight">${trans.RISK0}</span>
                                    <span class="moderate">${trans.RISK1}</span>
                                    <span class="important">${trans.RISK2}</span>
                                    <span class="extreme">${trans.RISK3}</span>
                                    `
})

/*
overlays.prcnub = overlay.instance({
    ident: "prcnub",
    trans: "PRCNUB",
    icon: "iconos_terrestre/lluvia-nubosidad.svg",
    // icon: "",
    layers: ["windParticles","prcnub"]
});
overlays.precip = overlay.instance({
    ident: "precip",
    trans: "RAIN",
    icon: "iconos_terrestre/lluvia-nieve.svg",
    // icon: "",
    layers: ["windParticles","precip"]
});
overlays.nieve = overlay.instance({
    ident: "nieve",
    trans: "SNOW",
    icon: "iconos_terrestre/nieve.svg",
    // trans: "RAIN_THUNDER",
    // icon: "",
    layers: ["windParticles", "nieve"]
});
overlays.nubes = overlay.instance({
    ident: "nubes",
    trans: "CLOUDS",
    icon: "iconos_terrestre/nubosidad.svg",

    // icon: "7",
    layers: ["windParticles", "nubes"]//,
    // paintLegend: function(e) {
    //     this.m.renderLegend(t.rain.c, e, this.m)
    // }
});
overlays.acumprec = overlay.instance({
    // parentMenu: "rain",
    ident: "acumprec",
    trans: "RACCU",
    icon: "iconos_terrestre/lluvia_acumulada.svg",
    // icon: "9",
    // isAccu: true,
    layers: ["acumprec"]
});
overlays.prob = overlay.instance({
    // parentMenu: "rain",
    ident: "prob",
    trans: "PPREC",
    // icon: "9",
    icon: "iconos_terrestre/probabilidad.svg",
    // isAccu: true,
    layers: ["prob"]
});
overlays.temp2m = overlay.instance({
    ident: "temp2m",
    trans: "TEMPERATURE",
    // icon: "",
    icon: "iconos_terrestre/temperatura.svg",
    layers: ["windParticles", "temp2m"],
    hasMoreLevels: true
});
overlays.viento = overlay.instance({
    ident: "viento",
    hasMoreLevels: true,
    trans: "WIND",
    // icon: "|",
    icon: "iconos_terrestre/viento.svg",
    layers: ["windParticles", "viento"]
    // layers: ["wind"]
});
overlays.rachas = overlay.instance({
    // parentMenu: "wind",
    ident: "rachas",
    trans: "GUST",
    // icon: "",
    icon: "iconos_terrestre/rachas-maximas.svg",
    layers: ["windParticles", "rachas"]
});
overlays.rayos = overlay.instance({
    // parentMenu: "rain",
    ident: "rayos",
    trans: "THUNDER",
    // icon: "",
    icon: "iconos_terrestre/rayos.svg",
    layers: ["windParticles", "rayos"]
});
overlays.humedad = overlay.instance({
    // parentMenu: "temp",
    ident: "humedad",
    // icon: "/",
    icon: "iconos_terrestre/humedad_r.svg",
    trans: "RH",
    layers: ["windParticles", "humedad"],
    hasMoreLevels: true
});
overlays.uvi = overlay.instance({
    // parentMenu: "uvi",
    ident: "uvi",
    trans: "UVI",
    // icon: "c",
    icon: "iconos_terrestre/indice-uv.svg",

    layers: ["windParticles", "uvi"]
});
overlays.visibilidad = overlay.instance({
    // parentMenu: "clouds",
    ident: "visibilidad",
    trans: "VISIBILITY",
    // icon: "c",
    icon: "iconos_terrestre/visibilidad.svg",
    layers: ["windParticles", "visibilidad"]
});


overlays.oleajetotal = overlay.instance({
    ident: "oleajetotal",
    trans: "WAVES",
    // icon: "",
    icon: "iconos_mar/oleaje-total.svg",
    parentMenu: "menu-mar",
    // layers: ["waveParticles", "waves"]
    layers: ["waveParticles","oleajetotal"]
});
overlays.oleajeviento = overlay.instance({
    // parentMenu: "waves",
    ident: "oleajeviento",
    trans: "WWAVES",
    // icon: "|",
    icon: "iconos_mar/mar-viento.svg",
    parentMenu: "menu-mar",
    layers: ["windWaveParticles","oleajeviento"]
});
overlays.oleajefondo = overlay.instance({
    // parentMenu: "waves",
    parentMenu: "menu-mar",
    ident: "oleajefondo",
    trans: "SWAVES",
    // icon: "|",
    icon: "iconos_mar/mar-fondo.svg",
    layers: ["swellWaveParticles","oleajefondo"]
});
overlays.oleajealtura = overlay.instance({
    // parentMenu: "waves",
    parentMenu: "menu-mar",
    ident: "oleajealtura",
    trans: "HMWAVES",
    // icon: "|",
    icon: "iconos_mar/altura-mar.svg",
    layers: ["oleajealtura"]
});
overlays.tempmar = overlay.instance({
    // parentMenu: "waves",
    parentMenu: "menu-mar",
    ident: "tempmar",
    trans: "SST",
    icon: "iconos_mar/temperatura-mar.svg",
    // icon: "",
    // layers: ["currentParticles", "sst"]
    layers: ["tempmar"]
});

overlays.duaod550 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "duaod550",
    trans: "AOD550",
    // icon: "",
    icon: "iconos_aire/calima.svg",
    // layers: ["ecmwfWindParticles600h", "aod550"]
    layers: ["ecmwfWindParticles700h", "duaod550"]
});
overlays.pm2p5 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "pm2p5",
    trans: "PM2P5",
    // icon: "",
    icon: "iconos_aire/particulas-2.svg",
    layers: ["ecmwfWindParticles", "pm2p5"]
});
overlays.pm10 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "pm10",
    trans: "PM10",
    // icon: "",
    icon: "iconos_aire/particulas-10.svg",
    layers: ["ecmwfWindParticles", "pm10"]
});
overlays.no2 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "no2",
    trans: "NO2",
    // icon: "",
    icon: "iconos_aire/dioxido-nitrogeno.svg",
    layers: ["ecmwfWindParticles", "no2"]
});
overlays.so2 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "so2",
    trans: "SO2",
    // icon: "",
    icon: "iconos_aire/dioxido-azufre.svg",
    layers: ["ecmwfWindParticles", "so2"]
});
overlays.o3 = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "o3",
    trans: "O3",
    // icon: "",
    icon: "iconos_aire/ozono.svg",
    layers: ["ecmwfWindParticles", "o3"]
});
overlays.co = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-aire",
    ident: "co",
    trans: "CO",
    // icon: "",
    icon: "iconos_aire/monoxido-carbono.svg",
    layers: ["ecmwfWindParticles", "co"]
});

overlays.olive = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "olive",
    trans: "OLIVE",
    // icon: "",
    icon: "iconos_polen/olive-lineal.svg",
    layers: ["ecmwfWindParticles", "olive"]
});
overlays.alder = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "alder",
    trans: "ALDER",
    // icon: "",
    icon: "iconos_polen/alder-lineal.svg",
    layers: ["ecmwfWindParticles", "alder"]
});
overlays.birch = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "birch",
    trans: "BIRCH",
    // icon: "",
    icon: "iconos_polen/birch-lineal.svg",
    layers: ["ecmwfWindParticles", "birch"]
});
overlays.grass = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "grass",
    trans: "GRASS",
    // icon: "",
    icon: "iconos_polen/grass-lineal.svg",
    layers: ["ecmwfWindParticles", "grass"]
});
overlays.mugwort = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "mugwort",
    trans: "MUGWORT",
    // icon: "",
    icon: "iconos_polen/mugwort-lineal.svg",
    layers: ["ecmwfWindParticles", "mugwort"]
});
overlays.ragweed = overlay.instance({
    // parentMenu: "airQ",
    parentMenu: "menu-polen",
    ident: "ragweed",
    trans: "RAGWEED",
    // icon: "",
    icon: "iconos_polen/ragweed-lineal.svg",
    layers: ["ecmwfWindParticles", "ragweed"]
});

    // i.dewpoint = e.instance({
    //     parentMenu: "temp",
    //     ident: "dewpoint",
    //     trans: "DEW_POINT",
    //     icon: "",
    //     layers: ["windParticles", "dewpoint"],
    //     hasMoreLevels: !0
    // }),

    // i.gustAccu = e.instance({
    //     parentMenu: "wind",
    //     ident: "gustAccu",
    //     trans: "GUSTACCU",
    //     icon: "",
    //     isAccu: !0,
    //     layers: ["windParticles", "gustAccu"]
    // }),

    // overlays.pressure = overlay.instance({
    //     ident: "pressure",
    //     trans: "PRESS",
    //     icon: "",
    //     layers: ["windParticles", "pressure"]
    // }),




    // i.lclouds = e.instance({
    //     parentMenu: "clouds",
    //     ident: "lclouds",
    //     trans: "LOW_CLOUDS",
    //     icon: "",
    //     layers: ["windParticles", "lclouds"]
    // }),
    // i.mclouds = e.instance({
    //     parentMenu: "clouds",
    //     ident: "mclouds",
    //     trans: "MEDIUM_CLOUDS",
    //     icon: "",
    //     layers: ["windParticles", "mclouds"]
    // }),
    // i.hclouds = e.instance({
    //     parentMenu: "clouds",
    //     ident: "hclouds",
    //     trans: "HIGH_CLOUDS",
    //     layers: ["windParticles", "hclouds"],
    //     icon: ""
    // }),
    // i.cape = e.instance({
    //     parentMenu: "clouds",
    //     ident: "cape",
    //     trans: "CAPE",
    //     layers: ["windParticles", "cape"],
    //     icon: "~"
    // }),
    // i.cbase = e.instance({
    //     parentMenu: "clouds",
    //     ident: "cbase",
    //     trans: "CLOUD_ALT",
    //     icon: ":",
    //     layers: ["windParticles", "cbase"]
    // }),
    // i.snowAccu = e.instance({
    //     parentMenu: "rain",
    //     ident: "snowAccu",
    //     trans: "NEWSNOW",
    //     icon: "",
    //     isAccu: !0,
    //     layers: ["snowAccu"]
    // }),





    // i.swell1 = e.instance({
    //     parentMenu: "waves",
    //     ident: "swell1",
    //     trans: "SWELL",
    //     icon: "{",
    //     layers: ["waveParticles", "swell1"]
    // }),
    // i.swell2 = e.instance({
    //     parentMenu: "waves",
    //     ident: "swell2",
    //     trans: "SWELL2",
    //     icon: "",
    //     layers: ["waveParticles", "swell2"]
    // }),
    // i.swell3 = e.instance({
    //     parentMenu: "waves",
    //     ident: "swell3",
    //     trans: "SWELL3",
    //     icon: "",
    //     layers: ["waveParticles", "swell3"]
    // }),
    // i.swell = i.swell1,
    // i.currents = e.instance({
    //     parentMenu: "waves",
    //     ident: "currents",
    //     trans: "CURRENT",
    //     icon: "q",
    //     layers: ["currentParticles", "currents"]
    // }),


    // i.fog = e.instance({
    //     parentMenu: "clouds",
    //     ident: "fog",
    //     trans: "FOG",
    //     icon: "d",
    //     layers: ["fog"]
    // }),
    // i.snowcover = e.instance({
    //     parentMenu: "rain",
    //     ident: "snowcover",
    //     trans: "SNOWDEPTH",
    //     icon: "N",
    //     layers: ["windParticles", "snowcover"]
    // }),
    // i.cloudtop = e.instance({
    //     parentMenu: "clouds",
    //     ident: "cloudtop",
    //     trans: "CTOP",
    //     icon: "Q",
    //     layers: ["windParticles", "cloudtop"]
    // }),
    // i.deg0 = e.instance({
    //     parentMenu: "temp",
    //     ident: "deg0",
    //     trans: "FREEZING",
    //     icon: "",
    //     layers: ["windParticles", "deg0"]
    // }),
    // i.airQ = e.instance({
    //     ident: "airQ",
    //     trans: "AIR_QUALITY",
    //     icon: "",
    //     virtual: !0
    // }),
    // i.gtco3 = e.instance({
    //     parentMenu: "airQ",
    //     ident: "gtco3",
    //     trans: "OZONE",
    //     icon: "",
    //     layers: ["ecmwfWindParticles150h", "gtco3"]
    // }),

// i.fires = e.instance({
//     parentMenu: "airQ",
//     ident: "fires",
//     trans: "ACTIVE_FIRES",
//     icon: "",
//     layers: ["ecmwfWindParticles", "fires"]
// }),
// i.cosc = e.instance({
//     parentMenu: "airQ",
//     ident: "cosc",
//     trans: "COSC",
//     icon: "",
//     layers: ["ecmwfWindParticles", "cosc"]
// }),
// i.so2sm = e.instance({
//     parentMenu: "airQ",
//     ident: "so2sm",
//     trans: "SO2SM",
//     icon: "",
//     layers: ["ecmwfWindParticles", "so2sm"]
// }),
// i.dustsm = e.instance({
//     parentMenu: "airQ",
//     ident: "dustsm",
//     trans: "DUSTSM",
//     icon: "",
//     layers: ["ecmwfWindParticles", "dustsm"]
// }),
// i.ptype = e.instance({
//     parentMenu: "rain",
//     ident: "ptype",
//     trans: "PTYPE",
//     icon: "",
//     layers: ["ecmwfWindParticles", "ptype"],
//     paintLegend: function(e) {
//         this.m.renderLegend(n.ptype.getColor(), e)
//     }
// }),
// i.gh = e.instance({
//     ident: "gh",
//     layers: ["gh"]
// }),
// i.radarSat = e.instance({
//     ident: "radarSat",
//     virtual: !0,
//     trans: "RADAR_SAT",
//     icon: "O"
// }),
overlays.radar = overlay.instance({
    parentMenu: "menu-radar",
    selfMenu: true,
    allwaysOn: true,
    // poiInCities: !1,
    ident: "radar",
    trans: "RADAR",
    icon: "radar_blanco.svg",
    // icon: "",
    layers: ["radar"]
});
overlays.ir = overlay.instance({
    parentMenu: "menu-sat",
    allwaysOn: true,
    // poiInCities: !1,
    ident: "ir",
    trans: "IR",
    icon: "iconos_sat/infrared.svg",
    layers: ["ir"]
});
overlays.rgb = overlay.instance({
    parentMenu: "menu-sat",
    allwaysOn: true,
    // poiInCities: !1,
    ident: "rgb",
    trans: "RGB",
    icon: "iconos_sat/natural.svg",
    layers: ["rgb"]
});
overlays.vi = overlay.instance({
    parentMenu: "menu-sat",
    allwaysOn: true,
    // poiInCities: !1,
    ident: "vi",
    trans: "VI",
    icon: "iconos_sat/visible.svg",
    layers: ["vi"]
});
overlays.wv = overlay.instance({
    parentMenu: "menu-sat",
    allwaysOn: true,
    // poiInCities: !1,
    ident: "wv",
    trans: "WV",
    icon: "iconos_sat/wv.svg",
    layers: ["wv"]
});
// i.satelliteIRBT = e.instance({
//     ident: "satellite",
//     trans: "SATELLITE",
//     trans2: "INFRA+",
//     hideFromList: !0,
//     icon: ""
// }),
// i.capAlerts = e.instance({
//     ident: "capAlerts",
//     trans: "WX_WARNINGS",
//     icon: "",
//     layers: ["capAlerts"]
// }),
// overlays.map = overlay.instance({
//     ident: "map",
//     trans: "HMAP",
//     icon: "",
//     layers: ["map"]
// });
// var s = overlay.extend({
//     icon: "",
//     trans: "EFORECAST"
// });
// return overlays.efiWind = s.instance({
//     ident: "efiWind",
//     trans2: "WIND",
//     layers: ["efiWind"]
// }),
//     overlays.efiTemp = s.instance({
//         ident: "efiTemp",
//         trans2: "TEMPERATURE",
//         hideFromList: !0,
//         layers: ["efiTemp"]
//     }),
//     overlays.efiRain = s.instance({
//         ident: "efiRain",
//         trans2: "RAIN",
//         hideFromList: !0,
//         layers: ["efiRain"]
//     }),

 */
export default overlays;



class PremiumLinkView{

    private premiumLink: HTMLElement;

    private openLinkPremium: (() => any) | null = null;


    constructor(premiumLinkElement: HTMLElement){
        this.premiumLink = premiumLinkElement;
        if (this.premiumLink) {
            this.premiumLink.addEventListener("click", this.click.bind(this));
        }
    }

    bindOpenLinkPremium( handler: () => any){
        this.openLinkPremium = handler;
    }

    click(){
        if (this.openLinkPremium)
            this.openLinkPremium();
    }

}

export default PremiumLinkView;

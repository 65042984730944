import rootscope from "./rootScope";
import http from "./http";


let storage, storagetmp = {
    isAvbl: 1,
    put: function(key, item) {
        return window.localStorage.setItem(key, JSON.stringify(item))
    },
    hasKey: function(key) {
        return key in window.localStorage
    },
    get: function(key) {
        return JSON.parse(window.localStorage.getItem(key))
    },
    remove: function(key) {
        return window.localStorage.removeItem(key)
    },
    getFile: function(filename, params) {
        if (void 0 === params)
            params = {};

        const storage = this;

        const json = this.get(filename);

        // if (json && (params.absoluteURL || json.version === rootscope.langversion)
        if (json && json.version === rootscope.langversion &&
                (! params.test || json.data && params.test && json.data[params.test]))
            return Promise.resolve(json.data);
        else
            return new Promise((
                function(resolve, reject) {
                    http.get(params.absoluteURL ? filename : rootscope.assets + "/" + filename, {
                        json: true
                    }).then(
                        function(response) {
                            const data = response.data;
                            if (params.test && !(params.test in data))
                                reject("File did not passed the test");
                            else {
                                storage.put(filename, {
                                    data: data,
                                    // version: params.absoluteURL ? "notAplicable" : rootscope.langversion
                                    version: rootscope.langversion
                                });
                                resolve(data);
                            }
                        }
                    ).catch((function(e) {
                            console.error("storage", "Failed to load lang file as .json", e);
                            reject(e);
                        }
                    ))
                }
        ))
    }

};

try {

    window.localStorage.setItem("test", "bar");

    if ("bar" !== window.localStorage.getItem("test")) {
        throw new Error("Comparsion failed");  // Se prueba que funciona localstorage
    }
    window.localStorage.removeItem("test");
    storage = storagetmp;

} catch (e) {   // Si salta una excepción
    var array = {};

    // r.getFile = storagetmp.getFile.bind(r),
    storage = {
        isAvbl: 0,
        put: function(key, item) {
            return array[key] = item
        },
        hasKey: function(key) {
            return key in array
        },
        get: function(key) {
            return key in array ? array[key] : null
        },
        remove: function(key) {
            return delete array[key]
        }
    };
};

export default storage;


// /**
//  * Modulo que devuelve una instancia de la clase Evented
//  *
//  * @module broadcast
//  */

import Evented from './Evented';

export default Evented.instance({
    ident: "bcast"
});

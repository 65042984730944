import GlObj from "./GlObj";
import store from "./store";
import storage from "./storage";
import rootScope from "./rootScope";

// define("testWebGl", ["GlObj", "store", "storage", "rootScope"], function(GlObj, store, storage, rootScope) {
    export default {
        shRectVS: "\n attribute vec2 aPos;" +
                  "\n varying vec2 vTc;" +
                  "\n void main(void) {" +
                  "\n   gl_Position = vec4( aPos, 0.0, 1.0 );" +
                  "\n   vTc = aPos.xy * 0.5 + 0.5;" +
                  "\n }\n",
        shEncodeDecodeFS: "\n precision highp float;" +
                          "\n uniform vec4 uPars;" +
                          "\n uniform sampler2D sTex0;" +
                          "\n varying vec2 vTc;" +
                          "\n void main(void) {" +
                          "\n   vec4 tex0 = texture2D( sTex0, vTc );" +
                          "\n   vec2 pos = tex0.ba + tex0.rg * uPars.x;" +
                          "\n   vec2 rg = fract( pos * uPars.y + uPars.z );" +
                          "\n   gl_FragColor.ba = pos + rg * uPars.w;" +
                          "\n   gl_FragColor.rg = rg;" +
                          "\n }\n",
        retOk: "ok",
        glo: new GlObj,
        // useRadar: function() {
        //     var e = !1;
        //     try {
        //         e = this.useRadarInner()
        //     } catch (e) {
        //         window.wError("testWebGl", "Error testing radar", e)
        //     }
        //     return this.glo.release(),
        //         e
        // },
        // useRadarInner: function() {
        //     var e = this
        //         , t = storage.get("radarTest01")
        //         , i = window.navigator.userAgent
        //         , s = function() {
        //         var t = e.runRadarTest();
        //         return storage.put("radarTest01", {
        //             status: t,
        //             ua: i
        //         }),
        //         t === e.retOk
        //     };
        //     return t && t.ua === i ? t.status === this.retOk : s()
        // },
        // runRadarTest: function() {
        //     return this.status = "error-unspecified",
        //         this.status
        // },
        useGLparticles: function() {
            var useglp = false;
            try {
                useglp = this.useGLparticlesInner();
            } catch (e) {
                console.error("testWebGl", "useGLparticles", e);
            }
            this.glo.release();

            return useglp;

        },

        useGLparticlesInner: function() {
            var testGl = this
                , disableWebGL = store.get("disableWebGL")
                , testResult = storage.get("webGLtest3")
                , userAgent = window.navigator.userAgent
                , doTest = function() {
                                var status = testGl.runParticlesTest();
                                storage.put("webGLtest3", {
                                    status: status,
                                    ua: userAgent
                                });
                                return status === testGl.retOk
                           };


            if ("desktop" === rootScope.platform &&
                // "embed2" !== W.target &&
                ! disableWebGL ) {

                if (testResult && testResult.ua === userAgent)
                    return testResult.status === this.retOk;
                else
                    return doTest();

            } else
                return false;


            // return "desktop" === rootScope.platform &&
            //         "embed2" !== W.target &&
            //         (! disableWebGL && (testResult && testResult.ua === userAgent ? testResult.status === this.retOk : doTest()))
        },

        runParticlesTest: function() {
            this.it = {};
            this.status = "error-unspecified";

            try {
                if (this.initWebGl())
                    this.runParticlesUpdateTest();
            } catch (e) {
                console.error("testWebGl", "Unspecified error", e)
            }
            this.it = null;
            return this.status;
        },

        runParticlesUpdateTest: function() {
            var testCode = 0;
            try {
                if (this.initParticleUpdateTest()) {
                    this.status = this.startParticleUpdateTest();
                    testCode = this.status === this.retOk ? 2 : 1
                }
            } catch (e) {
                this.status = "error-in-particle-update-test"
            }
            return testCode;
        },

        compileShader: function(vertexSource, fragmentSource, defines, name, text) {
            var program;
            text = text || "testWebGl";

            try {
                program = this.glo.createProgramObj(vertexSource, fragmentSource, defines, name);
            } catch (e) {
                console.error(text, e.full);
                this.shaderErrors++;
                program = null;
            }
            return program;
        },

        initWebGl: function() {
            var gl = null;
            try {
                this.it.fragment = document.createDocumentFragment();
                var glCanvas = document.createElement("canvas");
                this.it.fragment.appendChild(glCanvas);

                gl = this.glo.create(glCanvas, {
                        antialias: false,
                        depth: false,
                        stencil: false,
                        alpha: true,
                        premultipliedAlpha: true,
                        preserveDrawingBuffer: false
                    }, "testWebGl");
                this.it.gl = gl;

                if (gl)
                    this.it.framebuffer = this.glo.createFramebuffer();
                else if (window.WebGLRenderingContext)
                    this.status = "error-no-WebGL-context";
                else
                    this.status = "error-no-WebGL-browser";

            } catch (e) {
                console.error("testWebGl", "initWebGl exception: " + e)
            }
            return gl;
        },

        startParticleUpdateTest: function() {
            this.renderParticleUpdateTest(1 / 255.5, 255, .125 / 255, -1 / 255);

            if (this.compareDataFast(this.it.data0, this.it.data1) > 1e3)
                return "no-particles-update";
            else
                return this.retOk;

        },

        initParticleUpdateTest: function() {
            var glo = this.glo
                , gl = glo.get();

            this.shaderErrors = 0;
            this.it.shEncodeDecode = this.compileShader(this.shRectVS, this.shEncodeDecodeFS, null, "EncodeDecode", "glParticlesTest");

            if (this.shaderErrors > 0) {
                this.status = "error-shader-compilation";
                return false;
            }

            this.it.vertexBufferRect = glo.createBuffer(new Float32Array([-1, -1, 1, -1, 1, 1, -1, 1]));
            this.it.w = 128;
            this.it.h = 256;
            this.it.data0 = new Uint8Array(this.it.w * this.it.h * 4);
            this.it.data1 = new Uint8Array(this.it.w * this.it.h * 4);

            var ncol, nrow, pos = 0;
            for (nrow = 0; nrow < this.it.h; nrow++)
                for (ncol = 0; ncol < this.it.w; ncol++) {
                    this.it.data0[pos++] = ncol + ncol;
                    this.it.data0[pos++] = ncol + ncol + 1;
                    this.it.data0[pos++] = nrow;
                    this.it.data0[pos++] = nrow;
                }

            this.it.texture0 = glo.createTexture2D(gl.NEAREST, gl.NEAREST, gl.REPEAT, this.it.data0, this.it.w, this.it.h);
            this.it.texture1 = glo.createTexture2D(gl.NEAREST, gl.NEAREST, gl.REPEAT, this.it.data1, this.it.w, this.it.h);

            return true;

        },

        renderParticleUpdateTest: function(x, y, z, w) {
            var glo = this.glo
                , gl = glo.get();

            glo.bindFramebuffer(this.it.framebuffer, this.it.texture1);
            gl.viewport(0, 0, this.it.w, this.it.h);

            var shader = this.it.shEncodeDecode;

            gl.useProgram(shader.program);
            glo.bindAttribute(this.it.vertexBufferRect, shader.aPos, 2, gl.FLOAT, 0, 8, 0);
            glo.bindTexture2D(this.it.texture0, 0, shader.sTex0);
            gl.uniform4f(shader.uPars, x, y, z, w);
            gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
            gl.readPixels(0, 0, this.it.w, this.it.h, gl.RGBA, gl.UNSIGNED_BYTE, this.it.data1);
            glo.bindFramebuffer(null);
        },

        compareDataFast: function(data0, data1) {
            var ndiff, i;
            for (ndiff = 0, i = 0; i < data0.length; i++) {
                if (data0[i] !== data1[i])
                    ++ndiff;
            }
            return ndiff;
        }
    }
// });
import map from "./map";
import render from "./render";
import tileLayer from "./tileLayer";
import DataTiler from "./DataTiler";
import utils from "./utils";


const TileInterpolator = DataTiler.extend({
    createFun: function(cbfun) {
        if (!this.cb)
            this.cb=[];
        this.cb.push(cbfun);
        return this.getTiles(Promise.resolve(tileLayer.latestParams));
    },
    tilesReady: function(sortedTiles, mapscope, params) {
        const tileInterpolator = this,
            interpolateValue = function(coordx, coordy) {

                let datay = (coordy + tileInterpolator.offsetY) >> tileInterpolator.shift
                    , yTile = datay >> 8
                    , intY = datay - (yTile << 8)
                    , ywtable =(coordy + tileInterpolator.offsetY) % tileInterpolator.trans
                    , datax = (coordx + tileInterpolator.offsetX) >> tileInterpolator.shift
                    , xTile = datax >> 8
                    , intX = datax - (xTile << 8)
                    , xwtable = (coordx + tileInterpolator.offsetX) % tileInterpolator.trans
                    , trans = tileInterpolator.trans
                    , dTile = sortedTiles && sortedTiles[yTile] && sortedTiles[yTile][xTile];


                if (!dTile) {
                    console.warn("interpolator: Undefined dTile");
                    return NaN;
                }

                const data = dTile.data;

                if (!data) {
                    console.warn("interpolator: Undefined dTile.data");
                    return NaN;
                }
                let pos = tileInterpolator.offset + intX + (intY << 8) + intY << 2; // offset + intY * 256 * 4 + intX
                if (params.PNGtransparency && render.testPNGtransparency(data, pos))
                    return NaN;
                if (params.JPGtransparency && render.testJPGtransparency(data, pos))
                    return NaN;
                let rTL = data[pos]
                    , rTR = data[pos + 4]
                    , gTL = data[pos + 1]
                    , gTR = data[pos + 5]
                    , bTL = data[pos + 2]
                    , bTR = data[pos + 6]
                    , rBL = data[pos += 1028]
                    , rBR = data[pos + 4]
                    , gBL = data[pos + 1]
                    , gBR = data[pos + 5]
                    , bBL = data[pos + 2]
                    , bBR = data[pos + 6]
                    , wTL = (trans - ywtable) * (trans - xwtable)
                    , wTR = (trans - ywtable) * xwtable
                    , wBL = ywtable * (trans - xwtable)
                    , wBR = xwtable * ywtable
                    , N = trans * trans
                    , rValue = (rTL * wTL + rTR * wTR + rBL * wBL + rBR * wBR) / N
                    , gValue = params.interpolateNearestG ? render.interpolateNearest(null, 0, gTL, gTR, gBL, gBR, wTL, wTR, wBL, wBR) : (gTL * wTL + gTR * wTR + gBL * wBL + gBR * wBR) / N
                    , bValue = (bTL * wTL + bTR * wTR + bBL * wBL + bBR * wBR) / N;

                return [dTile.decodeR(rValue), dTile.decodeG(gValue), dTile.decodeB(bValue)]
            };

        for (let cbfun of this.cb)
            cbfun(  //callback?
                function(t) {
                    let lat = t.lat
                        , lon = t.lon
                        // , point = map.latLngToContainerPoint([lat, lon])
                        , x1unit = utils.lonDegToXUnit(lon)
                        , y1unit = utils.latDegToYUnit(lat)
                        , ntilesW = 1 << mapscope.dZoom
                        , lastTile = ntilesW - 1
                        , x = x1unit * ntilesW
                        , y = y1unit * ntilesW
                        , x0dtile = Math.floor(x)
                        , y0dtile = Math.floor(y);
                    x-=x0dtile;
                    y-=y0dtile;

                        const getDataTile = function(x, y, dZoom) {
                            for (let i = 0; i < sortedTiles.length; i++) {
                                const tiles = sortedTiles[i];
                                if (tiles.length && tiles[0].y === y && tiles[0].z === dZoom)
                                    for (let j = 0; j < tiles.length; j++) {
                                        const tile = tiles[j];
                                        if (tile.x === x)
                                            return tile
                                    }
                            }
                        }

                        const dTile = getDataTile(x0dtile, y0dtile, mapscope.dZoom);
                        const data = dTile && dTile.data;

                        if (!data)
                            return NaN;

                        let xweight = 256 * x
                            , yweight = 256 * y
                            , intX = utils.bound(Math.floor(xweight), 0, 255)
                            , intY = utils.bound(Math.floor(yweight), 0, 255);

                        xweight -= intX;
                        yweight -= intY;

                        let pos = 4 * (tileInterpolator.offset + intX + 257 * intY);

                        if (params.PNGtransparency && render.testPNGtransparency(data, pos))
                            return NaN;
                        if (params.JPGtransparency && render.testJPGtransparency(data, pos))
                            return NaN;
                        let rTL = data[pos]
                            , rTR = data[pos + 4]
                            , gTL = data[pos + 1]
                            , gTR = data[pos + 5]
                            , bTL = data[pos + 2]
                            , bTR = data[pos + 6]
                            , rBL = data[pos += 1028]
                            , rBR = data[pos + 4]
                            , gBL = data[pos + 1]
                            , gBR = data[pos + 5]
                            , bBL = data[pos + 2]
                            , bBR = data[pos + 6]

                            , wTL = (1 - yweight) * (1 - xweight)
                            , wTR = (1 - yweight) * xweight
                            , wBL = yweight * (1 - xweight)
                            , wBR = xweight * yweight
                            // , N = trans * trans

                            , rValue = (rTL * wTL + rTR * wTR + rBL * wBL + rBR * wBR)
                            , gValue = params.interpolateNearestG ? render.interpolateNearest(null, 0, gTL, gTR, gBL, gBR, wTL, wTR, wBL, wBR) : (gTL * wTL + gTR * wTR + gBL * wBL + gBR * wBR)
                            , bValue = (bTL * wTL + bTR * wTR + bBL * wBL + bBR * wBR) ;

                        return [dTile.decodeR(rValue), dTile.decodeG(gValue), dTile.decodeB(bValue)]

                    }, interpolateValue, true);
        this.cb=[];
    }
})

export default TileInterpolator;

import overlays from "./overlays";
import store from "./store";
import products from "./products";

import trans from "./trans";
import rootScope from "./rootScope";


class MapDescriptionController {
    constructor (view){
        this.view = view;

        store.on("overlay", this.updateDesc.bind(this));
        store.on("level", this.updateDesc.bind(this));
        store.on("product", this.updateDesc.bind(this));
        store.on("usedLang", this.updateDesc.bind(this));
        store.on("radarTimestamp", this.updateDesc.bind(this));
        store.on("timestamp", this.updateDesc.bind(this));

    }

    /**
     * Actualiza el nombre del tipo de mapa, su icono asociado, modelo y nivel
     */
    updateDesc() {
        const nameoverlay = store.get("overlay");
        const overlay = overlays[nameoverlay];
        const level = store.get("level");
        const product = products[store.get("product")];
        const selectedday = store.get("warningsday");


        let subtitulo = null;
        let titulo = null;

        // Si el nivel es distinto al de superficie y el overlay soporta varios niveles se añade
        if ( level !== "surface" && overlay.hasMoreLevels )
            subtitulo = rootScope.levelsData[level][0];

        // Si el modelo es distinto de los de por defecto se añade
        if ( product.ident !== "ecmwf" && product.ident !== "cams" && product.ident !== "ecmwfWaves" && product.ident !== "ecmwfEns")
            subtitulo = subtitulo ? ( subtitulo + " " + product.modelName): product.modelName;


        if (nameoverlay === "warnings" && selectedday !== "off"){
            const today = new Date();
            const todayTs = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)

            // Obtén la fecha actual
            const date = new Date(store.get("timestamp"));
            const dateTs = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)

            const warningsdays = [ trans.WARNINGSTODAY, trans.WARNINGSTOMORROW, trans.WARNINGSAFTERTOMORROW]
            const dayIndex = Math.floor((dateTs - todayTs) / (1000 * 60 * 60 * 24));

            if (dayIndex >= 0 && dayIndex <=2)
                titulo = warningsdays[dayIndex];
            else
                titulo = overlay.getName();

            subtitulo = null;
        }
        else if ( product.ident === "ecmwfWaves" || (product.ident === "ecmwf" && overlay.ident ==="tempmar") ){
            titulo = trans['SEAMAPS'];
            subtitulo = overlay.getName();
        }
        else if(product.ident === "satellite"){
            titulo = trans['SATMAPS'];
            subtitulo = overlay.getName();
        }
        else if(product.ident === "radar"){
            titulo = overlay.getName();
            const calendar = store.get("radarCalendar");
            if (calendar && store.get("radarTimestamp") > calendar.getCurrentTs()){
                subtitulo = trans['FORECAST'];
            }
        }
        else
            titulo = overlay.getName();

        let overlayicon = null;

        // Path del icono
        if (overlay.icon){
            overlayicon = (rootScope.resourcesServer ? rootScope.resourcesServer  : "")  + "css/svgs/" + overlay.icon
        }

        this.view.updateDesc(titulo, subtitulo, overlayicon);

    }

}

export default MapDescriptionController;

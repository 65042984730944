// W.define("TagPlugin", ["Plugin", "Window", "trans"], function(e, t, n) {

import Plugin from "./Plugin";
import broadcast from "./broadcast";
// import trans from "./trans";

const TagPlugin = Plugin.extend( {
        iAm: "plugin",
        unmountOnClose: false,
        exclusive: null,
        hasURL: true,
        _init: function() {
            // t._init.call(this),
            this.isLoaded = false;
            this.loading = false;
            this.isMounted = false;
            this.cssInserted = false;
            this.bodyClass = "on" + this.ident;
        },
        _unmount: function() {
            if (this.unmountOnClose) {
                this.node.parentNode.removeChild(this.node);
                this.isMounted = false;
            }
            else
                this.node.style.display = "none"
        },
        open: function(params) {
            const plugin = this;

            // if (this.closingTimer)
            //     clearTimeout(this.closingTimer);

            if (this.isLoaded){
                // if (! this.isMounted)
                //     this._mount();
                this._open(params);
                return Promise.resolve();
            }else{
                if (! this.loading )
                    this.load(params).then(
                        function(object) {
                            if(!plugin.isMounted)
                                plugin._mount(object);
                            plugin._open(params);
                        });
                return this.promise;
            }
        },
        _open: function(e) {
            const plugin = this;
            if ( ! this.isOpen ) {
                document.body.classList.add(this.bodyClass);
                // if(this.displayBlock)
                //     this.node.style.display = "block";
                // this.addHooks();
                setTimeout(function() {
                    // plugin.node.classList.add("open");
                    broadcast.emit("pluginOpened", plugin.ident);
                }, 50);
                this.isOpen = true;
                if ( this.timeout )
                    this.timeoutTimer = setTimeout(this.close.bind(this), this.timeout);
            }

            this.onopen(e);
            if(this.hasURL)
                this.url(e);
        },
        onopen: function() {},


        close: function(e){
            if(this.isOpen)
                this.onclose(e);
        },

        onclose: function() {},

        _mount: function(objectplugin) {
            const plugin = this;

            Object.keys(objectplugin).forEach(
                (key) =>{
                    plugin[key]=objectplugin[key];
                }
            )

            this.isMounted = true;
        //     const e = J.tags[this.ident];
        //
        //     if (e) {
        //         if(e.css && !this.cssInserted){
        //             this.cssInserted = true;
        //             document.head.insertAdjacentHTML("beforeend", "<style>" + e.css + "</style>");
        //         }
        //
        //         this.createNode(e.html);
        //         trans.translateDocument(this.node);
        //
        //         this.attachRefs();
        //
        //         if (e.callback && "function" == typeof e.callback)
        //             e.callback.call(this);
        //         this.isMounted = true;
        //     } else
        //         console.error("plugin", "Error, tag: " + this.ident + " was not registered")
        }
    });

export default TagPlugin;

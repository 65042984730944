import store from "./store";


class CaptureButtonController{
    constructor(view){
        this.view = view;
        this.view.bindGetFormattedDate(this.getFormattedDate.bind(this));
    }

    getFormattedDate(){
        const product = store.get("product");
        const timestamp = product === "radar" ? store.get("radarTimestamp") :
            product === "satellite" ? store.get("satelliteTimestamp") : store.get("timestamp");

        let formattedDate = null;

        if (timestamp) {
            const date = new Date(timestamp);
            const options = {
                weekday: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            };
            let lang = store.get("usedLang");
            lang = (lang === "auto") ? "en_US" : lang;
            console.info(lang)

            formattedDate = date.toLocaleDateString(lang.replace('_', '-'), options).replace(/^\w/, c => c.toUpperCase());
        }

        return formattedDate;
    }
}

export default CaptureButtonController;

import render from "./render";
import WindyMap from "./WindyMap";
import scope from "./rootScope";
import utils from "./utils";
import store from "./store";
import broadcast from "./broadcast";
import basemaps from "./baseMapsInstances";
import overlays from "./overlays";
import products from "./products";
import router from "./router";
import geolocation from "./geolocation";
import $ from "./$";



L.GridLayer.prototype.options.zIndex = void 0;

var map, d = 0, basemapname, u;

// u =  router.sharedCoords;
u = "location" === store.get("startUp") ? store.get("homeLocation") : router.sharedCoords;
if ( ! u )
    u = geolocation.getMyLatestPos();
//
// if("fallback" === u.source) {
    broadcast.on("newLocation", function (e) {
        if ( ! e.zoom )
            e.zoom = 5;
        map.center(e, 1);
    });
// }
// var h = ["vn", "in"].includes(store.get("country"))
//     , m = h ? 11 : 18;

map = new WindyMap("map-container",{
    zoomControl: false,
    keyboard: false,
    worldCopyJump: true,
    zoomAnimationThreshold: 3,
    fadeAnimation: false,
    center: [+u.lat, +u.lon],
    zoom: +u.zoom || 5,
    // center: [0, 0],
    // zoom: 3,
    minZoom: 3,
    maxZoom: 11,
    maxBounds: [[-90, -720], [90, 720]],
    attributionControl:false,
});


let marker = null;

function changeLocationMarker(coords){
    if(marker){
        map.removeLayer(marker);
    }
    const markers = map.myMarkers;
    marker = L.marker([coords.lat, coords.lon], {icon: markers.myLocationIcon}).addTo(map);
}

if ( store.get("locationMarker") ) {
    // Si hay definidas unas coordenadas de marcador se usan esas y sino las del centro del mapa
    let markerCoords = store.get("locationMarkerCoords");
    if( ! markerCoords )
        markerCoords = u;

    changeLocationMarker(markerCoords);
}



changeScopeMap();
// changeBaseMap(),
// p(store.get("graticule")),
map.on("moveend", changeScopeMap);
map.on("movestart", broadcast.emit.bind(broadcast, "movestart"));
broadcast.on("zoomIn", map.zoomIn, map);
broadcast.on("zoomOut", map.zoomOut, map);
broadcast.on("updateBasemap", changeBaseMap);
broadcast.on("updateMarkerLocation", changeLocationMarker);
store.on("map", setMap);

render.on("updateBasemap", changeBaseMap);
// store.on("graticule", p),
render.on("toggleSeaMask", function(hasSea) {


    // if (hasSea && !map.seaLayer ) {
    //         map.seaLayer = L.tileLayer(scope.tileServer + "/tiles/v9.0/grayland/{z}/{x}/{y}.png", {
    //         minZoom: 3,
    //         maxZoom: 11,
    //         updateWhenIdle: !!scope.isMobileOrTablet,
    //         updateWhenZooming: !1,
    //         keepBuffer: scope.isMobileOrTablet ? 1 : 4
    //     }).addTo(map),
    //         map.seaLayer.getContainer().classList.add("sea-mask-layer")
    // } else if ( !hasSea && map.seaLayer) {
    //     map.removeLayer(map.seaLayer);
    //     map.seaLayer = null;
    // }

});
// map.on("contextmenu", broadcast.emit.bind(broadcast, "rqstOpen", "contextmenu"));

var f = null;

// function p(e) {
//     e && !f && t.graticule.load().then(function() {
//         f = (new L.LatLngGraticule).addTo(map)
//     }),
//     !e && f && (map.removeLayer(f),
//         f = null)
// }

function changeScopeMap() {
    var center = map.getCenter()
        , bounds = map.getBounds()
        , size = map.getSize()
        , zoom = Math.round(map.getZoom());

    scope.map = {
        source: "maps",
        lat: center.lat,
        lng: center.wrap().lng,
        south: bounds._southWest.lat,
        north: bounds._northEast.lat,
        east: bounds._northEast.lng,
        west: bounds._southWest.lng,
        width: size.x,
        height: size.y,
        zoom: zoom
    };
    if( zoom !== d )
    {
        utils.replaceClass(/zoom\d+/, "zoom" + zoom, $('#visor2') || document.body);
        d = zoom;
    }
    broadcast.emit("mapChanged", scope.map)
}


var hasSea = null;
var wholeMap = null;
var insideBounds = null;


function setMap(){
    changeBaseMap({hasSea: hasSea, coverWholeMap: wholeMap, insideBounds: insideBounds});
}

function changeBaseMap(params) {
    // var basemaps = {
    //     graymap: scope.tileServer + "/tiles/v9.0/darkmap" + (scope.isRetina ? "-retina" : "") + "/{z}/{x}/{y}.png",
    //     sznmap: "https://windytiles.mapy.cz/turist-en/{z}-{x}-{y}",
    //     winter: "https://mapserver.mapy.cz/winter-m/{z}-{x}-{y}",
    //     satLocal: scope.tileServer + "/tiles/orto/v1.0/{z}/{z}-{x}-{y}.jpg",
    //     sat: "https://{s}.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?" + scope.hereMapsID
    // }
    // var basemaps = {
    //     foreground: scope.tileServer + '/teselas/foreground/{z}/{x}/{y}.png',
    //     light: scope.tileServer + '/teselas/light/{z}/{x}/{y}.png'
    // }
    // // , t = store.get("map")
    // , baselayer = null;
    // if(basemapname)
    //     console.info("mapa: " + basemaps[basemapname].url);

    hasSea = params.hasSea;
    wholeMap = params.coverWholeMap;
    insideBounds = params.insideBounds;

    var container;

    var baselayer = null, baselayer0 = null, basemap=store.get("map");
    var overlay=overlays[store.get("overlay")];
    // console.info(overlay.getName());
    // console.info(store.get("map"));
    // console.info(store.get("product"));
    var product = products[store.get("product")];

    const containerElement=$('#visor2') || document.body;

    if( basemap !== basemapname )
    {
        utils.replaceClass(/basemap-\S+/, "basemap-" + basemap, containerElement);
        basemapname = basemap;
    }
    utils.toggleClass(containerElement, wholeMap, "wholemap");

    // if(basemapname)
    //     basemap = basemapname;


    // baselayer = "map" !== store.get("overlay") || h ? "sat" === t ? {
    //     11: {
    //         url: basemaps.graymap
    //     },
    //     13: {
    //         url: basemaps.satLocal
    //     },
    //     18: {
    //         url: basemaps.sat,
    //         subdomains: "1234"
    //     }
    // } : {
    //     11: {
    //         url: basemaps.graymap
    //     },
    //     18: {
    //         url: basemaps[t] || basemaps.sznmap
    //     }
    // } : "sat" === t ? {
    //     13: {
    //         url: basemaps.satLocal
    //     },
    //     18: {
    //         url: basemaps.sat,
    //         subdomains: "1234"
    //     }
    // } : {
    //     18: {
    //         url: basemaps[t] || basemaps.sznmap
    //     }
    // },

    // baselayer0 = {
    //     // baselayer = "map" !== store.get("overlay") || h ? "sat" === t ? {
    //     11: {
    //         url: "https://services.meteored.com/img/background/white/{z}/{x}/{y}.png"
    //         // url: 'https://services.meteored.com/img/foreground/white/{z}/{x}/{y}.png'
    //         // url: 'https://tiles.windy.com/tiles/v10.0/darkmap/{z}/{x}/{y}.png'
    //
    //     }
    // };

    let basemapurl=basemaps[basemap].url;

    if (hasSea && basemaps[basemap].noseaurl)
        basemapurl=basemaps[basemap].noseaurl;



    baselayer0 = {
        // baselayer = "map" !== store.get("overlay") || h ? "sat" === t ? {
        11: {
            // url: "https://services.meteored.com/img/background/white/{z}/{x}/{y}.png"
            // url: 'https://services.meteored.com/img/foreground/white/{z}/{x}/{y}.png'
            // url: 'http://localhost/imagenes.meteored.com/foreground/{z}/{x}/{y}.png'
            //url: 'http://groenlandia.ogimet.com/teselas/foreground/{z}/{x}/{y}.png'
            url: basemapurl
            // url: 'http://groenlandia.ogimet.com:8080/styles/foreground-style/{z}/{x}/{y}.png'
            // url: 'https://tiles.windy.com/tiles/v10.0/darkmap/{z}/{x}/{y}.png'

        }
    };

    baselayer = {
        11: {
            url: scope.tileServer + '/img/foreground/black/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png'
            //  url: scope.tileServer + '/teselas/foreground/{z}/{x}/{y}.png'

        }
    };

    // L.tileLayer("https://services.meteored.com/img/background/white/{z}/{x}/{y}.png", {
    //     detectRetina: 0,
    //     minZoom: 3,
    //     maxZoom: 11,
    //     updateWhenIdle: true,
    //     updateWhenZooming: true,
    //     className: "basemap0-layer",
    //     keepBuffer: scope.isMobileOrTablet ? 1 : 4
    // }).addTo(map);

    map.baseLayer0 && map.removeLayer(map.baseLayer0);
    map.baseLayer && map.removeLayer(map.baseLayer);

    if (hasSea){
        // Teselas foreground marítimas
        map.baseLayer0 = L.tileLayerMulti(baselayer0, {
            detectRetina: 0,
            minZoom: 3,
            maxZoom: 11,
            updateWhenIdle: scope.isMobileOrTablet,
            updateWhenZooming: false,
            className: "sea-layer",
            keepBuffer: scope.isMobileOrTablet ? 1 : 4
        });

        // Color de fondo del mar
        container = map.getContainer();
        container.style.backgroundColor = basemaps[basemap].bgcolor;

        map.baseLayer0.addTo(map);
        map.activeLayer = map.baseLayer0;

        if (basemap === "land") {
            map.baseLayer = L.tileLayerMulti(baselayer,
                {
                    detectRetina: 0,
                    minZoom: 3,
                    maxZoom: 11,
                    updateWhenIdle: scope.isMobileOrTablet,
                    updateWhenZooming: false,
                    className: "sea-borders-layer",
                    keepBuffer: scope.isMobileOrTablet ? 1 : 4
                });

            map.baseLayer.addTo(map);
        }

        // tileLayer.setUrl(null);
        broadcast.emit("urlFgChanged", null);

    }
    else {
        // Capa de continentes debajo de la variable
        map.baseLayer0 = L.tileLayerMulti(baselayer0, {
            detectRetina: 0,
            minZoom: 3,
            maxZoom: 11,
            updateWhenIdle: scope.isMobileOrTablet,
            updateWhenZooming: false,
            className: "basemap0-layer",
            keepBuffer: scope.isMobileOrTablet ? 1 : 4
        });
        // Color de fondo del mar
        container = map.getContainer();
        container.style.backgroundColor = basemaps[basemap].bgcolor;

        // map.baseLayer0.addTo(map);
        if ( ! wholeMap && basemap !== "land") {
            // document.body.dataset.map = t,
            // tileLayer.setUrl(scope.tileServer + '/img/foreground/gray/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png', true)
            broadcast.emit("urlFgChanged", scope.tileServer + '/img/foreground/gray/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png');

            map.baseLayer0.addTo(map);
            map.activeLayer = map.baseLayer0;
        } else{
            if ( ! product.bounds || insideBounds ||  basemap === "land"){

                map.baseLayer = L.tileLayerMulti(baselayer ,
                    {
                        detectRetina: 0,
                        minZoom: 3,
                        maxZoom: 11,
                        updateWhenIdle: scope.isMobileOrTablet,
                        updateWhenZooming: false,
                        className: "basemap-layer",
                        keepBuffer: scope.isMobileOrTablet ? 1 : 4
                    });


                if (basemap === "land" && (! wholeMap || product.bounds))
                    map.baseLayer0.addTo(map);

                map.baseLayer.addTo(map);
                map.activeLayer = map.baseLayer;

                broadcast.emit("urlFgChanged", null);
                // tileLayer.setUrl(null);
            }
            else {
                // tileLayer.setUrl(scope.tileServer + '/img/foreground/black/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png', true);
                broadcast.emit("urlFgChanged", scope.tileServer + '/img/foreground/black/{z}/{x}/{y}'+(scope.isRetina ? "@2x" : "")+'.png', true);
                map.baseLayer0.addTo(map);
                map.activeLayer = map.baseLayer0;
            }
        }
    }
    // // Teselas foreground marítimas
    // map.baseLayer && map.removeLayer(map.baseLayer);
    // map.baseLayer = L.tileLayerMulti(baselayer, {
    //     detectRetina: 0,
    //     minZoom: 3,
    //     maxZoom: 11,
    //     updateWhenIdle: !!scope.isMobileOrTablet,
    //     updateWhenZooming: false,
    //     className: "basemap-layer",
    //     keepBuffer: scope.isMobileOrTablet ? 1 : 4
    // });
    // // map.baseLayer.addTo(map)
    broadcast.emit ("baseMapChanged", wholeMap ? "dark" : basemap);

}

// function changeOverlay( overlayname ){
//     var overlay=overlays[overlayname];
//
//     // if ( ! overlay.getCoverWholeMap())
//     changeBaseMap(overlay.preferredMap);
//
//     // console.info(overlay.getName());
//     // console.info(overlay.getCoverWholeMap());
//
// }
// store.on("overlay", changeBaseMap);
export default map;

import utils from "./utils";


function loadLazyImages(htmlElement) {
    const lazyImages = htmlElement.querySelectorAll(".lazy-image");
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {

                const img = entry.target;
                // console.info("observe: " + img.dataset["src"])
                if (img.dataset["src"]) {
                    img.src = img.dataset["src"];
                }
                observer.unobserve(img);
            }
        });
    });

    if (lazyImages)
        lazyImages.forEach(img => {
            // console.info(img.dataset["src"]);
            observer.observe(img)
        });
}



export default () => {
    return utils.debounce(loadLazyImages, 300);
    // return loadLazyImages;
}

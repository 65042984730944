import Class from "./Class";
import trans from "./trans";

export default Class.extend({
    ident: "",
    url: "",
    bgcolor: "#ddd",
    trans: "",

    getName: function() {
        return trans[this.trans] || this.ident
    }
})

// import basemaps from "./baseMapsInstances";
import store from "./store";
import rootScope from "./rootScope";
import trans from "./trans";
import products from "./products";
import overlay from "./Overlay";
import overlays from "./overlays";


class PoisSwitchController{
    constructor(view) {
        this.view = view;

        this.addElements();

        this.view.bindSelect(this.select.bind(this))

        // store.on("usedLang", this.updateMaps.bind(this));
        store.on("usedLang", this.updatePois.bind(this));
        store.on("product", this.updatePois.bind(this));
        store.on("overlay", this.updatePois.bind(this));
        store.on("pois", this.view.updateSelectedOption.bind(this.view));

    }

    updatePois(){
        this.view.remove();
        this.addElements();
    }

    addElements (){

        const poisselected = store.get ("pois");
        const pois = rootScope.pois;
        const product = products[store.get("product")];
        const overlay = overlays[store.get("overlay")];

        this.view.update(
            Object.keys(pois).map(
                function(poisname){

                    const disabled = (!product.symbols || !overlay.symbols) && poisname==="symbols";


                    return {
                        text: trans[pois[poisname][0]],
                        value: poisname,
                        class: poisname,
                        selected: poisselected === poisname,
                        disabled: disabled
                    };
                }
            )
        );

        // this.value = store.get ("map");
    }

    select (value){
        store.set("pois", value);
    }
}

export default PoisSwitchController;

import search from "./search";
import geolocation from "./geolocation";
import broadcast from "./broadcast";
import store from "./store";
import OverlaysMenuView from "../ui/OverlaysMenuView";
import rootScope from "./rootScope";
import utils from "./utils";


class SearchWindowController {

    constructor(view){
        this.view = view;
        this.view.bindInputText(this.search.bind(this));
        this.view.bindCenterCoords(this.centerCoords.bind(this));
        this.view.bindNotify(this.notify.bind(this));
        this.view.bindGetServer(()=>{return rootScope.resourcesServer});
        this.view.bindSearchLocation(this.searchLocation.bind(this));
        this.view.bindSearchCountry(()=>{search.searchCountry()});

        search.on("foundresults", this.updateResults.bind(this));

        // search.searchCountry();

    }

    searchLocation() {
        // const coords = geolocation.getGPSlocation();
        geolocation.getGPSlocation().then((coords) => {
            if (coords.source === "fallback")
                search.searchCountry();
            else
                search.searchLocation(coords);}
        );

    }

    search(text){
        search.search(text);
    }

    centerCoords(info){

        // const container = document.querySelector("#map-container");
        // const containerHeight = container.offsetHeight;
        let newLat = info.coords.lat;
        const zoomLocation = 8;

        if (rootScope.isMobile) {
            const mapHeight = 256 << zoomLocation;

            const distance = 370 / 2
            newLat = utils.unitYToLatDeg(utils.latDegToYUnit(info.coords.lat) + (distance) / (mapHeight));
        }
        // console.info(utils.latDegToYUnit(info.coords.lat) + (containerHeight - 370) / (mapHeight * 2 ))
        // // const detailWindow = document.querySelector("#locationfcst");
        // // const detailWindowHeight = detailWindow.offsetHeight;
        // //
        // console.info(containerHeight - 370)
        // console.info((containerHeight - 370) / (mapHeight * 2 ))
        // console.info(rootScope.isRetina)
        // console.info(utils.unitYToLatDeg(containerHeight - 370))



        geolocation.setIpLocation(newLat, info.coords.lon, zoomLocation);
        // broadcast.emit("rqstOpen", "picker", info.coords);
        broadcast.emit("rqstOpen", "detail", info);
    }

    updateResults(results){
        this.view.update(
            results.map(
                (result)=>{
                    return {
                        title: result.name,
                        subtitle: result.description,
                        flag: result.pais.bandera,
                        coords: result.coordenadas,
                        uid: result.uid,
                        url: result.url
                    };
                }
            )
        )

    }

    /**
     * Método que notifica la apertura o cierre de la ventana de busqueda.
     * @param {boolean} open - Estado de apertura de la ventana.
     * @private
     */
    notify(open){
        // Lanza eventos según el estado de apertura de la ventana.
        if (open){
            const event = new Event('overlayMenuOpened');
            document.dispatchEvent(event);
        }else{
            const event = new Event('overlayMenuClosed');
            document.dispatchEvent(event);
        }

        // Almacena el estado de apertura de la ventana.
        store.set("openMenus", open);
    }


}

export default SearchWindowController;

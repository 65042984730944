import Metric from "./Metric";
import trans from "./trans";

// define("metrics", ["Metric", "trans"], function(Metric, trans) {
    var precision,
        defaultConversion = function(value) {
            return value;
        },
        to100PercentConversion = {
            "%": {
                conversion: function(e) {
                    return Math.round(100 * e)
                },
                precision: 0
            }
        },
        getNearestIndexFun = function(value){
            const arrayIndex = Object.keys(this.aqi2text).map((key) => {return parseInt(key);})
            if (value < arrayIndex[0])
                return arrayIndex[0];

            let i=1;
            while (value > arrayIndex[i]  && i < arrayIndex.length)
                i++;

            if (i >= arrayIndex.length)
                return arrayIndex[arrayIndex.length - 1 ];
            else{
                const intervalue = (arrayIndex[i] + arrayIndex[i - 1]) / 2;
                return value < intervalue ? arrayIndex[i - 1] : arrayIndex[i];
            }
        };

    export default {
        temp: Metric.instance({
            ident: "temp",
            separator: "",
            defaults: ["°C", "°F"],
            conv: (precision = 0,
                {
                    "°C": {
                        conversion: function(e) {
                            return e;// - 273.15
                        },
                        precision: precision
                    },
                    "°F": {
                        conversion: function(e) {
                            return 9 * e / 5 + 32
                        },
                        precision: precision
                    }
                }),
            description: ["°C", "°F"],
            appCodes: ['c', 'f'],
            lines: [
                [-40, -40, -40],
                [-30, -30, -20],
                [-20, -20, -5],
                [-10, -10, 15],
                [0, 0, 30],
                [10, 10, 50],
                [20, 20, 70],
                [30, 30, 85],
                [40, 40, 100],
                [50, 50, 125]
            ]
            // lines: [[ -20, -5], [-10, 15], [ 0, 30], [10, 50], [20, 70], [30, 85], [40, 100]]
        }),
        wind: Metric.instance({
            ident: "wind",
            defaults: ["km/h"],
            conv: {
                kt: {
                    conversion: function(e) {
                        return 0.539957 * e
                    },
                    precision: 0
                },
                bft: {
                    conversion: function(e) {
                        e = e / 3.6;
                        return e < .3 ? 0 : e < 1.5 ? 1 : e < 3.3 ? 2 : e < 5.5 ? 3 : e < 8 ? 4 : e < 10.8 ? 5 : e < 13.9 ? 6 : e < 17.2 ? 7 : e < 20.7 ? 8 : e < 24.5 ? 9 : e < 28.4 ? 10 : e < 32.6 ? 11 : 12
                    },
                    precision: 0
                },
                // "m/s": {
                //     conversion: defaultConversion,
                //     precision: 0
                // },
                // "km/h": {
                //     conversion: function(e) {
                //         return 3.6 * e
                //     },
                //     precision: 0
                // },
                "m/s": {
                    conversion: function(e) {
                        return e / 3.6
                    },
                    precision: 0
                },
                "km/h": {
                    conversion: defaultConversion,
                    precision: 0
                },
                mph: {
                    conversion: function(e) {
                        return 0.621372 * e
                    },
                    precision: 0
                }
            },
            // description: ["km/h", "kt", "bft", "m/s", "mph"],
            // lines: [[0,  0, 0, 0, 0, 0],
            //         [10, 3, 5, 2, 3, 6],
            //         [20, 5, 10, 3, 5, 10],
            //         [35, 10, 20, 5, 10, 20],
            //         [55, 15, 30, 7, 15, 35],
            //         [70, 20, 40, 8, 20, 45],
            //         [100, 30, 60, 11, 30, 70]]

            description: ["kt", "bft", "m/s", "mph", "km/h"],
            appCodes: ['n', 'b', 's', 'm', 'k'],
            // lines: [[0, 0, 0, 0, 0, 0],
            //         [10, 5, 2, 3, 6, 10],
            //         [20, 10, 3, 5, 10, 20],
            //         [35, 20, 5, 10, 20, 35],
            //         [55, 30, 7, 15, 35, 55],
            //         [70, 40, 8, 20, 45, 70],
            //         [100, 60, 11, 30, 70, 100]]


            lines:[[0, 0, 0, 0, 0, 0],
            [10, 5, 2, 3, 6, 10],
            [20, 11, 3, 6, 12, 20],
            [30, 16, 4, 8, 19, 30],
            [40, 22, 5, 11, 25, 40],
            [50, 27, 6, 14, 31, 50],
            [60, 32, 7, 17, 37, 60],
            [70, 38, 8, 19, 43, 70],
            [80, 43, 9, 22, 50, 80],
            [100, 54, 10, 28, 62, 100],
            [120, 65, 11, 33, 75, 120],
            [140, 76, 13, 39, 87, 140]]


        }),
        rh: Metric.instance({
            ident: "rh",
            defaults: ["%"],
            conv: {
                "%": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["%"],
            lines: [[0, 0], [10, 10], [20, 20], [30, 30], [40, 40], [45, 45], [55, 55], [60, 60], [70, 70], [80, 80], [90, 90]]
        }),

        waterprec: Metric.instance({
            ident: "waterprec",
            defaults: ["mm", "in"],
            conv: {
                in: {
                    conversion: function(e) {
                        return .039 * e
                    },
                    precision: 0
                },
                mm: {
                    conversion: defaultConversion,
                    precision: 0
                }

            },
            description: ["mm","in"],
            appCodes: ['m', 'i'],

            lines: [[0, 0], [10, 10], [20, 20], [30, 30], [40, 40], [50, 50], [70, 70]]
        }),
        ivt: Metric.instance({
            ident: "ivt",
            defaults: ["kg/(m s)"],
            conv: {
                "kg/(m s)": {
                    conversion: defaultConversion,
                    precision: 0
                }

            },
            description: ["kg/(m s)"],
            appCodes: ["kg/(m s)"],

            lines: [[0, 0], [300, 300], [600, 600], [1000, 1000], [1600, 1600]]
        }),
        probprec: Metric.instance({
            ident: "probprec",
            defaults: ["%"],
            conv: {
                "%": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["%"],
            lines: [[15, 15], [20, 20], [25, 25], [30, 30], [35, 35], [40, 40], [45, 45], [50, 50], [55, 55], [60, 60], [65, 65], [70, 70], [75, 75], [80, 80], [85, 85], [90, 90], [95, 95]]
        }),

        clouds: Metric.instance({
            ident: "clouds",
            defaults: ["%"],
            conv: {
                rules: {
                    conversion: defaultConversion,
                    precision: 0
                },
                "%": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["rules", "%"],
            lines: [[0, "FEW", 0], [50, "SCT", 50],[60, "", 60], [70, "BKN", 70] ,[80, "", 80],[ 90, "", 90] , [100, "OVC", 100]]
        }),
        pressure: Metric.instance({
            ident: "pressure",
            defaults: ["hPa", "inHg", "mmHg"],
            conv: {
                hPa: {
                    conversion: function(e) {
                        return e / 100
                    },
                    precision: 0
                },
                kPa: {
                    conversion: function(e) {
                        return e / 1000
                    },
                    precision: 0
                },
                mb: {
                    conversion: function(e) {
                        return e / 100
                    },
                    precision: 0
                },
                mmHg: {
                    conversion: function(e) {
                        return e / 133.322387415
                    },
                    precision: 0
                },
                inHg: {
                    conversion: function(e) {
                        return e / 3386.389
                    },
                    precision: 2
                }
            },
            description: ["hPa", "mb",  "kPa", "inHg", "mmHg"],
            appCodes: ['h', 'm', 'k', 'i', 't']
            // lines: [[99e3, 990, 29.2, 742], [1e5, 1e3, 29.6, 750], [101e3, 1010, 29.8, 757], [102e3, 1020, 30.1, 765], [103e3, 1030, 30.4, 772]]
        }),
        rain: Metric.instance({
            ident: "rain",
            defaults: ["mm", "in"],
            conv: {
                in: {
                    conversion: function(e) {
                        return .039 * e
                    },
                    precision: 2
                },
                "l/m²":{
                    conversion: defaultConversion,
                    precision: 1

                },
                mm: {
                    conversion: defaultConversion,
                    precision: 1
                }

            },
            description: ["mm", "l/m²" ,"in"],
            appCodes: ['m', 'l', 'i'],
            // lines: [[1.5, 1.5, ".06"], [2, 2, ".08"], [3, 3, ".11"], [7, 7, ".24"], [10, 10, ".39"], [20, 20, ".78"], [30, 30, 1.2]]

            lines: [[ .2,   ".2",  ".2",  ".01"],
                    [  1,      1,     1,  ".04"],
                    [  3,      3,     3,  ".1"],
                    [  5,      5,     5,  ".2"],
                    [ 10,     10,    10,  ".4"],
                    [ 20,     20,    20,  ".8"],
                    [ 30,     30,    30,  1.2],
                    [ 40,     40,    40,  1.6],
                    [ 50,     50,    50,   2],
                    [ 70,     70,    70,  2.8],
                    [100,    100,   100,  3.9],
                    [150,    150,   150,  4.7]

            ]
        }),
        rainAccu: Metric.instance({
            ident: "rainAccu",
            defaults: ["mm", "in"],
            conv: {
                in: {
                    conversion: function(e) {
                        return .039 * e
                    },
                    precision: 2
                },
                "l/m²":{
                    conversion: defaultConversion,
                    precision: 1

                },
                mm: {
                    conversion: defaultConversion,
                    precision: 1
                }
            },
            description: ["mm", "l/m²", "in"],
            appCodes: ['m', 'l', 'i'],
            // lines: [[1.5, 1.5, ".06"], [2, 2, ".08"], [3, 3, ".11"], [7, 7, ".24"], [10, 10, ".39"], [20, 20, ".78"], [30, 30, 1.2]]
            lines: [
                [1,     1,    1,  ".04"],
                [3,     3,    3,  ".1"],
                [5,     5,    5,  ".2"],
                [10,   10,   10,   ".4"],
                [20,   20,   20,   ".8"],
                [30,   30,   30,   1.2],
                [40,   40,   40,   1.6],
                [50,   50,   50,    2],
                [150, 150,  150,   5.9],
                [300, 300,  300,   11.8]

            ]
        }),
        snowAccu: Metric.instance({
            ident: "snowAccu",
            defaults: ["cm", "in"],
            conv: {
                in: {
                    conversion: function(e) {
                        return .039 * e
                    },
                    precision: 2
                },
                cm: {
                    conversion: defaultConversion,
                    precision: 1
                }
            },
            description: ["cm", "in"],
            appCodes: ['m', 'f'],
            // lines: [[1.5, 1.5, ".06"], [2, 2, ".08"], [3, 3, ".11"], [7, 7, ".24"], [10, 10, ".39"], [20, 20, ".78"], [30, 30, 1.2]]
            lines: [
                [1, 1, ".04"],
                [3, 3, ".1"],
                [5, 5, ".2"],
                [10, 10, ".4"],
                [20, 20, ".8"],
                [30, 30, 1.2],
                [40, 40, 1.6],
                [50, 50, 2],
                // [60, 60, 2.4],
                // [70, 70, 2.8],
                // [100, 100, 3.9],
                [150, 150, 5.9],
                // [200, 200, 4.7],
                [300, 300, 11.8]

            ]
        }),
        cape: Metric.instance({
            ident: "cape",
            defaults: ["J/kg"],
            conv: {
                "J/kg": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["J/kg"],
            lines: [[0, 0], [500, 500], [1500, 1500], [2500, 2500], [5e3, 5e3]]
        }),
        gtco3: Metric.instance({
            ident: "gtco3",
            defaults: ["DU"],
            conv: {
                DU: {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["DU"],
            lines: [[150, 150], [220, 220], [280, 280], [330, 330], [400, 400]]
        }),
        aod550: Metric.instance({
            ident: "aod550",
            defaults: ["AOD"],
            conv: {
                AOD: {
                    conversion: defaultConversion,
                    precision: 3
                }
            },
            description: ["AOD"],
            lines: [[0, 0], [.2, .2], [.4, .4],  [.7, .7], [1, 1], [2, 2], [3, 3], [4.5, 4.5]]
        }),
        pm2p5: Metric.instance({
            ident: "pm2p5",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["µg/m³"],
            lines: [[0, 0], [4, 4], [8, 8], [12, 12], [16, 16], [20, 20], [25, 25], [30, 30], [40, 40], [50, 50], [60, 60], [70, 70], [220, 220], [510, 510], [800, 800]]
        }),
        pm10: Metric.instance({
            ident: "pm10",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["µg/m³"],
            lines: [[0, 0], [10, 10], [20, 20], [30, 30], [40, 40], [50, 50], [60, 60], [70, 70], [80, 80], [90, 90], [200, 200], [500, 500], [1300, 1300]]
        }),
        no2: Metric.instance({
            ident: "no2",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 2
                }
            },
            description: ["µg/m³"],
            lines: [[2, 2], [5, 5], [10, 10], [15, 15], [20, 20], [30, 30], [40, 40], [50, 50], [75, 75], [100, 100], [125, 125], [150, 150], [175, 175], [200, 200], [250, 250], [300, 300]]
        }),
        o3: Metric.instance({
            ident: "o3",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["µg/m³"],
            lines: [[0, 0], [20, 20], [30, 30], [40, 40], [50, 50], [60, 60], [70, 70], [80, 80],  [100, 100], [140, 140], [180, 180], [220, 220], [305, 305], [500, 500]]
        }),
        co: Metric.instance({
            ident: "co",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["µg/m³"],
            lines: [[0,0], [100,100], [200,200], [300,300], [400,400], [500,500], [700,700], [900,900], [2000,2000]]
        }),
        pollen: Metric.instance({
            ident: "pollen",
            defaults: ["#/m³"],
            conv: {
                "#/m³": {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["#/m³"],
            lines: [[0, 0], [2, 2], [5, 5], [10, 10], [15, 15], [20, 20], [30, 30], [40, 40], [50, 50], [70, 70], [90, 90], [200, 200], [5000, 5000]]
        }),
        altitude: Metric.instance({
            ident: "altitude",
            defaults: ["m", "ft"],
            conv: {
                m: {
                    conversion: function(e) {
                        return 100 * Math.round(e / 100)
                    },
                    precision: 0
                },
                ft: {
                    conversion: function(e) {
                        return 100 * Math.round(.0328 * e)
                    },
                    precision: 0
                }
            },
            description: ["m", "ft"],
            appCodes: ['m', 'f'],
            lines: [[0, 0, 0], [100, 100, 300], [400, 400, 1300], [1000, 1000, 3000], [1500, 1500, 5e3], [5e3, "5000", "FL150"], [9e3, "9k", "FL300"]]
        }),
        snow: Metric.instance({
            ident: "snow",
            defaults: ["cm", "in"],
            conv: {
                in: {
                    conversion: function(e) {
                        return .39 * e
                    },
                    precision: 1
                },
                cm: {
                    conversion: defaultConversion,
                    precision: 1
                }
            },
            description: ["cm", "in"],
            appCodes: ['m', 'f'],
            // lines: [[2, 2, ".8"], [5, 5, 2], [10, 10, 4], [50, 50, 20], [100, "1m", "3ft"], [300, "3m", "9ft"]]
            lines: [[.2, ".2", ".1"],
                [1, 1, ".4"],
                [3, 3, "1"],
                [5, 5, "2"],
                [10, 10, "4"],
                [20, 20, "8"],
                [30, 30, 12],
                [40, 40, 16],
                [50, 50, 20],
                [70, 70, 28],
                // [60, 600, 2.4],
                [100, 100, 39],
                [150, 150, 47]
            ]
        }),
        elevation: Metric.instance({
            ident: "elevation",
            defaults: ["m", "ft"],
            conv: {
                m: {
                    conversion: defaultConversion,
                    precision: 0
                },
                ft: {
                    conversion: function(e) {
                        return Math.round(3.28 * e)
                    },
                    precision: 0
                }
            },
            description: ["m", "ft"]
        }),
        distance: Metric.instance({
            ident: "distance",
            defaults: ["km", "mi"],
            conv: {
                km: {
                    conversion: function(e) {
                        return e / 1e3
                    },
                    precision: 1
                },
                mi: {
                    conversion: function(e) {
                        return e / 1609.344
                    },
                    precision: 1
                },
                NM: {
                    conversion: function(e) {
                        return e / 1852
                    },
                    precision: 1
                }
            },
            description: ["km", "mi", "NM"]
        }),
        waves: Metric.instance({
            ident: "waves",
            defaults: ["m", "ft"],
            conv: {
                m: {
                    conversion: defaultConversion,
                    precision: 1
                },
                ft: {
                    conversion: function(e) {
                        return 3.28 * e
                    },
                    precision: 0
                }
            },
            description: ["m", "ft"],
            appCodes: ['m', 'f'],
            lines: [[0, 0, 0], [0.2, 0.2, 0.7], [.5, .5, 1.6], [1, 1, 3.3], [2, 2, 6.6], [3, 3, 9.8], [4, 4, 13],
                [5, 5, 16.5], [6, 6, 20], [7, 7, 23], [8, 8, 26], [10, 10, 33], [12, 12, 39.5], [14, 14, 46], [16, 16, 53], [20, 20, 66]]
        }),
        currents: Metric.instance({
            ident: "currents",
            separator: " ",
            defaults: ["kt"],
            conv: {
                kt: {
                    conversion: function(e) {
                        return 1.943844 * e
                    },
                    precision: 1
                },
                "m/s": {
                    conversion: defaultConversion,
                    precision: 2
                },
                "km/h": {
                    conversion: function(e) {
                        return 3.6 * e
                    },
                    precision: 1
                },
                mph: {
                    conversion: function(e) {
                        return 2.236936 * e
                    },
                    precision: 1
                }
            },
            description: ["kt", "m/s", "mph", "km/h"],
            lines: [[0, 0, 0, 0, 0], [.2, .4, .2, .4, .7], [.4, .8, .4, .9, 1.4], [.8, 1.6, .8, 1.8, 2.9], [1, 2, 1, 2.2, 3.6], [1.6, 3.2, 1.6, 3.6, 5.8]]
        }),
        visibility: Metric.instance({
            ident: "visibility",
            defaults: ["km", "mi"],
            conv: {
                rules: {
                    conversion: function(e) {
                        return e / 1e3
                    },
                    label: "km",
                    precision: 1
                },
                km: {
                    conversion: function(e) {
                        return e / 1e3
                    },
                    precision: 1
                },
                mi: {
                    conversion: function(e) {
                        return .00328 * e
                    },
                    precision: 1
                }
            },
            description: ["rules", "km", "mi"],
            appCodes: ["r", "m", "f"],
            // lines: [[0, "LIFR", ".8", ".5"],
            //        [3000, "IFR", 2.7, 1.5],
            //        [7000, "MVFR", 6, 4],
            //        [16000, "VFR", 16, 10]]

            lines: [
                [100, "", ".1", ".06"],
                [500, "", ".5", ".03"],
                [1000, "", 1, ".6"],
                [2000, "", 2, "1.2"],
                [3000, "IFR", 3, "1.8"],
                [5000, "", 5, "3.1"],
                [10000, "MVFR", 10, "6.2"],
                [20000, "", 20, "12"]]
                // [16000, "VFR", 16, 10]]
        }),
        visibilityNoRules: Metric.instance({
            ident: "visibilityNoRules",
            defaults: ["km", "sm"],
            conv: {
                km: {
                    conversion: function(e) {
                        return e / 1e3
                    },
                    precision: 1
                },
                sm: {
                    conversion: function(e) {
                        return .00328 * e
                    },
                    precision: 1
                }
            },
            description: ["km", "sm"],
            lines: [[0, ".8", ".5"], [3e3, 2.7, 1.5], [7e3, 6, 4], [16e3, 16, 10]]
        }),
        so2: Metric.instance({
            ident: "so2",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 2
                }
            },
            description: ["µg/m³"],
            lines: [[2, 2], [5, 5], [10, 10], [15, 15], [20, 20], [30, 30], [40, 40], [50, 50], [75, 75], [100, 100]
                  , [125, 125], [150, 150], [175, 175], [200, 200], [400, 400], [600, 600], [800, 800]]
        }),
        dust: Metric.instance({
            ident: "dust",
            defaults: ["µg/m³"],
            conv: {
                "µg/m³": {
                    conversion: defaultConversion,
                    precision: 1
                }
            },
            description: ["µg/m³"],
            lines: [[0, 0], [50, 50], [100, 100], [500, 500], [800, 800]]
        }),
        cosc: Metric.instance({
            ident: "cosc",
            defaults: ["ppbv"],
            conv: {
                ppbv: {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["ppbv"],
            lines: [[0, 0], [50, 50], [100, 100], [500, 500], [1200, 1200]]
        }),
        radar: Metric.instance({
            ident: "radar",
            defaults: ["dBZ"],
            conv: {
                dBZ: {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["dBZ"],
            lines: [[0, 0], [30, 30], [40, 40], [50, 50], [60, 60], [70, 70]]
        }),
        ir: Metric.instance({
            ident: "ir",
            defaults: ["°C", "°F"],
            appCodes: ['c', 'f'],
            conv: {
                // K: {
                //     conversion: function(e) {
                //         return -.5 * e + 320
                //     },
                //     precision: 0,
                //     na: "> 240 K"
                // },
                "°C": {
                    conversion: function(e) {
                        return -.5 * e + 46.85
                    },
                    precision: 0,
                    na: "> -33°C"
                },
                "°F": {
                    conversion: function(e) {
                        return -.9 * e + 116.33
                    },
                    precision: 0,
                    na: "> -28°F"
                }
            },
            description: ["°C", "°F"],
            // lines: [[160, 240, -33, -28], [180, 230, -43, -46], [200, 220, -53, -64], [220, 210, -63, -82], [240, 200, -73, -100]]
            lines: [ [-80, -80, -112],[-60, -60, -76], [-40, -40, -40],  [-20, -20, -4] , [0, 0, 32], [20, 20, 68], [40, 40, 104]]
        }),
        wv: Metric.instance({
            ident: "wv",
            defaults: ["°C", "°F"],
            appCodes: ['c', 'f'],
            conv: {
                // K: {
                //     conversion: function(e) {
                //         return -.5 * e + 320
                //     },
                //     precision: 0,
                //     na: "> 240 K"
                // },
                "°C": {
                    conversion: function(e) {
                        return -.5 * e + 46.85
                    },
                    precision: 0,
                    na: "> -33°C"
                },
                "°F": {
                    conversion: function(e) {
                        return -.9 * e + 116.33
                    },
                    precision: 0,
                    na: "> -28°F"
                }
            },
            description: ["°C", "°F"],
            // lines: [[160, 240, -33, -28], [180, 230, -43, -46], [200, 220, -53, -64], [220, 210, -63, -82], [240, 200, -73, -100]]
            lines: [ [-75, -75, -103],[-65, -65, -85],[-55, -55, -67], [-45, -45, -49],  [-35, -35, -31] , [-25, -25, -13]]
        }),
        ptype: Metric.instance({
            ident: "ptype",
            defaults: ["ptype"],
            conv: {
                ptype: {
                    conversion: defaultConversion,
                    precision: 0,
                    label: ""
                }
            },
            ptype2text: {
                0: "RAIN",
                1: "JUST_RAIN",
                2: "THUNDER",
                3: "FZ_RAIN",
                4: "MX_ICE",
                5: "SNOW",
                6: "WET_SN",
                7: "RA_SN",
                8: "PELLETS",
                9: "LIGHT_THUNDER",
                10: "THUNDER",
                11: "HEAVY_THUNDER"
            },
            convertNumber: function(e) {
                return trans[this.ptype2text[e]]
            },
            renderLegend: function(e, t) {
                var n = this;
                e.getColor();
                var i = [1, 3, 4, 5, 6, 7, 8].map(function(t) {
                    return '<span style="background: ' + e.colorDark(t, 50) + ';">' + n.convertNumber(t) + "</span>"
                }).join("");
                t.style.background = null,
                    t.dataset.overlay = "ptype",
                    t.innerHTML = i
            }
        }),
        aqimet: Metric.instance({
            ident: "aqimet",
            defaults: ["aqi"],
            conv: {
                aqimet: {
                    conversion: defaultConversion,
                    precision: 0,
                    label: ""
                }
            },
            aqi2text:{
                0: "AQI_METEORED_GOOD",
                25: "AQI_METEORED_FAIR",
                45: "AQI_METEORED_MODERATE",
                65: "AQI_METEORED_UNHEALTHY",
                85: "AQI_METEORED_VERY_UNHEALTHY",
                105: "AQI_METEORED_HAZARDOUS"
            },
            getNearestIndex : getNearestIndexFun,
            convertNumber: function(index){
                return trans[this.aqi2text[this.getNearestIndex(index)]]
            },
            getLines(){
              return Object.keys(this.aqi2text).map(index=>{
                  return [index, this.convertNumber(index)];
              })
            },
            description: ["aqi"]
        }),
        aqimpol: Metric.instance({
            ident: "aqimpol",
            defaults: ["level"],
            conv: {
                aqimet: {
                    conversion: defaultConversion,
                    precision: 0,
                    label: ""
                }
            },
            aqi2text:{
                0: "POL_NULL",
                25: "POL_VERY_LOW",
                45: "POL_LOW",
                65: "POL_MODERATE",
                85: "POL_HIGH",
                105: "POL_VERY_HIGH"
            },
            getNearestIndex : getNearestIndexFun,
            convertNumber: function(index){
                return trans[this.aqi2text[this.getNearestIndex(index)]]
            },
            getLines(){
                return Object.keys(this.aqi2text).map(index=>{
                    return [index, this.convertNumber(index)];
                })
            },
            description: ["level"]
        }),
        aqieu: Metric.instance({
            ident: "aqieu",
            defaults: ["aqi"],
            conv: {
                aqimet: {
                    conversion: defaultConversion,
                    precision: 0,
                    label: ""
                }
            },
            aqi2text:{
                0: "AQI_EU_GOOD",
                25: "AQI_EU_FAIR",
                45: "AQI_EU_MODERATE",
                65: "AQI_EU_POOR",
                85: "AQI_EU_VERY_POOR",
                105: "AQI_EU_EXT_POOR"
            },
            getNearestIndex : getNearestIndexFun,
            convertNumber: function(index){
                return trans[this.aqi2text[this.getNearestIndex(index)]]
            },
            getLines(){
                return Object.keys(this.aqi2text).map(index=>{
                    return [index, this.convertNumber(index)];
                })
            },
            description: ["aqi"]
        }),
        aqiusa: Metric.instance({
            ident: "aqiusa",
            defaults: ["aqi"],
            conv: {
                aqimet: {
                    conversion: defaultConversion,
                    precision: 0,
                    label: ""
                }
            },
            aqi2text:{
                0: "AQI_USA_GOOD",
                55: "AQI_USA_MODERATE",
                105: "AQI_USA_UNHEALTHYSGROUPS",
                155: "AQI_USA_UNHEALTHY",
                205: "AQI_USA_VERY_UNHEALTHY",
                305: "AQI_USA_HAZARDOUS"
            },
            getNearestIndex : getNearestIndexFun,
            convertNumber: function(index){
                return trans[this.aqi2text[this.getNearestIndex(index)]]
            },
            getLines(){
                return Object.keys(this.aqi2text).map(index=>{
                    return [index, this.convertNumber(index)];
                })
            },
            description: ["aqi"]
        }),
        gh: Metric.instance({
            ident: "gh",
            defaults: ["m"],
            conv: {
                m: {
                    conversion: defaultConversion,
                    precision: 0
                }
            }
        }),
        fog: Metric.instance({
            ident: "fog",
            defaults: ["type"],
            conv: {
                type: {
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["type"],
            lines: [[1, "Fog"], [2, "Fog & rime"]]
        }),
        uvi: Metric.instance({
            ident: "uvi",
            defaults: ["uvi"],
            conv:{
                "uvi":{
                    conversion: defaultConversion,
                    precision: 0
                }
            },
            description: ["uvi"],
            lines: [[0.1, ".1"], [1, 1], [2, 2], [3, 3], [4, 4], [5, 5], [6, 6], [7, 7], [8, 8], [9, 9], [10, 10], [11, 11], [12, 12], [13, 13], [14, 14], [15, 15]]
        }),
        lightDensity: Metric.instance({
            ident: "lightDensity",
            defaults: ["#/km²"],
            conv: {
                "#/km²": {
                    conversion: defaultConversion,
                    precision: 2
                },

                "#/mi²": {
                    conversion: function(value){
                        return value * 1.60934 * 1.60934;

                    },
                    precision: 2
                }
            },
            description: ["#/km²", "#/mi²"],
            appCodes: ['m', 'f'],
            lines: [[0,     0,      0],
                    [.1, ".1",  ".25"],
                    [.5, ".5",  1.25],
                    [ 1,   1,   2.5],
                    [1.5, 1.5,  4],
                    [2,    2,   5],
                    [3,    3,   8],
                    [4,    4,   10],
                    [5,    5,   13],
                    [6,    6,   16],
                    [8,    8,   21],
                    [10,  10,   26],
                    [15,  15,   39],
                    [20,  20,   52],
                    [30,  30,   78],
                    [50,  50,   130],
                    [100, 100,  260]]
        }),
        efiWind: Metric.instance({
            ident: "efiWind",
            defaults: ["%"],
            conv: to100PercentConversion,
            description: ["%"],
            lines: [[-1, "unusually"], [-.75, "calm"], [.25, ""], [.75, "extreme"], [1, "wind"]]
        }),
        efiTemp: Metric.instance({
            ident: "efiTemp",
            defaults: ["%"],
            conv: to100PercentConversion,
            description: ["%"],
            lines: [[-1, "extreme"], [-.75, "cold"], [-.25, ""], [.25, ""], [.75, "extreme"], [1, "warm"]]
        }),
        efiRain: Metric.instance({
            ident: "efiRain",
            defaults: ["%"],
            conv: to100PercentConversion,
            description: ["%"],
            lines: [[-1, "very dry"], [0, ""], [.1, ""], [.75, "extreme"], [1, "precip."]]
        }),
        fires: Metric.instance({
            ident: "fires",
            defaults: ["W/m²"],
            conv: {
                "W/m²": {
                    conversion: defaultConversion,
                    precision: 3
                }
            },
            description: ["W/m²"],
            lines: [[0, "0"], [.05, ".05"], [.5, ".5"], [1, "1"]]
        })
    }
// });

/**
 * Clase Select
 */
class SelectView {
    private readonly selector: HTMLSelectElement | null;
    private selectElement : ((value:string|null) => any)  | undefined;

    constructor(selectViewElement: HTMLSelectElement) {
        this.selector = selectViewElement;
    }

    updateSelectedOption(value: string){
        // this.selector

        const oldOptionLi = <HTMLLIElement>this.selector?.getElementsByClassName("select")[0];
        // const value = optionLi?.getAttribute("value");
        const oldValue = oldOptionLi?.getAttribute("value");

        if (oldValue !== value) {
            if (oldOptionLi)
                oldOptionLi.classList.remove("select");

            const optionLi = this.selector?.querySelector(`[value="${value}"]`);

            optionLi?.classList.add("select");

        }

    }

    update(opciones:{text: string, value: string, class: string, selected: boolean, disabled: boolean}[]){
        const selectObj = this;
        for (const opcion of opciones){

            // if (typeof opcion.disabled === "undefined" || opcion.disabled) {
                const optionLi = document.createElement("li");
                optionLi.innerText = opcion.text;
                optionLi.setAttribute('value', opcion.value);

                if (opcion.selected)
                    optionLi.classList.add("select");

                optionLi?.addEventListener("click", function () {
                    const oldOptionLi = <HTMLLIElement>selectObj.selector?.getElementsByClassName("select")[0];
                    const value = optionLi?.getAttribute("value");
                    const oldValue = oldOptionLi?.getAttribute("value");

                    if (oldValue !== value) {
                        if (oldOptionLi)
                            oldOptionLi.classList.remove("select");
                        optionLi.classList.add("select");

                        if (selectObj.selectElement && selectObj.selector)
                            selectObj.selectElement(value);

                    }
                });

                if (typeof opcion.class !== "undefined")
                    optionLi.classList.add(opcion.class);

                if (typeof opcion.disabled !== "undefined" && opcion.disabled)
                    optionLi.classList.add("disabled");

                this.selector?.appendChild(optionLi);
            // }
        }

    }

    remove(){
        const selector = this.selector;

        if (selector)
            while (this.selector?.firstChild) {
                this.selector.removeChild(this.selector.firstChild);
            }

    }

    bindSelect (handler: (value:string|null) => any){
        this.selectElement = handler;
    }


}

export default SelectView;

import detectDevice from "./detectDevice";
import supportedLanguages from "./supportedLanguages";
import $ from "./$";

// var n = t.platform
//     , i = t.device
//     , s = window.navigator
//     ,

const device = detectDevice.device,
    platform = detectDevice.platform;

const nameserver=$('meta[name="nameserver"]');
const domain=$('meta[name="domain"]');

let coletilla;
if (PRODUCCION) {
    if (TEST) {
        coletilla = "test-";
    } else {
        coletilla = "";
    }
} else {
    coletilla = "local-";
}

var s = window.navigator, a = {
    // version: W.version,
    // target: W.target,
    platform: detectDevice.platform,
    device: detectDevice.device,
    supportedLanguages: supportedLanguages,
    langversion: 26,
    pollenCountries: ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "AL", "AD", "AM", "BY", "BA", "FO", "GE", "GI", "IS", "IM", "XK", "LI", "MK", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CH", "TR", "UA", "GB", "VA"],
    radarCountries: ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE", "AL", "AD", "AM", "BY", "BA", "FO", "GE", "GI", "IS", "IM", "XK", "LI", "MK", "MD", "MC", "ME", "NO", "RU", "SM", "RS", "CH", "TR", "UA", "GB", "VA", "CN" ,"KR" ,"JP" ,"TW" ,"VN" ,"MY" ,"SG" ,"TH" ,"PH" ,"KH" ,"MH" ,"ID" ,"NC" ,"FJ" ,"CK" ,"AU" ,"CA" ,"US" ,"MX" ,"CU" ,"SV" ,"NI" ,"PA" ,"GP" ,"MQ" ,"BM" ,"CW" ,"GF" ,"BZ" ,"GY" ,"BS" ,"CO" ,"BR" ,"AR" ,"TR" ,"KW" ,"SA" ,"IN" ,"MU", "CR", "GT", "VE"],
    // glParticlesOn: false,
    // server: "https://ims.windy.com",
    // server: "http://groenlandia.ogimet.com",
    // server: "",
    server: `https://${coletilla}services-{s}.meteored.com`,
    hurricanesServer: `https://${coletilla}services.meteored.com`,
    alertsServer: `https://${coletilla}services.meteored.com/web/warnings/v4`,
    resourcesServer: domain ? domain.content: "",
    // server: nameserver ? nameserver.content: "",
    servicesServer: `https://${coletilla}services-{s}.meteored.com`,
    searchURL: `https://services.meteored.com/web/search/viewer/v4`,
    weatherURL: `https://services.meteored.com/web/weather/viewer/v4/ecmwf/`,
    aqURL: `https://services.meteored.com/web/viewer-pub/location-info/v4/`,
    // server: "http://localhost",
    // nodeServer: "https://node.windy.com",
    // tileServer: "https://tiles.windy.com",
    tileServer: `https://${coletilla}services-{s}.meteored.com`,
    jsonServer: `https://${coletilla}services-{s}.meteored.com`,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    // community: "https://community.windy.com",
    // assets: "v/" + J.assets,
    websites: ["nodef", "apps", "dashboard", "ar", "at", "bo", "br", "ca", "cl", "cr", "de", "dk", "do", "ec", "en", "es", "eu", "fr", "hn", "it", "mx", "nl", "pa", "pe", "pt", "py", "ru", "uy", "ve"],
    assets: "js",
    levels: ["surface", "1000", "975", "950", "925", "900", "850", "800", "700", "600", "500", "400", "300", "250", "200", "150"],
    // pointForecast: "v2.5",
    // iconsDir: "img/icons4",
    overlays: ["radarSat", "radar", "satellite", "viento", "rachas", "waterprec", "gustAccu", "precip", "acumprec", "prob", "nieve", "prcnub" ,"acumnieve", "snowcover", "ptype", "rayos", "thunder", "temp2m", "dewpoint", "humedad", "isozero", "nubes", "hclouds", "mclouds", "lclouds", "fog", "cloudtop", "cbase", "visibilidad", "cape", "oleajetotal", "swell1", "swell2", "swell3","oleajefondo", "oleajeviento", "oleajealtura", "tempmar", "currents", "airQ", "no2", "pm2p5", "pm10", "duaod550", "so2","tcso2", "gtco3","o3", "co", "uvi","cosc", "dustsm", "so2sm", "fires", "pressure", "efiWind", "efiTemp", "efiRain", "capAlerts", "map", "olive", "alder", "birch", "grass", "mugwort", "ragweed", "ir", "rgb", "vi", "wv", "atemp", "aqimet", "aqieu", "aqiusa", "aqimpol", "ivt", "snowdepth", "warnings", "symbols", "daysymbols"],
    aqioverlays: ["aqimet", "aqieu", "aqiusa"],
    // acTimes: ["next12h", "next24h", "next2d", "next48h", "next60h", "next3d", "next5d", "next10d"],
    // isolines: ["off", "pressure", "gh", "temp", "deg0"],
    isolines: ["off", "pressure"],
    // localProducts: ["nems", "namConus", "namHawaii", "namAlaska", "iconEu", "arome", "camsEu"],
    localProducts: ["camsEu"],
    // globalProducts: ["gfs", "ecmwf", "sea", "geos5", "radar", "ecmwfWaves", "gfsWaves", "iconGlobal", "capAlerts", "cams", "map", "efi", "satellite", "camsGfas"],
    globalProducts: ["ecmwf", "gfs", "ecmwfWaves", "ecmwfEns", "cams", "radar", "satellite"],
    // seaProducts: ["sea", "ecmwfWaves", "gfsWaves"],
    //  camsProducts: ["cams", "camsEu"],
    camsProducts: ["cams", "camsEu"],
    // localPointProducts: ["namConus", "namHawaii", "namAlaska", "iconEu", "arome"],
    localPointProducts: [],
    // globalPointProducts: ["gfs", "ecmwf", "mblue"],
    // isCrawler: /googlebot/i.test(s.userAgent),
    isMobile: "mobile" === device,
    isTablet: "tablet" === device,
    isMobileOrTablet: "mobile" === device || "tablet" === device,
    // isMobile: false,
    // isTablet: false,
    // isMobileOrTablet: false,
    isRetina: window.devicePixelRatio && window.devicePixelRatio > 1,
    prefLang: s.languages ? s.languages[0] : s.language || s.browserLanguage || s.systemLanguage || s.userLanguage || "en_US",
    map: {},
    // hereMapsID: "app_id=Ps0PWVjNew3jM9lpFHFG&app_code=eEg9396D7_C6NCcM1DUK2A",
    levelsData: {
        "150": ["150 hPa", "13,5km FL450"],
        "200": ["200 hPa", "11.7km FL390"],
        "250": ["250 hPa", "10km FL340"],
        "300": ["300 hPa", "9000m FL300"],
        "400": ["400 hPa", "7000m FL240"],
        "500": ["500 hPa", "5500m FL180"],
        "600": ["600 hPa", "4200m FL140"],
        "700": ["700 hPa", "3000m FL100"],
        "800": ["800 hPa", "2000m 6400ft"],
        "850": ["850 hPa", "1500m 5000ft"],
        "900": ["900 hPa", "900m 3000ft"],
        "925": ["925 hPa", "750m 2500ft"],
        "950": ["950 hPa", "600m 2000ft"],
        "975": ["975 hPa", "300m 1000ft"],
        "1000": ["1000 hPa", "100m 360ft"],
        "100m": ["100 m", "330ft"],
        "total": ["total column", "total column"],
        surface: ["", ""]
    },
    radarRange: ["1", "2", "6", "12", "24"],
    satelliteRange: ["2", "6", "12", "24"],
    pois: {
        // favs: ["POI_FAVS", "k"],
        cities: ["POI_FCST", "&"],
        symbols: ["POI_SYMBOL", "&"],
        // wind: ["POI_WIND", ""],
        // temp: ["POI_TEMP", ""],
        // precip: ["POI_PRECIP", "H"],
        // metars: ["POI_AD", "Q"],
        // cams: ["POI_CAMS", "l"],
        // pgspots: ["POI_PG", ""],
        // kitespots: ["POI_KITE", ""],
        // surfspots: ["POI_SURF", "{"],
        // tide: ["POI_TIDE", "q"],
        // radiation: ["POI_RADIATION", ""],
        // firespots: ["ACTIVE_FIRES", ""],
        // airq: ["POI_AIRQ", ""],
        // radiosonde: ["POI_RADIOSONDE", ""],
        empty: ["POI_EMPTY", "t"]
    }
};
a.products = a.globalProducts.concat(a.localProducts).concat(a.camsProducts);
// a.products = a.globalProducts.concat(a.localProducts);

export default a;
// return a.products = a.globalProducts.concat(a.localProducts).concat(a.camsProducts),
//     a


import plugins from "./plugins";
import Class from "./Class";

export default Class.extend({
    _init: function() {
        this.isMounted = 0,
            this.isLoaded = !this.dependency || !this.dependency.length
    },

    open: function(params) {
        var renderer = this;

        if (this.isMounted)
            return Promise.resolve();

        if (this.isLoaded) {
            this.onopen( params );
            this.isMounted = true;
            return Promise.resolve();
        }
        else if (this.loadingPromise)
            return this.loadingPromise;
        else{
            this.loadingPromise = new Promise(function(resolve) {
                    plugins[renderer.dependency].open(params).then(function(core) {
                        renderer.isLoaded = true,
                            renderer.core = core,
                            renderer.onopen(params),
                            renderer.isMounted = true,
                            resolve()
                    }).catch(console.error.bind(null, "renderers", "Failed to load/open " + renderer.dependency)).then(function() {
                        renderer.loadingPromise = null
                    })
                }
            );
            return this.loadingPromise


        }

    },
    onopen: function() {},

    close: function(e) {
        this.onclose(e),
            this.isMounted = 0
    },

    onclose: function() {},

    paramsChanged: function() {},

    redraw: function() {}
})

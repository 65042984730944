/**
 * Clase que controla el comportamiento visual de LegendRender
 */
class LegendRenderView{

    /**
     * Elemento HTML que contiene las barras de las paletas de colores
     * @private
     */
    protected readonly bars: HTMLElement | null | undefined;
    /**
     * Elemento HTML que contiene el botón que despliega y esconde las paletas
     * @private
     */
    private readonly masInfo: HTMLElement | null | undefined;
    /**
     * Función manejadora que pinta las leyendas
     * @private
     */
    private paintLegend: (() => any) | null | undefined;
    /**
     * Función que notifica cuando se ha abierto o cerrado la leyenda
     * @private
     */
    private notify: ((open: boolean) => any) | undefined | null = null;
    /**
     * Elemento HTML que contiene un mensaje personalizado del modelo seleccionado
     * @private
     */
    private message: HTMLSpanElement | null | undefined;

    /**
     * Booleano que indica si está o no abierta la leyenda
     * @private
     */
    private isopen: boolean = false;

    /**
     * Elemento HTML que contiene la leyenda
     * @private
     */
    private legend: HTMLElement | null;
    /**
     * Crea e inicializa un objeto
     * @param divLegendElement
     */
    constructor(divLegendElement: HTMLElement | null){

        const divLegend = divLegendElement;

        this.legend = divLegendElement;
        this.bars = divLegend?.querySelector("#bars");
        this.masInfo = divLegend?.querySelector("#more_info");


        this.message = <HTMLSpanElement> document?.querySelector("#productmsg");

        const masInfo = this.masInfo;
        const close_leyenda: HTMLSpanElement = <HTMLSpanElement>divLegend?.getElementsByClassName("close-leyenda")[0];

        if (masInfo && divLegend) {
            masInfo.addEventListener("click",
                this.open.bind(this)
            );
        }

        if(close_leyenda && masInfo && divLegend){
            close_leyenda.addEventListener("click",
                this.close.bind(this)
            );

        }

    }

    /**
     * Devuelve un booleano indicando si está o no abierta la leyenda
     */
    isOpen(){
        return this.isopen;
    }

    /**
     * Función que muestra la leyenda
     */
    open(){
        const icon: HTMLSpanElement = <HTMLSpanElement> this.masInfo?.getElementsByClassName("icon")[0];
        const close_leyenda: HTMLSpanElement = <HTMLSpanElement> this.legend?.getElementsByClassName("close-leyenda")[0];
        /* Si está oculto... */
        if (!this.isopen) {
            /* ...lo mostramos */
            this.bars?.classList.add("open");
            this.legend?.classList.add("open");
            this.bars?.classList.remove("closed");

            icon.style.display = 'none';
            close_leyenda.style.display = 'flex';
            // texto.style.display = 'none';
            this.isopen = true;
            if(this.notify)
                this.notify(true);
        }
    }

    /**
     * Función que oculta la leyenda
     */
    close(){
        const icon: HTMLSpanElement = <HTMLSpanElement> this.masInfo?.getElementsByClassName("icon")[0];
        const close_leyenda: HTMLSpanElement = <HTMLSpanElement> this.legend?.getElementsByClassName("close-leyenda")[0];
        if (this.isopen) {
            /* ...lo ocultamos */
            this.bars?.classList.remove("open");
            this.legend?.classList.remove("open");

            // divBars.style.display = 'none';
            icon.style.display = 'flex';
            close_leyenda.style.display = 'none';
            // texto.style.display = 'block';
            this.isopen = false;
            if(this.notify)
                this.notify(false);
        }
    }

    /**
     * Renderiza la leyenda de una variable a partir de su paleta de color y métrica.
     *
     * @param paletteColor Array que contiene los colores de la paleta y los valores de magnitud para los que se aplica
     * @param paletteColorContinuous Si los colores de la paleta se van a pintar de forma continua
     * @param nlegend Número de leyenda
     * @param metric Array bidimensional que contiene los valores para los que pintar una anotación
     * @param metricUnit Unidad de la métrica
     * @param name Nombre de la variable de la leyenda
     *
     */
    renderLegend(paletteColor: [number, number[]][],
                 paletteColorContinuous: boolean,
                 nlegend: number,
                 metric: {value: number, text: string}[],
                 metricUnit: string,
                 name: string) {


        // Elemento <div> que contiene a la leyenda
        const newDiv = document.createElement("div");
        newDiv.id = "bar"+nlegend;

        const gradient = paletteColor,
            slotWidth = 100 / gradient.length;

        // Transforma un color [R, G, B, A] a un string "rgba(R, G, B, A)"
        const colorToString= function (color: number[]){
                return "rgba(" + color[0] + "," + color[1] + "," + color[2] + ", " + color[3] + ")";
            };


        // Array de colores en formato "rgba(R, G, B, A)"
        const gradientStrArr = gradient.map(
            function (slot: [number, number[]]){
                return colorToString(slot[1]);
            }
        );

        let index=0, value;

        // Se avanza hasta que el valor de la anotación no sea menor que el primer valor de la paleta
        while ( metric[index].value < gradient[0][0])
            index++;

        // Primer valor de anotación a pintar
        value = metric[index].value;

        // El primer color de la leyenda es el primer color de la paleta de colores
        let legendColors = gradientStrArr[0] + " 0% ";

        // Si la paleta es discreta se añade el mismo color para la siguiente posición
        if ( ! paletteColorContinuous )
            legendColors += ", "+gradientStrArr[0] + " " + slotWidth + "%";

        // Contiene la barra de colores
        const divColors= document.createElement("div");
        divColors.classList.add("bar_colors");

        // Primer slot de la paleta
        let spanColor = document.createElement("span");
        spanColor.style.width= slotWidth + "%";

        // Si el valor de la primera anotación está comprendido entre el primer
        // y el según valor de la paleta se añade al span del slot
        if (value >= gradient[0][0] && value < gradient[1][0]) {
            // El valor a mostrar se encuentra en la posición 1 y está en la unidad
            // indicada en el parámetro metricUnit
            spanColor.innerHTML = metric[index].text;
            index++;
        }

        divColors.appendChild(spanColor);


        let i = 1;
        // Se hace lo mismo con resto de elementos
        for (; i < gradientStrArr.length  - 1; i++ ){
            if (index < metric.length)
                value = metric[index].value;

            legendColors += ", " + gradientStrArr[i] + " " + i * slotWidth + "%";
            if (! paletteColorContinuous )
                legendColors += ", " + gradientStrArr[i] + " " + (i + 1) * slotWidth + "%";


            spanColor = document.createElement("span");
            spanColor.style.width= slotWidth + "%";

            if (index < metric.length && value >= gradient[i][0] && value < gradient[i+1][0]) {
                spanColor.innerHTML = metric[index].text;
                index++;
            }

            divColors.appendChild(spanColor);

        }

        legendColors+=", " + gradientStrArr[i] + " " + i * slotWidth + "%";

        // Se crea el span para el último elemento
        spanColor = document.createElement("span");
        spanColor.style.width= slotWidth + "%";
        // spanColor.id="ultimo";

        if (index < metric.length && metric[index].value >= gradient[i][0]) {
            spanColor.innerHTML = metric[index].text;
            index++;
        }

        divColors.appendChild(spanColor);


        const divText= document.createElement("div");
        divText.classList.add("bar_text");
        divText.innerText=name + " ("+metricUnit+")";

        // Se establecen los colores que se han ido formando en el bucle
        divColors.style.background = "linear-gradient(to right, " + legendColors + ")";

        newDiv.appendChild(divText);
        newDiv.appendChild(divColors);

        this.bars?.insertBefore(newDiv, this.bars.firstChild);

        // Si se detecta que el tamaño del texto del último span sobresale su ancho
        // Se justifica a la derecha
        // const domrect = spanColor.getBoundingClientRect();
        if (spanColor.clientWidth < spanColor.scrollWidth) {
            spanColor.classList.add("end");
        }


    }

    regendLegendHTML(html: string){
        this.bars?.insertAdjacentHTML("afterbegin", html);
    }

    /**
     * Establece el manejador que pinta las leyendas
     *
     * @param handler Función manejadora
     */
    bindPaintLegend(handler: () => any){
        this.paintLegend=handler;
    }

    /**
     * Asocia un manejador que notifica cuando se abre o cierra la ventana.
     * @param {(open: boolean) => any} handler - Función que maneja la notificación.
     * @public
     */
    bindNotify(handler: (open: boolean) => any){
        this.notify = handler;
    }

    /**
     * Actualiza las leyendas pintando las leyendas de las variables del tipo de mapa seleccionado
     */
    updateBars (){

        while (this.bars?.firstChild) {
            this.bars.removeChild(this.bars.firstChild);
        }

        if (this.paintLegend)
            this.paintLegend();

    }

}

export default LegendRenderView;

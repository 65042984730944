import render from "./render";
import store from "./store";
import storage from "./storage";



function decodeFun(value)
{
    const a = Math.random() * 100;
    const b = Math.random() * 50;

    return value * a + b;
}

const test = {

    doTest: function(size) {
        const array = [];
        // const result= [];
        const interpolateValue = render.interpolateBilineal;
        let time = 0;



        for (let i = 0; i < size * size; i++)
            array[i] = Math.floor(Math.random() * 256);

        for (let i = 0; i < size - 1; i++) {
            for (let j = 0; j < size - 1; j++) {
                let wTL = Math.random();
                let wBL = Math.random();
                let wTR = Math.random();
                let wBR = Math.random();

                let pos = i * size + j;
                const valueTL = array[pos];
                const valueTR = array[pos + 1];
                const valueBL = array[pos + size];
                const valueBR = array[pos + size + 1];

                const start = performance.now();
                interpolateValue(null, 0, 0, valueTL, valueTL, valueBL, valueBR, wTL, wTR, wBL, wBR, decodeFun);
                const end = performance.now();
                time += (end - start);
            }
        }



        return time;

    },

    getPerformance: function (){
        let result = store.get("performanceTest");
        let dotest = false;

        // Si el resultado almacenado es 'low' se va a repetir el test en al menos 5 intentos.
        // Los intentos deben estar separados entre sí al menos 24 horas. De esta manera
        // evitamos que haya dado un rendimiento low porque estuviera el dispositivo saturado
        if (result === "low"){
            const tsUpdated = storage.get("settings_performanceTest_ts");
            const ntries = store.get("performanceTestTries");

            if (ntries < 5 && Date.now() > tsUpdated + 24 * 3600 * 1000){
                store.set("performanceTestTries", ntries + 1);
                dotest = true;
            }
        }else if (result === "undefined")
            dotest = true;

        if (dotest) {
            let testValue = 100;
            for (let i = 0; i < 3; i++) {
                const value = this.doTest(128);
                testValue = Math.min(testValue, value);
            }
            result = testValue > 50 ? "low" : "high";
            store.set("performanceTest", result, {forceChange: true});
        }

        return result.replace('_predef','');

    }


}

export default test;

/**
 * Modulo que devuelve la clase Calendar, que permite gestionar los pasos de tiempo de un determinado modelo o producto.
 *
 * @module Calendar
 */

import format from "./format";
import utils from "./utils";
import Class from "./Class";
import trans from "./trans";


export default Class.extend({
    weekdays: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    calendarHours: 240,
    numOfHours: 240,
    localeHours: undefined,
    startOfTimeline: undefined,
    endOfcalendar: undefined,
    midnight: undefined,
    minifestFile: undefined,
    timestamps: undefined,
    interTimestamps: undefined,
    endOfCal: undefined,
    days: undefined,
    refTimeTs: undefined,

    /**
     * Inicializa una instancia de la clase Calendar
     *
     * @private
     */
    _init: function() {
        // 00:00:00 del día actual
        this.midnight = this.getMidnight();
        // Inicio de la linea temporal
        this.startOfTimeline = this.startOfTimeline || this.midnight;
        this.start = this.startOfTimeline.getTime();
        this.days = [];
        // Final de la linea temporal
        this.endOfcalendar = this.add(this.startOfTimeline, this.calendarHours, "hours");
        this.endOfCal = this.endOfcalendar.getTime();
        // this.maxTimestamp = this.endOfcalendar.getTime();
        // this.type = this.endOfcalendar < this.midnight ? "historical" : this.startOfTimeline < this.midnight ? "mixed" : "forecast",
        this.type = "forecast";
        this.timestamps = [];
        this.paths = [];
        this.interTimestamps = [];

        this.localeHours = format.getHoursFunction();

        if ( this.minifestFile && this.createTimestampsFromMinifest(this.minifestFile) )
            this.minifestValid = true;
        else
        {
            this.createTimestamps();
            this.minifestValid = false;
        }
        // Timestamp final
        this.end = Math.min(this.timestamps[this.timestamps.length - 1], this.endOfCal);

        for (let firstmidday = this.add(this.startOfTimeline, 12, "hours"), nday = 0; nday < this.calendarHours / 24; nday++) {
            let startday = this.add(this.startOfTimeline, nday, "days").getTime()
                , endday = this.add(this.startOfTimeline, 24, "hours")
                , enddayts = this.add(endday, nday, "days").getTime()
                , midday = this.add(firstmidday, nday, "days")
                , middayts = midday.getTime()
                , dayweek = this.weekdays[midday.getDay()];
            this.days[nday] = {
                display: dayweek + "2",
                displayLong: dayweek,
                day: midday.getDate(),
                middayTs: middayts,
                start: startday,
                end: enddayts,
                month: midday.getMonth() + 1,
                year: midday.getFullYear()
            }
        }
        for (let l = 1; l < this.paths.length; l++)
            this.interTimestamps.push(this.timestamps[l - 1] + Math.floor((this.timestamps[l] - this.timestamps[l - 1]) / 2));

        this.indxCurrentTs = this.indxBestTs(Date.now());

        return this;
    },

    getIdxCurrentTs: function (){
        return this.indxCurrentTs;
    },

    getCurrentTs: function (){
        return this.timestamps[this.indxCurrentTs];
    },

    /**
     * Suma n unidades (en horas o días) a una fecha
     *
     * @param   {Date}    fecha  Fecha a la que se le suma una cantidad
     * @param   {number}  count  Cantidad de horas o días a sumar
     * @param   {string}  type   Si lo que contiene count son horas o días
     *
     * @returns {Date}           Fecha resultado de la suma
     */
    add: function(fecha, count, type) {
        const date = new Date(fecha.getTime());
        date.setTime(fecha.getTime() +
            ("days" === type ? 24 : 1) * count * utils.tsHour);
        return date;
    },

    /**
     * Límita un timestamp al intervalo comprendido entre el ts de inicio y el final del calendario
     *
     * @param   {number}  timestamp timestamp al que se le aplica el bound
     *
     * @returns {number}            El mismo timestamp si está dentro del intervalo, el valor mínimo del intervalo
     *                              si está por debajo o el valor máximo del intervalo si el ts está por encima.
     *
     */
    boundTs: function(timestamp) {
        return utils.bound(timestamp, this.start, this.end);
    },

    /**
     * Devuelve la fecha con las 00:00:00 del día actual
     *
     * @returns {Date}              La fecha del día actual a las 00:00
     */
    getMidnight: function() {
        const fecha = new Date;   // fecha actual
        fecha.setHours(0);
        fecha.setMinutes(0);
        fecha.setSeconds(0);
        fecha.setMilliseconds(0);

        return fecha;
    },

    /**
     * Rellena el array de timestamps con timestamps múltiplos de 3 desde el ts inicial
     */
    createTimestamps: function() {
        const hourUTC = this.startOfTimeline.getUTCHours() % 3;

        // Si la hora UTC no es multiplo de 3 la hora de inicio se establece a la siguiente hora UTC multiplo de 3
        if ( hourUTC )
            this.startOfTimeline = this.add(this.startOfTimeline, 3 - hourUTC, "hours");
        for (let t = 0; t < this.numOfHours; t += 3) {
            const n = this.add(this.startOfTimeline, t, "hours");
            // this.paths.push(this.date2path(n));
            this.paths.push( this.refdate2path(this.startOfTimeline, t)); //FIXME
            this.timestamps.push(n.getTime());
        }
    },

    /**
     * Obtiene la fecha de pasada y de actualización de pasada a partir del objeto minifest de un producto
     *
     * @param   {Object}  minifest  Objeto que contiene la información de los timestamps de un producto
     */
    prepareTimesFromMinifest: function(minifest) {
        // if ( minifest && "object" == typeof minifest && minifest.ref && (minifest.dst || minifest.dstTime) ){
        if ( minifest && "object" == typeof minifest && minifest.ref && minifest.dst ){
            // refTime es la fecha de la pasada
            this.refTime = minifest.ref.replace(/(\d+)-(\d+)-(\d+)T(\d+):.*/, "$1$2$3$4");
            this.refTimeTxt = minifest.ref;
            // Cuando se actualizó la pasada?
            // this.updateTxt = minifest.update;
            this.refTimeTs = new Date(minifest.ref).getTime();
            this.updateTs = new Date(minifest.update).getTime();

            this.start = this.refTimeTs;
            this.startOfTimeline = new Date(this.start);

            return true;
        }
        else{
            console.error("Calendar", "Invalid format of minifest " + (minifest.dst ? "2.0" : "3.0"));

            return false;
        }
    },

    /**
     * Rellena el array de timestamps a partir de los intervalos leidos del objeto minifest de un producto
     *
     * @param   {Object}  minifest  Objeto que contiene la información de los timestamps de un producto
     */
    createTimestampsFromMinifest: function(minifest) {
        const calendar = this;
        if ( ! this.prepareTimesFromMinifest(minifest) )
            return false;
        if (minifest.dst) {
            let tshour = utils.tsHour
                , ndias = Math.min(12, this.numOfHours / 24)
                , final = this.add(this.startOfTimeline, ndias, "days").getTime()
                , refTimeDate = new Date(this.refTimeTs);

            minifest.dst.forEach(
                function(intervalo) {
                    for (let t = intervalo[1]; t <= intervalo[2]; t += intervalo[0]) {
                        const ts = calendar.refTimeTs + t * tshour;
                        if ( ts <= final ) {
                            calendar.timestamps.push(ts);
                            calendar.paths.push( calendar.refdate2path(refTimeDate, t));
                        }
                    }
                })
        }
        // else
        //     minifest.dstTime.forEach(
        //         function(ts) {
        //             const fecha = new Date(ts);
        //             calendar.timestamps.push(fecha.getTime());
        //             calendar.paths.push(fecha.toISOString().replace(/(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):.*/, "$1$2$3$4"));
        //         });

        return true;
    },


    // date2path: function(fecha) {
    //     return fecha.toISOString().replace(/(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):.*/, "$1/$2/$3/$4");
    // },
    /**
     * Genera el 'path' de las URLs de las teselas para una fecha de pasada y proyección
     *
     * @param   {Date}    pasada  Fecha de la pasada
     * @param   {Number}  proy    Proyección o paso de tiempo
     */
    refdate2path: function (pasada, proy) {
             // En las urls del servicio de maps-frontend no se tiene en cuenta la pasada
             return pasada.toISOString().replace(/(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):.*/,"$1$2$3$4") + "/" +utils.pad(proy, 3);
    },

    /**
     * Devuelve el paso de tiempo más cercano del producto o modelo
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          timestamp con el paso del tiempo del producto
     */
    bestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.timestamps[n];
        return this.timestamps[this.paths.length - 1];
    },

    /**
     * Devuelve el indice del paso de tiempo más cercano del producto o modelo
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          indice del timestamp con el paso del tiempo del producto
     */
    indxBestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return n;
        return this.paths.length - 1;
    },

    indxBestFloatTs: function(ts) {
        for (let t = this.timestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return n - 1 + (ts - t[n - 1])/ (t[n] - t[n-1]);
        return this.paths.length - 1;
    },
    /**
     * Devuelve el siguiente paso de tiempo al paso de tiempo más cercano del producto o modelo
     *
     * @param   {Number}   ts     timestamp del que se busca el paso del tiempo
     *
     * @returns {Number}          timestamp con el paso del tiempo del producto
     */
    nextBestTs: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.timestamps[Math.min(n + 1, this.paths.length - 1 )];
        return this.timestamps[this.paths.length - 1];
    },
    /**
     * Devuelve el path que corresponde al paso de tiempo más cercano
     *
     * @param   {Number}   ts      timestamp del que se busca el path
     *
     * @returns {string}           el path correspondiente al paso de tiempo más cercano
     */
    ts2path: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.paths[n];
        return this.paths[this.paths.length - 1];
    },
    /**
     * Devuelve el path que corresponde al siguiente paso de tiempo del paso de tiempo más cercano
     *
     * @param   {Number}   ts      timestamp del que se busca el path
     *
     * @returns {string}           El path correspondiente al siguiente paso de tiempo al más cercano
     */
    ts2nextpath: function(ts) {
        for (let t = this.interTimestamps, n = 0; n < t.length; n++)
            if (ts < t[n])
                return this.paths[Math.min(n + 1, this.paths.length - 1 ) ];
        return this.paths[this.paths.length - 1];
    },
    /**
     * Devuelve la proyección que corresponde con el paso de tiempo más cercano
     *
     * @param   {Number}   ts       timetamp del que se busca la proyección
     *
     * @returns {number}            La proyección correspondiente al paso de tiempo más cercano
     */
    ts2proy: function(ts) {
        return (this.bestTs(ts) - this.refTimeTs)/ 3600000;
    },
    /**
     * Devuelve la proyección que corresponde al siguiente paso de tiempo del paso de tiempo más cercano
     *
     * @param   {Number}   ts       Timetamp del que se busca la proyección
     *
     * @returns {number}            La proyección correspondiente al siguiente paso de tiempo al más cercano
     */
    ts2nextproy: function(ts) {
        return (this.nextBestTs(ts) - this.refTimeTs)/ 3600000;
    },
    // path2date: function(e) {
    //     const t = e.split("/");
    //     return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], 0, 0));
    // },
    /**
     * Devuelve la hora local para un timestamp
     *
     * @param   {Number}   ts       Timestamp del que se quiere obtener la hora
     *
     * @returns {string}            Un string con la hora local formateada a 24h o a 12h
     */
    ts2hour: function(ts) {
        const date = new Date(ts);
        return this.localeHours(date.getHours());
    },
    /**
     * Devuelve un string con el día de la semana y el día del mes
     *
     * @param   {Number}   ts       Timestamp del que se quiere obtener el string
     *
     * @returns {string}            Un string con el día de las semana (Lunes, martes,...) y el día del mes (1..31)
     */
    ts2day: function (ts){
        const date = new Date(ts)
            , daymonth = date.getDate()
            , dayweek = date.getDay();

        return trans[this.weekdays[dayweek]] + " " + daymonth;
    }
});

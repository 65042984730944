/**
 * Clase CheckBox
 */
class CheckBoxView {
    private checkbox: Element | null;
    private checked: boolean = false;

    constructor(checkBoxElement : Element ) {
        this.checkbox = checkBoxElement;
    }

}

export default CheckBoxView;

import store from "./store";
import products from "./products";
import trans from "./trans";

class ModelSwitchController{

    constructor(view) {
        this.view = view;

        this.addElements();

        this.view.bindSelect(this.select.bind(this));

        store.on("overlay", this.changeOverlay.bind(this));
        store.on("product", this.changeOverlay.bind(this));

    }

    addElements (){
        const availProducts = store.get("availProducts");
        const selectedProduct = store.get("product");

        this.view.update(
            availProducts.map(
                function(nameproduct){

                    return {
                        text: products[nameproduct].modelName,
                        value: nameproduct,
                        selected: nameproduct === selectedProduct,
                    };
                }
            )
        );
    }

    changeOverlay (){
        const oldModel = this.view.selector.value;
        const model = store.get("product");

        this.view.remove();
        this.addElements();

        this.view.selector.value = model;

    }

    select (value){
        store.set("product", value);
    }


}

export default ModelSwitchController;

import store from "./store";
import broadcast from "./broadcast";
import Class from "./Class";
import utils from "./utils";

//define("Metric", ["store", "broadcast", "Class"], function(store, broadcast, Class) {
    export default Class.extend({
        separator: "",
        defaults: [null, null],
        _init: function() {
            this.key = "metric_" + this.ident;
            store.insert(this.key, {
                def: this.getDefault(),
                save: true,
                sync: true,
                allowed: Object.keys(this.conv)
            });
            this.metric = store.get(this.key);
            store.on(this.key, this.onMetricChanged, this);
            store.once("isImperial", this.setDefault, this);
        },
        onMetricChanged: function(metric) {
            this.metric = metric;
            broadcast.emit("metricChanged", this.ident, metric);
        },
        getDefault: function() {
            return store.get("isImperial") && this.defaults.length > 1 ?
                this.defaults[1] :
                this.defaults[0];
        },
        setDefault: function() {
            store.setDefault(this.key, this.getDefault())
        },
        // convertValue: function(e, t) {
        //     if (!e)
        //         return "";
        //     else
        //         return this.convertNumber(e) + (t || this.separator) + (this.conv[this.metric].label || this.metric);
        //
        //     // return void 0 === e ? "" : this.convertNumber(e) + (t || this.separator) + (this.conv[this.metric].label || this.metric)
        // },

        convertValue: function(value, el1, el2) {
            if (void 0 === value)
                return "";

            let conv, r = this.convertNumber(value), convertedValue, label="";

            if ("number" == typeof r && ! isNaN(r) && r >= 1e3)
                convertedValue = utils.thousands(r);
            else
                convertedValue = r;

            conv = this.conv[this.metric];

            if (conv)
                label = conv.label;

            if(!label)
                label = this.metric;

            return convertedValue + (el1 || this.separator) + label + (el2 || "")
        },


        na: function() {
            return this.conv[this.metric].na || "-"
        },
        convertNumber: function(e, t) {
            void 0 === t && (t = void 0);
            var n = this.conv[this.metric]
                , i = n.conversion(e)
                , s = void 0 !== t ? t : n.precision;
            return s ? parseFloat(i.toFixed(s)) : Math.round(i)
        },
        listMetrics: function() {
            return Object.keys(this.conv)
        },
        howManyMetrics: function() {
            return this.listMetrics().length
        },
        setMetric: function(t) {
            store.set(this.key, t)
        },
        getLines: function(){
          return this.lines;
        },
        cycleMetric: function() {
            var index = this.description.indexOf(this.metric) + 1;
            if ( index === this.description.length )
                index = 0;
            this.setMetric(this.description[index]);
        }//,
        // renderLegend: function(paletteColor, element, metric, name) {
        //     var description = metric.description
        //         , lines = metric.lines
        //         , indexMetric = description.indexOf(this.metric) + 1;
        //
        //     paletteColor.getColor();
        //
        //     var gradient = paletteColor.gradient,
        //         slotWidth = 100 / gradient.length,
        //         // legendColors=[],
        //         colorToString= function (color){
        //             return "rgba(" + color[0] + "," + color[1] + "," + color[2] + ", " + color[3] + ")";
        //         }
        //     ;
        //
        //
        //
        //
        //     var gradientStrArr = gradient.map(
        //         function (slot){
        //             return colorToString(slot[1]);
        //         }
        //     );
        //     var continua = paletteColor.continuous;
        //     var index=0, value; //, value = lines[index][0];
        //
        //     while ( lines[index][0] < gradient[0][0])
        //         index++;
        //
        //     value = lines[index][0];
        //
        //     var legendColors = gradientStrArr[0] + " 0% ";
        //
        //     if ( ! continua)
        //         legendColors += ", "+gradientStrArr[0] + " " + slotWidth + "%";
        //     // else
        //     //     legendColors += gradientStrArr[1] + " " + slotWidth + "%";
        //
        //     var legendHTML = '<span style="width: ' + slotWidth + '%">';
        //
        //     if (value >= gradient[0][0] && value < gradient[1][0]) {
        //         legendHTML += lines[index][indexMetric].toString();
        //         index++;
        //     }
        //     legendHTML+="</span>";
        //
        //     // + "0" + '</span>';
        //
        //     for (var i = 1; i < gradientStrArr.length  - 1; i++ ){
        //         if (index < lines.length)
        //             value = lines[index][0];
        //         // if (! )No
        //         legendColors += ", " + gradientStrArr[i] + " " + i * slotWidth + "%";
        //         if (! continua)
        //             legendColors += ", " + gradientStrArr[i] + " " + (i + 1) * slotWidth + "%";
        //             // legendColors+= ", " + gradientStrArr[i] + " " + i * slotWidth + "% " + (i + 1) * slotWidth + "%";
        //
        //         // legendColors+= ", " + gradientStrArr[i] + " " + i * slotWidth + "% " + (i + 1) * slotWidth + "%";
        //         legendHTML += '<span style="width: ' + slotWidth + '%">'; //+ '</span>';
        //
        //         if (index < lines.length && value >= gradient[i][0] && value < gradient[i+1][0]) {
        //             legendHTML += lines[index][indexMetric].toString();
        //             index++;
        //             // if ( ++index === lines.length
        //         }
        //
        //         legendHTML+="</span>";
        //
        //     }
        //
        //     legendColors+=", " + gradientStrArr[i] + " " + i * slotWidth + "%";
        //
        //     legendHTML += '<span style="width: ' + slotWidth + '%">';
        //
        //     if (index < lines.length && lines[index][0] >= gradient[i][0]) {
        //         legendHTML += lines[index][indexMetric].toString();
        //         index++;
        //     }
        //
        //     legendHTML += '</span>';
        //     //
        //
        //
        //     // var length = lines.length
        //     //     , indexMetric = description.indexOf(this.metric)
        //     //     , slotWidth = 100 / (lines.length + 1)
        //     //     , legendColors = []
        //     //     , color = paletteColor.color(lines[0][0]);
        //     //
        //     // legendColors.push(color, color, color);
        //     //
        //     // var legendHTML = '<span style="width:' + slotWidth + '%">' + this.metric + "</span>";
        //     //
        //     // for ( var i = 0; i < length; i++) {
        //     //     var indexColor = lines[i][0]
        //     //         , nextIndex = lines[Math.min(i + 1, length - 1)][0]
        //     //         , minColor = paletteColor.color(indexColor)
        //     //         , maxColor = paletteColor.color(.5 * (indexColor + nextIndex));
        //     //
        //     //     legendColors.push(minColor, maxColor);
        //     //
        //     //     legendHTML += '<span style="width: ' + slotWidth + '%">' + lines[i][1 + indexMetric] + "</span>";
        //     // }
        //
        //     var divText= document.createElement("div");
        //     divText.classList.add("bar_text");
        //     divText.innerText=name + " ("+this.metric+")";
        //
        //     var divColors= document.createElement("div");
        //     divColors.classList.add("bar_colors");
        //
        //
        //     // divColors.dataset.overlay = this.ident;
        //     // element.classList[this.howManyMetrics() > 1 ? "remove" : "add"]("one-metric");
        //     divColors.style.background = "linear-gradient(to right, " + legendColors + ")";
        //     divColors.innerHTML=legendHTML;
        //
        //     // element.style.background = "linear-gradient(to right, " + legendColors.join(",") + ")";
        //     // element.innerHTML = legendHTML;
        //     element.appendChild(divText);
        //     element.appendChild(divColors);
        // }
    })
// })

import broadcast from "./broadcast";
import map from "./map";


class FullScreenButtonController {

    constructor(view) {
        this.view = view;

        this.view.bindRedrawLayers(this.redrawLayers.bind(this));
        // this.view.addEventListeners(this);
    }

    redrawLayers() {
        // broadcast.emit("redrawLayers");
        map.invalidateSize({debounceMoveend: true});
    }
}

export default FullScreenButtonController;

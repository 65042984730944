const countriesCodes =
    {
        "IR":{
            code: 1,
            lon: 53.89421229453263,
            lat: 32.49114219366275,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "SA":{
            code: 2,
            lon: 45.31778043678176,
            lat: 24.810411492944734,
            zoom_pc: 5.3,
            zoom_mv: 4.3
        },
        "AE":{
            code: 3,
            lon: 54.47685032834727,
            lat: 24.407827803763677,
            zoom_pc: 7.8,
            zoom_mv: 6.8
        },
        "GH":{
            code: 4,
            lon: -0.8455821625887762,
            lat: 7.885482952901476,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "ET":{
            code: 5,
            lon: 40.67138671875001,
            lat: 9.644076964907923,
            zoom_pc: 6.2,
            zoom_mv: 5.2
        },
        "YE":{
            code: 6,
            lon: 48,
            lat: 14.78347987136495,
            zoom_pc: 6.4,
            zoom_mv: 5.4
        },
        "CI":{
            code: 7,
            lon: -7.7914855,
            lat: 7.4608466,
            zoom_pc: 6.4,
            zoom_mv: 5.6
        },
        "MA":{
            code: 8,
            lon: -7.404176361434521,
            lat: 30.8,
            zoom_pc: 6.3,
            zoom_mv: 5.3
        },
        "IN":{
            code: 9,
            lon: 82.68159505784865,
            lat: 20.709582390086837,
            zoom_pc: 5,
            zoom_mv: 4
        },
        "MR":{
            code: 10,
            lon: -11.572475008456781,
            lat: 21.110159542987187,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "IS":{
            code: 11,
            lon: -18.865625368234486,
            lat: 65.01898798604408,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "SY":{
            code: 12,
            lon: 38.7510742187501,
            lat: 34.903952965590065,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "NO":{
            code: 13,
            lon: 17.941561354613107,
            lat: 65.06487940188688,
            zoom_pc: 4.7,
            zoom_mv: 3.9
        },
        "ZA":{
            code: 14,
            lon: 24.973667159126602,
            lat: -28.73527698114298,
            zoom_pc: 5.9,
            zoom_mv: 4.9
        },
        "BS":{
            code: 15,
            lon: -76.55404816504368,
            lat: 23.851990944839173,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "CF":{
            code: 16,
            lon: 20.853994671234425,
            lat: 6.527510000182121,
            zoom_pc: 6.3,
            zoom_mv: 5.3
        },
        "KZ":{
            code: 17,
            lon: 66.85161308294828,
            lat: 47.899761530635594,
            zoom_pc: 5,
            zoom_mv: 3.7
        },
        "ES":{
            code: 18,
            lon: -2.5,
            lat: 39.3,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "DE":{
            code: 19,
            lon: 10.438732,
            lat: 51.1259,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "IT":{
            code: 20,
            lon: 12.472817,
            lat: 41.998807,
            zoom_pc: 5.8,
            zoom_mv: 5
        },
        "NL":{
            code: 21,
            lon: 5.467653,
            lat: 52.184243,
            zoom_pc: 7.7,
            zoom_mv: 6.2
        },
        "GR":{
            code: 22,
            lon: 24.15834823369123,
            lat: 38.52,
            zoom_pc: 6.5,
            zoom_mv: 5.4
        },
        "TR":{
            code: 23,
            lon: 35,
            lat: 37.94508403410723,
            zoom_pc: 5.8,
            zoom_mv: 4.7
        },
        "MG":{
            code: 24,
            lon: 47.070400025769125,
            lat: -19.010903398184755,
            zoom_pc: 6,
            zoom_mv: 4.9
        },
        "BE":{
            code: 25,
            lon: 4.492814088492509,
            lat: 50.554386731658816,
            zoom_pc: 7.9,
            zoom_mv: 6.7
        },
        "WS":{
            code: 26,
            lon: -172.0623779296875,
            lat: -13.80074084711067,
            zoom_pc: 9,
            zoom_mv: 7.8
        },
        "RO":{
            code: 27,
            lon: 24.807214197503665,
            lat: 45.71539577109362,
            zoom_pc: 6.6,
            zoom_mv: 5.4
        },
        "AU":{
            code: 28,
            lon: 132.35613418156535,
            lat: -32.40624675464848,
            zoom_pc: 3.5,
            zoom_mv: 2.5
        },
        "VC":{
            code: 29,
            lon: -61.18560791015626,
            lat: 13.07412756381517,
            zoom_pc: 10,
            zoom_mv: 9
        },
        "UG":{
            code: 30,
            lon: 32.24277393684221,
            lat: 0.6448726258315625,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "TZ":{
            code: 31,
            lon: 35.362109375001,
            lat: -6.489983332670651,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "TM":{
            code: 32,
            lon: 59.405889948541436,
            lat: 38.41990737560266,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "TG":{
            code: 33,
            lon: 1.1179679530940012,
            lat: 8.5370900198655,
            zoom_pc: 7.3,
            zoom_mv: 6.3
        },
        "SD":{
            code: 34,
            lon: 31.326505592902016,
            lat: 13.804640790530856,
            zoom_pc: 5.4,
            zoom_mv: 4.4
        },
        "LA":{
            code: 35,
            lon: 104.42454495704612,
            lat: 18.238814255238804,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "NZ":{
            code: 36,
            lon: 172.5155630360745,
            lat: -41.19598885546359,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "GW":{
            code: 37,
            lon: -15.144653320312502,
            lat: 11.775947798478454,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "IQ":{
            code: 38,
            lon: 44.28327233084478,
            lat: 33.431138324959385,
            zoom_pc: 6.1,
            zoom_mv: 5.1
        },
        "BH":{
            code: 39,
            lon: 50.57282636038412,
            lat: 26.06100682138164,
            zoom_pc: 10,
            zoom_mv: 9
        },
        "AZ":{
            code: 40,
            lon: 47.7959342072732,
            lat: 40.135060391963094,
            zoom_pc: 7.2,
            zoom_mv: 6.2
        },
        "GM":{
            code: 41,
            lon: -15.362650672344687,
            lat: 13.169707287166272,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "CM":{
            code: 42,
            lon: 13.203433130336485,
            lat: 7.278127504171087,
            zoom_pc: 6.3,
            zoom_mv: 5.3
        },
        "VG":{
            code: 43,
            lon: -64.7114339,
            lat: 18.5219483,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "CN":{
            code: 44,
            lon: 103.00781250000001,
            lat: 36.38591277287654,
            zoom_pc: 3.9,
            zoom_mv: 2.9
        },
        "MZ":{
            code: 45,
            lon: 36.36846023738953,
            lat: -19.012147556491403,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "ZW":{
            code: 46,
            lon: 29.52935842948666,
            lat: -19.276346840963825,
            zoom_pc: 6.5,
            zoom_mv: 5.5
        },
        "HU":{
            code: 47,
            lon: 19.510062253132585,
            lat: 47.073914119453065,
            zoom_pc: 6.9,
            zoom_mv: 5.9
        },
        "GB":{
            code: 48,
            lon: -2.845024,
            lat: 54.988164,
            zoom_pc: 5.3,
            zoom_mv: 4.5
        },
        "BZ":{
            code: 49,
            lon: -88.56236400155,
            lat: 17.02697994015405,
            zoom_pc: 7.9,
            zoom_mv: 6.9
        },
        "IL":{
            code: 50,
            lon: 35.249795212465465,
            lat: 31.330254024916684,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "BM":{
            code: 51,
            lon: -64.76785495434116,
            lat: 32.26968663505434,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "KI":{
            code: 52,
            lon: -158.30849841552677,
            lat: 2.2400603103352785,
            zoom_pc: 7.2,
            zoom_mv: 6.2
        },
        "LB":{
            code: 53,
            lon: 35.2869926,
            lat: 33.8722895,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "NP":{
            code: 54,
            lon: 84.20385916747713,
            lat: 27.846215400781436,
            zoom_pc: 6.7,
            zoom_mv: 5.7
        },
        "MM":{
            code: 55,
            lon: 97.58429632351006,
            lat: 19.810407940360694,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "NG":{
            code: 56,
            lon: 8.547363281250002,
            lat: 9.037002898469423,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "RS":{
            code: 57,
            lon: 20.5295226,
            lat: 44.1087763,
            zoom_pc: 7.2,
            zoom_mv: 6
        },
        "US":{
            code: 58,
            lon: -95.268904,
            lat: 37.419118,
            zoom_pc: 3.8,
            zoom_mv: 3
        },
        "MY":{
            code: 59,
            lon: 108.9522248104523,
            lat: 2.9470782347596725,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "NE":{
            code: 60,
            lon: 8.197375861243273,
            lat: 16.406976380665462,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "KG":{
            code: 61,
            lon: 74.69342468894294,
            lat: 41.27674445048369,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "UA":{
            code: 62,
            lon: 31.427689710641697,
            lat: 48.00406613981369,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "DZ":{
            code: 63,
            lon: 1.298105835659107,
            lat: 28.880234953353575,
            zoom_pc: 5,
            zoom_mv: 4
        },
        "ML":{
            code: 64,
            lon: -3.866955513896573,
            lat: 16.978679704508135,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "CG":{
            code: 65,
            lon: 15.129361778067977,
            lat: -0.804208245654707,
            zoom_pc: 6.5,
            zoom_mv: 5.5
        },
        "BN":{
            code: 66,
            lon: 114.59564208984376,
            lat: 4.5654735507102915,
            zoom_pc: 9,
            zoom_mv: 8
        },
        "AR":{
            code: 67,
            lon: -68.090545,
            lat: -40.347314,
            zoom_pc: 4.2,
            zoom_mv: 3.4
        },
        "BI":{
            code: 68,
            lon: 29.97117806880609,
            lat: -3.4734068025509224,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "OM":{
            code: 69,
            lon: 56.54299782022736,
            lat: 21.666516806904283,
            zoom_pc: 6.1,
            zoom_mv: 5.1
        },
        "BG":{
            code: 70,
            lon: 25.521240234375004,
            lat: 42.50450285299051,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "GT":{
            code: 71,
            lon: -90.09506712884861,
            lat: 15.697894011697503,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "HT":{
            code: 72,
            lon: -72.96569824218751,
            lat: 18.833515396433512,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "PY":{
            code: 73,
            lon: -58.267493468336454,
            lat: -23.513715521762943,
            zoom_pc: 6.5,
            zoom_mv: 5.5
        },
        "DM":{
            code: 74,
            lon: -61.33129008427306,
            lat: 15.418909557278567,
            zoom_pc: 10,
            zoom_mv: 9
        },
        "TH":{
            code: 76,
            lon: 101.09227146387504,
            lat: 13.190492688374631,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "VI":{
            code: 77,
            lon: -65.0188695,
            lat: 18.0665782,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "HK":{
            code: 78,
            lon: 113.9876163,
            lat: 22.3526738,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "MW":{
            code: 79,
            lon: 34.19387984322611,
            lat: -13.270987184876686,
            zoom_pc: 6.7,
            zoom_mv: 5.7
        },
        "GN":{
            code: 81,
            lon: -11.32138682127288,
            lat: 9.572839070382324,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "BR":{
            code: 82,
            lon: -55.272,
            lat: -14.616,
            zoom_pc: 4.6,
            zoom_mv: 3.3
        },
        "IE":{
            code: 83,
            lon: -8.204866497020726,
            lat: 53.4119610645289,
            zoom_pc: 6.9,
            zoom_mv: 5.9
        },
        "BJ":{
            code: 84,
            lon: 2.286529250145457,
            lat: 9.166375323956295,
            zoom_pc: 6.9,
            zoom_mv: 5.9
        },
        "BD":{
            code: 85,
            lon: 90.35156250000001,
            lat: 23.765236889758672,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "TT":{
            code: 86,
            lon: -61.15264892578125,
            lat: 10.676802582247122,
            zoom_pc: 9,
            zoom_mv: 8
        },
        "VN":{
            code: 87,
            lon: 106.2861619576739,
            lat: 16.147304516269557,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "PK":{
            code: 88,
            lon: 68.67376503000976,
            lat: 30.44700893376774,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "IO":{
            code: 89,
            lon: 72.42714709268826,
            lat: -7.357956006793675,
            zoom_pc: 10.8,
            zoom_mv: 9.8
        },
        "TL":{
            code: 90,
            lon: 125.44993135408298,
            lat: -8.955551483379015,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "DJ":{
            code: 91,
            lon: 42.59219016356459,
            lat: 11.644884553378196,
            zoom_pc: 8.5,
            zoom_mv: 7.5
        },
        "QA":{
            code: 92,
            lon: 51.190771817632914,
            lat: 25.26088115816016,
            zoom_pc: 8.5,
            zoom_mv: 7.5
        },
        "TJ":{
            code: 93,
            lon: 71.12368145125679,
            lat: 38.39864719059471,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "UZ":{
            code: 94,
            lon: 64.38278715610961,
            lat: 41.20455134970973,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "EG":{
            code: 95,
            lon: 31.003417968750004,
            lat: 27.449790329784214,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "SV":{
            code: 96,
            lon: -88.88488769531251,
            lat: 13.592599757147152,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "DO":{
            code: 97,
            lon: -70.1032284840735,
            lat: 18.702255299570115,
            zoom_pc: 7.7,
            zoom_mv: 6.7
        },
        "KE":{
            code: 98,
            lon: 38.16650390625001,
            lat: -0.02394530819134536,
            zoom_pc: 6.5,
            zoom_mv: 5.5
        },
        "FI":{
            code: 99,
            lon: 25.620117187500004,
            lat: 65.20146842127558,
            zoom_pc: 5,
            zoom_mv: 4
        },
        "CY":{
            code: 100,
            lon: 33.11279296875001,
            lat: 35.22767235493586,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "AF":{
            code: 101,
            lon: 67.76480746199901,
            lat: 33.82273354825585,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "AN":{
            code: 102,
            lon: -65.10498046875001,
            lat: 14.689881366618774,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "BW":{
            code: 103,
            lon: 24.411621093750004,
            lat: -22.492257220085193,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "TO":{
            code: 104,
            lon: -174.7484891885511,
            lat: -20.808875456525428,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "MV":{
            code: 105,
            lon: 70.9963764,
            lat: 3.1097164,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "PL":{
            code: 106,
            lon: 19.069260401897,
            lat: 51.997900137486,
            zoom_pc: 6.3,
            zoom_mv: 5.3
        },
        "GY":{
            code: 107,
            lon: -59.10061991229943,
            lat: 4.904438253994419,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "LY":{
            code: 108,
            lon: 17.698419840294534,
            lat: 26.958476340622603,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "RW":{
            code: 109,
            lon: 29.3199869,
            lat: -1.9435639,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "NA":{
            code: 110,
            lon: 17.8726617,
            lat: -22.9037722,
            zoom_pc: 5.8,
            zoom_mv: 4.8
        },
        "KN":{
            code: 111,
            lon: -62.8366839,
            lat: 17.2495511,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "LR":{
            code: 112,
            lon: -9.397388145291501,
            lat: 6.455384716832533,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "BB":{
            code: 113,
            lon: -59.56169128417969,
            lat: 13.179114050717942,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "CR":{
            code: 114,
            lon: -84.200903,
            lat: 9.75803,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "EC":{
            code: 115,
            lon: -78.57197265625001,
            lat: -1.7575368113083125,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "CO":{
            code: 116,
            lon: -73.04447718366494,
            lat: 3.9797239326897276,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "KM":{
            code: 118,
            lon: 43.865069605804,
            lat: -12.003101866894836,
            zoom_pc: 8.5,
            zoom_mv: 7.5
        },
        "SO":{
            code: 119,
            lon: 46.359811272191465,
            lat: 5.230323659918098,
            zoom_pc: 5.9,
            zoom_mv: 4.9
        },
        "CZ":{
            code: 120,
            lon: 15.571784260865941,
            lat: 49.4001673151542,
            zoom_pc: 6.9,
            zoom_mv: 5.9
        },
        "SB":{
            code: 121,
            lon: 160.64208984375003,
            lat: -9.102096738726443,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "RU":{
            code: 122,
            lon: 108.535,
            lat: 67.53,
            zoom_pc: 3.5,
            zoom_mv: 2.5
        },
        "SR":{
            code: 123,
            lon: -55.80487671208008,
            lat: 3.9857157769139744,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "TW":{
            code: 124,
            lon: 120.82766961915382,
            lat: 23.50623577543614,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "LK":{
            code: 125,
            lon: 80.68779334288669,
            lat: 7.810046288687805,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "LT":{
            code: 126,
            lon: 23.818359375000004,
            lat: 55.16004309876882,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "JM":{
            code: 127,
            lon: -77.31628417968751,
            lat: 17.99963161491188,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "CD":{
            code: 128,
            lon: 22.033192006354877,
            lat: -4.278141087047829,
            zoom_pc: 5.2,
            zoom_mv: 4.2
        },
        "MD":{
            code: 129,
            lon: 28.43681447039345,
            lat: 47.01232905249933,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "KH":{
            code: 130,
            lon: 104.952392578125,
            lat: 12.511665400971031,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "PW":{
            code: 131,
            lon: 134.42321777343753,
            lat: 7.3025362053673275,
            zoom_pc: 9.5,
            zoom_mv: 8.5
        },
        "SK":{
            code: 132,
            lon: 19.654541015625004,
            lat: 48.44377831058805,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "KW":{
            code: 133,
            lon: 47.55122623307104,
            lat: 29.312272680568842,
            zoom_pc: 8.5,
            zoom_mv: 7.5
        },
        "KR":{
            code: 134,
            lon: 127.33223906275475,
            lat: 36.02930628471471,
            zoom_pc: 6.7,
            zoom_mv: 5.7
        },
        "FR":{
            code: 135,
            lon: 2.526931,
            lat: 46.161201,
            zoom_pc: 5.8,
            zoom_mv: 4.8
        },
        "CH":{
            code: 137,
            lon: 8.262001486173235,
            lat: 46.65625818191163,
            zoom_pc: 7.8,
            zoom_mv: 6.5
        },
        "LV":{
            code: 138,
            lon: 24.494108031430077,
            lat: 56.705295949782105,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "PE":{
            code: 139,
            lon: -74.13078500232861,
            lat: -10.2,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "SE":{
            code: 140,
            lon: 17.527880702785936,
            lat: 62.98486638477425,
            zoom_pc: 4.8,
            zoom_mv: 3.8
        },
        "BA":{
            code: 141,
            lon: 17.630821069912013,
            lat: 43.95238653247354,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "SI":{
            code: 142,
            lon: 14.906151082400124,
            lat: 45.98510632663338,
            zoom_pc: 7.7,
            zoom_mv: 6.7
        },
        "PA":{
            code: 143,
            lon: -80.14526367187501,
            lat: 8.819938928283147,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "SL":{
            code: 144,
            lon: -11.928348512282001,
            lat: 8.24850918703467,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "MT":{
            code: 145,
            lon: 14.400505612280377,
            lat: 35.88276289881932,
            zoom_pc: 10,
            zoom_mv: 9
        },
        "LU":{
            code: 146,
            lon: 6.1042986342798455,
            lat: 49.81759737978471,
            zoom_pc: 9.5,
            zoom_mv: 8.5
        },
        "PG":{
            code: 147,
            lon: 147.83203125000003,
            lat: -7.514980942395872,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "MH":{
            code: 148,
            lon: 161.7332697,
            lat: 9.5606612,
            zoom_pc: 5.5,
            zoom_mv: 4.5
        },
        "GQ":{
            code: 149,
            lon: 9.481657103625157,
            lat: 2.1472952096172144,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "SZ":{
            code: 150,
            lon: 30.902344,
            lat: -26.516566,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "VE":{
            code: 151,
            lon: -66.5,
            lat: 6.83762687766569,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "LS":{
            code: 152,
            lon: 28.295288085937504,
            lat: -29.816816857649936,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "ZM":{
            code: 153,
            lon: 28.146972656250004,
            lat: -14.115267411122709,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "UY":{
            code: 154,
            lon: -55.82153320312501,
            lat: -32.62087018318113,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "MX":{
            code: 155,
            lon: -101.843262,
            lat: 23.241346,
            zoom_pc: 4.8,
            zoom_mv: 4
        },
        "AO":{
            code: 156,
            lon: 17.773611459184934,
            lat: -11.871667761104039,
            zoom_pc: 5.7,
            zoom_mv: 4.7
        },
        "GA":{
            code: 158,
            lon: 11.538237715327432,
            lat: -0.8204792693004193,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "FJ":{
            code: 160,
            lon: 177.86175606452258,
            lat: -15.580227483395896,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "TV":{
            code: 161,
            lon: 177.72583007812503,
            lat: -7.297087564171992,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "NR":{
            code: 162,
            lon: 166.93322191487428,
            lat: -0.530412449598254,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "JP":{
            code: 163,
            lon: 134.12109375000003,
            lat: 34.52466147177175,
            zoom_pc: 4.8,
            zoom_mv: 3.8
        },
        "AT":{
            code: 164,
            lon: 13.079224,
            lat: 47.798397,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "CA":{
            code: 165,
            lon: -110.478516,
            lat: 60.586967,
            zoom_pc: 3.3,
            zoom_mv: 2
        },
        "HN":{
            code: 166,
            lon: -78.15,
            lat: 15.43,
            zoom_pc: 7.5,
            zoom_mv: 6.3
        },
        "MK":{
            code: 167,
            lon: 21.802368164062504,
            lat: 41.43860847395721,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "BF":{
            code: 168,
            lon: -1.4605287332633845,
            lat: 11.714095918776671,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "KY":{
            code: 169,
            lon: -80.60442637703959,
            lat: 19.385250721727385,
            zoom_pc: 8.6,
            zoom_mv: 7.6
        },
        "AD":{
            code: 170,
            lon: 1.5276327,
            lat: 42.5422486,
            zoom_pc: 10,
            zoom_mv: 8.7
        },
        "MU":{
            code: 171,
            lon: 59.01855468750001,
            lat: -20.591652120829167,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "GD":{
            code: 172,
            lon: -61.68960571289063,
            lat: 12.097066997628836,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "ST":{
            code: 173,
            lon: 7.119140625,
            lat: 0.8019757647536726,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "JO":{
            code: 174,
            lon: 36.78222656250001,
            lat: 31.18460913574325,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "AW":{
            code: 175,
            lon: -69.96720889630419,
            lat: 12.503452356762637,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "BO":{
            code: 176,
            lon: -63.4767888935258,
            lat: -16.388379515967852,
            zoom_pc: 5.9,
            zoom_mv: 4.9
        },
        "CK":{
            code: 177,
            lon: -165.4332633,
            lat: -15.5831925,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "BY":{
            code: 178,
            lon: 27.978472550459283,
            lat: 53.53730680927531,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "CV":{
            code: 181,
            lon: -23.8623046875,
            lat: 15.908508237156719,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "PT":{
            code: 182,
            lon: -8.702607,
            lat: 39.720558,
            zoom_pc: 6.8,
            zoom_mv: 5.9
        },
        "NI":{
            code: 183,
            lon: -85.41743525156888,
            lat: 12.965981885753202,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "TD":{
            code: 184,
            lon: 18.57369904285142,
            lat: 15.475474933537805,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "SC":{
            code: 185,
            lon: 55.44937133789063,
            lat: -4.699616380090987,
            zoom_pc: 9,
            zoom_mv: 8
        },
        "SG":{
            code: 186,
            lon: 103.81776249411249,
            lat: 1.3357089811686969,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "VU":{
            code: 187,
            lon: 167.97713732802973,
            lat: -16.654814653303728,
            zoom_pc: 6.7,
            zoom_mv: 5.7
        },
        "FK":{
            code: 188,
            lon: -59.49096679687501,
            lat: -51.7202226169593,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "PH":{
            code: 189,
            lon: 122.80387104738507,
            lat: 13.05917665776563,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "TN":{
            code: 190,
            lon: 9.846777826338336,
            lat: 33.9423025674652,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "MO":{
            code: 192,
            lon: 113.56258392333986,
            lat: 22.158790993735032,
            zoom_pc: 9.4,
            zoom_mv: 8.4
        },
        "EE":{
            code: 193,
            lon: 25.8837890625,
            lat: 58.62549730245039,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "GL":{
            code: 194,
            lon: -41.48,
            lat: 75.89,
            zoom_pc: 3.4,
            zoom_mv: 2.2
        },
        "GE":{
            code: 195,
            lon: 43.41145362135315,
            lat: 42.153730667811594,
            zoom_pc: 6.8,
            zoom_mv: 5.8
        },
        "CL":{
            code: 196,
            lon: -72.202148,
            lat: -37.649034,
            zoom_pc: 4.1,
            zoom_mv: 3.1
        },
        "AL":{
            code: 197,
            lon: 19.96914171228791,
            lat: 41.13721530191829,
            zoom_pc: 7.5,
            zoom_mv: 6.5
        },
        "DK":{
            code: 198,
            lon: 11.67349528027009,
            lat: 56.098271460451194,
            zoom_pc: 6.7,
            zoom_mv: 5.7
        },
        "PR":{
            code: 199,
            lon: -66.48376464843751,
            lat: 18.119749966946426,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "FM":{
            code: 200,
            lon: 151.59744621347136,
            lat: 6.853911867737898,
            zoom_pc: 4.7,
            zoom_mv: 3.7
        },
        "ID":{
            code: 201,
            lon: 116.89453125000001,
            lat: -1.9332268264771106,
            zoom_pc: 4,
            zoom_mv: 3
        },
        "MN":{
            code: 202,
            lon: 104.38950855846458,
            lat: 46.50675327461666,
            zoom_pc: 4.5,
            zoom_mv: 3.5
        },
        "CU":{
            code: 203,
            lon: -79.49707031250001,
            lat: 21.3303150734318,
            zoom_pc: 6.5,
            zoom_mv: 5.5
        },
        "AG":{
            code: 204,
            lon: -62,
            lat: 17.07778960654538,
            zoom_pc: 9,
            zoom_mv: 8
        },
        "LC":{
            code: 205,
            lon: -61.1106003,
            lat: 13.9128128,
            zoom_pc: 6,
            zoom_mv: 5
        },
        "EH":{
            code: 206,
            lon: -12.825821480350232,
            lat: 24.56200851788201,
            zoom_pc: 5.6,
            zoom_mv: 4.6
        },
        "SH":{
            code: 207,
            lon: -10.986328125000002,
            lat: -25.562265014427492,
            zoom_pc: 4,
            zoom_mv: 3
        },
        "AQ":{
            code: 208,
            lon: 134.9978113,
            lat: -82.862752,
            zoom_pc: 3,
            zoom_mv: 2
        },
        "AM":{
            code: 209,
            lon: 45.07690429687501,
            lat: 40.073868105094846,
            zoom_pc: 7.8,
            zoom_mv: 6.8
        },
        "HR":{
            code: 210,
            lon: 16.501464843750004,
            lat: 44.41808794374849,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "SN":{
            code: 211,
            lon: -14.490966796875002,
            lat: 13.998036539606128,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "KP":{
            code: 212,
            lon: 127.79068317110686,
            lat: 40.18238404597182,
            zoom_pc: 6.6,
            zoom_mv: 5.6
        },
        "ME":{
            code: 213,
            lon: 19.243,
            lat: 42.707,
            zoom_pc: 8.5,
            zoom_mv: 7.3
        },
        "XK":{
            code: 214,
            lon: 20.8380848,
            lat: 42.5004874,
            zoom_pc: 8.8,
            zoom_mv: 7.5
        },
        "LI":{
            code: 215,
            lon: 9.549262,
            lat: 47.14879,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "MC":{
            code: 216,
            lon: 7.419995,
            lat: 43.735998,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "SM":{
            code: 217,
            lon: 12.460847,
            lat: 43.940914,
            zoom_pc: 10.4,
            zoom_mv: 9.4
        },
        "BT":{
            code: 218,
            lon: 90.447019,
            lat: 27.354511,
            zoom_pc: 8,
            zoom_mv: 7
        },
        "ER":{
            code: 219,
            lon: 40.14917,
            lat: 15.258404,
            zoom_pc: 7,
            zoom_mv: 6
        },
        "VA":{
            code: 220,
            lon: 12.4528,
            lat: 41.9036,
            zoom_pc: 8,
            zoom_mv: 7
        }
    };

export default countriesCodes;

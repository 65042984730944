import CheckBoxView from "../ui/CheckBoxView";
import store from "./store";

class CBParticulasController{
    constructor(view) {
        this.view = view;

        const anim = store.get("particlesAnim");

        this.view.checkbox.checked = anim !== "off";

        this.view.checkbox.addEventListener("change", this.change.bind(this));

        store.on("particlesAnim",function(type){
            this.view.checkbox.checked = type !== "off";
        }.bind(this))

    }


    change() {
        if( ! this.view.checkbox.checked )
            store.set("particlesAnim", "off");
        else
            store.set("particlesAnim", "on");
    }

}

export default CBParticulasController;

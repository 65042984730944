import store from "./store";


class PluginButtonController{

    constructor (view){
        this.view= view;
        this.view.bindOpenPlugin(this.openPlugin.bind(this));
    }

    openPlugin (pluginid){
        store.set(pluginid, "on");
    }
}

export default PluginButtonController;

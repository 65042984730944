

class MessageController {

    constructor(view) {
        this.view = view;

        document.addEventListener("ShowMessage",this.showText.bind(this));
    }


    showText(e){
        this.view.showText(e.detail);
    }

}

export default MessageController;

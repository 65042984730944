import colors from "./colors";
import layer from "./Layer";
import metrics from "./metrics";
import legends from "./legends";

// define(["colors", "metrics", "Layer", "legends"], function(colors, metrics, layer, i) {
var layers = {},
    pow2 = function(exp) {  // a
        // exp = -9.965784285 es el valor que hace que 2^exp sea igual 0.001, así que para los valores inferiores no
        // hace falta calcular su exponencial y devolvemos directamente 0
        return (exp > -9.965784285) ? Math.max(0, Math.pow(2, exp) - .001) : 0;
    },

    discretepow2 = function(exp){

        if (exp < -9.965784285)
            return 0;

        const minexp = -10;
        const floatarray=[0.000976562,  0.001953125,  0.00390625,  0.0078125,  0.015625,  0.03125,  0.0625, 0.125,  0.25, 0.5]

        const upint=Math.ceil(exp);
        const bottomint= Math.floor(exp);

        const floatvalue= exp - bottomint;

        const updecodevalue = upint >= 0 ? 1 << upint : floatarray[ upint - minexp ];
        const bottomdecodevalue = bottomint >= 0 ? 1 << bottomint : floatarray[ bottomint - minexp ]

        return floatvalue * (updecodevalue - bottomdecodevalue) + bottomdecodevalue - .001;

    };


// return s.capAlerts = n.instance({
//     ident: "capAlerts",
//     renderer: "capAlerts"
// }),
layers.pressureIsolines = layer.instance({
    ident: "pressureIsolines",
    renderer: "isolines",
    filename: "presion",
    levels: ["surface"]
}),
layers.symbols = layer.instance({
    ident: "symbols",
    renderer: "symbols"

    // filename: "symbols",
    // levels: ["surface"]
}),
layers.daysymbols = layer.instance({
    ident: "daysymbols",
    renderer: "symbols",
    daily: true
    // filename: "symbols",
    // levels: ["surface"]
}),
layers.hurricanes = layer.instance({
    ident: "hurricanes",
    renderer: "hurricanes"
}),
layers.warnings = layer.instance({
    ident: "warnings",
    renderer: "warnings"
})
//     s.ghIsolines = n.instance({
//         ident: "ghIsolines",
//         renderer: "isolines",
//         levels: ["950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"]
//     }),
//     s.tempIsolines = n.instance({
//         ident: "tempIsolines",
//         renderer: "isolines",
//         levels: ["surface", "950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"]
//     }),
//     s.deg0Isolines = n.instance({
//         ident: "deg0Isolines",
//         renderer: "isolines",
//         levels: ["surface"]
//     }),
layers.windParticles = layer.instance({
    ident: "windParticles",
    renderer: "particles",
    filename: "viento",
    fileSuffix: "png",
    coverWholeMap: false,
    renderParams: {
        particlesIdent: "wind"
    }
}),
layers.ecmwfWindParticles = layer.instance({
    ident: "ecmwfWindParticles",
    renderer: "particles",
    product: "ecmwf",
    levels: ["surface"],
    filename: "viento",
    fileSuffix: "png",
    coverWholeMap: false,
    renderParams: {
        particlesIdent: "wind"
    }
}),
//     s.ecmwfWindParticles150h = n.instance({
//         ident: "ecmwfWindParticles150h",
//         renderer: "particles",
//         product: "ecmwf",
//         levels: ["150h"],
//         filename: "wind",
//         fileSuffix: "jpg",
//         renderParams: {
//             particlesIdent: "wind"
//         }
//     }),
//     s.ecmwfWindParticles600h = n.instance({
//         ident: "ecmwfWindParticles600h",
//         renderer: "particles",
//         product: "ecmwf",
//         levels: ["600h"],
//         filename: "wind",
//         fileSuffix: "jpg",
//         renderParams: {
//             particlesIdent: "wind"
//         }
//     }),
layers.ecmwfWindParticles500h = layer.instance({
    ident: "ecmwfWindParticles500h",
    renderer: "particles",
    product: "ecmwf",
    levels: ["500"],
    filename: "viento",
    fileSuffix: "png",
    coverWholeMap: false,
    renderParams: {
        particlesIdent: "wind"
    }
}),
layers.ecmwfWindParticles700h = layer.instance({
    ident: "ecmwfWindParticles700h",
    renderer: "particles",
    product: "ecmwf",
    levels: ["700"],
    filename: "viento",
    fileSuffix: "png",
    coverWholeMap: false,
    renderParams: {
        particlesIdent: "wind"
    }
}),
layers.waveParticles = layer.instance({
    ident: "waveParticles",
    renderer: "particles",
    filename: "waves",
    PNGtransparency: true,
    renderParams: {
        particlesIdent: "waves"
    }
}),
layers.windWaveParticles = layers.waveParticles.instance({
    ident: "windWaveParticles",
    filename: "windwaves"
}),
layers.swellWaveParticles = layers.waveParticles.instance({
    ident: "swellWaveParticles",
    filename: "swellwaves"
}),
//     s.currentParticles = n.instance({
//         ident: "currentParticles",
//         renderer: "particles",
//         PNGtransparency: !0,
//         product: "sea",
//         filename: "sstcombined",
//         renderParams: {
//             particlesIdent: "currents"
//         }
//     }),
layers.viento = layer.instance({
    ident: "viento",
    filename: "viento",
    renderParams: {
        renderFrom: "RG",
        coverWholeMap: true
    },
    fileSuffix: "png",
    c: colors.wind,
    m: metrics.wind
}),
layers.temp2m = layer.instance({
    ident: "temp2m",
    dataQuality: "top",
    qualityLevels: ["surface"],
    c: colors.temp,
    m: metrics.temp,
    renderParams: {
        coverWholeMap: true
    }
}),
layers.atemp = layer.instance({
    ident: "atemp",
    c: colors.atemp,
    m: metrics.temp,
    levels: ["surface", "850"],
    renderParams: {
        coverWholeMap: true
    }
}),
layers.waterprec = layer.instance({
   ident: "waterprec",
   filename: "waterprec",
   renderParams: {
       coverWholeMap: true
   },
   fileSuffix: "png",
   c: colors.waterprec,
   m: metrics.waterprec
}),
layers.ivt = layer.instance({
    ident: "ivt",
    filename: "ivt",
    renderParams: {
        coverWholeMap: true,
        renderFrom: "RG"
    },
    fileSuffix: "png",
    c: colors.ivt,
    m: metrics.ivt
}),
layers.ivtVectors = layer.instance({
    ident: "ivtVectors",
    renderer: "particles",
    levels: ["surface"],
    filename: "ivt",
    fileSuffix: "png",
    coverWholeMap: false,
    renderParams: {
        particlesIdent: "ivtvectors"
    }
}),

// s.dewpoint = n.instance({
//     ident: "dewpoint",
//     c: colors.temp,
//     m: t.temp
// }),
layers.rachas = layer.instance({
    ident: "rachas",
    filename: "rachas",
    renderParams: {
        coverWholeMap: true
    },
    fileSuffix: "png",
    c: colors.wind,
    m: metrics.wind
}),
// s.gustAccu = n.instance({
//     ident: "gustAccu",
//     fileSuffix: "jpg",
//     JPGtransparency: !0,
//     pathGenerator: "{server}/{directory}/{acTime}/257w<z>/<y>/<x>/gust-surface.jpg?acc=maxip",
//     c: colors.wind,
//     m: t.wind
// }),
layers.humedad = layer.instance({
    ident: "humedad",
    c: colors.rh,
    filename: "humedad",
    fileSuffix: "png",
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.rh
}),
// s.pressure = n.instance({
//     ident: "pressure",
//     fileSuffix: "png",
//     PNGtransparency: !0,
//     c: colors.pressure,
//     m: t.pressure
// }),
layers.prcnub = layer.instance({
    ident: "prcnub",
    filename: "prcnub",
    fileSuffix: "png",
    c: colors.clouds,
    legends: ["nubes", "precip", "nieve"],
    dataQuality: "top",
    renderParams: {
        renderFrom: "RGB",
        isMultiColor: true,
        coverWholeMap: false,
    },
    transformG: discretepow2,
    transformB: discretepow2,
    getColor2: function() {
        return colors.rain.getColor()
    },
    getColor3: function() {
        return colors.snow.getColor()
    },
    getAmountByColor: function(value1, value2, value3) {
        // return value2 < .3 ? 0 : value2 < 3 ? value2 : value2 < 6 ? 2 : 3
        return value3 < .2 ? (value2 < .2 ? 0 : 1 ) : 2;
        // return value3 <= 0.001 ? (value2 <= 0.001 ? 0 : 1 ) : 2;
    }

}),
layers.precip = layer.instance({
    ident: "precip",
    // renderer: "tileLayerPatternator",
    filename: "prcnub",
    fileSuffix: "png",
    // PNGtransparency: true,
    c: colors.rain,
    trans: "PREC",
    // bgColor: [146, 130, 70, 255],
    // bgColor: [89, 89, 89, 255],
    // bgColor: [0, 0, 0, 255],
    m: metrics.rain,
    // renderParams: {
    // //     pattern: "rainPattern",
    // //     interpolateNearestG: true
    // }
    renderParams: {
        renderFrom: "G",
        coverWholeMap: false
        // isMultiColor: true
    },

    transformG: discretepow2
    // transformR: a,
    // transformG: function(e) {
    //     return Math.round(4 * e) / 4
    // }
}),
layers.nieve = layer.instance({
    ident: "nieve",
    // renderer: "tileLayerPatternator",
    filename: "prcnub",
    fileSuffix: "png",
    // PNGtransparency: true,
    c: colors.snow,
    trans: "SNOW",
    dataQuality: "top",
    // bgColor: [146, 130, 70, 255],
    // bgColor: [89, 89, 89, 255],
    renderParams: {
        coverWholeMap: false,
        renderFrom: "B"//,
        // isMultiColor: true
    },
    transformB: discretepow2,
    m: metrics.snow
    // m: t.rain,
    // renderParams: {
    //     pattern: "rainPattern",
    //     interpolateNearestG: true
    // },
    // transformR: a,
    // transformG: function(e) {
    //     return Math.round(4 * e) / 4
    // }
}),

// s.ptype = n.instance({
//     ident: "ptype",
//     renderer: "tileLayerPatternator",
//     filename: "rainlogptype",
//     fileSuffix: "png",
//     PNGtransparency: !0,
//     c: colors.justGray,
//     m: t.ptype,
//     renderParams: {
//         pattern: "ptypePattern",
//         interpolateNearestG: !0
//     },
//     transformR: a,
//     transformG: Math.round
// }),
// s.thunder = n.instance({
//     ident: "thunder",
//     filename: "lightdens",
//     transformR: a,
//     c: colors.lightDensity,
//     m: t.lightDensity
// }),
layers.nubes = layer.instance({
    ident: "nubes",
    filename: "prcnub",
    renderParams: {
        coverWholeMap: false

        // renderFrom: "RG",
        // isMultiColor: !0
    },
    trans: "CLOUDS",
    c: colors.clouds,
    m: metrics.clouds
    // m: t.rain,
    // transformG: function(e) {
    //     return e < 10 ? e : 10 * (e - 10) + 10
    // },
    // getColor2: function() {
    //     return colors.rainClouds.getColor()
    // },
    // getAmountByColor: function(e, t) {
    //     return t < .3 ? 0 : t < 3 ? 1 : t < 6 ? 2 : 3
    // }
}),
layers.rayos = layer.instance({
    ident: "rayos",
    filename: "rayos",
    // transformR: pow2,
    c: colors.lightDensity,
    m: metrics.lightDensity
}),
layers.uvi = layer.instance({
    ident: "uvi",
    filename: "uvi",
    // transformR: pow2,
    c: colors.uvi,
    m: metrics.uvi
}),
// s.lclouds = n.instance({
//     ident: "lclouds",
//     c: colors.lclouds,
//     m: t.clouds
// }),
// s.mclouds = n.instance({
//     ident: "mclouds",
//     c: colors.mclouds,
//     m: t.clouds
// }),
// s.hclouds = n.instance({
//     ident: "hclouds",
//     c: e.hclouds,
//     m: t.clouds
// }),
// s.cape = n.instance({
//     ident: "cape",
//     c: e.cape,
//     m: t.cape
// }),
// s.cbase = n.instance({
//     ident: "cbase",
//     fileSuffix: "png",
//     PNGtransparency: !0,
//     c: e.cbase,
//     m: t.altitude,
//     l: i.cbase
// }),
// s.fog = n.instance({
//     ident: "fog",
//     filename: "fogtype",
//     fileSuffix: "png",
//     c: e.fog,
//     m: t.fog
// }),
// s.snowAccu = n.instance({
//     ident: "snowAccu",
//     c: e.snow,
//     m: t.snow,
//     transformR: a,
//     fileSuffix: "png",
//     PNGtransparency: !0,
//     pathGenerator: "{server}/{directory}/{acTime}/257w<z>/<y>/<x>/snowaccumulationlog-surface.png",
//     renderParams: {
//         interpolate: "interpolateOverlay"
//     }
// }),
layers.acumprec = layer.instance({
    ident: "acumprec",
    transformR: discretepow2,
    filename: "acumprec",
    coverWholeMap: false,
    fileSuffix: "png",
    PNGtransparency: true,
    // pathGenerator: "{server}/{directory}/{acTime}/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // pathGenerator: "{server}/{directory}/next3d/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // renderParams: {
    //     interpolate: "interpolateOverlay"
    // },
    c: colors.rainAccu,
    // bgColor: [146, 130, 70, 255]
    m: metrics.rainAccu,
    // l: i.rainAccu
}),
layers.acumnieve = layer.instance({
    ident: "acumnieve",
    transformR: discretepow2,
    filename: "acumnieve",
    coverWholeMap: false,
    fileSuffix: "png",
    PNGtransparency: true,
    // pathGenerator: "{server}/{directory}/{acTime}/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // pathGenerator: "{server}/{directory}/next3d/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // renderParams: {
    //     interpolate: "interpolateOverlay"
    // },
    c: colors.snowAccu,
    // bgColor: [146, 130, 70, 255]
    m: metrics.snowAccu,
    // l: i.rainAccu
}),
layers.snowdepth = layer.instance({
    ident: "snowdepth",
    transformR: discretepow2,
    filename: "snowdepth",
    coverWholeMap: false,
    fileSuffix: "png",
    PNGtransparency: true,
    // pathGenerator: "{server}/{directory}/{acTime}/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // pathGenerator: "{server}/{directory}/next3d/257w<z>/<y>/<x>/rainaccumulationlog-surface.png",
    // renderParams: {
    //     interpolate: "interpolateOverlay"
    // },
    c: colors.snowAccu,
    // bgColor: [146, 130, 70, 255]
    m: metrics.snowAccu,
    // l: i.rainAccu
}),
layers.prob = layer.instance({
    ident: "prob",
    // transformR: pow2,
    filename: "prob",
    fileSuffix: "png",
    PNGtransparency: true,
    c: colors.probprec,
    // bgColor: [146, 130, 70, 255]
    m: metrics.probprec,
    // l: i.rainAccu
}),

layers.oleajetotal = layer.instance({
    ident: "oleajetotal",
    filename: "oleajetotal",
    PNGtransparency: true,
    renderParams: {
        // interpolate: "interpolateWaves",
        renderFrom: "RG",
        isMultiColor: true,
        sea: true
    },
    c: colors.waves
    ,
    getColor2: function() {
        return colors.icecover.getColor()
    },
    getAmountByColor: function(value1, value2) {
        return value2 > 0 ? 1 : 0;
    },
    m: metrics.waves
}),
layers.oleajeviento = layers.oleajetotal.instance({
    ident: "oleajeviento",
    filename: "oleajeviento"
}),
layers.oleajefondo = layers.oleajetotal.instance({
    ident: "oleajefondo",
    filename: "oleajefondo"
}),
layers.oleajealtura = layers.oleajetotal.instance({
    ident: "oleajealtura",
    filename: "oleajealtura"
}),
// s.swell1 = s.waves.instance({
//     ident: "swell1"
// }),
// s.swell2 = s.waves.instance({
//     ident: "swell2"
// }),
// s.swell3 = s.waves.instance({
//     ident: "swell3"
// }),
// s.swell = s.swell1,
// s.currents = n.instance({
//     ident: "currents",
//     filename: "sstcombined",
//     PNGtransparency: !0,
//     renderParams: {
//         renderFrom: "RG",
//         sea: !0
//     },
//     c: e.currents,
//     m: t.currents
// }),


layers.tempmar = layer.instance({
    ident: "tempmar",
    PNGtransparency: true,
    // pathGenerator: "{server}/{directory}/latest/257w<z>/<y>/<x>/sst-surface.png",
    renderParams: {
        sea: true,
        renderFrom: "RG",
        isMultiColor: true
    },
    filename: "tempmar",
    c: colors.sst,
    getColor2: function() {
        return colors.icecover.getColor()
    },
    getAmountByColor: function(value1, value2) {
        // return value2 < .3 ? 0 : value2 < 3 ? value2 : value2 < 6 ? 2 : 3
        return value2 > 0 ? 1 : 0;
        // return value3 <= 0.001 ? (value2 <= 0.001 ? 0 : 1 ) : 2;
    },
    m: metrics.temp,
    l: legends.sst
}),
layers.visibilidad = layer.instance({
    ident: "visibilidad",
    c: colors.visibility,
    filename: "visibilidad",
    m: metrics.visibility
}),

layers.isozero = layer.instance({
    ident: "isozero",
    levels: ["surface"],
    c: colors.isozero,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.altitude
}),
// s.snowcover = n.instance({
//     ident: "snowcover",
//     filename: "snowcoverlog",
//     transformR: a,
//     c: e.snow,
//     m: t.snow
// }),
// s.cloudtop = n.instance({
//     ident: "cloudtop",
//     hasParticles: !0,
//     levels: ["surface"],
//     c: e.levels,
//     m: t.altitude,
//     l: i.ctops
// }),
// s.deg0 = n.instance({
//     ident: "deg0",
//     levels: ["surface"],
//     c: e.deg0,
//     m: t.altitude
// }),
// s.cosc = n.instance({
//     ident: "cosc",
//     filename: "chem_cosc",
//     c: e.cosc,
//     m: t.cosc,
//     transformR: function(e) {
//         return Math.pow(2, e) - 1
//     }
// }),
// s.so2sm = n.instance({
//     ident: "so2sm",
//     filename: "chem_so2sm",
//     c: e.so2,
//     m: t.so2,
//     fileSuffix: "png",
//     transformR: function(e) {
//         return Math.pow(2, e) - .001
//     }
// }),
// s.dustsm = n.instance({
//     ident: "dustsm",
//     filename: "chem_dustsm",
//     hasParticles: !1,
//     c: e.dust,
//     m: t.dust,
//     transformR: function(e) {
//         return Math.pow(2, e) - .1
//     }
// }),
layers.radar = layer.instance({
    ident: "radar",
    renderer: "radar",
    dataQuality: "top",
    c: colors.radar,
    m: metrics.radar,
    coverWholeMap: false,
}),

layers.ir = layer.instance({
    ident: "ir",
    renderer: "satellite",
    // dataQuality: "top",
    c: colors.ir,
    m: metrics.ir,
    coverWholeMap: false,
}),

layers.rgb = layer.instance({
    ident: "rgb",
    renderer: "satellite",
    // dataQuality: "top",
    c: colors.ir,
    // m: metrics.satellite,
    coverWholeMap: false,
}),

layers.vi = layer.instance({
    ident: "vi",
    renderer: "satellite",
    // dataQuality: "top",
    c: colors.ir,
    // m: metrics.satellite,
    coverWholeMap: false,
}),

layers.wv = layer.instance({
    ident: "wv",
    renderer: "satellite",
    // dataQuality: "top",
    c: colors.wv,
    m: metrics.wv,
    coverWholeMap: false,
}),

// s.satellite = n.instance({
//     ident: "satellite",
//     renderer: "satellite",
//     c: e.satellite,
//     m: t.satellite
// }),
// s.gtco3 = n.instance({
//     ident: "gtco3",
//     c: e.gtco3,
//     m: t.gtco3
// }),
layers.pm2p5 = layer.instance({
    ident: "pm2p5",
    c: colors.pm2p5,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pm2p5,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2

}),
layers.pm10 = layer.instance({
    ident: "pm10",
    c: colors.pm10,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pm10,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2

}),
layers.no2 = layer.instance({
    ident: "no2",
    c: colors.no2,
    m: metrics.no2,
    renderParams: {
        coverWholeMap: true
    },
    transformR: discretepow2
}),
layers.so2 = layer.instance({
    ident: "so2",
    c: colors.so2,
    m: metrics.so2,
    renderParams: {
        coverWholeMap: true
    },
    // m: t.no2,
    transformR: discretepow2
}),
layers.tcso2 = layer.instance({
    ident: "tcso2",
    c: colors.so2,
    m: metrics.so2,
    renderParams: {
        coverWholeMap: true
    },
    levels: ["total"],
    // m: t.no2,
    transformR: discretepow2
}),
layers.o3 = layer.instance({
    ident: "o3",
    c: colors.o3,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.o3,
    transformR: discretepow2
}),
layers.co = layer.instance({
    ident: "co",
    c: colors.co,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.co,
    transformR: discretepow2
}),
layers.duaod550 = layer.instance({
    ident: "duaod550",
    filename: "duaod550",
    coverWholeMap: false,
    c: colors.aod550,
    m: metrics.aod550,
    transformR: discretepow2
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
}),
layers.aqimet = layer.instance({
    ident: "aqimet",
    filename: "aqimet",
    dataQuality: "discrete",
    renderParams: {
        coverWholeMap: true
    },
    c: colors.aqimet,
    m: metrics.aqimet

}),

layers.aqieu = layer.instance({
    ident: "aqieu",
    filename: "aqieu",
    dataQuality: "discrete",
    renderParams: {
        coverWholeMap: true
    },
    c: colors.aqieu,
    m: metrics.aqieu

}),

layers.aqiusa = layer.instance({
    ident: "aqiusa",
    filename: "aqiusa",
    dataQuality: "discrete",
    renderParams: {
        coverWholeMap: true
    },
    c: colors.aqiusa,
    m: metrics.aqiusa

}),
layers.aqimpol = layer.instance({
    ident: "aqimpol",
    filename: "aqimpol",
    dataQuality: "discrete",
    renderParams: {
        coverWholeMap: true
    },
    c: colors.aqimpol,
    m: metrics.aqimpol

}),
layers.olive =layer.instance({
    ident: "olive",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
layers.alder =layer.instance({
    ident: "alder",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
layers.birch =layer.instance({
    ident: "birch",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
layers.grass =layer.instance({
    ident: "grass",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
layers.mugwort =layer.instance({
    ident: "mugwort",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
layers.ragweed =layer.instance({
    ident: "ragweed",
    c: colors.pollen,
    renderParams: {
        coverWholeMap: true
    },
    m: metrics.pollen,
    // transformR: function(e) {
    //     return Math.pow(2, e) - .001
    // }
    transformR: discretepow2
}),
// s.gh = n.instance({
//     ident: "gh",
//     m: t.gh
// }),
layers.map = layer.instance({
    ident: "map",
    renderer: "map"
})
// s.efiWind = n.instance({
//     ident: "efiWind",
//     filename: "wsi",
//     renderer: "efi",
//     c: e.efiWind,
//     m: t.efiWind
// }),
// s.efiTemp = n.instance({
//     ident: "efiTemp",
//     filename: "ti",
//     renderer: "efi",
//     c: e.efiTemp,
//     m: t.efiTemp
// }),
// s.efiRain = n.instance({
//     ident: "efiRain",
//     filename: "tpi",
//     renderer: "efi",
//     c: e.efiRain,
//     m: t.efiRain
// }),
// s.fires = n.instance({
//     ident: "fires",
//     filename: "frpfire",
//     c: e.fires,
//     m: t.fires,
//     transformR: function(e) {
//         return Math.pow(2, e) - 1e-4
//     }
// }),
export default layers;

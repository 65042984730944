import store from "./store";
import broadcast from "./broadcast";
import Evented from "./Evented";


class Animation {

    constructor() {
        // Tiempo que tarda en cambiar de un timestamp a otro
        this.timeStep=400;
        // Tiempo de espera una vez ha cambiado de timestamp
        this.timeSleep=500;

        // Tiempo acumulado desde que finalizó el timesleep
        this.acumTime=0;
        // Si se han renderizado o no todas las teselas de un timestamp
        this.redrawFinished = false;

        this.playing = false;

        this.evented=Evented.instance();

        broadcast.on("redrawFinished", function () {
            this.redrawFinished = true;

        }.bind(this));

    }

    getEvented(){
        return this.evented;
    }

    getCalendar(){
        return store.get("calendar");
    }

    getTimestamp(){
        return store.get("timestamp");
    }

    setTimestamp(timestamp){
        return store.set("timestamp", timestamp);
    }

    stepAnimation(timestamp){

        const calendar = this.getCalendar();
        // Identificador de la animación
        this.frameID = requestAnimationFrame(this.stepAnimation.bind(this));

        // Si no se ha inicializado el timestamp previo
        if (! this.prevtimestamp)
            this.prevtimestamp = timestamp;

        // Tiempo transcurrido desde que se pintó el último frame
        let timeElapsed = timestamp - this.prevtimestamp;

        // Si se ha cambiado de timestamp tiene que pasar 500 ms y haberse renderizado todas las teselas
        // Si no se ha cambiado de timestamp se se notifica a los listeners cuando han pasado más de 30 ms
        if ( this.waiting && timeElapsed > this.timeSleep && this.redrawFinished ||  ! this.waiting && timeElapsed >= 15)
        {
            this.acumTime += timeElapsed;

            if (this.loading){
                this.loading = false;
                this.evented.emit("timeAnimationLoading", false);
            }

            if (this.waiting) {
                this.waiting = false;
                this.acumTime = 0;
                timeElapsed = 0;
            }

            // Se actualiza el timestamp previo
            this.prevtimestamp = timestamp;

            const nextTs = calendar.nextBestTs(this.actualTs);

            const fakeTs = this.actualTs + this.acumTime / (this.timeStep) * (nextTs - this.actualTs);
            if (fakeTs < nextTs) {
                store.set("fakeTimestamp", fakeTs);
            }


            const indxBestTs = calendar.getIdxCurrentTs();
            const currentTs = calendar.timestamps[indxBestTs];

            if (this.acumTime > this.timeStep){

                if (this.futuresteps && (nextTs === this.actualTs) || !this.futuresteps && (nextTs > currentTs)) {
                    const indexend = this.futuresteps ? (calendar.timestamps.length - 1) : indxBestTs;
                    if (this.loopsteps > 0)
                        this.actualTs = calendar.timestamps[indexend - this.loopsteps];
                    else
                        this.actualTs = calendar.timestamps[0];
                }else
                    this.actualTs = nextTs;

                this.waiting = true;
                this.redrawFinished = false;

                this.setTimestamp(this.actualTs);
            }
            else if (this.futuresteps && this.actualTs !== calendar.end || !this.futuresteps && (nextTs <= currentTs)) {
                this.evented.emit("timeAnimationElapsed", timeElapsed / (this.timeStep - 100));
            }

        }else if (this.waiting && timeElapsed > 600){
            this.loading = true;
            this.evented.emit("timeAnimationLoading", true);
        }

    }

    startAnimation(loopsteps, futuresteps){
        if (!this.playing) {
            this.loopsteps = loopsteps;
            this.actualTs = this.getTimestamp();
            this.playing = true;
            this.futuresteps= futuresteps;
            this.evented.emit("timeAnimationStart");
            this.stepAnimation(0);
        }

    }

    pauseAnimation(){
        if (this.frameID) {
            console.info ("cancelando frame")
            cancelAnimationFrame(this.frameID);
            this.frameID = null;
        }
    }

    stopAnimation(){
        if (this.frameID) {
            cancelAnimationFrame(this.frameID);
            this.frameID = null;
        }
        if (this.playing){
            this.prevtimestamp = 0;
            this.playing=false;
            this.evented.emit("timeAnimationStop");
        }

    }

    on(nameEvent, func){
        this.evented.on(nameEvent, func)
    }


}

export default Animation;

import product from "./Product";
import $ from "./$"
import calendar from "./Calendar";
import http from "./http";
import store from "./store";
import utils from "./utils";
import RadarCalendar from "../plugins/radar/RadarCalendar";
import scope from "./rootScope";

// define("products", ["Product", "StaticProduct", "Calendar", "$", "rootScope", "radar", "satellite"], function(product, t, n, i, s, a, r) {
var products = {},
    // , ecmwfMSG = "© 2016 ECMWF: This service is based on data and products of the European Centre for Medium-range Weather Forecasts";
    year = new Date().getFullYear().toString(),
    ecmwfMSG ="ECMWF © "+year+" Meteored",
    camsMSG ="CAMS © "+year+" Meteored";
// , c = "© Generated using Copernicus Atmosphere Monitoring Service Information [2019]. Neither the European Commission nor ECMWF is responsible for any use of this information."
// , d = '<a href="https://atmosphere.copernicus.eu/" target="_blank"><img style="max-width:150px;height:auto;" src="img/providers/copernicus-white.svg" /></a>';
products.ecmwf = product.instance({
    ident: "ecmwf",
    // directory: "ecmwf-hres",
    directory: "cep010",
    modelName: "ECMWF",
    modelResolution: 9,
    provider: "ECMWF",
    interval: 720,
    copy: ecmwfMSG,
    dataQuality: "normal",
    betterDataQuality: ["precip", "nieve", "prcnub", "nubes", "lclouds", "mclouds", "hclouds", "cbase", "acumnieve", "acumprec", "snowcover", "ptype", "tempmar", "wind", "snowdepth"],
    // levels: ["surface", "1000", "950", "925", "900", "850", "800", "700", "600", "500", "400", "300", "250", "200", "150"],
    levels: ["surface", "1000", "925",  "850", "700", "500", "300" ],
    overlays: ["snowcover", "nieve", "prcnub", "viento", "temp2m", "atemp", "pressure", "uvi",  "nubes", "lclouds", "mclouds", "hclouds", "humedad", "rachas", "waterprec", "cbase", "cape", "dewpoint", "precip", "visibilidad", "isozero", "cloudtop", "rayos", "thunder", "acumnieve", "acumprec", "ptype", "tempmar", "gustAccu", "ivt", "snowdepth", "warnings", "symbols", "daysymbols"],
    acTimes: ["next12h", "next24h", "next3d", "next5d", "next10d"], //acumulados
    // isolines: ["pressure", "gh", "temp", "deg0"],
    isolines:["pressure"],
    symbols: true,
    _init:  function() {
        product._init.call(this);

        this.loadMinifest().then(
            (response) => {
                this.minifest = response.data;
                this.calendar = calendar.instance({
                    numOfHours: this.forecastSize,
                    minifestFile: response.data
                });

                store.setDefault("calendar", this.calendar);
                store.setDefault("path", this.calendar.ts2path(Date.now()));

                this.setExpireTime();
            }
        );
    },

    createBackupMinifest: function() {

        const nowTs = Date.now();
        // Se resta 12 horas a la fecha actual y se coge la pasada más cercana
        const lastTs = nowTs - 12 * 3600 * 1000 - ( (nowTs - 12 * 3600 * 1000) % ( 12 * 3600 * 1000 ) );

        const dateRef = new Date(lastTs);

        this.minifest = {
            note: "!!!This is automatically generated fallback minifest!!!",
            ref: dateRef.toISOString(),
            dst: [[1, 1, 90], [3, 93, 144]]
        };
        this.calendar = calendar.instance({
            numOfHours: this.forecastSize,
            minifestFile: this.minifest
        });
    }
}),
    products.ecmwfEns = product.instance({
        ident: "ecmwfEns",
        provider: "ECMWF",
        interval: 720,
        // copy: c,
        copy: ecmwfMSG,
        PNGtransparency: true,
        // imVersion: 3,
        modelName: "ECMWF ENS",
        levels:["surface"],
        modelResolution: 40,
        fileSuffix: "png",
        directory: "ens020",
        dataQuality: "normal",
        overlays: ["prob"],
        acTimes: [],
        isolines: [],
        labelsTemp: false,
        symbols: true
        // logo: d
    }),
    products.cams = product.instance({
        ident: "cams",
        provider: "Copernicus",
        interval: 1440,
        // copy: c,
        copy: camsMSG,
        PNGtransparency: true,
        // imVersion: 3,
        modelName: "CAMS",
        levels:["surface"],
        modelResolution: 40,
        fileSuffix: "png",
        directory: "cams040",
        dataQuality: "low",
        overlays: ["o3", "duaod550", "pm2p5", "pm10", "no2", "so2", "tcso2", "co", "aqimet", "aqieu", "aqiusa"],
        acTimes: [],
        isolines: [],
        labelsTemp: false,
        symbols: true
        // logo: d
    }),
    products.camsEu = product.instance({
        ident: "camsEu",
        provider: "Copernicus",
        interval: 1440,
        copy: camsMSG,
        PNGtransparency: true,
        // imVersion: 3,
        modelName: "CAMS EU",
        modelResolution: 10,
        fileSuffix: "png",
        directory: "cams010",
        bounds: {
            west: -25,
            east: 45,
            north: 70,
            south: 30
        },
        // maxTileZoom: 3,
        dataQuality: "normal",
        levels: ["surface"],
        overlays: ["o3", "pm2p5", "pm10", "no2", "so2", "co", "olive", "alder", "birch", "grass", "mugwort", "ragweed", "aqimet", "aqieu", "aqiusa", "aqimpol"],
        acTimes: [],
        isolines: [],
        labelsTemp: false,
        symbols: true,
        availability: {
            "aqimpol" : {
                start: { month: 1, day: 1},
                end: { month: 9, day: 30}
            },
            "olive" : {
                start: { month: 1, day: 1},
                end: { month: 6, day: 30}
            },
            "alder" : {
                start: { month: 1, day: 1},
                end: { month: 6, day: 30}
            },
            "birch" : {
                start: { month: 3, day: 1},
                end: { month: 7, day: 31}
            },
            "grass" : {
                start: { month: 3, day: 1},
                end: { month: 7, day: 31}
            },
            "mugwort" : {
                start: { month: 6, day: 1},
                end: { month: 9, day: 30}
            },
            "ragweed" : {
                start: { month: 6, day: 1},
                end: { month: 9, day: 30}
            }
        }
        // logo: d
    }),
    //     o.camsGfas = e.instance({
    //         ident: "camsGfas",
    //         provider: "Copernicus",
    //         interval: 1440,
    //         copy: c,
    //         PNGtransparency: !0,
    //         imVersion: 3,
    //         modelName: "CAMS",
    //         modelResolution: 40,
    //         fileSuffix: "png",
    //         directory: "analysis/cams-gfas",
    //         dataQuality: "normal",
    //         levels: ["surface"],
    //         overlays: ["fires"],
    //         acTimes: [],
    //         isolines: [],
    //         labelsTemp: !1,
    //         logo: d
    //     }),
    //     products.wrfeur010 = product.instance({
    //         ident: "wrfeur010",
    //         provider: "NOAA",
    //         interval: 360,
    //         modelName: "WRF",
    //         modelResolution: 22,
    //         global: false,
    //         PNGtransparency: true,
    //         directory: "eur010",
    //         // maxTileZoom: 3,
    //         dataQuality: "normal",
    //         // betterDataQuality: ["rain", "clouds", "lclouds", "mclouds", "hclouds"],
    //         betterDataQuality: ["rain", "snow", "prcnub", "clouds", "lclouds", "mclouds", "hclouds", "cbase", "snowAccu", "rainAccu", "snowcover", "ptype", "sst", "wind"],
    //         // levels: ["surface", "100m", "975h", "950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"],
    //         levels: ["surface", "1000", "950", "925", "900", "850", "700", "600", "500", "400", "300"],
    //         // overlays: ["wind", "temp", "pressure", "clouds", "rh", "gust", "dewpoint", "rain", "lclouds", "mclouds", "hclouds", "snowAccu", "rainAccu", "ptype", "gustAccu", "cape"],
    //         overlays: ["wind", "temp"],
    //         acTimes: ["next12h", "next24h", "next3d", "next5d", "next10d"],
    //         bounds: {
    //             west: -20.714,
    //             east: 26.476,
    //             north: 63.779,
    //             south: 23.879
    //         }
    //         // isolines: ["pressure", "gh", "temp"]
    //     }),
    products.gfs = product.instance({
        ident: "gfs",
        provider: "NOAA",
        interval: 360,
        modelName: "GFS",
        modelResolution: 22,
        directory: "gfs1h025",
        // maxTileZoom: 3,
        dataQuality: "low",
        betterDataQuality: ["precip", "nubes", "lclouds", "mclouds", "hclouds"],
        // levels: ["surface", "100m", "975h", "950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"],
        levels: ["surface", "1000", "950", "925", "900", "850", "700", "600", "500", "400", "300"],
        overlays: ["viento", "temp2m", "pressure", "nubes", "humedad", "rachas", "dewpoint", "precip", "nieve", "prcnub", "lclouds", "mclouds", "hclouds", "acumnieve", "acumprec", "ptype", "gustAccu", "cape", "visibilidad", "uvi"],
        acTimes: ["next12h", "next24h", "next3d", "next5d", "next10d"]
        // isolines: ["pressure", "gh", "temp"]
    }),
    //     o.iconEu = e.instance({
    //         ident: "iconEu",
    //         provider: "DWD",
    //         interval: 360,
    //         modelName: "ICON",
    //         modelResolution: 7,
    //         JPGtransparency: !0,
    //         animation: !0,
    //         dataQuality: "high",
    //         betterDataQuality: ["rain", "clouds", "lclouds", "mclouds", "hclouds"],
    //         directory: "icon-eu",
    //         forecastSize: 144,
    //         bounds: {
    //             west: -23,
    //             east: 44,
    //             north: 70,
    //             south: 30
    //         },
    //         levels: ["surface", "950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"],
    //         overlays: ["snowcover", "wind", "temp", "pressure", "clouds", "lclouds", "mclouds", "hclouds", "rh", "gust", "cape", "dewpoint", "rain", "deg0", "snowAccu", "rainAccu", "ptype", "gustAccu"],
    //         acTimes: ["next12h", "next24h", "next2d", "next3d", "next5d"],
    //         isolines: ["pressure", "gh", "temp"]
    //     }),
    // products.arome = product.instance({
    //     ident: "arome",
    //     provider: "MF",
    //     interval: 360,
    //     modelName: "AROME",
    //     modelResolution: 2,
    //     PNGtransparency: true,
    //     // animation: true,
    //     dataQuality: "ultra",
    //     betterDataQuality: [],
    //     directory: "arome0025",
    //     labelsTemp: false,
    //     forecastSize: 72,
    //     JPGtransparency: true,
    //     bounds: {
    //         west: -8,
    //         east: 12,
    //         north: 53,
    //         south: 38
    //     },
    //     levels: ["surface", "1000", "950", "925", "900", "850", "700", "600", "500", "400", "300"],
    //     // overlays: ["wind", "temp", "clouds", "lclouds", "mclouds", "hclouds", "rh", "gust", "cape", "dewpoint", "rain", "ptype"],
    //     overlays: ["viento","temp2m","rachas","humedad","nubes","precip", "nieve", "prcnub", "snowAccu", "acumprec"]//,
    //     // acTimes: [],
    //     // isolines: []
    // }),
    //     o.iconGlobal = e.instance({
    //         ident: "iconGlobal",
    //         provider: "DWD",
    //         interval: 360,
    //         modelName: "ICON",
    //         modelResolution: 13,
    //         labelsTemp: !1,
    //         dataQuality: "normal",
    //         directory: "icon-global",
    //         forecastSize: 144,
    //         overlays: ["fog"],
    //         levels: ["surface"]
    //     }),
    //     o.nems = e.instance({
    //         ident: "nems",
    //         modelName: "NEMS",
    //         modelResolution: 4,
    //         provider: "Meteoblue.com",
    //         interval: 720,
    //         JPGtransparency: !0,
    //         animation: !0,
    //         dataQuality: "high",
    //         betterDataQuality: ["rain", "clouds"],
    //         directory: "mbeurope",
    //         labelsTemp: !1,
    //         forecastSize: 72,
    //         bounds: {
    //             west: -19,
    //             east: 33,
    //             north: 57,
    //             south: 33
    //         },
    //         levels: ["surface", "975h", "950h", "925h", "900h", "850h"],
    //         overlays: ["wind", "temp", "clouds", "rh", "gust", "dewpoint", "rain"],
    //         logo: '<a class="mobilehide" href="https://www.meteoblue.com/" target="_blank">NEMS4 model by <img style="max-width:90px;height:auto;" src="img/logo-mb.svg" /></a>'
    //     }),
    //     o.mblue = t.instance({
    //         ident: "mblue",
    //         modelName: s.isMobile ? "MBLUE" : "METEOBLUE"
    //     });
    // var u = e.extend({
    //     provider: "NOAA",
    //     interval: 360,
    //     modelName: "NAM",
    //     dataQuality: "high",
    //     betterDataQuality: ["rain", "clouds", "lclouds", "hclouds", "mclouds"],
    //     JPGtransparency: !0,
    //     forecastSize: 72,
    //     animationSpeed: 2e3,
    //     levels: ["surface", "975h", "950h", "925h", "900h", "850h", "800h", "700h", "600h", "500h", "400h", "300h", "250h", "200h", "150h"],
    //     overlays: ["wind", "temp", "clouds", "rh", "gust", "pressure", "dewpoint", "rain", "lclouds", "hclouds", "mclouds", "snowAccu", "rainAccu", "ptype", "gustAccu"],
    //     acTimes: ["next12h", "next24h", "next48h", "next60h"],
    //     isolines: ["pressure", "temp"]
    // });
    // return o.namConus = u.instance({
    //     ident: "namConus",
    //     modelResolution: 5,
    //     directory: "nam-conus",
    //     bounds: {
    //         west: -137,
    //         east: -55,
    //         north: 53,
    //         south: 20
    //     }
    // }),
    //     o.namHawaii = u.instance({
    //         ident: "namHawaii",
    //         modelResolution: 3,
    //         directory: "nam-hawaii",
    //         bounds: {
    //             west: -167,
    //             east: -147,
    //             north: 30,
    //             south: 14
    //         }
    //     }),
    //     o.namAlaska = u.instance({
    //         ident: "namAlaska",
    //         modelResolution: 6,
    //         directory: "nam-alaska",
    //         bounds: {
    //             west: -179,
    //             east: -129,
    //             north: 80,
    //             south: 53
    //         }
    //     }),
    products.ecmwfWaves = product.instance({
        ident: "ecmwfWaves",
        modelName: "ECMWF WAM",
        modelResolution: 13,
        provider: "ECMWF",
        interval: 720,
        copy: ecmwfMSG,
        labelsTemp: false,
        symbols: true,
        directory: "wam012",
        fileSuffix: "png",
        dataQuality: "normal",
        // overlays: ["waves", "swell1", "swell2", "swell3", "wwaves"],
        overlays: ["oleajetotal", "oleajeviento", "oleajefondo", "oleajealtura"],
        levels: ["surface"]
    });


    products.radar=product.instance({
        ident: "radar",
        // animation: false,
        modelName: "",
        interval: 3,
        directory: "radar",
        dataQuality: "top",
        // server: "https://services-{s}.meteored.com",
        // server: "https://test-services.meteored.com",
        // pathGenerator: "{server}/{directory}/{path}/257w<z>/<y>/<x>/{filename}-{level}.{fileSuffix}",
        // pathGenerator: "{server}/img/maps/{directory}/<z>/<x>/<y>/{path}_{filename}.{fileSuffix}",
        // pathGenerator: "https://pangea.ogimet.com/cgi-bin/teselas_otf?model={directory}&param={filename}&z=<z>&x=<x>&y=<y>&ts={path}&tipo=datos&format={fileSuffix}",
        pathGenerator: "{server}/img/tiles/viewer/{directory}/<z>/<x>/<y>/{path}_reflectividad.{fileSuffix}",
        labelsTemp: false,
        overlays: ["radar"],
        levels: ["surface"],
        open: function() {

            this.loadMinifest()
            return Promise.resolve();
        },
        loadMinifest: function() {
            const server = this.server || scope.server;
        //     // return http.get(this.server + "/" + this.directory + "/" + this.directory + ".json?" + this.getTimeFrag())
        //     return http.get(this.server + "/" + this.directory + "/" + this.directory + ".json?" + this.getTimeFrag())
        //     return http.get("https://pangea.ogimet.com/imagenes.meteored.com/teselasdatos/radar/minifest.json");
        //     return http.get(this.server + "/cgi-bin/radar_otf?model=radar&list=minifest");
            return http.get(utils.template(server + "/web/tiles/viewer/v2/"+ this.directory + ".json?" + this.getTimeFrag(), {s : 'a'}))
        }
        ,
        moveTs: function(e) {
            let timestamps = this.calendar.timestamps.past
                , radarTimestamp = store.get("radarTimestamp")
                , lastIndex = timestamps.length - 1
                , selectedTimestamp = timestamps.find(
                    function(ts) {
                        return ts >= radarTimestamp
                    }
                );
            if (void 0 === selectedTimestamp)
                selectedTimestamp = timestamps[lastIndex];

            const index = utils.bound(timestamps.indexOf(selectedTimestamp) + (e ? 1 : -1), 0, lastIndex);
            store.set("radarTimestamp", timestamps[index])
        }

    });

products.satellite=product.instance({
    ident: "satellite",
    // animation: false,
    modelName: "",
    interval: 3,
    directory: "satellite",
    fileSuffix: "jpg",
    // server: "https://services-{s}.meteored.com",
    // dataQuality: "top",
    // server: "https://rdr.windy.com",
    // pathGenerator: "{server}/{directory}/{path}/257w<z>/<y>/<x>/{filename}-{level}.{fileSuffix}",
    // pathGenerator: "{server}/img/maps/{directory}/<z>/<x>/<y>/{path}_{filename}.{fileSuffix}",
    // pathGenerator: "https://pangea.ogimet.com/cgi-bin/teselas_otf?model={directory}&param={filename}&z=<z>&x=<x>&y=<y>&ts={path}&tipo=datos&format={fileSuffix}",
    // pathGenerator: "https://test-services.meteored.com/cgi-bin/sat_otf?z=<z>&x=<x>&y=<y>&ext={fileSuffix}&coastlines=false&type={filename}&utime={path}", //res=2
    pathGenerator: "{server}/img/tiles/viewer/satellite/<z>/<x>/<y>/{path}_{filename}{res}.{fileSuffix}",
    // pathGenerator: "https://services.meteored.com/img/tiles/satelites/<z>/<x>/<y>/{path}_{filename}{res}.{fileSuffix}",

    labelsTemp: false,
    overlays: ["ir", "vi", "rgb", "wv"],
    levels: ["surface"],
    open: function() {

        this.loadMinifest()
        return Promise.resolve();
    },
    loadMinifest: function() {
        const server = this.server || scope.server;
        //     // return http.get(this.server + "/" + this.directory + "/" + this.directory + ".json?" + this.getTimeFrag())
        //     return http.get(this.server + "/" + this.directory + "/" + this.directory + ".json?" + this.getTimeFrag())
        // return http.get( server + "/web/tiles/viewer/v2/satellite.json?"+ this.getTimeFrag());
        return http.get(utils.template(server + "/web/tiles/viewer/v2/satellite.json?" + this.getTimeFrag(), {s : 'a'}));
    }
    ,
    moveTs: function(e) {
        let timestamps = this.calendar.timestamps
            , satelliteTimestamp = store.get("satelliteTimestamp")
            , lastIndex = timestamps.length - 1
            , selectedTimestamp = timestamps.find(
            function(ts) {
                return ts >= satelliteTimestamp
            }
        );
        if (void 0 === selectedTimestamp)
            selectedTimestamp = timestamps[lastIndex];

        // const index = utils.bound(timestamps.indexOf(selectedTimestamp) + (e ? 1 : -1), 0, lastIndex);
        const index = utils.bound(timestamps.indexOf(selectedTimestamp) + e, 0, lastIndex);
        store.set("satelliteTimestamp", timestamps[index])
    }

});



//     o.gfsWaves = e.instance({
    //         ident: "gfsWaves",
    //         modelName: "Wavewatch 3",
    //         modelResolution: 22,
    //         provider: "NOAA",
    //         interval: 360,
    //         labelsTemp: !1,
    //         directory: "wwatch",
    //         fileSuffix: "png",
    //         dataQuality: "low",
    //         overlays: ["waves", "swell1", "swell2", "wwaves"],
    //         levels: ["surface"]
    //     }),
    //     o.sea = t.instance({
    //         ident: "sea",
    //         animation: !1,
    //         modelName: "NESDIS",
    //         modelResolution: 22,
    //         provider: "NOAA",
    //         labelsTemp: !1,
    //         interval: 2880,
    //         dataQuality: "low",
    //         overlays: ["currents"],
    //         levels: ["surface"],
    //         pathGenerator: "{server}/sst/latest/257w<z>/<y>/<x>/{filename}.png"
    //     }),
    //     o.geos5 = e.instance({
    //         ident: "geos5",
    //         modelName: "NASA GEOS-5",
    //         modelResolution: 22,
    //         labelsTemp: !1,
    //         provider: "NASA",
    //         interval: 3240,
    //         directory: "nasa-chem",
    //         dataQuality: "normal",
    //         overlays: ["cosc", "dustsm", "so2sm"],
    //         levels: ["surface"]
    //     }),
    //     o.radar = a,
    //     o.satellite = r,
    //     o.capAlerts = t.instance({
    //         ident: "capAlerts",
    //         productReady: !0,
    //         labelsTemp: !1,
    //         modelName: "CAP Alerts",
    //         interval: 0,
    //         provider: "National weather institutes",
    //         overlays: ["capAlerts"]
    //     }),
    //     o.map = t.instance({
    //         ident: "map",
    //         productReady: !0,
    //         labelsTemp: !1,
    //         modelName: "Outdoor Map",
    //         interval: 0,
    //         provider: "Seznam.cz",
    //         overlays: ["map"]
    //     }),
    //     o.efi = e.instance({
    //         ident: "efi",
    //         provider: "ECMWF",
    //         interval: 720,
    //         copy: l,
    //         imVersion: 3,
    //         modelName: "ECMWF",
    //         modelResolution: 9,
    //         labelsTemp: !1,
    //         directory: "forecast/ecmwf-efi",
    //         dataQuality: "normal",
    //         levels: ["surface"],
    //         overlays: ["efiWind", "efiTemp", "efiRain"]
    //     }),
export default products;

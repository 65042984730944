import scope from "./rootScope";
import render from "./render";
import utils from "./utils";
import loader from "./dataLoader";
import renderTile from "./renderTile";
import products from "./products";
import store from "./store";
import test from "./performanceTest";
import broadcast from "./broadcast";


export default L.TileLayer.extend({
    latestParams: {},
    options: {
        async: 1,
        maxZoom: 11,
        updateWhenIdle: scope.isMobileOrTablet,
        updateWhenZooming: 0,
        keepBuffer: scope.isMobileOrTablet ? 1 : 4,
        disableTransformForTiles: 1,
        subdomains: 'abc',
    },
    syncCounter: 0,
    inMotion: 0,
    hasSea: 0,
    coverWholeMap: false,
    className: "overlay-layer",
    onAdd: function(map) {

        if (products[this.product].bounds){
            var map = this._map
                , bounds = map.getBounds();
            var insidebounds =
                products[this.product].pointIsInBounds(bounds.getSouthWest()) &&
                products[this.product].pointIsInBounds(bounds.getNorthEast());

            if (this.insideBounds !== insidebounds) {
                this.insideBounds = insidebounds;
            }

        }

        render.emit("updateBasemap", {coverWholeMap: this.coverWholeMap, hasSea: this.hasSea, insideBounds: this.insideBounds});

        // console.info("onadd");
        L.GridLayer.prototype.onAdd.call(this, map),
            map.on("movestart", this.onMoveStart, this),
            map.on("moveend", this.onMoveEnd, this),
            this.on("load", this.checkLoaded, this);
    },
    onRemove: function(map) {
        map.off("movestart", this.onMoveStart, this),
            map.off("moveend", this.onMoveEnd, this),
            this.off("load", this.checkLoaded, this),
            L.GridLayer.prototype.onRemove.call(this, map),
            this.hasSea = 0,
            document.body.classList.remove("sea"),
            render.emit("toggleSeaMask", this.hasSea)
    },
    onMoveStart: function() {
        this.inMotion = 1
    },
    onMoveEnd: function() {
        this.inMotion = 0,
        this._loading || this.redrawFinished()
    },
    checkLoaded: function() {
        this.inMotion || this.redrawFinished()
    },

    redrawLayer: function() {
        var map = this._map
            , bounds = map.getPixelBounds()
            , TLPoint = bounds.min
            , BRPoint = bounds.max
            , TLcoords = TLPoint.divideBy(256)._floor()
            , BRcoords = BRPoint.divideBy(256)._floor()
            , boundTiles = L.bounds(TLcoords, BRcoords)
            , zoom = Math.round(map.getZoom());

        // console.info("redrawlayer");

        if (zoom > 11)
            this.redrawFinished();
        else {
            this.removeOtherTiles(zoom, boundTiles);
            var coords = this.sortTilesFromCenterOut(boundTiles);
            this._tilesToLoad = coords.length;
            for (var i = 0; i < coords.length; i++) {
                var coordTile = coords[i]
                    , key = this._tileCoordsToKey(coordTile);
                if(key in this._tiles)
                    this.redrawTile(this._tiles[key])
                else
                    --this._tilesToLoad || this.redrawFinished()   // Si llega a 0 llama a redrawFinished
            }
        }
    },
    // Borra las teselas que se salen de los límites
    removeOtherTiles: function(zoom, boundTiles) {
        var min = boundTiles.min
            , max = boundTiles.max;
        for (var tilekey in this._tiles) {
            var a = tilekey.split(":")
                , x = a[0]
                , y = a[1];
            (+a[2] !== zoom || +x < min.x || +x > max.x || +y < min.y || +y > max.y) && this._removeTile(tilekey)
        }
    },
    // Repinta la tesela
    redrawTile: function(tile) {
        var layercanvas = this;
        tile.coords = this._wrapCoords(tile.coords);
        var url = this.getTileUrl(tile.coords);
        var whichTile = render.whichTile(tile.coords, this.latestParams)
            , syncCounter = this.syncCounter;

        whichTile.s = this._getSubdomain(tile.coords);

        loader.loadTile(whichTile).then(function(loader) {
            layercanvas.renderTile.call(layercanvas, test.getPerformance() === "low" ? 2 : 1, tile.el, syncCounter, whichTile, loader, url);

            if ( --layercanvas._tilesToLoad === 0)
                layercanvas.redrawFinished();

        }).catch(console.error)
    },
    // Cuando se cambian los parámetros
    paramsChanged: function(params) {



        if(params.fullPath !== this.latestParams.fullPath || params.layer !== this.latestParams.layer)
        {


            this.latestParams = utils.clone(params);

            // bounds = map.getBounds()
            // products[this.latestParams.product].pointIsInBounds(map)

            if ( this.latestParams.sea !== this.hasSea ||
                this.latestParams.coverWholeMap !== this.coverWholeMap ||
                this.latestParams.product !== this.product ) {
                // var productname = this.latestParams.product;
                // var product = products[productname];

                if (this.latestParams.sea !== this.hasSea)
                    this.hasSea = this.latestParams.sea;

                if (this.latestParams.coverWholeMap !== this.coverWholeMap)
                    this.coverWholeMap = this.latestParams.coverWholeMap;

                if (this.latestParams.product !== this.product)
                    this.product = this.latestParams.product;

                if (products[this.product].bounds){
                    var map = this._map
                        , bounds = map.getBounds();
                    var insidebounds =
                        products[this.product].pointIsInBounds(bounds.getSouthWest()) &&
                        products[this.product].pointIsInBounds(bounds.getNorthEast());

                    if (this.insideBounds !== insidebounds) {
                        this.insideBounds = insidebounds;
                    }

                }

                render.emit("updateBasemap", {coverWholeMap: this.coverWholeMap, hasSea: this.hasSea, insideBounds: this.insideBounds});
            }

            this.syncCounter++;
            this.redrawLayer()
        }
    },
    // Ordena las teselas de menor a mayor distancia al centro
    sortTilesFromCenterOut: function(boundTiles) {
        var y, x, coordsTiles = [], center = boundTiles.getCenter(), zoom = this._tileZoom;
        for (y = boundTiles.min.y; y <= boundTiles.max.y; y++)
            for (x = boundTiles.min.x; x <= boundTiles.max.x; x++) {
                var coord = new L.Point(x,y);
                coord.z = zoom,
                    coordsTiles.push(coord)
            }
        coordsTiles.sort(function(elem1, elem2) {
            return elem1.distanceTo(center) - elem2.distanceTo(center)
        });
        return  coordsTiles;
    },
    redrawFinished: function() {
        render.emit("rendered", "tileLayer")
    },
    // Crea una tesela
    createTile: function(coords, done) {
        var layercanvas = this;
        coords = this._wrapCoords(coords);

        var url = this.getTileUrl(coords);
        // console.info("createTile");

        var whichTile = render.whichTile(coords, this.latestParams)   // Obtenemos coordenadas de tesela de datos
            , canvas = L.DomUtil.create("canvas")                     // Se crea el canvas sobre el que se va a pintar
            , syncCounter = this.syncCounter;

        whichTile.s = this._getSubdomain(coords);

        canvas.width = canvas.height = scope.isRetina ? 512 : 256;
        // canvas.width = canvas.height = 512;

        loader.loadTile(whichTile).then(function(loader) {

                canvas.style.width = canvas.style.height = "256px";
                layercanvas.renderTile.call(layercanvas, test.getPerformance() === "low" ? 2 : 1, canvas, syncCounter, whichTile, loader, url); // Con la tesela de datos cargada se renderiza la de colores

                done(void 0, canvas);
            }
        ).catch(console.error);

        return canvas   // Se devuelve la tesela renderizada
    },
    // _tileOnLoad: function(done, canvas, foretile){
    //     console.info("holaaaa");
    //     done(void 0, canvas)
    // },
    // _tileOnError: function(done, canvas, foretile){
    //     console.info("adiosss");
    //     done(void 0, canvas)
    // },
    // _onMoveEnd: function () {
    //     console.info("_onmoveend");
    //     L.GridLayer.prototype._onMoveEnd.call(this);
    // },

    _update: function (center) {

        // console.info("_update");

        var productname = this.latestParams.product;
        var product = products[productname];


        if ( product.bounds ) {
            var map = this._map
                , bounds = map.getBounds();
            var insidebounds =
                product.pointIsInBounds(bounds.getSouthWest()) &&
                product.pointIsInBounds(bounds.getNorthEast());

            if (this.insideBounds !== insidebounds) {
                this.insideBounds = insidebounds;
                render.emit("updateBasemap", {coverWholeMap: this.coverWholeMap, hasSea: this.hasSea, insideBounds: this.insideBounds});
                this.redrawLayer();
            }



            // console.info("Inside Bounds: " + insidebounds);
            // console.info(bounds);

        }
        L.GridLayer.prototype._update.call(this, center);
        // // console.info (bounds);
    },
    init: function(params) {
        this.latestParams = utils.clone(params);

        if (this.latestParams.sea !== this.hasSea)
            this.hasSea = this.latestParams.sea;

        if (this.latestParams.coverWholeMap !== this.coverWholeMap)
            this.coverWholeMap = this.latestParams.coverWholeMap;

        if (this.latestParams.product !== this.product)
            this.product = this.latestParams.product;

        const tileLayer = this;
        broadcast.on("urlFgChanged",
            function (url, noRedraw){
                tileLayer.setUrl(url, noRedraw);
            }
        );
        // changeBaseMap();
    },
    renderTile: renderTile
})

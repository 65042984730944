

class MessageView {

    private readonly msgdiv : HTMLElement | null;

    private activo: boolean = false;

    constructor(messageElement:HTMLElement) {
        this.msgdiv = messageElement;

        const msgview = this;

        document.addEventListener('mousedown', function(e) {
            if (msgview.activo) {
                const msgscreen = msgview.msgdiv;
                msgscreen?.classList.add("close");
                msgscreen?.classList.remove("open");
            }
        });

    }

    showText(message: string){

        const msgnode = this.msgdiv?.querySelector("#message");
        const textnode = document.createTextNode(message);

        while (msgnode?.firstChild) {
            msgnode?.removeChild(msgnode?.firstChild);
        }
        msgnode?.appendChild(textnode);

        this.msgdiv?.classList.remove("close");
        this.msgdiv?.classList.add("open");
        this.activo=true;
    }

}

export default MessageView;
